import React, {useRef, useEffect, useState} from "react";
import ChaptersMenu from "../components/ChaptersMenu";
import GalleryImage from "../components/GalleryImage";
import SubscribeForm from "../components/SubscribeForm";
import {pageTransitionDelay} from "../transitions/TransitionConstants";
import { Helmet } from 'react-helmet-async';

const Cliche = () => {
    const ref = useRef(null);

    let url = new URL(window.location.href);
    const hash = window.location.hash;
    const queryParams = new URLSearchParams(window.location.search);
    let queryLang = queryParams.get('lang');

    // REFACTOR - check for hash and param and do the logic
    if (!queryLang) {
        if (hash) {
            const params = new URLSearchParams(hash.substring(hash.indexOf("?")));

            if (!params.get('lang')) {
                queryLang = "EN";
            } else {
                queryLang = params.get('lang').toUpperCase();
            }
        } else {
            queryLang = "EN";
        }
    } else {
        queryLang = queryLang.toUpperCase();
    }

    const [language, setLanguage] = useState(queryLang);

    const changeLanguage = (lang) => {
        setLanguage(lang);
    }

    useEffect(() => {
        let currentLocation = window.location.href;
        const queryParams = new URLSearchParams(window.location.search);
        const queryLang = queryParams.get('lang');

        queryParams.set('lang', language);

        const queryString = queryParams.toString();
        let urlExtras = '?' + queryString;

        if (hash) {
            let hashOnly = hash.substring(0, hash.indexOf("?"));

            if (hashOnly === '') {
                hashOnly = hash;
            }

            setTimeout(function () {
                const anchorCommentId = `${hashOnly.substring(hashOnly.indexOf("#") + 1)}`;
                const anchorComment = document.getElementById(anchorCommentId);
                if(anchorComment){
                    anchorComment.scrollIntoView({ behavior: "smooth" });
                }
            }, pageTransitionDelay * 1000);

            urlExtras = hashOnly + urlExtras;
            history.replaceState(null, null, urlExtras);
        } else {
            history.pushState(null, null, urlExtras);
        }

    }, [ref, language]);

    const scrollToClick = (e) => {
        e.preventDefault();
        const target = (e.target.getAttribute("href").substring(1));
        const targetElement = document.getElementById(target);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: "smooth" });
        }
    }

    return (
        <>
            <Helmet>
                <title>Cliché Tales Blog</title>
            </Helmet>
            <div className="blog" ref={ref}>
                <section className="section hero no-padding">
                    <div className="hero__content">
                        <div className="hero__title-group">
                            <div className="blog__language-select">
                                <a className={`${language === "HU" ? 'active' : ''} blog__language-button`} onClick={() => { changeLanguage('HU') }}>Magyar</a>
                                <a className={`${language === "EN" ? 'active' : ''} blog__language-button`} onClick={() => { changeLanguage('EN') }}>English</a>
                            </div>
                            <h1 className="hero__title heading heading--1 align--center margin-xl--bottom">
                                {language === "HU"
                                    ? <>Klisé Misék</>
                                    : <>Cliche Tales</>
                                }
                            </h1>
                            <p className="margin-sm-xl--bottom">
                                {language === "HU"
                                    ? <>Menjen a scroll meg a swipe</>
                                    : <>Let's scroll and swipe</>
                                }
                            </p>
                            <a className="link link--underline margin-lg--top no-margin-sm" onClick={scrollToClick} href="#subscribe">
                                {language === "HU"
                                    ? <>Feliratkozás</>
                                    : <>Subscribe</>
                                }
                            </a>
                        </div>
                        <div className="hero__arrow">
                            <div className="hero__arrow-box">&nbsp;</div>
                        </div>
                    </div>
                </section>
                <div className="container container--700 blog__content">

                    <ChaptersMenu title={`${language === "HU" ? 'Fejezet választó' : 'Chapter Select'}`} menu={`${language === "HU" ? 'Fejezetek' : 'Chapters'}`} chapters={[
                        {id: 'khm', title: `${language === "HU" ? 'Irbod jene teso' : 'You sgould qrite bro'}`},
                        {id: 'extra', title: `${language === "HU" ? 'Adalék' : 'Extra'}`},
                        {id: 'ekam', title: `${language === "HU" ? 'Chocolate Damn' : 'Chocolate Damn'}`},
                        {id: 'dve', title: `${language === "HU" ? 'Chamundi Hill' : 'Chamundi Hill'}`},
                        {id: 'treeni', title: `${language === "HU" ? 'Kelvin' : 'Kelvin'}`},
                        {id: 'chatvaari', title: `${language === "HU" ? 'Osztály kirándulás' : 'Class trip'}`},
                        {id: 'pancha', title: `${language === "HU" ? 'Záróvonal' : 'Solid lines'}`},
                        {id: 'shat', title: `${language === "HU" ? 'Palota Dristi' : 'Palace Dristi'}`},
                        {id: 'sapta', title: `${language === "HU" ? 'A Guru és én - 1/3 Rész' : 'The Guru and I - Part 1/3'}`},
                        {id: 'astha', title: `${language === "HU" ? 'Ez ilyen lassú aerobik, nem?' : 'Is this like slow aerobics, right?'}`},
                        {id: 'nava', title: `${language === "HU" ? 'Végre péntek' : 'TGIF'}`},
                        {id: 'dasa', title: `${language === "HU" ? 'A Guru és én - 2/3 Rész' : 'The Guru and I - Part 2/3'}`},
                        {id: 'ekadasa', title: `${language === "HU" ? 'Rövidek' : 'Shorts'}`},
                        {id: 'dvadasa', title: `${language === "HU" ? 'A krikett ütő' : 'The cricket bat'}`},
                        {id: 'trayodasa', title: `${language === "HU" ? 'Ízek, Imák, Kegyelmet' : 'Eat, Pray, Bombs'}`},
                        {id: 'caturdasa', title: `${language === "HU" ? 'Mélység' : 'Depth'}`},
                        {id: 'pancadasa', title: `${language === "HU" ? 'Naplópó' : 'Bum diary'}`},
                        {id: 'sodasa', title: `${language === "HU" ? 'A Kigyóisten - 1/3 Rész' : 'The Snake God - Part 1/3'}`},
                        {id: 'saptadasa', title: `${language === "HU" ? 'A Kigyóisten - 2/3 Rész' : 'The Snake God - Part 2/3'}`},
                        {id: 'astadasa', title: `${language === "HU" ? 'A Kigyóisten - 3/3 Rész' : 'The Snake God - Part 3/3'}`},
                        {id: 'ekonavimsatih', title: `${language === "HU" ? 'A Guru és én - 3/3 Rész' : 'The Guru and I - Part 3/3'}`},
                        {id: 'vimsatih', title: `${language === "HU" ? 'Rövidítés az utolsóhoz' : 'Shortcut to the last one'}`},
                        {id: 'gallery', title: `${language === "HU" ? 'Galéria' : 'Gallery'}`},
                        {id: 'subscribe', title: `${language === "HU" ? 'Feliratkozás' : 'Subscribe'}`},
                    ]} />

                    <article id="khm" className="blog__article">
                        {language === "HU"
                            ? <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">Khm</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">Irbod jene teso</h2>
                                </hgroup>
                                <p>Yo, az van, hogy azon gondolkodtam minek nem csinálok valami tipikusat ha már Indiába jöttem, megint (ezt ilyen Forrest Gump-os “találkoztam az elnökkel megint” hangon olvasd).</p>
                                <p>Egy reggeli beszélgetés közben a spanom Natalie amúgy arra, hogy ő is, Kriszti is, meg én is úgy jöttünk ide, hogy előtte felmondtunk a munkahelyünkön azt mondta:</p>
                                <blockquote>Such a cliché group<span>Natalie the Wise</span></blockquote>
                                <p>Estére ezek a bölcs szavak annyit érlelődtek bennem, hogy elkezdtem ezt leírni, lesz ahogy lesz, meg aztán Andris is mondta, “Irbod jene teso” ami azt jelenti, hogy “Írnod kéne tesó”, csak ő ilyen ellenőrzés mentes egyből küldős bro. <b>Have fun.</b></p>
                            </>
                            : <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">Khm</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">You sgould qrite bro</h2>
                                </hgroup>
                                <p>Yo, so I was thinking, why don't I do something typical since I came to India again (read this in a Forrest Gump "and I met the president of the United States again" tone).</p>
                                <p>During a morning conversation, my buddy Natalie pointed out that she, Kriszti, and I came here after quitting our jobs; she noted:</p>
                                <blockquote>Such a cliché group<span>Natalie the Wise</span></blockquote>
                                <p>By the evening, these wise words had resonated with me, and I started writing this. It'll be what it'll be. Andris also texted, "You sgould qrite bro," which means "You should write, bro," just he's the send it without proofread type of bro. <b>Have fun.</b></p>
                            </>
                        }
                    </article>
                    <article id="extra" className="blog__article">
                        {language === "HU"
                            ? <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">Extra</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">Adalék</h2>
                                </hgroup>
                                <p>Másodszor vagyok Mysore-ban és először motorozok. India olyan mint egy sípálya (copyright to Hetti) csak ha az nem lenne elég, hogy mindenki összevissza halad, még szemből is jönnek néha. Ez azért sípályán bravúros lenne. Másfél hete már itt vagyunk és már lement 7 reggeli jóga gyakorlás Sharathjivel A GURU-val. Szintén lement úgy ezer masala dosa (tudod ha tudod).</p>
                                <p>Hungary-t képviselik ebben a szezonban István a pesti Bandha Works guru, Andris aki általában itt Andreas, Hetti akinek megmaradt a melója, Kriszti akivel most először beszéltünk úgy igazán és jó arc, Réka a másik magyar autorizált tanár aki itt többnyire spanyol, és Dia a feleség-bajtárs-védőnőm. Ja meg én.</p>
                                <p>Én egy kezdő gyakorló vagyok pár éve, nyomom ahogy tudom, amolyan jól van az úgy módszerrel, küzdök. Próbálok haverkodni itt az introvertált ember tengerben mindenfélekivel. Ez egy igazi melting pot, szó szerint mert elolvadsz olyan meleg van. Izzadtam már életemben sokat de szerencsére csak a mozgás, a 25+ fok és a csípős kaja triggerel, szóval itt az izzadás nagyjából non-stop. Amúgy ez jó mert van egy kis ünnepi túlsúly rajtam, és azt remélem az majd emiatt elfolyik valahova.</p>
                                <p>Ezek jutnak most így hirtelen eszembe de majd még mondom mi van ha meg nem unom, vagy ugye te. <b>Peace.</b></p>
                            </>
                            : <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">Extra</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">Supplement</h2>
                                </hgroup>
                                <p>I'm in Mysore for the second time, and it's my first time riding a motorcycle. India is like a ski slope (copyright to Hetti), except if that isn’t chaotic enough, people sometimes come at you from the opposite direction. That would be quite a stunt on a ski slope. We've been here for a week and a half, which means we've already been in seven morning yoga practice with Sharathji, the GURU. We've also shoved down about a thousand masala dosas (you know if you know).</p>
                                <p>Representing Hungary this season are István, the Bandha Works Guru in Budapest; Andris, or Andreas, as he's known here by some; Hetti, who managed to keep her job; Kriszti, whom I spoke to the first time here and she's cool; Réka, the other Hungarian authorized teacher who's mostly speaking Spanish here, and Dia, my wife/partner/nurse/besty. Oh, and me.</p>
                                <p>I’ve been a beginner practitioner for a couple of years, doing my best, following an "it's fine" method, struggling. I'm trying to socialise with everyone in this sea of introverted people. It's a real melting pot, quite literally, because you melt due to the heat. I've sweated a lot in my life, but fortunately, it's only triggered by movement, temperatures over 25 degrees Celsius, and spicy food. So here, sweating is pretty much non-stop. That actually might be a good thing because I have a little holiday weight, and I hope it will melt away somewhere.</p>
                                <p>These are the things that came to mind suddenly, but I'll tell you more if I don't get bored of it or if you don't get bored first. <b>Peace.</b></p>
                            </>
                        }
                    </article>
                    <article id="ekam" className="blog__article">
                        {language === "HU"
                            ? <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">एकम् Ekam</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">Chocolate Damn</h2>
                                </hgroup>
                                <p>István rám írt, hogy nincs e kedv valami kis nasihoz a Cocoa Vault-nál AKA Chocolate Man-nél (csokis ember), de mivel esik az eső csak majd mikor eláll. Mondom, de mehet. Ahogy eláll az eső felülök a motorra és a nyugatiak pergésének főútján lévő Ganesha templom előtti kereszteződésben, egyszer csak a motor lehal alattam.</p>
                                <p>Mondom mi a Patandjali van, mire látom hogy jó eséllyel az a baj, bár nem vagyok szakértő, és igazából ekkora motorra nincs jogsim se, meg amúgy még biciklit se szívesen szerelgetek, hogy nincs benzin. Mondom, de jó, és elkezdem tolni a mocit egészen az Itsy Bitsy/kékszínű bank ATM kanyarig (van piros színű bank is), majd szólok Istvánnak, hogy nem jó valami de megyek gyalog mert kell a sütim, megérdemlem.</p>
                                <p>Odaérek, pacsizunk (tiszteletteljesen persze), beverek egy tahinis sütit, és közben kitaláljuk, hogy egy literes víz meg István mocija pont jó, hogy vigyünk egy kis benyát a lehalt baby-nek. Csak persze elkezd szakadni az eső, olyan esőerdős/trópusi módon, you know. De mindegy, ez van. Amíg a másfél kilóméterre lévő Indiai Oil-ig értünk full bőrigáztunk. Ami talán egész szép viszont, hogy a benzinkutas (látszik rutinból) úgy tölti a literes Himalaya vizes flakont mint egy boss, egy csepp se megy mellé, nem lesz olyan a külseje, már-már meginnám olyan ízléses.</p>
                                <p>Fel a mocira, vissza a másikért. A vaksötét és a vízzel feltöltött Marianna árok méretű kátyuk megannyi vízes kis meglepivel szolgálnak, de amikor nem ez van akkor egy busz előz be minket azt nyakig lelocsol. Moci készen áll, beletöltöm szépen a Himalaya benzint, de ugye miért is indulna el.</p>
                                <p>Szerencsére egy tuk-tukos pont van még ott a sarkon, oda tolom a mocit és mondom neki hogy</p>
                                <blockquote>Szerencsétlen fehér gyíkember vagyok segítsél már szép testvér!<span>Zolly a Panel Proli</span></blockquote>
                                <p>Sitty-sutty mutatja is hogy kell beröfizni a motort ha ilyen van, el is indul faszán. Felpattanok rá de hát ugye csuromvízesen (még továbbra is ömlik a víz) ahogy rámarkolok a gázra elkezd elrohanni alólam a motor, amit csak valami égi segítséggel (amit valószínűleg Dia kért rám) tudok a fal előtt megállítani.</p>
                                <p>Kicsit a sípcsontomról a fékezés lekarcol egy darab bőrt emléknek (nyugi anyu semmi komoly), ami jobban vérzik mint kéne mert vizes a lábam de amúgy ok, mert lábon simán kihordom.</p>
                                <p>István itt komolyan aggódik mi lesz velem de elenged azzal a feltétellel, hogy írjak neki ha hazaérek, én meg mint egy gladiátor aki halálba megy köszöntöm és indulok. Hagyj ne mondjam, hogy kb 20 perc extra összevissza azt se tudom hol vagyok meg ez a rossz kanyar volt után, olyan 30 perc alatt értem haza, az egyébként 10 perces távon, miközben bőrigázva szorítottam azt a szart az életemért. <b>Élek, véga.</b></p>
                            </>
                            : <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">एकम् Ekam</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">Chocolate Damn</h2>
                                </hgroup>
                                <p>István messaged me, asking if I was up for a snack at Cocoa Vault, AKA Chocolate Man's place, but because it was raining only after it stopped. I told him hell yeah. As soon as the rain ceased, I hopped on my motorcycle, and as I arrived at the intersection in front of the Ganesha temple on the main road where Westerners usually be, the bike suddenly died on me.</p>
                                <p>I thought, "What the Patanjali?" Then I realised it's likely an issue of, though I'm no expert, and I don't even have a license for a bike this size, and I don't really enjoy tinkering even with bicycles, so I figured the issue is it's out of gas. I started pushing the bike to the Itsy Bitsy/blue bank ATM corner (there's also a red bank). I messaged István that something was wrong, but I'll walk because I needed my treat; I've earned it.</p>
                                <p>I arrived, fist bumped István (respectfully, of course), ordered a tahini cookie, and we figured out that a litre of water bottle and István's bike would be just right to take some gas for the stranded baby. Of course, the rain started pouring, tropical-style, you know. But it's all good, that's how it is. We went full throttle to the Indian Oil station about a mile away. What's pretty cool is that the gas guy (obviously it was not his first try) filled the litre-sized Himalaya water container like a boss; it was not a drop spilt, and it looked so classy you could almost drink it.</p>
                                <p>Back on the bike, we went to get the other one. The pitch-black, water-filled Mariana Trench-sized potholes offered many surprises, but a bus splashed us from head to toe when they weren’t the issue. The bike was ready, and I poured in the Himalayan gasoline, but of course, it wouldn't start.</p>
                                <p>Fortunately, a rikshaw was nearby, and I pushed the bike there, telling the driver:</p>
                                <blockquote>I'm a poor, clueless white lizard man; help me, brother.<span>Zolly the Lizard</span></blockquote>
                                <p>He quickly showed me how to start the bike in such cases, and it started smoothly. I hopped on, soaking wet (the rain kept pouring), and as I revved the throttle, the bike started rushing forward, slipping out from under me. Only with some divine help (probably requested by Dia) I managed to stop it just before hitting a wall.</p>
                                <p>The braking scraped a piece of skin from my shin as a souvenir (don't worry, Mom, nothing serious). It bled more than it should have because my leg was wet, but I was fine, as I could easily walk that off.</p>
                                <p>István was seriously worried about me, but he let me go on the condition that I'd message him when I got home. Like a gladiator who was about to die, I saluted him and left. After about 20 minutes of extra wandering around, not knowing where I was or how to get home, I made it in about 30 minutes to a location that's usually a 10-minute ride. I was soaked to the bone, clutching that bike for dear life. <b>I'm alive, the end.</b></p>
                            </>
                        }
                    </article>
                    <article id="dve" className="blog__article">
                        {language === "HU"
                            ? <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">द्वे Dve</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">Chamundi Hill</h2>
                                </hgroup>
                                <p>Van ez a hely itt Mysore-ban amit úgy hívnak Csamundi hegy ilyen magyarul. Vannak fent templomok, árusok, tehenek, lady boy-ok, majmok, kecskék kb minden.</p>
                                <p>Reggel gyaki után Andrissal meg Diával, meg pár kókusszal bennünk kitaláltuk csapassunk pár dosa-t mert még van hely. Most nagyon rákaptunk egy Paradise Hotel nevű helyre amit valami magyar szocreál de egyszer valaha biztos fancy hotelhez tudnék hasonlítani.</p>
                                <p>Csak mi vagyunk az étteremben hárman, mindig. Több igazából a személyzet, de az mondjuk kell is mert hát tudunk olyat kérdezni amit csak raj intelligenciával tudnak processzálni. Na mindegy is a lényeg, hogy Andrisnak 1-2 ghee-s dosa, Diának meg nekem 1-2 olaj-ghee-mentes dosa kell, ez az alap. Aztán néha más is kell mert we are hungry hungarians. A “néha” meg amúgy mindig, de ebbe most nem megyek bele, mert megdolgozunk érte, pont.</p>
                                <p>Miután bevertük a csípős kalória bombát Andreas bedobja hogy</p>
                                <blockquote>Nem megyünk fel a hegyre?<span>Mr Bimba</span></blockquote>
                                <p>Bármi jöhet ezen a ponton csak ne munka meg felelősség. Előbb eldobjuk a retek vízes büdös jóga gönceink haza és megindulunk. Ez egy kb 30-40 perces út keresztül az India-i megállás nélküli flúgos futamon, ahol hiába figyelsz mert annyit nem lehet. Itt hinni kell, elhiszed hogy átjutsz a körforgalmon, meg hogy eljutsz ahova akarsz. Shanti, shanti. Hit nélkül ez RIP.</p>
                                <p>A szerpentin a hegyre amúgy nice. Nem tűnik fontosnak de az érzés miatt ezt megpróbálom leírni: van egy kapu ahol át kell menni a parkolóba, 10 rúpia a vám. Mint egy pláza parkoló kb. Az van hogy ha motoron, biciklin, gyalog, elefánton vagy BÁRHOGY vagy, ha hagysz egy centi helyet BÁRMILYEN irányba oda biztosan kerül valaki/valami.</p>
                                <p>Na most gurulunk a fizetős kapu felé egy ilyen öt-hat motoros kis gócpontban amit minden irányból autósok szorítanak az egy! sávos úton. Oda érek nyújtom a pénzt, erre mögöttem elkezd a kocsiban az odi dudálni, aztán megint, meg megint és így tovább. Érted várom a visszajárót, nulla kontrolom van mikor kapom meg, de van akinek úgy tűnök mint egy világtalan bámészkodó mert fél másodperce nem haladok sehova.</p>
                                <p>Amúgy ne értsd félre a legtöbb ember olyan chillax itt, hogy beszarok, ez nem egy road rage, ez csak simán ilyen. Itt a Pesti vezetési stílust a legjobb ha gyorsan Mad Max-esre cseréli az ember.</p>
                                <p>Leparkolás, séta az árusokon át, “no money, poor hungarians, no-no”, ez a mantra. Mantra nem kántálás mert ez szerintem védikus. Látunk egy majmot aki oda megy egy nőhöz, felemeli a ruháját és benyul a farmer zsebéhez, hogy mi van ott. A csajnál amúgy nulla pánik mert hiszen tudja, hogy kiette a zsebéből már a zöldséges biryanit szóval nem lesz gond.</p>
                                <p>Tolunk egy kis templomozást (tisztelet), kapunk a fejünkre pettyet, amit persze a Swami mikor vizet ad a tenyerembe egyből véletlen rákenek a homlokomra, ami miatt nekem inkább pötty helyett ilyen oroszlánkirály Szimba kenetem lesz.</p>
                                <p>Szép a város innen fentről. Kicsit lemegyünk az 1008 lépcsőből úgy 100-at egy kilátóhoz, tényleg nagyon szíp, jól megcsinálták. Véletlen se megyünk le többet mert ahogy mondás tartja ugye</p>
                                <blockquote>ami le megy, annak a motorja fent maradt a parkolóban<span>Gravity</span></blockquote>
                                <p>Vissza esünk a mocihoz, ami ma is szeszélyes, nem indul. Megfejtjük hogy rábasztam, de sikerül beröfizni, annyi a csavar, hogy megállni nem szabad mert akkor nem indul.</p>
                                <p>Hm gondolom mi sem könnyebb ebben a Svájc szerűen szabályt követő forgalomban nem megállni, de aztán rájövök ettől csak helyibb leszek. Szerencsére néhány nagyon izzasztóan rossz helyen lefulladás után, rájött a moci, hogy még nincs Diwali, még meló van, és onnatól végig ok volt.</p>
                                <p>Hazafele tűnik fel, hogy hát szétégett a karom, mert még vannak a felnőtt léttel néhol korlátaim, és hát magamat naptejjel kenegetni az egy olyan dolog ami eszembe nem jut. Irány a csokis ember egy kis aloe veráért, aki azért jól kinevet, bár gyanítom mivel indiai sose használt még ilyet.</p>
                                <p>Már előre pisis az alsó gatyám hogy majd reggel mikor tépik a karom, hogy valami marichyasana szerű dolgot előadjak, milyen kellemes lesz a bőrömben lenni. Valami lesz. <b>Szia.</b></p>
                            </>
                            : <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">द्वे Dve</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">Chamundi Hill</h2>
                                </hgroup>
                                <p>There's this place here in Mysore called Chamundi Hill. It has temples, vendors, cows, ladyboys (what?), monkeys, goats, pretty much everything.</p>
                                <p>After morning practice, Andris, Dia, and I, along with a few coconuts inside, decided to go for some dosas because there was still space for that. We're hooked on a place called Paradise Hotel, which is like an old socialist-era establishment that you can tell was fancy in her glory days.</p>
                                <p>We're usually the only ones in the restaurant, the three of us. Well, except for the staff, they are more, but that is very well needed as we tend to ask questions that require their hive intelligence to process. Anyway, the basics are 1-2 ghee dosas for Andris, 1-2 oil-and-ghee-free dosas for Dia and me, and occasionally more because we are hungry Hungarians. "Occasionally" basically means always, but I won't get into that now. We earn it, period.</p>
                                <p>After devouring the spicy calorie bomb, Andreas suggests:</p>
                                <blockquote>Let's go up the hill.<span>Mr Bimba</span></blockquote>
                                <p>At this point, anything is fine as long as it's not work and responsibility. First, we drop off our soaked and stinky yoga gear at home and set off. It's about a 30-40 minute ride through the Indian non-stop whacky race, where no matter how much you pay attention, it doesn't help. Here, you have to believe. You must believe you'll get through the roundabout and reach your destination. Without faith, this is R.I.P.</p>
                                <p>The road to the hill is actually quite nice. It might not seem crucial, but I'll try to describe the feeling: you must pass a toll booth to reach the parking lot. It costs 10 rupees, like a mall parking lot. Whether you're on a motorcycle, bicycle, walking, riding an elephant, or whatever, if you leave an inch of space in any direction, someone or something will surely take that spot.</p>
                                <p>So, we're heading towards the toll booth, and it's like a little hub with five or six motorbikes and all sorts of vehicles trying to squeeze in from all directions on a one-lane road. I reach out to pay, and the car behind me starts honking, then honking again and again, and so on. You see, I'm waiting for my change. I have no control over when I'll get it, and to some, I look like a clueless bystander because I haven't moved in half a second.</p>
                                <p>Don't get me wrong, most people are so chillaxed here that it's mind-blowing. This isn't a road rage; it's just how it is. You better trade in your Western driving style for Mad Max mode.</p>
                                <p>We park, walk through the vendors, and keep chanting my mantra: "No money, poor Hungarian, no-no." I think it’s a mantra, not a chant because it sounds Vedic. We spot a monkey approaching a lady, lifting her dress, and reaching into her pocket to see what's in there. The lady remains calm because she knows she already ate the veg biryani out of her pocket, so there is no problem.</p>
                                <p>We do a little temple visit (respect), and they put a dot on our heads. When the Swami gives me water in my palm, I accidentally smear it all over my forehead. So, instead of a dot, I end up with a Lion King Simba-like anointment.</p>
                                <p>The city looks beautiful from up here. We descend 100 out of the 1008 steps to reach a viewpoint, and it's genuinely stunning. We don't go down any further because, as the saying goes:</p>
                                <blockquote>What goes down left its motorbike in the parking lot.<span>Gravity</span></blockquote>
                                <p>We return to the bike, which is moody and won’t start today. We figure out I’m fucked, but we manage to kick-start it. The catch is that I can't stop because it won't start again.</p>
                                <p>How hard can it be to avoid stopping in this Swiss-like orderly traffic? But then I realised that not stopping makes me fit in better here. Thankfully, after sweating due to bad stalls in unfortunate places, the bike understands that it's not Diwali yet; there's still work to be done, and from then on, everything is fine.</p>
                                <p>On the way home, I notice my arm is sunburned because there are certain limits to my adulting, and smearing myself with sunscreen isn't something that crosses my mind. We head to the Chocolate Man for a bit of aloe vera. He laughs at me, but I suspect he's never used any SPF product before, as he is Indian.</p>
                                <p>I'm already dreading how my sunburned arm will feel in the morning when I attempt some Marichyasana-like pose with an assistant. It’ll be somehow. <b>Bye.</b></p>
                            </>
                        }
                    </article>
                    <article id="treeni" className="blog__article">
                        {language === "HU"
                            ? <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">त्रीणि Treeni</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">Kelvin</h2>
                                </hgroup>
                                <p>Van egy hűtőszekrény a pecóban amit bérlünk. Sajnos hamar rájöttünk, hogy ez inkább csak egy szekrény. Amúgy egész jól mutat, ilyen modern szerű. A legjobb a márkanév rajta, ami csak így szerényen: Kelvinator. <b>Param-pam-param.</b></p>
                                <p>Ez a hűtő ilyen felül külön ajtós fagyasztós, alul sima frigó. Van két pecek benne:</p>
                                <ol>
                                    <li>A mélyhűtőben amivel el lehet dönteni, hogy minden energiát a hűtő kapjon e (Bollywood Star Trek)</li>
                                    <li>A sima hűtőben egy tekerő amivel 1-5 között lehet állítani ennek a bestiának az erejét</li>
                                </ol>
                                <p>Szóval ezeket nyomkodtam tekertem 1-2 napig, ilyen problem solver fejjel, teszteltem mikor, hogy ha egyáltalán van e Kelvin életében változás. Nem jártam sikerrel. Két nap után jeleztem a tulajnak, hogy ez így nem jó, mire mondta, hogy RELAX küldöm az engineer-t.</p>
                                <p>Még aznap jött is valaki, aki kinyitotta a mélyhűtőt ahol a energia kapcsolót olyan állásban hagytam, hogy leszarom-a-jeget-csak-ne-rohadjon-meg-alul-minden. A mérnök jelezte, hogy ooh hát itt a gond, majd átnyomta a kapcsolót. Én itt mindent próbáltam, hogy megértse ezen már túl vagyok, de sajnos nem beszélt angolul, és a házi néni Lalitha (szeretjük őt) szintén limitált szókincsel nyomul, így aztán vállba veregette magát a jó munkás ember, lefotózta a művét és távozott.</p>
                                <p>Úgy voltunk vele, hogy nem rég vagyunk itt, még párszor kibírjuk a chilivel töltött, chili pelyhes, peri-peri-s, Scoville 5000-es kaját, szóval jegelhetjük (meh) a témát egy-két napig. Ami viszont beugrott, hogy rakok vizet a fagyóba, és ha az ilyen, tudod folyós, akkor az már talán meggyőző lesz a legnagyobb mérnöknek is, hogy a Kelvinator túl meleg.</p>
                                <p>Két nappal később reggel írtam megint. Ez a nap ilyen áramszünetes nap volt. Ez azt jelenti, hogy egy része a lakás konnektorjainak nem ment. Én nem értem, hogy van itt a villanyszerelés, de ezt már Andrissal beszéltük, és arra jutott, hogy jobb ha nem értjük.</p>
                                <p>Amúgy Andris villamos mérnök, és a saját fülemmel hallottam erre valakit azt mondani, hogy:</p>
                                <blockquote>De hát nincs is olyan sok villamos.<span>bocsi Janka</span></blockquote>
                                <p>Na mindegy a lényeg, nem jutott áram Kelvinnek. Nyilván egyből jött, hogy ooh az a baj sir, hogy no electricity. Elmondtam nem, nem az a baj, és szerencsére aki most vezeti a ház dolgait, jól beszél angolul, szóval meggyőztem, küldje a szervizet. Másnap jött is, kinyítja a fagyasztót, amiből némi hideg kiszökik és kerek szemekkel rám néz</p>
                                <blockquote>This works fine<span>Engineer #2</span></blockquote>
                                <p>Mondom "HÁH akkor, hogy magyarázod, hogy a víz nem egy darabban van hanem folyik?" Jött is a válasz amiben reménykedtem:</p>
                                <blockquote>Ah ok sir<span>Engineer #2</span></blockquote>
                                <p>És ezzel neki esett Kelvinnek. Egész szét szedte belül, tolmács segítségével elmondta, hogy valami nem jó, két nap az alkatrész, és majd jönnek. Mondom OK.</p>
                                <p>Két nap múlva, sokat voltunk el jóga után mert volt chanting meg elmentünk enni, meg ilyenek. Kb 12-re értünk haza, és ott találtunk a konyhában egy szétbontott hűtőt egy mérnökkel a földön, aki épp kibelezte szegény Kelvint. Otthon ha ilyen van, pláne ha egy hotelban (hívjuk ezt talán annak) a hűtőt elvinnék megszerelni, addig esetleg adnának egy másikat, aztán ha kész kicserélnék. Itt inkább az egész konyhából műhelyt csinálnak, és amúgy egész napra.</p>
                                <p>Az ember itt volt estig. Én kiültem a teraszra valamit csinálni, és egyszer csak azt vettem észre hogy eltűntek. Mondom yeah, a távozás legalább angolos volt. A fagyasztóban találtam egy pohár vizet, itt komolyan büszke voltam, hogy új tesztelési procedúrát dolgoztam ki. Lementem Lalitához aki mondta, hogy egy óra múlva visszajön az ember, megnézni a víz állagát. Nem jött vissza. A víz folyik. Kaptunk egy nagyon régi de egyenlőre működő hűtőt. A Kelvinator még itt van, támasztja a falat és várja a gépek lázadását. <b>Csumesz.</b></p>
                            </>
                            : <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">त्रीणि Treeni</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">Kelvin</h2>
                                </hgroup>
                                <p>There's a fridge in the flat we're renting. Unfortunately, we quickly realised it's more of a cabinet. It looks modern, with the best part being the brand name on it, which modestly says Kelvinator. <b>Param-pam-param.</b></p>
                                <p>This fridge is one of those with a separate freezer on top and a regular fridge below. It has two knobs inside:</p>
                                <ol>
                                    <li>In the freezer, there's a knob to decide if all the energy goes to the fridge (Bollywood Star Trek)</li>
                                    <li>A dial adjusts this beast’s power in the regular fridge from 1 to 5.</li>
                                </ol>
                                <p>So, I've been pressing these buttons and turning the dial for a day or two, trying to solve this, testing when, if ever, Kelvin changes its ways. I am unsuccessful. After two days, I informed the landlord that this wasn't working as expected. He replied</p>
                                <blockquote>RELAX, I'll send an engineer<span>The Landlord</span></blockquote>
                                <p>Someone came that same day and opened the freezer where I had left the energy switch in the I-don't-care-about-ice-just-don't-let-the-food-get-spoiled position. The engineer quickly concluded that this was the issue and pushed the button. I tried everything I could to make him understand I was past this solution, but unfortunately, he didn't speak English, and our landlady, Lalitha (we love her), also has a limited vocabulary. So the good worker patted himself on the back, took a few photos of his work, and left.</p>
                                <p>We thought we'd tolerate the chilli-filled, chilli-flaked, peri-peri, Scoville 5000-level food for a few days to ice the issue (pun intended). But then it hit me; I'll put water in the freezer, and if it stays liquid, perhaps even the most prestigious engineer might understand that the Kelvinator is too warm.</p>
                                <p>Two days later, I messaged again in the morning. It was a day of power outages, so some of the apartment's outlets weren't working. I don't understand the wiring here, but Andris and I discussed it and concluded it's best not to understand it.</p>
                                <p>By the way, Andris is an electrical engineer. Anyway, my point was that Kelvin didn't have electricity for a whole day. </p>
                                <p>Naturally, the landlord’s first thought was, "Oh, the problem now is that there's no electricity!" I told them, "No, that's not it." Luckily, the person managing the house now speaks English well, so I convinced him to send a technician. The next day, someone came, opened the freezer, and some cold air escaped. He looked at me wide-eyed and said</p>
                                <blockquote>This works fine<span>Engineer #2</span></blockquote>
                                <p>I said, "Oh, really? How do you explain that the water isn't in one solid piece but flowing?" He replied</p>
                                <blockquote>Ah ok sir<span>Engineer #2</span></blockquote>
                                <p>And he started to work on Kelvin. He completely dismantled him inside and explained with the help of a translator that something was wrong and that it would take two days to get the parts needed, and he’d return. I said, "Okay."</p>
                                <p>Two days later, after a long yoga session that included chanting and going out to eat, we came back around noon to find a technician in the kitchen, with Kelvin in pieces on the floor. Back at home, if this happened, especially in a hotel (I think we can call this place that), they'd take the fridge to fix it, provide a replacement, and then bring it back once it's working. Here, they turn the whole kitchen into a workshop for the entire day.</p>
                                <p>The guy was here until evening. I sat outside on the terrace working on something, and suddenly, they were gone. I thought, "Well, at least their leave was like the English way." I found a glass of water in the freezer and was genuinely proud to have invented a new testing procedure for real-life engineers. I went to Lalitha, who told me the guy would be back to check the water in an hour. He never returned. The water remained in a liquid form. We have an old but functioning fridge for now. Kelvinator is still here, supporting the wall and waiting for the machines' uprising. <b>See ya.</b></p>
                            </>
                        }
                    </article>
                    <article id="chatvaari" className="blog__article">
                        {language === "HU"
                            ? <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">चत्वारि Chatvaari</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">Osztály kirándulás</h2>
                                </hgroup>
                                <p>Mysore körül sok a dzsungel, és emiatt sok a szafari lehetőség. Egyszer már voltam ilyenen Diával meg Rózsával és nem volt rossz. Láttunk nagy pókokat meg elefántokat, meg pókokat, meg őzeket (pókokat).</p>
                                <p>A Guru szombatonként a vezetett óra után konferenciát tart. Van valami téma de nagy részt freestyle, majd válaszol a rajongói kérdésekre (respect). Néha vannak jó kérdések néha meg csak úgy kérdeznek valamit tőle mert csend van. A mostani konf péntekre esett a szombati holdnap miatt, és Saharath Ji bedobta, hogy jó ötlet eltakarodni a dzsungelbe mert “hosszú hétvége” lesz. Az újaknak, ez egy simán két napos hétvégét jelent, mert máskor amúgy heti hat hajnalban gyakorlás van.</p>
                                <p>A Bimba Travel el is kezdte a szervezkedést amire Dia, Hetti és én be is fizettünk. A buszos szafari (2.5 óra) es a taxi (2-2 óra oda-vissza, plusz megvár) kb 1800 rúpia fejenként. Azt fontos megjegyeznem, hogy a legtöbb ilyen szafari ultimate-mega-giga célja az, hogy láss egy tigrist. Hogy ez miért olyan fontos, Milne univerzuma segít:</p>
                                <blockquote>A Tigris, az mindig egy jellem, sőt jellem és Tigris az egy!<span>Richard M. Sherman / Robert B. Sherman</span></blockquote>
                                <p>Te meg akarod a jellemet és kész (amúgy angolul: “The wonderful thing about Tiggers, Is Tiggers are wonderful things”).</p>
                                <p>A cél Kabini Kakanakote lett (tuti nem tudtad kiolvasni elsőre), Andris már veterán, ez lesz a harmadik útja ide. Még nem látott cicust. Mi abban bízunk, hogy három a magyar igazság (neki), mi pedig az igazság farvizén lecsaljuk a távot. </p>
                                <p><b>Nagyon fontos:</b> kell sok kaja ha az ember Magyar és elmozdul a lakhelyétől az ismeretlenbe. Először leküldtünk egy nagy ebédet, aztán bementem a boltba, elvettem egy kék Gatorade-t (elektrolit van benne), meg négy csomag Lays chipset illetve egy nagyobb tábla peanut chiki-t (ez kb karamella meg mogyoró egyben). Mikor fizettem egy bácsi egész undorosan nézett rám, mert hát kék víz chipsel az nem valami szattvikus, de ez van, legalább nem csíp.</p>
                                <p>Azért egy nagy doboz humuszt is gyorsan összeraktunk, Dia mosott paradicsomot, meg pakolt magokat, datolyát, készen állunk. Andrisnál volt a tala ahol beültünk egy kocsiba, és indultunk is a másfél órás útra. Mellékes de elmondom, hogy a kocsi üti a gyalogost, és a motorost ebben az úti sakkban, és néha olyan előzéseket nyomott a sofőr, hogy felnézni nem akartam.</p>
                                <p>Mikor megérkeztünk be kellett mutatni a elővásárolt jegyeket, cserébe kaptunk ülés számot a buszra. Nagyon jó arc volt a pénztáros, mondta, hogy reggel láttak tigrist két kölyökkel, és kérdezte, hogy van egy Wade nevű foreigner, ő hol van? Mondtuk, hogy nem velünk jött (ezt párszor el kellett mondani). Wade-n múlt, hogy végül egymás mellé ülhettünk mert nem jött, köszönjük Wade.</p>
                                <p>A busz olyan volt, hogy nem volt üveg az ablak keretekben csak a szélvédőnél, és valami tankból kiszerelt diesel motorja lehetett, hang alapján. Mondani se kell, hogy ahogy felszálltunk még az álló buszon bevertünk egy csomó humuszt chipsel meg amivel tudtuk, amire Hetti nevetve megjegyezte:</p>
                                <blockquote>Most komolyan ennyire magyarok vagyunk?<span>Hetti the One with a Job</span></blockquote>
                                <p>Ez egy nem zökkenőmentes buszozás, Ace Ventura meg a kicsi kocsi bumm bumm megy a fejemben. Sok majmot és őzet látunk, akik látszólag már megszokták ennek a busznak a lágy hangját, de azért kicsit odébb szaladnak ahogy fülsüketítően lopakodunk. Talizunk egy bivaly családdal, és látunk egy bocit ahogy éppen szopizik, nagyon cukker.</p>
                                <p>Aztán egyszer csak ott volt a fűben.</p>
                                <blockquote>Sssshhhhhhhhhh QUIET!<span>Gentle Safari Bus Driver</span></blockquote>
                                <p>A sofőr finoman, és kedvesen jelezte az utazóknak, hogy mindenkinek pofa súlyba vagy taka van. Épp alvóideje volt a cicunak, amit egy sípoló hanggal (not so smooth) próbált a főni megzavarni, csak a fotók végett. Zero fuck given a tigris részéről. Amúgy Dia a cicánkra, Nancy-re alapozva teljesen jól kiszámolta, hogy most lesz az alvóideje a macsekoknak itt is, very good SwamiJi.</p>
                                <p>Volt két kis srác a buszon (amin amúgy mindenki indiai volt rajtunk kívűl) akik néha oda sikítottak a macskára, amire szóltak nekik, hogy kuss, ami nem érdekelte őket nagyon, és így tovább. A kisebbik rájött, hogy most bármit elér ha hangos, és kizsarolt egy nyalókát az anyjától.</p>
                                <p>A tigris mintha tudta volna, hogy mi addig rontjuk a levegőt itt amíg nem mozdul, egyszer csak felemelte a fejét és elég cicásan ásított egyet, kicsit tisztogatta a jobb oldalának három százalékát, majd visszafeküdt. Kész vagyunk, szafari pipa.</p>
                                <p>Visszafele ahogy esteledett lelassult a haladás is. Nincs a falvakban, utakon közvilágítás. Az esti vezetés az külön műfaj. Mindenki a távolsági reflektort nyomja. Néha nem, aztán előzésnél megint de. Talán azért, hogy megvakuljon és megrémüljön aki amúgy teljesen jogosan jön szembe a másik sávban. A lényeg, hogy én SEMMIT nem látok, megvakítanak a lámpákkal. Andris ül elöl és hasonlóan érzi magát. A kérdés, hogy akkor ők miért látnak? Ja az lemaradt, hogy a gázról csupán emiatt senki nem száll le. Szóval ez elég para, de valahogy mégis működik.</p>
                                <p>Chandra a sofőrünk beszél nekünk arról amit Sharathji is megemlített a konferencián, hogy szombaton, holnap, egy hallottak napjára hasonlító ünnep van, amikor meg kell emlékezni a felmenőinkről, valamilyen felajánlást kell tenni nekik, és imádkozni. Ezt másnap meg is tettük, remélem eljutott hozzájuk az üzenet. <b>Shanti.</b></p>
                            </>
                            : <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">चत्वारि Chatvaari</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">Class trip</h2>
                                </hgroup>
                                <p>There's a lot of jungle around Mysore, offering many safari opportunities. I've been on one with Dia and Rózsa before, and it wasn't bad. We saw big spiders, elephants, more spiders, and deer (spiders).</p>
                                <p>The Guru holds a conference after the led class every Saturday. There's usually a topic, but it's mostly freestyle, and he answers questions from his fans (respect). Sometimes, there are good questions; sometimes, people ask them because the room is quiet. The latest conference was scheduled for Friday because of the moon day on Saturday, and SaharathJi suggested we go to the jungle because it would be a "long weekend." For those who don't know, this means a two-day weekend because we usually practice six days a week.</p>
                                <p>The Bimba Travel began organising it, and Dia, Hetti, and I paid for our tickets. The bus safari (2.5 hours) and the taxi (2-2 hours round trip, plus waiting) cost around 1800 rupees per person. Most of these safaris' ultimate goal is to see a tiger. Why it's so important, I'll let Milne's universe help:</p>
                                <blockquote>The wonderful thing about Tiggers, Is Tiggers are wonderful things.<span>Richard M. Sherman / Robert B. Sherman</span></blockquote>
                                <p>And you want wonderful.</p>
                                <p>Our target was Kabini Kakanakote (I bet you could not read it on the first try), where Andris is a veteran; this will be his third trip here (without luck), and because three times is the charm, we hope to use his tailwind to seal the deal.</p>
                                <p><b>Very important:</b> one needs a lot of food if they're Hungarian and moving away from their safe house. First, we ordered a big lunch, and then I went to the store and grabbed a blue Gatorade (it contains electrolytes), four packs of Lay's chips, and a large bar of peanut chikki (like caramel and peanuts combined). When I paid, the guy looked at me with disgust because blue sports drinks and chips are not very sattvic, but that's how it is; at least it doesn't have chilli.</p>
                                <p>We quickly prepared a big box of hummus. Dia washed tomatoes and packed some seeds and dates, and we were ready. We met at Andris’s place, got in the car, and began the 1.5-hour journey. By the way, cars overtake pedestrians and motorbikes in this chess game on the road, and sometimes, the driver's manoeuvres were so intense (or loco) that I didn't even want to look.</p>
                                <p>When we arrived, we had to show the pre-purchased tickets to get our seat numbers for the bus. The cashier was very friendly and told us that they saw a tiger with two cubs in the morning (yay), and he asked about a foreigner named Wade. We said he didn't come with us (we had to repeat this several times). It was because of Wade that we could sit together because he didn't show up. Thank you, Wade.</p>
                                <p>The bus did not have glasses in the window frames, only on the windshield, and it probably had an engine that was salvaged from a tank, judging by the noise. Needless to say, as we got on the bus, we immediately opened the hummus and chips and made them disappear while we were at the bus stop. Hetti said while laughing:</p>
                                <blockquote>Are we really this Hungarian now?<span>Hetti the One with a Job</span></blockquote>
                                <p>This is not a smooth bus ride; Ace Ventura and the “chitty chitty bang bang” play repeatedly in my head. We see many monkeys and deer who seem to have gotten used to the gentle hum of this bus, but they are still running away as we sneak by deafeningly. We come across a buffalo family and see a fawn nursing, very cute.</p>
                                <p>Then, out of nowhere, it was in the grass.</p>
                                <blockquote>Sssshhhhhhhhhh QUIET!<span>Gentle Safari Bus Driver</span></blockquote>
                                <p>The driver politely and kindly signalled to the passengers that everyone should shut the hell up or get the hell out. It was the cat's nap time, which the driver tried to disturb with a squeaking sound (not so smoothly) for the sake of photos. Zero fuck given by the tiger. By the way, Dia predicted the cats' nap times, using our cat Nancy as a reference, and it was spot on. Good job, SwamiJi.</p>
                                <p>There were two kids on the bus (everyone on the bus was Indian except for us) who occasionally yelled at the cat. They were told to zip it, which didn't interest them much, so they shouted again, and so on. The younger one realised he could achieve anything by being loud now, and he blackmailed a lollipop out from his mother (nice).</p>
                                <p>It seemed like the tiger knew that we'd be here to annoy until she moved, and then, she suddenly raised her head and yawned in a rather cat-like way, cleaned about three per cent of its right side, and then lay back down. We're done; the safari is completed.</p>
                                <p>On the way back, as it got darker, our progress slowed down. There's no street lighting in the villages and roads. Night driving is a different game. Everyone has their high beams on. Sometimes not, then during overtaking, high beams again. Maybe it's to blind and frighten those rightfully coming from the opposite lane. I can’t see shit; they are blinding me with lights. Andris sits in the front seat and has similar issues. The question is how they can still see the road. Anyway, NO ONE gets off the gas just because of this. It's pretty scary, but somehow it still works.</p>
                                <p>Our driver, Chandra, talked about the holiday that Sharathji also mentioned in the conference, similar to what we have at home called “The Day of the Dead”. It is a day to remember our ancestors, make an offering, and pray. We did it the next day, and I hope the message reached them. <b>Shanti.</b></p>
                            </>
                        }
                    </article>
                    <article id="pancha" className="blog__article">
                        {language === "HU"
                            ? <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">पञ्च Pancha</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">Záróvonal</h2>
                                </hgroup>
                                <p>Tudom, tudom, kezd már uncsi lenni, hogy megint a közlekedés irányából akarok megosztani valami gondolatot, de a sok különbség között Indiában, ez az, ami folyamatosan történik velem.</p>
                                <p>Itt nincs záróvonal. Vagyis van de mindenki leszarja, néha konkrétan a tehenek. Van olyan felfestés (útburkolati jel ha nerd vagy), hogy dupla záró vonal, ami között van fél méter, ahol sűrű csíkozás van. Ezt használják autósok arra, hogy ennek a közepén menjenek, így előzve mindenkit. Persze jobbról, balról, mindenhonnan közben átmegy ezen mindenki, mintha ott se lenne.</p>
                                <p>Bemegyek egy üzletbe, összerakom amit akarok majd oda állok a pénztárhoz. Van valaki előttem aki épp fizet. Megjelenik egy arc aki fogja magát és beáll az elé aki előttem van, majd elkezdi felrakni a cuccát a kasszára. Nem hagytam magam előtt helyet, semennyit. Egy fing nem fért oda, mégsem én jövök mert nincs sor. Ha itt most odaszólnék valamit (próbáltam párszor) még én lennék cringe.</p>
                                <p>Egy másik kapcsolódó jelenség amit úgy hívok, hogy a de-most-miért-multi-tasking. Fizetnél a boltban, erre az eladó felveszi a telefont és közben elkezd beszélni Kannadául (az itt a fő nyelv) a melleted állónak. Egyszerre csinál három (vagy még több) dolgot, de legalább így mindenki egyformán gyér szolgáltatást kap.</p>
                                <p>Valahogy ez nehezen fér a fejembe, már az első perctől. Mysore-ban az látszik, hogy mint közösség nagyon összetartóak az emberek. Az együttélést mégis úgy adják elő, mintha más nem is létezne. Mindezek ellenére, ez senkit nem idegel ki, az emberek lazák, kedvesek. Ez nem egy negatív kritika akar lenni inkább csak ilyen értetlenkedés, mint mikor megnézel egy Youtube tutorialt egy indiaitól és több kérdésed lesz mint válaszod.</p>
                                <p>A teljesség kedvéért Sharathji-hez jönnek mindenfelől emberek, és köztük is van aki pont áttapos rajtad ha arra van dolga amerre szotyizol, ehhez nem kell indiainak lenni.</p>
                                <p>Azt verték belém, hogy legyek tekintettel másokra, az időseknek adjam át a helyem, várjam ki a sorom stb. A mi “kultúránk” ilyen. Ezért üvöltözünk a volán mögött, vagy válunk fiatalon Karenné és küldünk el embereket a búsba ha nem tetszik valami (kérem a panaszkönyvet).</p>
                                <p>Rengeteg záróvonal van bennünk (jöhet egy Pulitzer). Mégis ez a sok szabály, és főképp az energia amit arra költünk hogy azokat betartassuk másokkal nem segít. Lehet az utak biztonságosabbak nálunk, de mentálisan és spirituálisan egyértelműen vesztésre állunk.</p>
                                <p>Megjegyzés: remélem világos voltam, ha legközelebb meglátlak az Aldiban kitúrlak a sorból mintha ott se lennél, szóltam. <b>Béka.</b></p>
                            </>
                            : <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">पञ्च Pancha</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">Solid lines</h2>
                                </hgroup>
                                <p>I know, I know, it's starting to get a bit repetitive that I want to share a thought from the perspective of transportation again. Still, this consistently happens to me among the many differences in India.</p>
                                <p>There are no solid lines here. Well, there are, but no one gives a crap about them, and the cows sometimes literally crap on them. There's a type of road marking (if you're a road nerd) that's a double solid line with about half a meter of space between them and densely striped. Car drivers use this space to overtake everyone else. Of course, everyone crosses them from the right, left, and every direction as if it doesn't exist.</p>
                                <p>I enter a store, gather the items I want, and then stand in line at the cashier. There's someone in front of me who is in the process of paying. Suddenly, someone appears, pushes themselves forward, and starts placing their items on the counter in front of the person already there. I didn't leave any space in front of me, a fart couldn’t fit, yet I'm not the one next in line because there's no such concept as a line here. If I were to say something now (I've tried a few times), I would be the cringe guy.</p>
                                <p>Another related phenomenon I call the “but-why-multitasking." You're at the store, ready to pay, and the cashier answers the phone while simultaneously conversing in Kannada (the primary language here) with the person next to you. They're doing three (or more) things at once, but at least that way, everyone receives equally subpar service.</p>
                                <p>Somehow, it's hard to wrap my head around this from the first moment. In Mysore, it's clear that the people are very close-knit as a community. Yet they live as if others don't even exist. However, none of this irritates anyone; people are relaxed and kind. This is not meant to be a negative critique, but more like a sense of wonder, like when you watch a YouTube tutorial from an Indian and end up with more questions than answers.</p>
                                <p>For the sake of completeness, people from all over come to see Sharathji, and among them, some will push past you if they have business in the direction you're strolling; you don't need to be Indian for that.</p>
                                <p>I was taught to be considerate of others, to give up my seat for older people, wait my turn, etc. This is our "culture”. That's why we yell behind the wheel or turn into a Karen at a young age and send people to oblivion if something doesn't suit us (please pass the complaint book and where is the manager?).</p>
                                <p>We have a lot of solid lines within us (a Pulitzer might be in order). Yet all these rules, especially the energy we spend enforcing them on others, don't help. Our roads might be safer, but mentally and spiritually, we're clearly losing.</p>
                                <p>Note: I hope I was clear; if I see you at Aldi next time, I'll push you out of the line as if you weren't there. <b>Ribbit.</b></p>
                            </>
                        }
                    </article>
                    <article id="shat" className="blog__article">
                        {language === "HU"
                            ? <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">षट् Shat</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">Palota Dristi</h2>
                                </hgroup>
                                <p>Sharathji kicsit unorthodox módon tett egy bejelentést a hétfői Mysore óra közben. </p>
                                <blockquote>A szombati konferencia után meglátogattak a kormány emberei az otthonomban.<span>Sharathji the spy?</span></blockquote>
                                <p>Ez ilyen segglyuk összeszorítósan hangzik (mula bandha amúgy) de ez nem az a story, hogy kiderült a gururól, hogy kettős ügynök.</p>
                                <p>Most indul a Mysore Dasara ami egy 10 napos ünnep Karnataka államban. 1610 óta van ez a veretős fesztivál (már tegnap este Martin Garrix pörgött a közelben) és persze van egy kapcsolódó mítosz is.</p>
                                <p className="box">
                                    <em>
                                        A mítosz szerint a Chamundi hegyen élt egy bivalydémon Mahishasura, akire sok évnyi vezeklés és meditáció után Brahma isten (a teremtő) olyan áldást tett, hogy ember nem ölheti meg. Ezen felbuzdulva a démon egy egomániás bad ass boss lett, és elkezdte bántani és zaklatni az embereket. Shiva (a pusztítás istene) felesége Parvati, talált egy kiskaput az áldásban, és az istenek felhatalmazásával felvette Chamundeshwari alakját és egy oroszlánháton 10 nap alatt legyakta a démont helyreállítva a Dharmát (a természet rendjét). Hát ebbe szakértők biztos beletudnának kötni, de azt hiszem valami ilyesmi a lényeg.
                                    </em>
                                </p>
                                <p>Lesz minden ebben a búcsúban, például elefántos felvonulás meg ilyenek, de majd még úgy is írok róla (ha jó lesz).</p>
                                <p>Na szóval, az a lényeg, hogy felkérték a Guru-t, hogy tartson egy vezetett órát, reggel hatkor a Mysore Palace előtt, megkezdve az idei Dasara-t. Ő pedig megkért minket, hogy menjünk oda és csapassuk a nagy urak előtt a gyakorlást. Kicsit nyavajogtunk, hogy a szép matracunk bele kell tenni az elefánt szarba, de persze hamar megértettük, hogy ez Sharathji-nek ez fontos, és mint tanítványai, megyünk utána akármi van.</p>
                                <p>A palota elé leterítettek egy nagy ponyvát (ez engem meglepett) ami így szemre legalább ezer matracnak elég. Bárki csatlakozhatott amúgy a gyakorláshoz, és szépen meg is telt a tér. Volt egy színpad amiről a vezetett órát Sharathji vezényli majd, rengeteg székkel az ilyen-olyan politikusoknak, nagy embereknek, illetve hely néhány gyakorlónak, akiket előre kiválasztott a guru. Réka is a kiválasztott kevesek között volt, de mondjuk ok, tudtam, hogy neki egy Ashtanga első sorozat az annyira effortless mint nekem egy orrfújás (good job, respect).</p>
                                <p>Azt eddig nem nagyon mondtam, hogy azért ez nem semmi hely. A napfelkelte előtt értünk a palota előtti térre, a természet épp ébredezett. Rengeteg féle és több száz madár repkedett felettünk (sas meg ilyenek). Persze egyből az jutott eszembe, ha elkezdenének kaksizni ránk az szó szerint szőnyeg bombázás lenne. A nap elkezdett feljönni a távolban az poszteres volt amúgy.</p>
                                <p>A színpad lassan megtelt rengeteg komoly tekintetű indiai férfival. Maradjunk annyiban, hogy elég ritka, nőket látni vezetői poziciókban itt is, de ez a kultúra teljesen máshogy épül fel mint a mienk, szóval ebbe inkább bele se megyek.</p>
                                <p>Sharath megragadta a mic-ot, és egy rövid beszéd után jött a:</p>
                                <blockquote>Samasthiti<span>Sharathji the Guru not Spy</span></blockquote>
                                <p>A szokásosnál gyorsabb tempót diktált, mivel nem csak a saját padawanjai gyakoroltak. Közben mindenhol TV stábok meg kamerák pörögtek. Aki ismer az tudja, hogy én bizony még puding vagyok, hogy a teljes első sorozatot tolhassam, de emiatt legalább mozizhattam a történéseket a színpad körül, és az igen szórakoztató volt.</p>
                                <p>Megjelent egy kocsi, és kiszált egy nagy darab barna inges figura. Erre a színpadon ülők szép sorban feláltak és elkezdtek pozicióba kerülni. Pontosan nem tudom milyen magas beosztású valaki volt ez az ember, de az biztos, hogy itt ő volt a nap mert mindenki körülötte keringett.</p>
                                <p>Sharathji eközben a színpadon folytatta a vezetett óra számolását. Nem tudom pontosan mire számítottak az urak, mennyi idő lesz egy ilyen óra (általában kb 90 perc), de láthatóan unták már a felénél. Igen ezt nézni, nem a legszórakoztatóbb dolog, leszámítva talán a Garbha pindasana-t amikor sokan csinálják (ilyen kis embrió szerű golyóvá vált emberek gurulnak előre-hátra, körbe, néha  egymásnak).</p>
                                <p>Szóval a főni jó egy órát késett, majd leült középre a színpadon. Eltelt néhány perc, és már ott is termett az indiai MC nő, és megsúgta a Guru-nak, hogy:</p>
                                <blockquote>Times up, speed it up, last one<span>Madame Master of Urge</span></blockquote>
                                <p>Sharathji engedve a nyomásnak, szólt, hogy nincs sok időnk, majd olyan tempóban kezdett el számolni, ahogy én amúgy általában tudok ha rám van bízva. Szóval gyorsan nah. Az utolsó levezető pózt, ami a szokásos szadizós számolós Utputihi (lótuszban ülsz azt nyomod ki magad kézzel a földtől) el is engedte ezúttal, ami kisebb röhögést váltott ki a tanítványok között.</p>
                                <p>Ahogy a politika rövidre vágta a gyakorlásunk, én is rövidre szabom az ő fellépésüket.</p>
                                <p>Az MC Kannadául beszélt nem angolul, azt hiszem neveket sorolt, a nézőtéren semmi reakció, nem értjük. Hangos taps és üdrivalgás amikor azt halljuk, hogy:</p>
                                <blockquote>Paramaguru Sharath Jois<span>Madame MC in Kannada</span></blockquote>
                                <p>Beszédek, egyik unalmasabb mint a másik de legalább egész sok van, aztán beszél a fő-főni, hogy a jóga fontos (azért végig csinálni nem hagyta), majd mindenféle színes valamiket ajándékoznak össze-vissza egymásnak, Sharathji-t pedig beöltöztetik Maharadzsának (idéztem itt Andrist aki reggel 5:30-kor “Blue T-shirt” névre hallgat).</p>
                                <p>A legjobb rész az volt, mikor egy valaki épp beszélt valamiről, és kisétált (persze vezetéssel) négy elefánt a palotából, amire nyilván minden figyelem oda összpontosult, de az emberünk a színpadon, mint egy profi színész, nem állt le a monológjával. Kicsit már-már sajnáltam amúgy.</p>
                                <p>Végre valami ami hasonlít az otthonra... a politikusok.</p>
                                <p>Volt azért valami amire szerintem sokáig emlékezni fogok, amikor a napüdvözletek közben, a lefelé néző kutyapózban a lábam között a naracsvörös felkelő napot néztem a ködös, pálmafás távolban. Azért az beadta na, megérte. <b>Csumesz.</b></p>
                            </>
                            : <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">षट् Shat</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">Palace Dristi</h2>
                                </hgroup>
                                <p>Sharathji made a somewhat unorthodox announcement during Monday's Mysore class.</p>
                                <blockquote>After the conference on Saturday, government officials visited me in my home.<span>Sharathji the spy?</span></blockquote>
                                <p>This sounds a bit like a tight squeeze in your Mula Bandha, but this is not the story of discovering that the Guru is a double agent.</p>
                                <p>Mysore Dasara is beginning; it is a 10-day-long festival in Karnataka. This vibrant festival (someone had a rave blasting Martin Garrix nearby last night) has been celebrated since 1610, and, of course, it comes with its story.</p>
                                <p className="box">
                                    <em>
                                        According to the myth, a buffalo demon named Mahishasura lived on the Chamundi Hill. After many years of penance and meditation, Brahma (the creator) granted him a boon that no man could kill him. Empowered by this, the demon became an egomaniacal badass boss, tormenting and harassing people. Parvati, the wife of Shiva (the god of destruction), found a loophole in the boon and, with the blessings of the gods, took the form of Chamundeshwari, hopped on a lion, and in 10 days, destroyed the demon, restoring Dharma (the natural order). Experts could probably find issues with my interpretation, but I think this is the essence of the story.
                                    </em>
                                </p>
                                <p>This party will include everything, such as elephant processions and more, and I'll write about it when it's over if it turns out well (or if I visit).</p>
                                <p>So, the Guru was invited to conduct a led class at 6 am in front of the Mysore Palace, marking the beginning of this year's Dasara (Yoga Dasara). He asked us, students, to join him and practice in front of the dignitaries. We grumbled a bit about placing our nice yoga mats on elephant dung, but we quickly understood that this was important to Sharathji, and as his students, we would go along with it, no matter what.</p>
                                <p>A large tarp was spread in front of the palace (that surprised me), and it seemed enough for at least a thousand mats. Anyone could join the practice, and the area filled up nicely. There was a stage where Sharathji would lead the class, with plenty of chairs for various politicians, dignitaries, and a few practitioners selected by the guru. Réka was among the few chosen, but OK, I knew that for her, an Ashtanga primary series is as effortless as for me blowing my nose (good job, respect).</p>
                                <p>I haven't mentioned that this place is quite remarkable. We arrived in front of the palace before sunrise, and nature was awakening. There were various types of birds, and hundreds of them were flying above us (eagles and such). Of course, I couldn't help but think that if they started doing their business on us, it would literally be a carpet bombing. The sunrise in the distance was a poster shot.</p>
                                <p>The stage slowly filled up with many serious-looking Indian men. Let's just say that it's pretty rare to see women in leadership positions here as well, but this culture is structured entirely differently from ours so I won't dwell on that.</p>
                                <p>Sharath took the mic, and after a brief speech, he said:</p>
                                <blockquote>Samasthiti<span>Sharathji the Guru not Spy</span></blockquote>
                                <p>He led the class faster than usual since it wasn’t just his padawans practising. Meanwhile, TV crews and cameras were everywhere. If you know me, I'm still a novice in practising the primary series (I stop way before it ends), but at least I could enjoy watching what was happening around the stage, which was quite entertaining.</p>
                                <p>A car arrived, and a tall man in a brown shirt got out. All the people sitting on the stage stood up one by one and got into position. I'm not exactly sure what high-ranking position this man held, but he was clearly the centre of attention.</p>
                                <p>Meanwhile, Sharathji continued counting in the practice on the stage. I don’t know how much time they expected such a class to take (usually about 90 minutes), but it was clear that they were getting impatient halfway through. Watching this practice was not the most exciting, except when many people were doing Garbha Pindasana (the embryo-like beings rolling back and forth in a ball) together, sometimes bumping into each other.</p>
                                <p>So, the big boss was about an hour late and sat in the middle of the stage. After a few minutes, an Indian MC came out and whispered to the Guru:</p>
                                <blockquote>Times up, speed it up, last one<span>Madame Master of Urge</span></blockquote>
                                <p>In response to the pressure, Sharathji announced that we didn't have much time left and began counting at a pace I usually can achieve when it's left up to me. So, it was fast, to say the least. He even skipped the final pose, the Utputihi (sitting in lotus and pushing yourself from the floor), eliciting laughter among the students.</p>
                                <p>As politics cut short our practice, I'll cut short their performance.</p>
                                <p>The MC spoke in Kannada, not English, and I think she was listing names. The audience did not react; we didn't understand. There was loud applause and cheering when we heard:</p>
                                <blockquote>Paramaguru Sharath Jois<span>Madame MC in Kannada</span></blockquote>
                                <p>Speeches followed, one more boring than the next, but at least there were quite a few. Then the big boss spoke about the importance of yoga (though he didn't let us finish), and various colourful items were gifted back and forth. Sharathji was dressed up as a Maharaja (I'm quoting Andris here, who listens to the name "Blue T-shirt" in the morning at 5:30).</p>
                                <p>The best part was when someone was speaking, and four elephants walked out of the palace (led by handlers), and naturally, everyone's attention shifted to them. However, like a professional actor, the man on stage didn't stop his monologue. I almost felt sorry for him.</p>
                                <p>Finally, something that resembles home... the politicians. Meh.</p>
                                <p>There was something, though, I think I'll remember for a long time when, during the sun salutations in downward-facing dog, I looked between my legs at the bright red rising sun in the misty, palm-tree-filled distance. It was quite a view, totally worth it. <b>Laters.</b></p>
                            </>
                        }
                    </article>
                    <article id="sapta" className="blog__article">
                        {language === "HU"
                            ? <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">सप्त Sapta</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">A Guru és én<br /><span className="heading__sub align--center display--block">1. rész</span></h2>
                                </hgroup>
                                <p>A Guru azt jelenti tanár. Na jó van sok féle értelmezése ennek a szónak, jelentheti azt, hogy vezető, vagy az egyik kedvenc rap-eremet (R.I.P.), szakértő, spirituális pásztor meg bánom is én még mi, a lényeg, hogy a mi gurunk az egy tanár. Jó azért nem szimplán egy jóga tanár, különben itt se lennénk, de ne is úgy képzeld mint az Eat Pray Love-os lélek gurut.</p>
                                <p>Malcolm Gladwell sokszor ismételt elmélete szerint 10,000 óra a mágikus szám a nagysághoz. Ennyi időt kell beletenni bármibe, hogy világ klasszis példakép legyél valamiből. Napi 3 órával számolva ezt kb 9 év elérni. Maradjunk annyiban, hogy ezt Sharathji egy párszor már lekörözte gyakorlásból és tanításból is. És ezt persze érzed a közelében. Ez a legnyugatibb megközelítés ami segíthet megérteni mit jelent vele gyakorolni. Mintha Van Gogh tanítana festeni, vagy Lewis Hamilton tanítana vezetni.</p>
                                <p>Tavaly kezdődik a sztorim vele. Én úgy jöttem ide, hogy hallottam ezt-azt, meg gyakoroltam valameddig, valahogy és ennyi. Nem sokat reméltem és nem is igazán aggódtam. István annál inkább, nagyon sok extra energiát bele tett abba, hogy a lehető legfelkészültebb legyek. Much love. Egész az első gyakorlásig (ami vezetett óra volt) rá voltam erre lazulva, de ott egyből rájöttem, hogy ezt itt nem lehet el himi-humizni. Ő is rájött (legalábbis így éreztem), hogy nekem több törődés kell.</p>
                                <p>Egész sokszor odajött már az első naptól és segített, igazított vagy csak szurkolt aztán morgott mikor nem hoztam valamit össze. Az is persze hozzájárult gondolom ehhez, hogy a szezon beginner kategóriáját életműdíjjal nyertem. Egész hamar meg kaptam az első nevemet is, amit én úgy hallottam (aztán lehet beképzelem, de nem egyszer hallottam mondani), hogy:</p>
                                <blockquote>Elefánt, menj oda!<span>Sharathji the OG (Original Guru)</span></blockquote>
                                <p>Két dolog erről, hogy:</p>
                                <ol>
                                    <li>Akármilyen gyász név ez, voltak irigyeim mert kiléptem a “You” kategóriából (bi-bi-bí??)</li>
                                    <li>A másik, hogy pff oké, nem voltam épp gebe de azért nagyon kövér sem, de ami nem segített, az a sereg sportos-sovány-izmos jógi akik körülvettek.</li>
                                </ol>
                                <p>Abban a kontextusban megértem, haragudni se tudtam rá. Amikor elindultam a matracommal a kijelölt helyemre a tatami-ra rám mosolygott. Én is mosolygtam de azért bele rejtettem egy kis grimaszt. Olyan tiszteletteljesen lázadok félét. Vagy olyan lehetett mint amit Kumar kap mikor belép elém a sorba a boltban. Mindegy a lényeg, hogy azt üzente a búrám, hogy oké-értem-de-szeretném-hogy-tudjad-ennél-jobban-is-megy. Az elvegyülés amúgy nehezemre esik.</p>
                                <blockquote>Nincs több chapati és tészta<span>Sharathji Dietetikus Tanácsadó</span></blockquote>
                                <p>Valahol a második hét vége felé, szóval olyan tíz gyakorlás után, nem tudtam egy reggel menni a vezetett órára, mert hasmenésem volt. Az itt néha van az embernek bárhonnan jön. Másnap a sima Mysore classnál csináltam a kis egy-másfél órás ücsörgős várakozásomat Diával, Rózsával meg a többiekkel, mikor oda jött hozzánk Sharathji, rám nézett és nekem szegezte ezt:</p>
                                <blockquote>Hol voltál tegnap? Nem gyakorolsz?<span>Sharathji a kiképző tiszt</span></blockquote>
                                <p>Mondtam neki, hogy “stomach issues”, meg “sorry, sorry” de ez nem hatotta meg.</p>
                                <blockquote>Attól jönnöd kell, ez nem kifogás, itt is van WC. Miért vagy ilyen lusta?!<span>Sharathji a kiképző tiszt</span></blockquote>
                                <p>Ez volt a pont ahonnan a nevem <b>Skipper</b> lett. Egész pontosan néhány napig így hívott, majd, gondolom én, hogy tovább büntessen, egy fejmozdulattá lettem. Nagyon nehéz itt eldönteni, hogy mi az amit belemagyaráz az ember a szitukba, és mi a guru szándéka. Ez igen rendszeres téma az itt gyakorlók között. Egy valami viszont rendesen sokkoló, hogy hány felé figyel Sharathji, mintha mindenki szemével is látna. </p>
                                <p>Ment ez így kb egy hétig, amikor egyszer csak az az érzés fogott el paschimottanasana közben (ülsz a földön egyenes lábakkal, belekapaszkodsz a nagylábujjaidba és előre hajolsz), hogy vége a büntimnek, most már nem vagyok Skipper. Abban a pillanatban ahogy ez belémhasított Guruji keze a hátamra került, és elkezdett lefele nyomni. Nem tudtam, hogy ott van mögöttem. Azért ez elég spiri volt nah. Innen folytatom. <b>Salut.</b></p>
                            </>
                            : <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">सप्त Sapta</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">The Guru and I<br /><span className="heading__sub align--center display--block">Part 1</span></h2>
                                </hgroup>
                                <p>Guru means teacher. Well, Okay, this word has many interpretations; it can mean a leader or one of my favourite rappers (R.I.P.), an expert, a spiritual guide, or who knows what else. The point is that our Guru is a teacher. But think of him as something other than just a yoga teacher; otherwise, we wouldn't be here. And don't imagine him as the soul Guru from "Eat Pray Love".</p>
                                <p>According to Malcolm Gladwell's often repeated theory, 10,000 hours is the magical number for greatness. You need to invest this amount of time in anything to become a world-class expert. Calculating with 3 hours per day, it would take about nine years to achieve this. Let's say that Sharathji has surpassed this a few times over, both in practice and teaching. And you can feel this around him. This was the most Western approach I could think of to help you understand what it means to practice with him. It's like Van Gogh teaching you painting or Lewis Hamilton teaching you to drive.</p>
                                <p>My story with him began last year. I came here having heard this and that, practising to some extent, somehow. I didn't expect much, and I wasn't really worried. István, on the other hand, put a lot of extra energy into making me as prepared as possible. Much love. Right up to the first practice (a led class), I was relaxed about it, but I immediately realised that you can't just fake your way through it here. He also realised (at least that's how I felt) that I needed more attention.</p>
                                <p>He often came over from the first day and assisted, adjusted, or cheered and maybe grumbled when I couldn't put something together. I guess my winning the season’s beginner category award with a lifetime achievement also contributed to this extra attention. I quickly got my first nickname (or I might just have imagined it, although I heard it a few times), which I heard as:</p>
                                <blockquote>Elephant go there!<span>Sharathji the OG (Original Guru)</span></blockquote>
                                <p>Two things about this:</p>
                                <ol>
                                    <li>It’s not the most flattering name; still, some were envious as it meant I moved out from "You" (yay?).</li>
                                    <li>Second thing, okay, I wasn't exactly thin, but I wasn’t fat either; what didn't help was the crowd of athletic, agile, and muscular yogis surrounding me.</li>
                                </ol>
                                <p>But in that context, I understood and couldn't be mad. When I was walking with my mat to my designated spot on the tatami, he smiled at me. I smiled back, but I hid a little rebellious grimace in it. Or it looked like the one I gave someone when they cut in front of me in a queue. Anyway, It got the message across: "Okay, I understand, but I want you to know that I can do better." Blending in is not my thing.</p>
                                <blockquote>No more chapati and pasta for you<span>Sharathji Dietetic Advisor</span></blockquote>
                                <p>Somewhere towards the end of the second week, after about ten practices, I couldn't go to the led class one morning because I had diarrhoea, which can happen to anyone here. The next day, during the regular Mysore class, I was doing my little one-and-a-half-hour waiting with Dia, Rózsa, and the gang when Sharathji came over to us. He looked at me and said:</p>
                                <blockquote>Where were you yesterday? You are not practicing?<span>Sharathji the Drill Sergeant</span></blockquote>
                                <p>I told him, "stomach issues" and said, "Sorry, sorry," but it didn't move him.</p>
                                <blockquote>You should come, that is not an excuse, we have toilets in the locker room. Why are you so lazy?!<span>Sharathji the Drill Sergeant</span></blockquote>
                                <p>That was the point where I got my new nickname, <b>Skipper</b>. That's what he called me for a few days; then, to further punish me, I became only a head nod. It's tough to decide here what is one's interpretation and the guru's intention. This is a regular topic among practitioners. One thing, though, is consistently shocking: how much Sharathji is aware of everyone, as if he can see through their eyes.</p>
                                <p>This continued for about a week until suddenly, in the middle of Paschimottanasana (sitting on the ground with straight legs, grabbing your big toes, and bending forward), I felt the punishment was over, and I was no longer Skipper. The moment this realisation hit me, Guruji's hand landed on my back, and he started pushing me downward. I didn't know he was right behind me. It was pretty spiritual. I'll continue from here. <b>Salut.</b></p>
                            </>
                        }
                    </article>
                    <article className="blog__article">
                        {language === "HU"
                            ? <>
                                <p className="box no-margin--bottom">
                                    <strong className="margin--bottom">Farewell Kelvin</strong>
                                    A Kelvinator az örök fagyás mezőkre távozott. Mire hazaértünk a gyakorlás után már nem volt itt. Lalitha intézte így. Helyette itt maradt ez a Sarsung ami úgy néz ki mintha a mai Schwarzenegger játszaná az I’ll be back-et a Terminator első részében. Amúgy kérdeztem és Kelvin nem tér vissza. R.I.P.
                                </p>
                            </>
                            : <>
                                <p className="box no-margin--bottom">
                                    <strong className="margin--bottom">Farewell Kelvin</strong>
                                    Kelvinator has departed to the eternal frosting grounds; as we returned from practice, it was no longer here. Lalitha took care of it. In its place, we have this Samsung, which looks like how the Terminator would look if Schwarzenegger played the role today. By the way, I asked, and Kelvin won’t be back. R.I.P.
                                </p>
                            </>
                        }
                    </article>
                    <article id="astha" className="blog__article">
                        {language === "HU"
                            ? <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">अष्ट Astha</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">Ez ilyen lassú aerobik, nem?</h2>
                                </hgroup>
                                <p>Az Ashtanga jógiságoskodást Budapesten kezdtem a <a href="https://bandha.works" className="link" target="_blank">BandhaWorks</a>-ben. Nem magamtól tapadtam erre rá, hanem Dia már jó ideje nyomta olyan kitartóan, hogy az megbirizgálta a kiváncsiságom. Akkoriban életemben talán először volt egy kosarazós rutinom (heti öt-hatszor lementem), szintén Dia rendszeres gyakorlásából inspiráltan. Szóval muszály volt ezt megnéznem magamnak. A fő motivációm egyébként az volt, hogy visszaszerezzem a kontrollt a befeszült testem felett.</p>
                                <p>Amúgy a címben feltett kérdés egy Angel nevű ex-kollegámból esett ki egy céges osztály találkozón, mikor összefoglaltam mibe vagyok épp: nem iszom, nem cigizem, vegán vok, jógázom. Nincs valami jó marketingje ezeknek a dolgoknak Magyarországon, szerencsére szeretem a viccet meg a trollkodást, de azért hát, hogy is mondjam, “fuck you” és “xd”.</p>
                                <p>Emlékszem mikor még nagyon zöld gyakorlóként, kb néhány hónap után azt hittem, a bandhákról való iszonyat minimál olvasás után hogy értek mindent, hogy ez sima. Egy gyakorlás közben úgy éreztem, ez az, most aztán minden bandhát feszítek egyszerre (Maha Bandha). Mentem is óra után Istvánhoz dicsekedni, mire ő kedvesen és tiszteletteljesen lecsicskított, hogy:</p>
                                <blockquote>Igen? Az nem semmi, mondjuk a sok-sok év alatt ez nekem még nem jött össze, de hajrá.<span>István at work</span></blockquote>
                                <p>Kicsit vissza is vettem az arcomból gyorsan.</p>
                                <p>Mikor először lementem Andival kezdtem gyakorolni, megmutatta az első azt hiszem négy asana-t aztán abban maradtunk, hogy “juj-juj húha”, szóval, hogy elég nekem egy kevés póz így az elején. Egyébként ez “juj-juj húha” egy visszatérő reakció egész sok tanártól amikor megpróbált rajtam segíteni, vagy épp új asana-t adni. Andi nagyon rendi volt amúgy, én meg hajlékony voltam mint egy vasrúd, de megnyugtatott(?), hogy ez még csak a semmi.</p>
                                <p>A Covid lezárások előtt pár hónappal volt ez amikor pont jött Réka tanítani mint vendég tanár. Istvántól be voltam szarva (tisztelet), Réka kicsit más volt. Eggyel mosolygósabb lett a hangulat de azért ha közel jött szorítottam mindent ahogy tudtam. Ügyesen kombinált egy laza, it’s all good érzést egy ilyen poroszos nincs kiszállás szerű módszerrel. István egy kicsit talán jobban befelé figyelős atmoszférát teremt mikor tanít, hallani lehet ahogy néz, és ez elég, hogy visszaterelje a figyelmed a légzéshez. Érezni lehet rajtuk az elhivatottságot ami eléggé ragályos.</p>
                                <p>Megtiszteltetés velük gyakorolni, rengeteg figyelmet és segítséget kaptam tőlük, amiért hálás vagyok (much love). Az elvárás, bár ez a szó nem igazán illik ehhez a jógához mert ez rajtad múlik, hasonló volt, minden nap gyakorolj, mintha csak fogatmosnál.</p>
                                <p>A lényeg, hogy nagyon jók a tanárok a BandhaWorks-ben, ami miatt sok rendszeres gyakorló van. Ha valaki innen megy Indiába, jól felkészítenek erre, full parampara van (a Guru tanításainak továbbadása), amit tapasztalatból tudok, confirmed. Ha érdekel milyen ez és Budapest körül laksz, ajánlom, hogy próbálj ki egy kezdő tanfolyamot (<a href="https://bandha.works/tanfolyam/" className="link" target="_blank">link a tanfolyamokra</a>). Veszteni valód nincs max néhány kalória.</p>
                                <p>Erről jut eszembe egy másik kedves István sztorim. Szóba hoztam már az ünnepi úszó gumim, amit korábban is felvettem, és a Covidos otthon rohadás segített is ezt szépen felfújni. Valamikor amikor visszatértünk gyakorolni, nem is tudom melyik asana-nál voltak épp gondjaim (melyiknél nem?), de rákérdeztem Istvánnál, hogy mikor lesz ez jobb, még meddig kell kínlódni. Ezt válaszolta:</p>
                                <blockquote>Ahhoz… hogy ez megtörténjen… ahhoz… előbb bizonyos fizikai feltételeknek még teljesülnie kell.<span>István the Soft</span></blockquote>
                                <p>Ilyen finoman se mondták még nekem korábban, hogy “gyengusz vagy duci”, mekkora gól. <b>Zolly voltam, tisztelet.</b></p>
                            </>
                            : <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">अष्ट Astha</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">Is this like slow aerobics, right?</h2>
                                </hgroup>
                                <p>I started Ashtanga yoga in Budapest at <a href="https://bandha.works" className="link" target="_blank">BandhaWorks</a>. I didn't get into this on my own; Dia had been practising it for a while, and her dedication piqued my curiosity. At that time, for the first time, I had a regular basketball routine (I went five to six times a week), also inspired by Dia's regular practice. So, I had to check this yoga out for myself. My primary motivation was to regain control over my tense body.</p>
                                <p>By the way, the question in the title came up during a company reunion when I summed up what I was into: I quit drinking, I quit smoking, I'm vegan, and I do yoga. These things don't have a good rep in Hungary; fortunately, I enjoy jokes and trolling, but still, how should I put it, "fuck you" and "xd."</p>
                                <p>I remember when I was still a very green practitioner, after just a few months, I thought after a minimal reading that I understood everything about the bandhas, that it was easy. During one practice, I felt like, oh yeah, that's it, I was squeezing all the bandhas at once (Maha Bandha). I went to István after class to brag about it, and he kindly and respectfully put me in my place:</p>
                                <blockquote>Yeah? That's something. I've been practising for many years and haven't achieved that yet, but go for it.<span>István at work</span></blockquote>
                                <p>I quickly toned down my enthusiasm.</p>
                                <p>When I first started practising, I practised with Andi. She showed me the first four asanas, and then we settled with ”oh-oh ouch", meaning these few poses will be plenty for me. By the way, this "oh-oh ouch" is a recurring reaction from many teachers when they try to help or show me new asanas. Anyway, Andi was very kind; I, on the other hand, was as flexible as a steel rod, but she reassured(?) me that this was just the beginning.</p>
                                <p>This happened a few months before the Covid lockdown when Réka came to Budapest as a guest teacher. I was slightly afraid of István (respect), and Réka was different. The atmosphere became a bit more cheerful, but I still clenched everything as hard as I could when she came close. She combined a relaxed "it's all good" feeling with a strict, demanding practice method. When István teaches, he creates this introverted atmosphere; you can hear him as he watches, and it's enough to draw your attention to your breathing. You can feel their dedication, and it's pretty contagious.</p>
                                <p>It's an honour to practice with them, and I'm grateful for the attention and support I've received (much love). Although this word doesn't quite fit with this yoga because it's up to you, the expectation was similar: practice every day as if brushing your teeth.</p>
                                <p>The bottom line is that the teachers at BandhaWorks are great, which is why there are many regular practitioners there. If you're going to India from here, they prepare you well; it is full parampara (following our Guru's teachings), and I can confirm that from experience. If you're interested in this and live in Budapest or nearby, I recommend trying a beginner's course (<a href="https://bandha.works/tanfolyam/" className="link" target="_blank">link</a>). You have nothing to lose except maybe a few calories.</p>
                                <p>This reminds me of another tale with István. I've already mentioned my spare tires (love handles) that I used to wear, which the Covid-induced home rot helped me inflate. At some point, when we returned to practice, I don't remember which asana was causing me trouble (which one didn't?), but I asked István when this would get better and how much longer I had to struggle. His response was:</p>
                                <blockquote>To... make that go away... certain physical conditions still need to be met.<span>István the Soft</span></blockquote>
                                <p>I've never been told so gently before that you’re “weak and fatty,” what a goal. <b>I was Zolly, respect.</b></p>
                            </>
                        }
                    </article>
                    <article id="nava" className="blog__article">
                        {language === "HU"
                            ? <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">नव Nava</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">Végre péntek</h2>
                                </hgroup>
                                <p>István csütörtök este kérdezte, hogy van e kedv rárepülni az új Scorsese/Leo filmre amit épp játszani kezdtek a mozik. Mondom, hogy a viharszigetbe ne lenne. Másnapra, péntekre terveztük, hogy megyünk, egyébként aznap ünnepeljük Diával a 18. évfordulónkat (igen, annyi), és mivel alapból pálmafás helyen vagyunk a filmezés az okénak tűnik mint program.</p>
                                <p className="box">Egyszer voltunk Mysoreban moziban, pár hete, a “The Creator”-t néztük és egész jó volt az élmény. A film előtt mindössze néhány reklám volt, egytől egyig arról szólt, hogy a cigibe beledöglessz, és nem túlzok, hogy rákos szerveket szedtek ki sebészek a műtőasztalon, meg véglegesen előrehaladott testi elváltozásokkal szenvedő embereket mutattak elrettentésképp. Nagyvásznon. Elég hatásos volt. Nem olyan kis himi-humi mint otthon a cigisdobozon a képek. Amúgy mindezek előtt lement az Indiai himnusz amit persze állva végig kell hallgatni. A filmben minden trágár szó ki volt vágva a hangból és a feliratból is, és akármikor valaki rápirított egy blázra (még amikor egy robot is) kiírták a sarokba, hogy “smoking kills”. Very informative.</p>
                                <p>Közben megjött Mysoreba Matej, Natalie férje Szlovéniából, és megbeszéltünk egy pénteki közös welcome reggelit a Santoshában, ami egy ilyen kellemes kis földönülős hely, persze csak ha nem az asztalnál ülsz. Van ott is dosa de azért nem egy Paradise Hotel. Hetti és Andris is tapad a filmezésre, hosszú hétvége jön szóval szétcsaphatjuk magunk extra large popcornal, fröcsöghet a pepsi meg ami van.</p>
                                <p>Két dosa-val (meg még egyel) később próbálom meg venni a jegyeket, de nagyon vergődős itt minden ilyen dolog. Semelyik kártyám nem szeretik ezek a finnyás site-ok. A Next plázában lévő Inox nevű mozi ahol ilyen normálabb időben vetítik a filmet, este hatkor. Amúgy ez is necces mert Scorsese úgy csinál az utóbbi években mintha minden karira terrás vinyókat kapna, vagy csak unja a vágást, nem tudom, de minden filmje ilyen reciprok tik tok, száz év hosszú. Ez a film laza négy óra.</p>
                                <p>A baj az, hogy nem nagy kedvem van csak a jegy vásárlásért átvergődni a már felébredt méhkason meg aztán vissza. Hirtelen bevillan a kultúrám, hogy fizethetek is néhány rúpiát valami Indiainak (outsource), hogy megvegye a jegyeket nekünk a helyi kártyájával (support). A felszolgáló arc Dilip (ejtsd: Dgyilipppö), simán beadja, veszi is a jegyeket közép-középre, aztán küldi emailben, nagyon sima. Heten megyünk este végül mert Natalie-ék is érzik (plusz Dia, István, Mr. Bimba, Hetti és szerénységem). Minden kész és még csak 11 óra. Jó mondjuk 3-kor kezdtük a napot mint a pékek.</p>
                                <p>Kicsit beszélgetünk, aztán egy kávé, aztán taka haza. A Natalie és Matej reunion tiszteletére Dia bedobja, hogy csináljunk valami ebédet, szóval irány a Loyal World nevű szupermarket. Ma megint van szép Oyster (laska) gomba szóval kitalálunk abból valamit. Amúgy nem tudom mondtam e, de itt valahogy minden finomabb. A zöldség, gyümölcs, bármi, olyan mint gyerekkoromban. Diával összeszedünk mindent ami kell, egyébként nem dicsekedni akarok de elég legendary házi gizdák vagyunk.</p>
                                <p className="box">
                                    <b>A menü:</b><br />Barnarizs spagetti, pirított kakukkfüves oyster gomba, sült koktélparadicsom gyömbérrel és curryvel, párolt brokkoli, egy lágy hummusz, Dia pedig viszi saláta bárt abból ami van (paprika, uborka, paradicsom, jégsali, gránátalma), a vendégvárást, szórakoztatást, mehet a fejpöri.
                                    <br />
                                    <br />
                                    <b>A tálalás:</b>
                                    <br />
                                    Rizstészta, a tetejére megy a gomba összekeverve a paradicsommal, arra a párolt brokkoli majd az egészet szépen ízlésesen nyakon öntjük a humusszal, mellé pedig salátát teszünk.
                                </p>
                                <p>A Jamie Zollyver show itt véget ér, de azt elárulom, hogy nagyot ment a cucc, nem volt panasz.</p>
                                <p>Amúgy Matej elég sokat késett, ami kezdett gyanús lenni. Mivel jó volt a társaság senki se nézegette a telóját amikor egyszer csak kiderült, hogy Natalie lazán bezárta a reteszt mikor elindult (Matej még melózott), és csóri gyerek már egy jó ideje próbálta ezt jelezni.</p>
                                <p>Mint egy hős lovag felpattanok a paripámra, hogy kiszabadítsam ezt a ádámcsutkás szőrös Szlovén herceget a vártoronyból (amúgy egy ház tetején vannak a harmadikon), aki az ablak rácsot rázva várja a megmentőjét. Jó arc amúgy ÉNÉSÉN-es pólóba csapatja (a bandám ha nem vágesz, hozott a srác merch-öt Indiába), tisztelet my G. A rakománnyal vissza suhanok, aztán befejezzük szépen az ebédeztetést.</p>
                                <blockquote>Let’s go to the mall… today<span>Robin Sparkles</span></blockquote>
                                <p>Indulhat a flúgos futam, csúcsidőben. A pláza kb 35 perc motorral. Volt már erről szó, annyira zakkant ez az egész, hogy beszarsz. Egy jelenet amúgy retinába ég, egy irodai munkás kosztümbe öltözött nő valahogy betéved majdnem az út közepére miközben sétál. Gyalogos, tehén, szamár, motor minden összefog ellene. Jövünk, mellettünk jobbra egy busz, balra maradék India, és hát senki nem igazán le se szarja szegényt, és így valahogy ki-háromszögezik a közlekedők egy ilyen egy négyzetméteres területre, ami persze mozog. Próbál menni mindenfele és végülis megy is, de elég wtf nézni amibe van. Ha emlékszel a Highway Crossing Frog játékra (békával kell átmenni az autópályán) maradjunk annyiban, hogy annak a békának itt annyi esélye lenne mint hóembernek a sivatagban.</p>
                                <p>Na mindegy, megcsináljuk, átjutunk. Ennél a plázánál nagyon nagy a biztonság, mielőtt lehajthatunk le kell szállni a motorról és villantani a csomagtartót. Mondjuk azon kívül, hogy belenéz a szekus semmi nem történik, szóval kicsit kételkedünk, hogy ez mire való de hát mit tudhatok én. Parkolás után szintén át kell menni egy fémdetektoron ami rendre sípol, mert hát erre való, meg van nálunk mindenféle dolog, és senki nem mondja, hogy ne legyen. Azért erre itt már bekeményít a security néni, szépen kedvesen ránk mosolyog és azt mondja:</p>
                                <blockquote>Namasté<span>Madame Security</span></blockquote>
                                <p>Nagyon para. Remegek. Alig bírok megszólalni, majd elcsukló hangon, nehogy még a végén ne engedjen be minket, annyit merek kinyögni:</p>
                                <blockquote>Namasté<span>Zolly a Befosott</span></blockquote>
                                <p>A mozi előtt van egy kapu amit valamiért a kezdés előtt tíz perccel nyitnak csak ki, aztán mikor bejutunk végre, a büfében csak egy ember van aki rendeléseket vesz fel, és igen, ez egy igen tipik Magyar megállapítás, tudom. Amúgy sebaj nincsenek sokan. Ennyi chilli után mind úgy tárazzuk a kukoricát mintha nem lenne holnap, hirtelen átfut a fejemen a Super Size Me című film, aztán meg ki szalad a számon. Nagyba vagyunk ma nah.</p>
                                <p>Elfoglaljuk a helyeinket, vagyis hát leülünk a sorunkba valahol mert elég üres a mozi. Nagyon patika tiszta az egész (már szint fura ez), a kép, a hang tiszta, az ülések meg olyanok, hogy ha hátradőlsz akkor félig fekhetsz benne. Most nincs annyi baj a cigivel, nem akarják itt, hogy hányjunk, Marvel előzetesek viszont vannak, amik belőlem már hasonló reflexet váltanak ki. A film kezdés előtt, két Indiai srác érkezik mellém, és szépen megkér, hogy húzzunk már a helyükről. A teremben rajtunk kívül van kb öt másik ember. Ez legalább annyira fura mint, hogy tiszta a szék, meg egy hónap India után nem is igazán értem mi ez a szabály követés, de közben meg tisztelet van bennem mert hát ja, nem a helyemen ülök, szóval szépen leülünk ahova kell. Már-már helyienek éreztem magam egy percig de gyorsan is ki is rántották ezt a gondolatot a fejemből.</p>
                                <p>A félidős szünetben, mert igen itt van olyan, mint a színházban, ki lehet menni rötyire meg még extra takarmányért. Csak miattad, te miattad aki ezt olvassa, rendeltem egy sült krumplit. Miattad eszem te állat. Kiakartam próbálni milyen ez, hogy írjak róla. Dia közben fázik meg alszik, betakarta magát mindennel ami nálunk volt. Folytatódik a film és olyan húsz perccel beljebb, hozzák is a sültkrumplit.</p>
                                <p>Ja igen a rendelésnél mondani kellett a termet meg az ülést, nem csak tippelték hol vagyok. A krumpli amúgy lemarja az arcom, valami peri-peri-vel azért leszórták, hogy legyen íze. Adtak kis ketchupot is amivel próbálnám a mérget kiszívni a krumpliból, de a ketchup is chillis szóval az is csak pofoz egyet a nyelvemen. Közben Dia néha felkel mikor valakit fejbe lőnek, ami így ütemszerűen van előadva ebben a filmben. Amúgy jó mozi volt, ajánlom megnézni (Killers of The Flower Moon), ritka már az ilyen mély és ennyire jól eljátszott történet ebben a világban. Szép hetet, még majd jövök. <b>Pá.</b></p>
                            </>
                            : <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">नव Nava</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">TGIF</h2>
                                </hgroup>
                                <p>István asked on Thursday evening if we were up for catching the new Scorsese/Leo movie that just started playing in theatres. I told him, hell yeah. We planned to go the next day, which, by the way, was also our 18th anniversary with Dia (yes, that long). Since we were in a palm tree paradise, to begin with, going to the movies seemed like a great idea.</p>
                                <p className="box">We've been to a cinema in Mysore once, a few weeks ago, to see "The Creator," and it was pretty nice. Before the movie, there were only a few commercials, all about the dangers of smoking. I'm not exaggerating; they showed surgeons removing organs from a smoker on the operating table and people suffering from advanced health issues due to smoking. It was on a big screen and quite effective, much more so than the little warning pictures on cigarette packs back home. Before the movie, they played the Indian national anthem, which you need to stand through. In the film, every swear word was cut from the audio and subtitles, and every time someone lit up a cigarette (even a robot), they displayed "smoking kills" in the corner. Very informative.</p>
                                <p>Meanwhile, Matej, Natalie's husband from Slovenia, arrived in Mysore, and we decided to have a welcome breakfast at Santosha on Friday. It's a cosy little sit-on-the-floor spot, except, of course, if you're sitting at a table. They have dosa there, but it's no Paradise Hotel. Hetti and Andris are also up for the movie, and since it's a long weekend, we can bury ourselves in extra-size popcorn, a river of Pepsi, and whatever else they have.</p>
                                <p>A couple of dosas later (and one more extra), I tried to buy the tickets, but it was quite a struggle. None of my cards were to the liking of these picky websites. The Inox cinema in the Next Mall is where they show the film at a more reasonable time, at 6 pm. However, it's still risky because Scorsese seems to have got TB hard drives for every Xmas or just got bored with editing, so he has a thing for long, epic movies these days. This one was a leisurely four hours.</p>
                                <p>The problem is, I didn't feel like going through the hassle of getting tickets and returning to the already buzzing beehive. Then, it hit me that I could use my culture and outsource this task by paying an Indian guy to purchase the tickets with his local card (support). Dilip, the friendly waiter, was up for it, bought the tickets for the middle seats and emailed them to us. In the end, there were seven of us going in the evening – Natalie and Matej, Dia, István, Mr. Bimba, Hetti, and yours truly. Everything was set, and it was only 11 am. Not bad, but we also need to consider that we started our day at 3 am, just like bakers.</p>
                                <p>We chatted a bit, had coffee, and then went back home. In honour of Natalie and Matej's reunion here, Dia suggested we make some lunch, so off we went to Loyal World, the supermarket. They had beautiful oyster mushrooms today, so we decided to create a meal out of those. By the way, I don't know if I mentioned it, but everything tastes better here. Whether it's vegetables, fruits, or anything else, it's like a memory from my childhood. Dia and I gathered everything we needed, and without trying to brag, we’re legendary hosts.</p>
                                <p className="box">
                                    <b>The menu:</b><br />Brown rice spaghetti, sautéed oyster mushrooms with thyme, roasted cherry tomatoes with ginger and curry, steamed broccoli, a creamy hummus, and Dia was in charge of a salad bar with whatever we had on hand (bell peppers, cucumbers, tomatoes, iceberg lettuce, pomegranate). She was also in order of welcoming and entertainment, so it was time for some to spin on our heads and have some fun.
                                    <br />
                                    <br />
                                    <b>The serving:</b>
                                    <br />
                                    Rice noodles on the bottom, oyster mushrooms mixed with tomatoes on top, then the steamed broccoli, and we drizzled the whole thing gracefully with hummus and added salad on the side.
                                </p>
                                <p>That was the end of the Jamie Zollyver show. But I can tell you, the food was a hit – no complaints.</p>
                                <p>By the way, Matej was running quite late, which started to seem suspicious. Since we were all having a good time, nobody was checking their phones. Suddenly, it turned out that Natalie had casually locked the door behind her when she left (Matej was still working), and the poor kid had been trying to get our attention for quite a while.</p>
                                <p>I felt like a heroic knight jumping on my horse to rescue this Adam's-apple-bearded Slovenian prince from his tower (by the way, their flat is on the rooftop). He was waiting at the window, shaking the bars, wearing an ÉNÉSÉN t-shirt – my band's merch he brought all the way to India. Respect, my G. I rushed back with the package, and we finished our lunch.</p>
                                <blockquote>Let’s go to the mall… today<span>Robin Sparkles</span></blockquote>
                                <p>Let's start the whacky race during rush hour. It takes about 35 minutes on a scooter to get to the mall. As I mentioned before, it's so wild here that you can't help but freak out. One scene etched into my memory was a woman dressed in an office outfit who somehow ended up in the middle of the road while walking. Pedestrians, cows, donkeys, scooters – everything was coming at her from all sides. We were coming on one side, a bus on the other, and the rest was just the rest of India. No one really paid much attention to her, and the way everyone manoeuvred around her in this one square meter of moving space was mind-boggling. If you remember the game "Highway Crossing Frog" (where a frog has to cross a highway), let's say that that frog would have had about as much chance as a snowman in the desert here.</p>
                                <p>Anyway, we made it through the race. Security looks like a number-one priority at this mall. To park our scooters before entering, we had to open the trunks, although, aside from a quick peek by the security guy, little happened. So, we were sceptical about what this was all for, but who am I to know anything about this? After parking, we had to pass through a metal detector that beeped every time, considering we had all sorts of things with us, but no one told us not to. However, here, the security lady was firmer, looked really serious, and, while smiling at us, kindly said:</p>
                                <blockquote>Namasté<span>Madame Security</span></blockquote>
                                <p>It was terrifying. I was shaking, barely able to speak. I murmured something back, afraid she might not let us in, and all I could manage was:</p>
                                <blockquote>Namasté<span>Zolly the Shaken</span></blockquote>
                                <p>A cinema had a gate that they only opened ten minutes before the start for a reason. Finally, when we made it inside, only one person was working at the snack stand, and yes, I know, this is quite a Hungarian observation. But no worries, there weren't many people. After all the chilli-infused experiences earlier, we were stockpiling popcorn like there's no tomorrow. The "Super Size Me" film was on my mind; then it slipped out of my mouth as well. We were going all in today.</p>
                                <p>We took our seats, or rather, we sat somewhere in our row because the cinema was quite empty. Everything was spotless and nice, which was, to be honest, quite odd. The picture and sound quality were excellent, and the seats were comfortable; you could lie down in them. Smoking was less of an issue here; they didn't want us to vomit. However, Marvel trailers triggered a similar reflex in me.</p>
                                <p>Before the movie started, two Indian guys arrived and politely asked us to move from their seats. In the whole cinema, there were five other people besides us. Their ask was just as strange as the clean seats. After a month in India, I didn't quite understand this sudden rule-following attitude, but I had respect because, well, we weren't sitting in our assigned seats, so we took our seats as instructed. I felt like I belonged here for a brief moment, but that feeling was quickly erased.</p>
                                <p>During the intermission, yes, they have one here, similar to the theatre; you can go outside to stretch your legs, visit the loo or get some extra snacks. I ordered French fries – all for your sake, for the one reading this. I wanted to try them and write about it. Meanwhile, Dia was feeling a bit chilly and napping, bundled up in everything we had with us. The movie continued, and they bought the French fries about twenty minutes in.</p>
                                <p>By the way, when ordering, you had to mention the room and your seat number; they weren't just guessing where I was. The fries, of course, almost burned the skin off my face. They sprinkled some peri-peri seasoning on them to give them some flavour. They also provided a little ketchup, which I used as an attempt to neutralise the hotness, but the ketchup was spicy too. It was another punch to the tongue.</p>
                                <p>Dia occasionally woke up when someone got shot in the movie, which happened rhythmically. It was a great movie, and I recommend watching it ("Killers of The Flower Moon"). It's rare to see such a deep and well-acted story in this world. Have a great week, and I'll be back soon. <b>See ya</b>.</p>
                            </>
                        }
                    </article>
                    <article id="dasa" className="blog__article">
                        {language === "HU"
                            ? <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">दश Dasa</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">A Guru és én<br /><span className="heading__sub align--center display--block">2. rész</span></h2>
                                </hgroup>
                                <p>Ott fejeztem be a sztorit, hogy a Skipper napok véget értek. A vezetett órát követően mikor újra keze alá vett a Guru, másnap, kaptam egy új billogot.</p>
                                <p className="box">Nyilván ennek is van némi háttere, bár ez elég beszédes név lesz, azért kifejtem. Akkortájt a “Marichyasana A” volt az utolsó pózom, és hát egyedül a Vishnuért nem sikerült ez nekem. Ha nem tudod mi ez a póz (vagy ki az a Vishnu), a Google a spanod, de szavakkal leírva valami ilyen: ülsz, bal lábad nyújtva a földön, jobb lábad behajlítva-behúzva a mellkasodhoz, előre dőlve a jobb kezeddel balról átkarolod a lábad jobb irányba csavarodva miközben a jobb kezeddel a hátad mögött megfogod a bal csuklódat. Öt mély lassú légzésig tartod a pózt, aztán a másik oldalra ugyanez. Vishnu meg Isten.</p>
                                <p>Ezt a pózt így szerintem nem lehet felfogni, de a lényeg, hogy ez nekem annyira megy mint micimackónak gondolkodni. Nem mintha bármi könnyen menne ebben a sorozatban, de az egy másik téma, valami olyan címmel, hogy “Miért utálom (vagy szeretem?) magam ennyire?”.</p>
                                <p>Hogy még több perspektívát adjak a sztorihoz hagyj foglaljam össze neked, hogy nagyjából mi volt a napi rutinunk 2022-ben mint bőrnyakú első alkalmosok itt, az Ashtanga fővárosában, here you go.</p>
                                <p>A gyakorlók (sok száz van) több batch-re (csoportra) vannak osztva már regisztrációt követően. A tanárok, jedi mesterek hajnal 4-re jöhetnek, nekik nem kell várni csak leteszik a mandukát és csapathatják is, vagy levitálhatnak a föld felett, tudom is én mibe vannak. Akik már voltak itt legalább egyszer azok 6:30-ra jönnek, akik többször azok 5:30-ra, akik meg elsősök 7:30-ra vagy 8-ra jöhetnek (idén már 9-kor is van batch).</p>
                                <p>Dia, Rózsa és én 7:30-as időt kaptunk. Ez persze nem azt jelenti, hogy akkor kell a shala-hoz érni (jóga terem), no-no-no-no-noo (ezt próbáld úgy olvasni, hogy közben balra-jobbra billeg a fejed). Olyan hetven perccel előbb kellet minden reggel ott lenni a kinti kapu előtt, hogy ne a tömeg legvégére kerüljünk. Az út meg kb huszonöt perc szóval, hat előtt tíz perccel indultunk. Ott várni kell vagy száz emberrel egy harminc percet, ez azért fontos egyébként mert mikor beengednek, ha egyből hátulról indulsz akkor minden perc késésed felszorzódik mire ténylegesen kezdheted a gyakorlást. Szóval vársz kint, nyílik az ajtó és jön a light-os de annál intenzívebb tolakodás, meg rohanás az öltözőbe.</p>
                                <blockquote>Ne beszéljetek! A telót tüntesd el!<span>Sharathji Privacy Specialist</span></blockquote>
                                <p>Ledobod a cuccod, mert ugye a ruha alatt már fel vagy öltözve jógához és már kint elővettél minden szart ami kell, megpróbálsz közben nem felborulni miközben beléd botlanak, és taka ki a tatami elé leülni a földre egy halom ember közé. Persze első nem vagy, az lehetetlen.</p>
                                <p className="box">A Sharath Yoga Centre “tatami” egy ilyen ducktape-el kirakott négyzetrács 78 matracnak a helye van kijelölve (de ki számolja). Amikor vezetett óra van és 400 ember jön egyszerre akkor ezek a határok ilyen únióvá válnak, szóval mintha nem is lennének, de azért tudod, hol a helyed kezdőként, nem a Guru lábához mész.</p>
                                <p>Szóval ott ülök a földön, minimum 30 percet mikor egyszer csak:</p>
                                <blockquote>Befeszült vállú srác<span>Guruji a Megbocsátó</span></blockquote>
                                <p>Nem nagyon vágom le, hogy nekem szól elsőre ezért megismétli kicsit erélyesebben: </p>
                                <blockquote>Befeszült vállú srác gyere! Mi van, nem gyakorolsz?<span>GuruJi szteroidon</span></blockquote>
                                <p>Itt már mutogat meg egyértelműbb mi van szóval megindulok a helyemre egy halk röhögés kiséréteben a többi várakozótól. Persze rám mosolyog, jelzi, hogy nincs harag. Bizonytítottam, hogy nem vagyok Skipper, mondjuk azt is, hogy nem vagyok hajlékony. Mindegy azért ez jobb mint az elefánt. Amúgy az elefántokkal semmi bajom, cuki kis óriások.</p>
                                <p>Ez megy nem is tudom meddig, mert a napok itt gyorsabban tűnnek el mint a közpénz Felcsúton (full leszarom, több ilyen nem lesz, utálom az egészet, de jó poénnak tűnt). Az egyik reggel morcosabb vagyok az általános reggel hatos morcosságomnál (már ha ez egy szó egyáltalán), és amikor Sharathji hívja a stiff shoulder guy-t, mire a többi jógi kuncogni kezd, valahogy sikerül ezt mellre szívnom. Ahogy megyek be a helyemre amire mutat a főni, oda sikerül hajítanom egy ilyen már-kicsit-unom-ezt-a-poént szemforgatást a Gurura. Persze egyből megbánom mert rájövök, hogy elmúltam 5 éves nem kéne hisztiznem, de ez van, shots fired.</p>
                                <p>Ledobom a földre a kártyámat (shala card amin rajta a fejem, Sharathji feje, nevem, dátum amire jövök, ez fontos, hogy mindig elöl legyen vagy kapod a savat) és elkezdem letenni a szőnyeget, mikor egyszer csak megjelenik a végében és lepislogja a kártyám, aztán mosolyog egyet és elsétál. Mondom király legalább nem veszünk össze. Befejezem ami a sorozatom, és indulok a levezető helyhez ami egy hosszú paravánnal leválasztott rész. Amúgy néha azt érzem, hogy a levezető ami kb tizenkét asana (nem számoltam meg sorry vagyok) simán elmegy egy gyakorlásnak de ez más téma megint.</p>
                                <p>Szóval dzsanázok a paraván mögé, mikor hallom, hogy: </p>
                                <blockquote>Zoltan! Zoltan!!<span>Guruji?</span></blockquote>
                                <p>Visszafordulok, hogy mi van, mert ugye ez a nevem. Néz rám a Guru és kérdezi kézmozdulatokkal, hogy sikerült e megfognom Marichyasana-ban a kezem, mire jelzem neki, hogy a lónak a patát sikerült, majd int és mosolyog, hogy jó az, majd menni fog. Azért belül elpirulok, hogy jó, hogy fejlődik a kapcsolatunk.</p>
                                <p className="box">Hogy miért “fontos” dolog azon kívűl, hogy a tanár-diák kapcsolat itt inkább érződik apa-gyerek kapcsolatnak, az, hogy a Guru a neveden szólít, vagy, hogy picit személyesebbé válik e az utatok arról sokat gondolkodtam már. Nagyon sokan vagyunk itt, és elég futószalag érzést kelt ez. Mivel minden ember a saját életének főszereplője, ez elég feszítő tud lenni. Mind ismertük a Gurut valamennyire hírről, internetről egyéb helyekről mielőtt ide jöttünk, de itt gyorsan kiderül, hogy ő azért messzebb van mint gondolod. Ami viszont tényleg “áll a földön” érzés, hogy annak ellenére mennyi ember jön megy itt, Sharathji memóriájában rengeteg a hely. Ha nem is nevez mindenkit a nevén, megvan neki, hogy te ki vagy. Amiért engem jobban megfigyelt szerintem csak abból fakad (így gondolom legalább is), hogy sokat kellett tanítani. Más szavakkal, világítottak a felvett pózaim ezek között a jógik között.</p>
                                <p>Másnap, hogy bekérdezte Sharathji sikerült e az ön pacsi a hátam mögött, a szokásos rutin ment, vártam, hogy kezdhessek végre, hogy vége legyen ennek mára és mehessek enni meg aludni. Egyszer csak hallom, hogy:</p>
                                <blockquote>Zoltan gyere<span>Sharathji a Nevező</span></blockquote>
                                <p>Kicsit egyből felbátorodtam, de hát ismersz (persze csak ha ismersz), vastag a bőr a képemen mint egy elefántnak. Megyek befele a matracommal a küzdőtérre, ahol Sharathji nagy mosollyal vár mint a csávó a fénycsövekkel mikor begurul a repülő a parkoló helyére, mutatja, hogy hol szálljak le. Rám néz és mondja:</p>
                                <blockquote>Zoltan<span>Guruji az Astanga Papa</span></blockquote>
                                <p>Itt pedig azt sikerült kinyögnöm, hogy:</p>
                                <blockquote>Inkább csak Zolly<span>Zolly a Szemtelen</span></blockquote>
                                <p>Mondta, hogy “ok” és elsétált, nekem meg a fejemben mentek a fejfogós wtf gifek, meg a mic drop. Másnap reggel, úgy hívott be, hogy “Zolly”, szóval valahogy ez bevált. Andris kedvéért fejezem be ilyen kurtán, mert szeretek bajszot tépkedni (a legutóbbi post végét azt mondta kicsit összecsapkodtam). Hamarosan jön a 3. rész. <b>Békesség legyen már.</b></p>
                            </>
                            : <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">दश Dasa</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">The Guru and I<br /><span className="heading__sub align--center display--block">Part 2</span></h2>
                                </hgroup>
                                <p>I wrapped up the story just as Skipper's days were coming to an end. The day after the practice, when I was back in the hands of the Guru, I got a fresh stamp.</p>
                                <p className="box">Clearly, there's a story behind this; even though my new name will be quite self-explanatory, I'll explain it anyway. At that time, "Marichyasana A" was my final pose, and, well, I just couldn't nail it for Vishnu's sake. If you're not sure what this pose is (or who Vishnu is), Google's your buddy, but in words, it's something like this: you sit with your left leg extended on the ground, your right leg bent and pulled in towards your chest, leaning forward with your right arm wrapping around your right leg, twisting to the right, while your right-hand grabs your left wrist behind your back. You hold the pose for five slow, deep breaths, then repeat on the other side. Vishnu, well, is a god.</p>
                                <p>I think this asana can't be understood like this, but the main thing is that it's as easy for me as it is for Winnie the Pooh to think. Not that anything comes easy in this series, but that's a different topic, with a title like 'Why do I hate (or love?) myself so much?</p>
                                <p>To provide more perspectives on the story, let me summarise for you our daily routine in 2022 as first-time noobs here in the Ashtanga capital; here you go.</p>
                                <p>The practitioners (there are many hundreds) are divided into several batches after registration. The teachers, Jedi masters, can arrive as early as 4 am; they don't have to wait; they just put down their Manduka and can start practising or levitating above the ground; who knows what they are doing. Those who have been here at least once arrive at 6:30 am, those with more experience at 5:30 am, and the rookies can come at 7:30 am or 8 am (there's even a batch at 9 am this year).</p>
                                <p>Dia, Rózsa (Rose), and I got the 7:30 am batch. This, of course, doesn't mean that's when you should arrive at the shala (yoga place), no-no-no-no-noo (try to read this while shaking your head left and right). We had to be there every morning about seventy minutes earlier in front of the outdoor gate to avoid ending up at the very end of the crowd. The journey takes about twenty-five minutes, so we left about ten minutes before six. You have to wait there with around a hundred people for about thirty minutes, and this is important because when they let you in, if you start from the back, every minute of delay gets multiplied, by the time you can actually begin your practice. So, you wait outside, the door opens, and there's a light but intense rush and pushing as you fast walk to the locker room.</p>
                                <blockquote>Don’t talk! Put your phone inside!<span>Sharathji Privacy Specialist</span></blockquote>
                                <p>You drop your stuff because, of course, you're already dressed for yoga under your clothes, and you've taken out all the shit you need outside. You try not to topple over while people bump into you, and you quickly get out and sit in front of the tatami amidst a pile of people. Of course, you're not the first; that's impossible.</p>
                                <p className="box">At the Sharath Yoga Centre, the "tatami" is a grid of squares marked with duct tape, and there's a place for 78 mats (but who's counting?). When there's a led class, and 400 people come at once, these boundaries become a sort of union, so it's as if they don't even exist, but you still know your place as a beginner—you're not going to put your mat in front of the Guru's feet.</p>
                                <p>So there I am, sitting on the floor for at least 30 minutes, when suddenly:</p>
                                <blockquote>Stiff shoulder guy<span>Guruji the Forgiving</span></blockquote>
                                <p>I don't quite get it at first, so he repeats it a bit more emphatically:</p>
                                <blockquote>Stiff shoulder guy come! Are you not practising?<span>Guruji on Steroids</span></blockquote>
                                <p>Here, he starts gesturing more clearly, so I make my way to my spot with some quiet laughter from the other waiting students. Of course, he smiles at me, indicating there's no anger. I've proved that I'm not a Skipper and also that I'm not flexible. Anyway, it's better than being called an elephant. By the way, I have nothing against elephants; they're cute little giants.</p>
                                <p>This goes on, and I don't even know for how long because the days here pass faster than public money in Hungarian politics (I couldn't resist; there won't be more of these; I hate it all, but it seemed like a good example). One morning, I'm grumpier than my usual morning-at-six grumpiness (if this is a thing), and when Sharathji calls the stiff-shoulder guy, and the other yogis start giggling, somehow I manage to take it to heart. As I go to the spot the boss is pointing to, I throw in one of those "I'm a bit tired of this joke" eye rolls at the Guru. Of course, I immediately regretted it because I realised I was over five years old, and I shouldn't have thrown a tantrum, but I couldn’t help it: shots fired.</p>
                                <p>I drop my shala card on the floor (a card with my picture, Sharathji's picture, my name, and the date I'm attending; it's important to always have it in front of you, or you get yelled at) and start unrolling my mat when suddenly, Sharathji appears at the end of it, glances at my card, gives me a smile, and walks away. I think, "Great, at least we're not having a fight.” I finish my practice and head to the finishing postures area, which is separated by a long partition wall thingy. By the way, sometimes I feel that the finishing postures, which is about twelve asanas (I didn't count, sorry, I'm just lazy), is as intense as a full practice, but that's another topic again.</p>
                                <p>So, I'm walking behind the wall when I hear:</p>
                                <blockquote>Zoltan! Zoltan!!<span>Guruji?</span></blockquote>
                                <p>I turn back to see what's happening because, after all, he's calling my name. The Guru looks at me and asks with hand gestures if I managed to catch my hand in Marichyasana, to which I signal to him that I managed to catch the thin air only. He nods and smiles, indicating it's good, it will come, and that I can go. Still inside, I blush with the thought that our relationship is progressing.</p>
                                <p className="box">Why is it "important" that the Guru calls you by your name? The teacher-student relationship here feels more like a father-child relationship, and that is kind of like a turning point when your journey becomes a bit more personal. I was thinking about this a lot. There are so many of us here, and it can create a rather conveyor belt-like feeling. As each person is the main actor in their own life, this feeling can be quite itchy. We all knew the Guru to some extent from our teachers, the web, and other places before coming here, but it quickly becomes apparent that he is further than you think. What really gives you a jaw-drop feeling, however, is that despite the many people coming and going here, Sharathji has a lot of free space in his memory. While he may not call everyone by name, he knows who you are. The reason I think he noticed me more is simply because he had to teach me a lot. In other words, my asanas shone among these yogis.</p>
                                <p>The next day, after Sharathji asked me if I succeeded in the self-high-five behind my back, the usual routine continued. I was waiting to finally start, hoping to finish for the day and go eat and sleep. Suddenly, I hear:</p>
                                <blockquote>Zoltan come<span>Sharathji the Name-dropper</span></blockquote>
                                <p>I got a little galvanised right away, but you know me (of course, only if you know me), I have a thick skin on my face like an elephant. I enter the arena with my mat, where Sharathji is waiting with a big smile, like the guy with the light wands when the plane rolls into its parking spot. He shows me where to land. He looks at me and says:</p>
                                <blockquote>Zoltan<span>Guruji the Ashtanga Daddy</span></blockquote>
                                <p>And here, I managed to blurt out that:</p>
                                <blockquote>I prefer Zolly<span>Zolly the Shameless</span></blockquote>
                                <p>He said, “Okay,” and walked away, and in my head, I had all those mind-boggling WTF gifs and a mic drop. The next morning, he called me in as "Zolly," so somehow that worked. I'll finish now, for Andris' sake, because I like to pull his moustache (he said I finished my last post a bit hasty). The 3rd part is coming soon. <b>Let it be peace!</b></p>
                            </>
                        }
                    </article>
                    <article id="ekadasa" className="blog__article">
                        {language === "HU"
                            ? <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">एकादश Ekadasa</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">Rövidek</h2>
                                </hgroup>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">Cica vita</span></h2>
                                <p>Lett egy cicánk. Vagyis igazából lett egy néhány. A kutyák a macs/eb harcot itt toronymagasan nyerik. Ráadásul olyanok ezek a kutyák mint valami NPC-k (nem játszható karakterek, ez ilyen videójáték dolog), mintha copy paste lenne mind, és kb négy eredeti modell van összesen. Hajnalban néha olyan is van, hogy kisebb falkákban rohannak ugatva a motor felé mikor kanyarodunk, szóval kemények nah.</p>
                                <p>Az első emeleten lakunk és van erkélyünk, kertibútorral. Egyik reggel nyitom a függönyt és visszapislog rám egy cica a székből. Nem nagy termetű, de nem is kicsi, vörös foltos fehér alapon. Persze ahogy kinyitom az ajtót beszarik és elszalad, majdnem leesik egy emeletet. Ugrás pár nappal későbbre amikor az Amazon meghozza a száraz macskatápot. Így könnyebb lesz azért macskát fogni mint papayával. Ki is rakunk egy tálba kicsit este és behúzzuk a függönyt.</p>
                                <p>Másnap mikor hazaérünk a gyakorlásból, mint egy vetélkedőben, nyitom a függönyt, hogy lássuk a nyereményt. A tál üres, vagyis egy kevés maradt a hangyáknak, de cica sehol. Amúgy olyan kígyúrtak ezek hangyák, hogy hárman visznek egy száraz macskatápot. Kicsit töltünk még a kajára azért, ki tudja mennyi ilyen szteroidos hangya jön még.</p>
                                <p>Kövi nap, ugyanez a rutin, és bingó, most ott a nyeremény. Elfogyott a kaja. Dia a tárgyaló, neki a vibe-ja cicásabb, és szépen kezd is kialakulni a szükséges bizalom. A cica amúgy ahogy kifér belőle üvölt velünk, de gondolom ez csak kannada nyelv, kell itt a hangerő ebben a zajban. Szép lassan kap még enni, rágás nélkül tolja a száraztápot, kb így belélegzi az egészet. Bevásárlás, mosás, kispolgári dolgok.</p>
                                <p>Elkezdek főzni, jön majd Andreas ebédelni. Ebéd után kimegyünk a cicuhoz az erkélyre mert amúgy ott alszik reggel óta. Már meg is lehet simizni, de látszik nincs hozzászokva, meg a tough love-ot szereti, néha így odakap, odacsap. Egyszer csak lentről elkezd egy másik cica kiabálni. Ő lehet akinek egyszer adtam a napokban enni mert pont volt nálam egy tasak macskakaja. Van egy kutya tál a kapu előtt amibe este raknak a kutyiknak rizst, abba tettem neki kaját, és mikor elég messzi mentem rárepült. Szóval ő lehet az és nagyon hangosan kiabál. Gondolom éhes, itt minden éhes. Dia gyorsan lerohan és rak enni a kutyatálba. A cicánknak ez annyira nem bejövős, visszaszól, ideges, helyezkedik. Ez már az ő helye, mi már az ő turistái vagyunk.</p>
                                <blockquote>Miaooooo shhhh miauuuuuuu<span>Defender of the Balcony</span></blockquote>
                                <p>Megindul a cicánk, full bekapcsolja a gettót, neki rohan ennek a másiknak. Elkergeti a szomszédba és sarokba szorítja. Itt azért sajnálom az új cicát de rosszkor jött és hangosan. Elég para a harc és az effektek. Mi meg mozizunk egy háromnegyed órát, mintha a NatGeo menne. Ilyenek miatt tűnnek el itt a napok. Nem tudjuk meg mi a csata kimenetele, hogy kikapja a bajnoki övet, de az erkély macs azóta jött többször, szóval nem veszített. Van más macska is, egy fehér, fekete farokkal, váltogatják a széket a teraszon. <b>See ya kitty</b>.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">Sakk szakkör</span></h2>
                                <p>Andris elindított egy sakk szakkört, vagyis inkább suck kört mert mindenkit elver. Mr Bimba elég rég sakkozik a chess.com app-al. Ha beszélgetsz vele és szar unalmas vagy akkor előrántja az iPhone-t és lép egyet valamelyik partyban amiben benne van valakivel. Ültünk az Anokhi-ban ahol reggelizni szoktunk mostanában, és szépen mindenki feltette a telóra a játékot. Neki mentem de az a bajom, hogy hamar beleunok és elkezdek mindent leütni a tábláról. Andreas meg ugye kenegeti, bár elmondása szerint:</p>
                                <blockquote>Én nem vagyok jó játékos, csak nagyon szeretem a játékot, lenyűgöz<span>Andris Bullshitting</span></blockquote>
                                <p>Persze vágod, hogy aki ilyet mond az ellen esélyed nincs ha nem játszol folyton. Mostmár ott tartunk ahogy Dia kezd belejönni, hogy már ő is elver, és nem tartozik a kedvenc időtöltéseim közé veszíteni. A helyi sakkbajnokunkból esett ki ez a mondat arra a témára, hogy miért a rövidebb mantrát választotta amikor volt hosszabb is:</p>
                                <blockquote>Nincs nekem időm megnyugodni.<span>András a Chessdmegster</span></blockquote>
                                <p>A mantrás dolog háttere bonyolult meg nem is értek hozzá, csak hát ha már ugye ilyen türelmetlen vagy akkor lépjél már ember! Mindegy nekem sincs időm folyton veszíteni, szóval, király fekszöl, delete account, delete app, sakk matt, <b>Zolly out</b>.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">Aszfalatozás</span></h2>
                                <p>Van egy pár nagyon szar állapotú út itt. Azt hallottam Sharathji-től, hogy Gokulam, ami a jógik központi kerülete Mysoreban, nem is olyan régen még egy dzsungel volt. Ezt a hagyatékot jó néhány utca örzi. És annál tőbb út. Egy elég látványos városfejlesztés megy éppen, új led világítás, útfestés meg mindféle dolog pereg most. Mentünk egy gyaki után Hetti, Dia meg Andris enni az Anokhiba.</p>
                                <p>Ez a hely a Contour road-ra nyílik ami egy hosszabb és fontos út, ez köt össze nagyon sok helyet ahol eszünk, lakunk, vásárolunk stb. Elég leszart állapotban van, nem kátyuk vannak benne hanem rendes árkok, hiába vagy harminc méter széles mert néhol két méteren kell megoldani a kétirányú közlekedést, ami itt sokszor harminc méteren is necces. Szóval leparkolunk a motorral az Anokhi előtt mire odajön egy arc, és mondja, hogy betonozás lesz, vigyük feljebb a motort. Gondolom ok, csak reggelizünk egyet, az nem sok idő de kedvesen kér szóval csinálom amit mond. Bemegyünk enni, már kezd beállni a rendelés, én egy smoothie bowl-t nyomok granolával, meg néha egy humuszos toast-ot, többiek gyümölcs salit, pohár smoothiet ilyesmi. Nincs masala dosa, amit viccből kértem egyszer, azóta a pincér úgy hozza a smoothiem, hogy:</p>
                                <blockquote>Here is your masala dosa, sorry we are out of chutney<span>Funny G</span></blockquote>
                                <p>Bedaráljuk a brekfeszt azt beindulunk. A kapuba érve látjuk, hogy amíg gyümiztünk az út közepét ilyen néhány meter szélesen leaszfaltozták. Nem vesztegette itt az időt senki nagyon. Lezárással se szaroztak, szóval motor, autó, busz, minden a zsír új aszfalton csapatja. A munka minőségét még nem lehet megítélni, de azért amíg egy beton járda szigetet a nyugati téren otthon hónapokig raktak össze, itt egy reggeli alatt elkezdték, elvégezték és átadták egy komolyabb útszakasz részleges felújítását. Két nappal később meg lett az egész teljes szélességben. <b>Nice</b>.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">Palacsinta King</span></h2>
                                <p>Van itt Mysoreban néhány hely ahol adnak palacsintát, de az igazán király cuccért, hozzánk kell jönni. Ha még jobban el akarnám túlozni azt mondanám, hogy ez a pala egy világítótorony ami segít visszatalálni önmagadhoz mikor kezded az útad elveszíteni. Itt a recept ha érdekel (ha nem akkor is itt van):</p>
                                <ol>
                                    <li>
                                        <div className="display--flex flex-direction--column">
                                            Mixelés
                                            <ul className="list display--block list--disc">
                                                <li>2 szétnyomott banán</li>
                                                <li>1 bögre mandula tej (vagy bármilyen növényi tej, a bociknak maradjon szépen meg s tehén tej)</li>
                                                <li>1 evőkanál citrom lé</li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="display--flex flex-direction--column">
                                            Mixelés
                                            <ul className="list display--block list--disc">
                                                <li>2 evőkanál cukor (vagy valami édesítő, pl datolya szirup az jó bele)</li>
                                                <li>1 kiskanál sütőpor</li>
                                                <li>1 kiskanál fahéj</li>
                                                <li>1 csapat vanília aroma</li>
                                                <li>1 1/2 bögre zabliszt</li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="display--flex flex-direction--column">
                                            A tetejére
                                            <ul className="list display--block list--disc">
                                                <li>1 karikázott banán</li>
                                                <li>Juharszirup vagy lekvár</li>
                                                <li>Ha fancy vagy akkor áfonya</li>
                                                <li>Vagy amit akartok</li>
                                            </ul>
                                        </div>
                                    </li>
                                </ol>
                                <p>Turmix gépben vagy kézi turmixal csapasd szét az “1. Mixelés” alatti összetevőket, legyél ezzel alapos. Add hozzá a “2. Mixelés” alatti cuccost és pörgesd azt a gépet még egy darabig amíg szép lesz a tészta, pihentesd 5 percet.</p>
                                <p>Amíg a tészta pihen melegíts egy serpenyőt vagy palacsintasütőt közepes fokon. Ha van 1/3 bögre mérőd használd azt, ha nincs akkor egy kisebb csészét, merj a tésztából és folyasd a serpenyőbe, kb három adag egyszerre ráfér. Amikor a három adag rajta akkor kicsit így cibáld meg a serpenyőt minden irányba, hogy jobban szétterüljön a pala.</p>
                                <p>Süsd amíg az alja barna (elkezdenek majd légbuborékok megjelenni) aztán forgasd meg őket süsd barnára a másik felét is. Mehet a tányérra, rá a banán, szirup meg amit akarsz. <b>Easy</b>.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">Konferenciázgatások</span></h2>
                                <p>Arne, vagy ahogy én hívom Señor Espejel egy Mexikói jóga tanár, gyakorló, aki szerény tizenhat éve jár vissza a Gurutól tanulni. Mivel behízelegtem magunk Diával az 5:30-as csoportba (erről majd írok még a Guru és én 3-ban) van szerencsém Arne-val dumálgatni jóga után, mert kb egyszerre végzünk (ő mondjuk négykor kezdi de hát jedi mester). Mr Diaz (a teljes neve Arne Espejel Diaz, Mexikóban az apa után az anya vezetéknevét is megkapod) péntek délután felhívott, és megkért egy szívességre. Szeretné a szombati konferencia idején kölcsönkérni a telefonomat, mert az övé egy hokibb Android (mondjuk nekem is “csak” iPhone 13 van), és tartana egy Zoom meetinget.</p>
                                <p>Mondom szép dolog ez, lelépni mikor Guruji beszél és Zoomozgatni, de alapvetően segítőkész arc vagyok, szóval mondtam, hogy sima. Arra is megkért, hogy regisztráljuk be őt együtt, segítsek összerakni a konferenciát, meg ilyenek. Szóval nem egy tech mogul de legalább valamiben valaki lehetek itt. Elment este valami szülinapi buliba és az éjszaka kellős közepén érkezett meg hozzánk kilenckor. Ezt kb kibírni olyan itt mint gyerekként a szilvesztert, minden erőmre szükség volt, hogy nyitva maradjon a szemem (sokat pörgött a fejembe filmben Tom és Jerry mikor fogpiszkálóval támasztja ki Tom a szemhéját).</p>
                                <p>Dia már aludt mikor beesett, így lementem a kert részhez, ott csináltunk neki Zoom fiókot, meg konferenciát szombat reggel 9-re ami Mexikó városban péntek este 21:30. A meghívottak a tanítványai, a családja meg még mit tudom én kik. Egyszer csak nézem valami kifut a mosodás részről, aztán vissza. Aztán befut a székem alá és akkor már látom, ez egy patkány. Gyorsan tovább szalad. Arnénak mondom, hogy wtf jezus, mire rám néz:</p>
                                <blockquote>Hm it’s a small, it’s a mouse<span>Arne Zooming</span></blockquote>
                                <p>Szerintem nem egér volt de inkább legyen, hogy az volt. Abban maradunk, hogy a vezetett óra után, ami 8:15 körül ér véget oda adom a telóm és akkor csapathatja a konfot.</p>
                                <p>A szombati vezetett óra után (amikor Guruji diktálja a tempót, és mindenki azt követi, ugyanazt csináljuk) konferencia van, és az óra vége és a Sharathji Show között olyan 25 perc szokott lenni. Ez alatt kell sok száz másik emberrel egy nem sok száz férőhelyes öltözőben átvenni a szarrá izzadt göncöd, kimenni az udvarra levegőt kapni, meg esetleg valamit inni vagy enni (arcot mosni, fényvédőzni meg amihez gusztusod van).</p>
                                <p className="box">Tegnap egykor ettünk utoljára (péntek 13-kor RIP) és a vércukrunk Diával nem igazán találjuk, szóval rástartolok a büfére, Dia meg a kókuszos csávóra. Ha későn érsz ki már akkora lesz a sor, hogy kevés esélyed marad enni mielőtt kezdődik a konfi, bent meg nem lehet enni-inni. Szerzek gyorsan két cékla dzsúszt, egy zöld szmúúszit, kekszet, kávét, vizet, Dia meg közben iszik két kókuszt. Most már okék vagyunk.</p>
                                <p>Passzolom a telóm Arnénak és mondja, hogy maradhatnék a közelében mert hátha van valami, de már korábban ledobtam Guruji széke elé a motyóm, hogy halljam mit mond és inkább oda megyek. Ilyen közel még amúgy nem ültem. Amúgy se értem mit akar Arné, nem fogok koccolni miközben beszél a főni, mert akkor kapok.</p>
                                <p>Sharathji elkezdi a hatodik konferenciát a szezonban, érdekesek a témák, jön a Diwali, van annak kapcsán kis szervezkedés, mert lesz egy nagy party a Shalanál. Erről biztos írok majd (ha jó lesz). Mikor kajáról beszél engem néz (vagy csak beképzelem) és mosolyog, de már ledobtam jó pár kilót (és ő is) mióta itt vagyok, szóval nincs már elefánt a szobában.</p>
                                <p>Egy boríték van a széke alatt amit néha kicsit tologat. Elkezd arról beszélni, hogy ritkán történik meg, hogy egy gyakorló certified szintre lép, de a mostani szezonban ketten is vannak akik elérik ezt. A certified más mint az autorizált tanár, az kb egy ilyen Ashtanga PhD. Egyszer csak Guruji a színpadra hívja Arnét. Mondom magamban WTF a csávó most koccolt le konferenciát tartani Mexikónak mikor ebbe van?</p>
                                <p>De nem így van, csak én vagyok lassú, Arné jön fel a színpadra, óriási taps kiséretében. Könnyes a tekintete, több százan tapsolják meg a rengeteg munkát és évet ami alatt kitartóan napi szinten gyakorolt, itt volt. Hozott ajándékot Sharathji-nek, egy tradicionális szoknya ruhát (elfelejtettem a nevét sorry) cserébe megkapja a borítékot amiben benne az oklevél. Egyébként nem sok ember érte el ezt a szintet, ötven-néhány. Visszaül a helyére Arné majd Sharathji szól neki, hogy gyere vissza a telefonnal és mutasd meg a családnak a sok embert aki gratulál, majd visszajön kezében a telefonommal. Valahol itt esik le, hogy aaaaha, szóval akkor értem már. Tényleg lassú vagyok.</p>
                                <p>Amúgy volt egy másik nyom is amit Arné elejtett, meghívott minket egy bulira 13 órára. Ő persze tudta ez mi lesz és ennek a megnüplésére hívott, csak nem beszélt róla, érthető okból. <b>Felicidades mi Jefe</b>.</p>
                                <p className="box no-margin--bottom margin-lg--top">
                                    <strong className="margin--bottom">Kelvin visszatért</strong>
                                    A Kelvinator 2000 visszatért. Lent van a masógép meletti konyhánál, és fagyos mint az északi sark. Ez egy másik gép, egy klón, amit egy céllal küldtek vissza, hogy hidegre tegyen mindent is. Lalitha azt mondta csak szólok, és a mostani leharcolt kis gépünk terminálásra kerül, és Kelvin beköltözik. Még nem tudom merem e.
                                </p>
                            </>
                            : <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">एकादश Ekadasa</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">Shorts</h2>
                                </hgroup>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">Cat feud</span></h2>
                                <p>We got a cat. Actually, we got a few. When it comes to the cat/dog rivalry, the dogs here win by a landslide. Furthermore, these dogs are like some NPCs (non-playable characters, it's a video game thing), as if they were copy-pasted, and there are about four original models in total. Sometimes, smaller packs rush towards the motorbike in the early morning, barking when we turn. So, they're pretty tough.</p>
                                <p>We live on the first floor and have a balcony with garden furniture. One morning, I open the curtains, and a cat from the chair stares back at me. It's not big, but not small either, with red spots on white fur. Of course, as I open the door, it gets scared and runs away, nearly falling down the floor. Fast forward a few days when Amazon delivers the dry cat food. It will be easier to catch a cat with that than with papaya. We put some in a bowl in the evening and draw the curtains.</p>
                                <p>The next day, when we return from practice, it's like a game show. I open the curtains to see the prize. The bowl is empty, except for a bit left for the ants, but the cat is nowhere to be found. These ants are so ripped, by the way, that three of them carry a piece of dry cat food. We add more food in case more of these steroid ants show up.</p>
                                <p>The day after, with the same routine and bingo, the prize is there. The food is gone. Dia is in the living room; her vibe is more cat-friendly, and trust is slowly forming. By the way, the cat howls at us, but I assume that's just Kannada cat language; you need the volume in this noise. She slowly gets more to eat, swallowing the dry food without chewing, like inhaling it. Grocery shopping, laundry, mundane things.</p>
                                <p>I start cooking as Andreas is coming for lunch. After lunch, we go to the balcony to check on the kitty because that's where she's been sleeping since morning. We can already pet her, but it's clear she's not used to it, and she likes tough love; she sometimes bites or swats. Suddenly, another cat from downstairs starts yelling. It might be the one I gave some food to recently because I had a packet of cat food with me. There's a dog bowl in front of the gate where they put rice for the dogs in the evening; I put food in it, and when I backed away, she flew towards it. So, it might be her, and she's very loud. I think she's hungry; everyone's hungry here. Dia quickly rushes down to put food in the dog bowl. Our cat is not thrilled about this; she retorts, gets nervous, and asserts her territory. This is her place, and we are her tourists now.</p>
                                <blockquote>Miaooooo shhhh miauuuuuuu<span>Defender of the Balcony</span></blockquote>
                                <p>Our cat springs into action, fully engaging the "ghetto mode," and charges at the other cat. She chases the newcomer and drives her away, cornering her. I feel sorry for the new cat, but she came very loudly at a bad time. The battle and the effects are intense. We watch for about forty-five minutes as if it were National Geographic. It's moments like these that pass the days here. We don't know the battle’s outcome who will win the championship belt, but the balcony cat has returned several times since, so she didn't lose. There's another cat, a white one with a black tail, and they take turns on the chair on the balcony. <b>See ya kitty</b>.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">Chess club</span></h2>
                                <p>Andris started a chess club, or rather, a “stress club” because he beats everyone. Mr Bimba has been playing chess on the chess.com app for a while. If you're with him and you are boring, he'll pull out his iPhone and make a move in one of the ongoing games. We were sitting at Anokhi, where we usually have breakfast these days, and everyone downloaded the game on their phones. I challenged him, but the problem was that I quickly lost interest and started killing everyone on the board. Meanwhile, Andreas ripping us keeps saying:</p>
                                <blockquote>I am not a good player, I just really love the game, it fascinates me<span>Andris Bullshitting</span></blockquote>
                                <p>Of course, you know that if someone says stuff like that, you have no chance against them unless you play all the time. Now we've reached the point where Dia is starting to get into it, and she beats me as well, and it isn’t my favourite thing to lose. Our local chess champion had this to say about why he chose the shorter mantra when there was a longer one:</p>
                                <blockquote>I don't have time to calm down.<span>András the Stressmaster</span></blockquote>
                                <p>The background to the mantra thing is complicated, and I don't really understand it, but if you're impatient, make your move finally, right? Well, I don't have time to lose all the time either, so the king’s dead; delete the account, the app, checkmate, and <b>Zolly out</b>.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">Asphalting</span></h2>
                                <p>There are some very shitty roads here. I heard from Sharathji that Gokulam, the central district for yogis in Mysore, was a jungle not long ago. Many streets still bear the remnants of this heritage, and some roads are even more. A significant city development is underway, with new LED lighting, road painting, and various other things happening right now. After a yoga class with Hetti, Dia, and Andris, we went to eat at Anokhi.</p>
                                <p>This place opens onto Contour Road, a longer and more important road connecting many places where we eat, live, shop, etc. It's in a pretty bad condition; its potholes are more like ditches. Despite being about thirty meters wide, in some places, we need to manage two-way traffic within just two meters, which can be tricky even on a thirty-meter-wide road here. So we park the motorbike in front of Anokhi, and a guy comes over and says there will be asphalting work, so we should move the bike further up. Okay, we'll have a quick breakfast, which won't take much time, but he asks politely, so I do what he says. We go inside to eat, and we order the usual. For me, a smoothie bowl with granola and sometimes a hummus toast, while the others get fruit salad, a glass of smoothie, and so on. There's no masala dosa, which I jokingly asked for once, so the waiter now brings my smoothie always by saying:</p>
                                <blockquote>Here is your masala dosa, sorry we are out of chutney<span>Funny G</span></blockquote>
                                <p>We finish breakfast, and then we head out. Upon reaching the gate, we saw that while we were having our fruits and stuff, they’d asphalted the middle part of the road, spanning a few meters in width. Nobody wasted much time here. They didn't mess around with road closures either, so the bikes, cars, buses, everyone enjoying the fresh new asphalt. The quality of the work is yet to be judged. Still, while it took months to assemble a concrete sidewalk island in one of the most frequent squares in Budapest, they started, completed, and partially gave a significant section of the road back to the public while we only had breakfast. Two days later, the entire width was done. <b>Nice</b>.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">Pancake King</span></h2>
                                <p>A few places in Mysore serve pancakes, but for the truly royal stuff, you have to come to us. If I wanted to exaggerate even more, these pancakes are like a beacon that helps you find your way back to yourself when you start losing your path. Here's the recipe if you're interested (if not, here it is anyway):</p>
                                <ol>
                                    <li>
                                        <div className="display--flex flex-direction--column">
                                            Mixing
                                            <ul className="list display--block list--disc">
                                                <li>2 mashed bananas</li>
                                                <li>1 cup of almond milk (or any plant-based milk; leave the cow's milk to the calves)</li>
                                                <li>1 tablespoon of lemon juice</li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="display--flex flex-direction--column">
                                            Mixing
                                            <ul className="list display--block list--disc">
                                                <li>2 tablespoons of sugar (or any sweetener, like date syrup, which works well)</li>
                                                <li>1 teaspoon baking powder</li>
                                                <li>1 teaspoon cinnamon</li>
                                                <li>1 splash of vanilla extract</li>
                                                <li>1 1/2 cups of oat flour</li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="display--flex flex-direction--column">
                                            Topping
                                            <ul className="list display--block list--disc">
                                                <li>1 coined banana</li>
                                                <li>Maple syrup or jam</li>
                                                <li>If you're feeling fancy, add some blueberries</li>
                                                <li>Or whatever you prefer</li>
                                            </ul>
                                        </div>
                                    </li>
                                </ol>
                                <p>In a blender or with a hand mixer, blend the ingredients from "1. Mixing" thoroughly. Add the components from "2. Mixing" and mix until the batter is smooth. Let it rest for 5 minutes.</p>
                                <p>While the batter rests, heat a pan or pancake griddle over medium heat. If you have a 1/3 cup measuring cup, pour the batter onto the pan; if not, use a smaller tea cup. Pour about three portions at a time onto the pan. When all three pieces are on the pan, gently wiggle it in all directions to spread the batter more evenly.</p>
                                <p>Cook until the bottom turns brown (you'll see air bubbles forming), flip them and cook the other side until it's brown. Transfer to a plate top with sliced banana and your choice of syrup. <b>Easy</b>.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">Conferencing</span></h2>
                                <p>Arne, or as I like to call him, Señor Espejel, is a Mexican yoga teacher and practitioner who has humbly been returning to the Guru for the past sixteen years to learn. Since I begged Dia and myself into the 5:30 am batch (I'll write more about this in "The Guru and I part 3"), I have the opportunity to chat with Arne after yoga because we finish at about the same time (he starts at four, though, but he's a Jedi Master). Mr Diaz (his full name is Arne Espejel Diaz; in Mexico, you get both your father's and mother’s last names) called me up on Friday afternoon and asked me for a favour. He would like to borrow my phone during the Saturday conference because he has an oldish Android (although I "only" have an iPhone 13), and he wants to host a Zoom meeting.</p>
                                <p>I thought what a neat idea to leave while Guruji was speaking and start Zooming (this was sarcasm), but I'm generally a helpful person, so I said no biggy. He also asked me to help him register an account and set up the Zoom meeting. He is not tech-savvy, but at least I can shine a bit here. He went to a birthday party in the evening, and he arrived at our place around “midnight” at 9 pm. It's almost like enduring New Year's Eve as a child here; I needed all my strength to keep my eyes open (I had a scene from Tom and Jerry in my head where Tom props open his eyelids with a toothpick).</p>
                                <p>Dia was already asleep when he arrived, so I went down to the garden area, where we set up his Zoom account and the conference for Saturday at 9 am, which is 9:30 pm in Mexico City. The invitees were his students, his family, and who knows who else. All of a sudden, I see something running out from the laundry area and then back. Then it darts under my chair, and by then, I can see it's a rat. It quickly scurries away. I tell Arne, "WTF, Jesus!" to which he looks at me:</p>
                                <blockquote>Hm it’s a small, it’s a mouse<span>Arne Zooming</span></blockquote>
                                <p>I think it wasn't a mouse, but let's just say it was. We agreed that after the led class, which ends around 8:15 am, I'll give him my phone, and then he can have his conference.</p>
                                <p>After the led class on Saturday (when Guruji dictates the pace, and everyone follows, doing the same practice), there is a conference, and there are usually about 25 minutes between the end of the class and the start of the Sharathji Show. During this time, you have to change out of your sweat-soaked stinky clothes with hundreds of people in a changing room that was not built for hundreds of people, go outside to get some fresh air, and maybe have something to drink or eat (wash your face, apply sunscreen, and do whatever you fancy).</p>
                                <p className="box">Yesterday, we ate our last meal at around 1 pm, and Dia and I couldn’t really find our blood sugar levels, so I went for the buffet, and Dia went for the coconut guy. If you arrive late, the line gets so long that you have little chance to eat before the conference starts, and you can't eat or drink inside. I quickly get two beet juices, a green smoothie, cookies, coffee, water, and Dia drinks two coconuts in the meantime. Now we're all set.</p>
                                <p>I passed my phone to Arne, and he asked to stay nearby because there might be some issue, but I had already placed my mat in front of Guruji's chair earlier to hear what he said, so I preferred to go there. I've never sat this close before. Anyway, I don't understand what Arne wants, I'm not going to bail while the boss is speaking because that's an easy way to get in trouble.</p>
                                <p>Sharathji starts the sixth conference of the season, and the topics are interesting. Diwali is coming up, and there's a bit of organising around that because there will be a big party at the Shala. I'll definitely write about that (if it turns out well). When he talks about food, he looks at me (or maybe I'm just imagining it) and smiles, but I've sweated quite a few kilos (and so has he) since I've been here, so there's no more elephant in the room (pun intended).</p>
                                <p>There's an envelope under his chair that he occasionally nudges. He starts talking about how it rarely happens that a practitioner steps up to the certified level, but this season, there are two who will achieve it. Certified is different from an authorised teacher; it's kind of like an Ashtanga PhD. Suddenly, Guruji calls Arne up to the stage. I'm thinking to myself, "WTF? Is this guy now popping off to give a conference in Mexico when Guruji asks for him?”</p>
                                <p>But that's not the case. I'm just slow. Arne comes up on the stage to massive applause. He has tears in his eyes as hundreds of people applaud the years of hard work and dedication he put in daily while being here. He brings a gift for Sharathji, a traditional skirt (I forgot its name, sorry), and in exchange, Arne got the certificate in the envelope. Not many people reach this level, around fifty-something. Arne returns to his seat, and then Sharathji tells him to come back with the phone to show his family all the people congratulating him. He comes back with my phone in his hand. Somewhere around here, it clicks, "Aha!" So that's when I finally got it. I'm really slow.</p>
                                <p>By the way, there was another clue that Arne dropped: he invited us to a party at 1 pm. Of course, he knew what it would be and invited us to celebrate this achievement, but he didn't talk about it for understandable reasons. <b>Felicidades mi Jefe</b>.</p>
                                <p className="box no-margin--bottom margin-lg--top">
                                    <strong className="margin--bottom">Kelvin is back</strong>
                                    The Kelvinator 2000 is back. It's down in the kitchen next to the washing machine, and it's as cold as the North Pole. This is a different machine, a clone, sent back for one purpose: to put you on freeze. Lalitha said just one work and our current machine would be terminated, and Kelvin will move in. I'm not sure if I dare.
                                </p>
                            </>
                        }
                    </article>
                    <article id="dvadasa" className="blog__article">
                        {language === "HU"
                            ? <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">द्वादश Dvadasa</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">A krikett ütő</h2>
                                </hgroup>
                                <p>Indiába a krikett (cricket) olyan mint Európában a foci, nagyon pop, sokaknak szinte már vallás. Én semmit nem tudok erről csak annyit, hogy hasonló mint a baseball. Mondjuk a baseballról is csak annyit tudok, hogy a baseball ütő gengszter dolog, meg egyszer Diával ebédeltünk a L.A. Dodgers stadionban, de csak mi voltunk ott.</p>
                                <p>Épp most megy a krikett világbajnokság, és nagyon forró a téma. Gondolom emiatt van, hogy Sharathji a legutóbbi konfin megemlítette, ha jó idő lesz a Diwalis buli napján szombaton, akkor játszunk.</p>
                                <blockquote>Megrendeltem a felszerelést két hete az Amazonról, már ott van a csomagtartómban<span>PreparedJi</span></blockquote>
                                <p>Sharathji fiatalon azon volt, hogy profi krikett játékos legyen, és Indiai forrásból tudom hogy nagyon ment neki. Csak aztán a nagyapja rávette erre a izéra, nyújtózkodásra (respect, only joking, no offense). Szóval megy a VB azt rápörgött a labdázásra, nem lehet hibáztatni.</p>
                                <p>Van egy Indiai srác a csoportunkban, Sumith, aki persze vágja a szabályokat, és akit megkérdeztem nem akar e adni egy gyakorlati gyorstalpalót. Simán rajta van, jó arc. Az elméletre meg alapokra rámegyek magam, a milleniál ösztön hajt.</p>
                                <p><b>Ezek kellenek a játékhoz:</b></p>
                                <ul className="list list--disc margin-md--bottom">
                                    <li>Labda (kb mint egy teniszlabda olyan)</li>
                                    <li>Ütő (egy összetömörített evező szerű cucc, nem olyan mint a baseball ütő)</li>
                                    <li>Kapu 1 (három karó egy talpon aminek a tetejét két kicsi pálca köti össze, amik ha csak megsimizi a laszti a kaput leesnek, jelezve a gólt vagy mit, ez megy az ütőjátékos mögé)</li>
                                    <li>Kapu 2 (egy karó egy talpon, ez megy a dobó játékoshoz)</li>
                                    <li>Védőfelszerelés, ami nekünk nem kell mert gagyi pudingok vagyunk</li>
                                </ul>
                                <p>Sejtem mennyire érdekelnek a szabályok, de  azért csak megpróbálom röviden összefoglalni amit feldolgoztam belőle, tartsd szem előtt, hogy nem vagyok szakértő, ha vágod a krikettet köss belém ha pontatlan voltam. Ez azoknak szól akiknek fogalmuk nincs mint ahogy nekem se volt. Ha nem érdekel, nem muszáj olvasni, szabad ember vagy.</p>
                                <p>Egy krikett csapat 11 játékosból áll, plusz egy csere van a padon, ha sérülés lenne. Ha láttál filmen baseball-t akkor érted mi a cél (ha nem akkor se bonyi): valaki dobja a labdát egy ütős arc felé, az pedig próbálja elütni. Pontokat kell szerezni és az nyer akinek a végén több van (ezt sokkoló volt olvasni, tudom).</p>
                                <p>Több játékmód van de csak egyet veszek elő a Húsz20 (Twenty20) nevűt. Ez egy kb 3 órás meccs. Két félidőből áll, az elsőben az egyik csapat csak dobál, a másik csapat csak üt aztán fordítva. Csak az a csapat szerezhet pontot aki éppen ütéseket végez (van valami kivétel, mindegy). Azért Húsz20 mert 20 játszma van félidőnként, ahol egy játszma az 6 érvényes dobást jelent.</p>
                                <p>Egy füves ovális mezőn megy a játék, középen van egy 20 méter hosszú dobósáv nevű rövid füves (salakos szerű) rész.</p>
                                <p className="box">
                                    <b>A felállás a következő:</b>
                                    <br />
                                    A dobó csapat 11 játékosa a pályán van, 10 szétszoródva, hogy elkapják a labdát, 1 pedig dobál. Az ütő csapat csak 2 játékosa van a pályán egyszerre, egy-egy a két kapunál (mind a 11 játékos fog ütni a félidőben, biztos van kivétel, nem baj). A három karós kapu előtt várja az egyik ütő játékos a labdát, a kapu mögött meg van egy elkapó a másik csapatból. Szemben velük, az egy karós kapunál a dobó játékos van, innen hajigál, illetve itt áll a másik ütős játékos is, hogy miért azt mindjárt.
                                    <br />
                                    <br />
                                    <b>A játék menete kb ez:</b>
                                    <br />
                                    A dobó nekifutásból eldobja a 20 méterre lévő kapu felé a labdát, ami előtt áll az ellenfél ütő játékosa, aki próbálja ezt eltalálni. Ha eltalálja az neki jó, ha nem akkor az a dobónak, feltéve, hogy szabályosat dobott, nem volt széles (homály), nem gurult, és egyszer de csak egyszer lepattant a földre a labda. Ha nem dobott szabályosat akkor az pont az ütős csapatnak.
                                </p>
                                <p><b>Ha eltalálta a labdát az ütő játékos:</b></p>
                                <ul className="list list--disc margin-md--bottom">
                                    <li>Ha kirepül az űrbe az 6 pont.</li>
                                    <li>Ha kimegy a pályáról akár gurulva anélkül, hogy 10 pályán lévő ellenfél közül bárki megfogja az 4 pont.</li>
                                    <li>Ha nem megy ki a labda akkor a két ütő játékos (ugye van az a másik ütős a dobó oldalán aki eddig csak szotyizott) elkezdhet egymás felé futni, hogy elérje a szemben, 20 méterre lévő vonalat. Ha mindketten elérik az 1 pont. Aztán vissza futhatnak a helyükre az még 1 pont. Addig rohangálhatnak amíg akarnak de van egy csavar: a pályán lévő 10 ellenfél ha megszerzi a labdát és ledobja vele valamelyik kaput akkor az aktuális ütő játékos kiesik, a meccsen többet nem üthet. Ha a kapu közelében maradnak nem szabad ledobni a kaput, szóval kockázatos futni de van, hogy megéri ha nagyon messze megy a labda és lassan tudják a dobók visszaküldeni.</li>
                                    <li>Ha úgy találja el a labdát, hogy lepattanás nélkül az ellenfélből valaki megfogja (mint Potter a cikeszt) akkor az ütő játékos kiesik, a meccsen többet nem üthet.</li>
                                </ul>
                                <p><b>Ha nem találta el a labdát:</b></p>
                                <ul className="list list--disc margin-md--bottom">
                                    <li>Ha elkapja az ütő játékos mögött lévő kapus akkor az egy szabályos dobás</li>
                                    <li>Ha eltalálja a kaput a labda az ütő játékos kiesik</li>
                                    <li>Ha csak hozzáér az ütő széle a labdához de az tovább megy, az ütő játékos kiesik</li>
                                    <li>Ha hozzáér kézzel vagy a kaput gátolva testtel az ütőjátékos a labdához akkor kiesik</li>
                                    <li>Ha kilép az ütő vonalon kívül az ütő játékos és a kapus megfogja a labdát gyorsan leütheti a kaput, és ha sikerül mielőtt az ütős a vonal mögé visszalép, akkor a csóri ütő játékos kiesik</li>
                                </ul>
                                <p>Szóval a lényeg, hogy a 20 X 6 szabályos dobás alatt kell az ütős csapatnak pontokat szerezni, de ha minden ütő játékos kiesik mielőtt ez a 120 dobás lemegy akkor félidő vége és fordulás. A 2. félidőbe kb úgy mennek a csapatok, hogy például 148-0 az állás. Ha a második félidőben a másik csapat ami már csak üt, eléri és túllépi ezt a 148 pontot (random pont csak a példáért) akkor a meccsnek vége. Ha nem éri el ezt a 120 szabályos dobásból akkor vesztettek. Minden más ugyanaz.</p>
                                <p>Szerintem ennyi elég, hogy valamennyit fel fogjon ebből az ember. Olyan ez a játék, hogy így mindenki áll, lézeng, aztán egyszer csak repül a dobás, és valami történik, aztán megint vársz, hogy valami legyen. De ettől még nem rossz ez egyáltalán, csak máshogy izgalmas mint egy kosárlabda, vagy foci.</p>
                                <p>Ahogy az elején megjegyeztem a Guru bevásárolt felszerelést, ami kell ehhez, és mivel pro, gondolom nem nagyon szeretne olyanokkal játszani akik szar bénák. Én meg játszani akarok vele szóval fel kell rá készülni. Nincs viszont cuccunk, neki meg van, hm.</p>
                                <p>Chanting vagyis kántálás előtt (ami heti háromszor van jóga után) ültünk kint a beton patkán, vártunk mielőtt bemegyünk. Sharathji jött ki a kapun és sétált a kocsijához, amikor Sumithal egymásra néztünk, hogy Guruji-nek van mindene amivel gyakizhatunk. Még kicsit noszogatott is Sumith. Én úgy voltam vele, hogy elkérem tőle, max nem adja oda. Dia, Andris meg Natalie kisebb sokkot kaptak, már, hogy egyáltalán rámegyek erre, bocsi a stresszért. Sumith meg gyorsan odébb ment egy ilyen ajjaj fejet vágva, nem fedeznek, I fly solo. Szóval odalépek a Guruhoz és kérdezem, hogy:</p>
                                <blockquote>Guruji el kérhetnénk a krikett cumót a kocsiból kölcsön, hogy felkészüljünk picit a szombatra? Vigyázunk rá megígérem…<span>Zolly the Pofátlan</span></blockquote>
                                <p>“Esküszöm nem piára kell” - na jó ezt nem mondtam. Van egy ilyen nézése a Gurunak, hogy így mellém néz belém, ha érted. Nehéz megmagyarázni, mindegy mondja, hogy:</p>
                                <blockquote>Okay<span>Sharathji Agreed</span></blockquote>
                                <p>És már nyitja is a csomagtartót. Kivesz egy ilyen nagyobb táskát, abba van minden, és elkezd kiszedni ütőt, labdát, műanyag cölöpök is vannak mindkét kapuhoz. Ekkor már Sumith meg Andris is oda mer jönni, csak az akasztást akarták elkerülni. Sharathji szól, hogy dobjunk már neki párat mert gondolom azért az ütőt ő akarja először felavatni. Sumith előre szalad és elkezd adogatni (nem tudom ez szakszerű e) ilyen kis lágyan. A Guru meg odaszól, hogy “ne simogass, csapassad”, Kannadául mondja szóval csak tippelek. Kap is pár gyorsabb labdát egyből. Persze ahogy a parkolóban Sharathji neki áll ennek a krikett bemutatónak, vagy ötven ember kezdi el filmezni, de nem szégyenlős, meg látszik, hogy nagyon penge ebben a sportban. Egy olyan 15-20 ütés után azért megjegyzi:</p>
                                <blockquote>Ok, ennyi volt, holnap szeretnék tudni gyakorolni<span>Sharathji the Batsman</span></blockquote>
                                <p>Átnyújtja a cuccokat, majd beszál a merdzsóba és elhajt. <b>What a legend.</b></p>
                                <p>Még aznap kimegyünk kora délután egy krikett pályára, az Idli Man mellé, ahol egyébként sose fogod kitalálni, de idlit lehet enni. Amúgy az egyik legjobb itt ha szereted. Beírtam ugyan a nagy WhatsApp csopiba, hogy jöhet bárki játszani de végül csak a kemény mag jött: Dia, Andris, Sumith, egy másik Kannada srác illetve Nicholas, aki hozta a kameráját filmezni (de kellett játszania is). Dobáltunk meg ütöttünk egy órát mindent (többnyire levegőt).</p>
                                <p>Ami nekem “jól” ment igazából az-az elkapás, ha jött a labda nem nyúltam annyit mellé. Iszonyat fárasztó ez, futni kell sokat a labdáért mert messzire tud menni, meg amíg nincs technikád minden fárasztóbb. Szóval egy óra bőven elég volt fizikailag (már lement pár órája másfél óra jóga), arra viszont nem volt elég, hogy jók legyünk krikettben. Jó lenne ha ilyen easy lenne. Viszont talán annyit ért ez, az elméleti felkészüléssel együtt, hogy nem leszek teljesen fogalmatlan ha úgy alakul, hogy a Nagy Sharath Jois-al elüthetek egy kis időt szombaton. <b>Csak a 6 pont, béke</b>.</p>
                            </>
                            : <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">द्वादश Dvadasa</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">The cricket bat</h2>
                                </hgroup>
                                <p>In India, cricket is like football (it’s not soccer) in Europe, it is very popular, almost a religion for many. I don't know anything about it, just that it's similar to baseball. Speaking of baseball, all I know is that the baseball bat is a gangster thing, and once I had lunch with Dia at the L.A. Dodgers stadium, but we were the only ones there.</p>
                                <p>The cricket World Cup is currently underway, and it's a hot topic. I guess that's why Sharathji mentioned in the recent meeting that we’ll play if the weather is good on Diwali party day, which is this Saturday.</p>
                                <blockquote>I ordered the equipment two weeks ago from Amazon, and it is in my trunk<span>PreparedJi</span></blockquote>
                                <p>Sharathji wanted to become a professional cricket player when he was young, and I heard from an Indian source that he was really good at it. But then his grandpa convinced him to focus on this, you know, stretching thingy (respect, only joking, no offence). So, with the World Cup going on, he got back into the game, and you can't blame him for that.</p>
                                <p>We have an Indian guy in our group, Sumith, who knows the rules, and I asked him if he'd be willing to give me a crash course. He's totally up for it; he’s cool. As for the theory and basics, I'll tackle that on my own, driven by millennial instincts.</p>
                                <p><b>This is what you need for the game:</b></p>
                                <ul className="list list--disc margin-md--bottom">
                                    <li>Ball (it’s like a tennis ball)</li>
                                    <li>Bat (a compact, paddle-like thing, not like a baseball bat)</li>
                                    <li>Wicket 1 (three stumps on one base, connected on top by two small sticks; if the bails on top fall when the stumps are hit, it signals a goal or something; this wicket stands behind the batsman)</li>
                                    <li>Wicket 2 (a single stump on a base, this is with the bowler)</li>
                                    <li>Protective gear, which we don't need because we are lame little puddings</li>
                                </ul>
                                <p>I understand that rules might not be the most exciting topic, but I'll briefly summarise what I've gathered. Remember that I'm not an expert, so if you know cricket well and spot any inaccuracies, please correct me. This is for those who have no idea, just like I didn't. If you're not interested, you don't have to read it – you're a free person.</p>
                                <p>A cricket team consists of 11 players plus one substitute on the bench in case of injury. If you've seen baseball in movies, you'll understand the objective (and if not, it's still simple): someone throws the ball toward a guy with the bat, who tries to hit it. The goal is to score points, and the team with more points at the end wins (I know, it was pretty shocking to read, right?).</p>
                                <p>There are various game formats in cricket, but I'll focus on "Twenty20”. The match lasts around 3 hours and consists of two halves. In the first half, one team bowls while the other team bats, and then they switch roles. Only the batting team can score points during their turn (with some exceptions I don’t care about now). It's called "Twenty20" because there are 20 overs in each half, each comprising 6 valid bowls.</p>
                                <p>The game is played on a grassy oval field, with a 20-meter-long area known as the cricket strip in the centre, covered by really short grass.</p>
                                <p className="box">
                                    <b>The setup is as follows:</b>
                                    <br />
                                    In the bowler team, all 11 players are on the field, with 10 scattered around to catch the ball, and 1 player acts as the bowler. The batting team has only 2 players on the field at a time, one at each end of the pitch (all 11 players in the batting team will get a chance to bat during their turn, with some exceptions). One of the batsmen stands in front of the three-stumps wicket, waiting for the ball, and behind the wicket, there is a fielder from the opposing team. Facing them, at the single stump wicket, is the bowler, who throws the ball, and the other batsman is positioned here as well, and I'll explain why shortly.
                                    <br />
                                    <br />
                                    <b>The game proceeds roughly as follows:</b>
                                    <br />
                                    With a running start, the bowler throws the ball towards the wicket located 20 meters away. In front of the wicket stands the batsman from the opposing team, who tries to hit the ball. If the batsman successfully hits the ball, it's in their favour. If they miss and the bowler's delivery is considered legal (not too wide or bouncing multiple times, but bounces at least once), no point goes to the batting team.
                                </p>
                                <p><b>If the batsman successfully hits the ball:</b></p>
                                <ul className="list list--disc margin-md--bottom">
                                    <li>If the ball goes out to space, it's worth 6 points.</li>
                                    <li>If the ball goes out of the field, either rolling or otherwise, without anyone from the opposing team catching it, it's worth 4 points.</li>
                                    <li>If the ball doesn't go out of the field, the two batsmen (including the one initially stationed at the other end and has yet to do much) can start running towards each other to reach the opposite goal line 20 meters away. If both batsmen successfully get this line, it's worth 1 point. They can then run back to their original positions for an additional point. They can keep running as long as they want, but there's a twist: the 10 fielding players on the opposition team can try to retrieve the ball and hit a wicket. If they succeed, the batsman closest to that wicket is out and can't bat anymore in the match. Running close to the wickets is risky, so it's a strategic decision.</li>
                                    <li>If the batsman hits the ball and it's caught by a fielder from the opposing team (like Potter catching the snitch), the batsman is out and can't bat anymore in the match.</li>
                                </ul>
                                <p><b>If the batsman doesn't hit the ball:</b></p>
                                <ul className="list list--disc margin-md--bottom">
                                    <li>If the wicketkeeper behind the batsman catches the ball, it's considered a valid bowl.</li>
                                    <li>If the ball hits the wicket, the batsman is out.</li>
                                    <li>If the ball brushes the edge of the bat and continues, the batsman is out.</li>
                                    <li>They are out if the batsman touches the ball with their hand or obstructs the wicket with their body.</li>
                                    <li>If the batsman steps outside the crease and the wicketkeeper quickly removes the bails from the stumps with the ball in hand, and if this happens before the batsman steps back inside the crease, the batsman is out.</li>
                                </ul>
                                <p>So, the essence of the game is that the batting team needs to score points within the 20 overs, and if all batsmen are out before these 120 deliveries are completed, it marks the end of the first half, and they switch sides. Let’s say the score is 148-0 in the second half. The match ends if the team batting in the second half reaches and surpasses these 148 points (using the example). They lose if they don’t get this target within the 120 valid deliveries. Everything else remains the same.</p>
                                <p>This should give someone a basic understanding of the game. Cricket is a bit like that – it's a game where everyone is waiting, and then suddenly someone throws the ball, something happens, and then you wait again for the next moment. But it's not bad at all; it's just a different kind of excitement compared to basketball or football (it is not soccer).</p>
                                <p>As I mentioned at the beginning, Guru has bought the necessary equipment for this, and being a pro, he wouldn't want to play with complete beginners. I want to play with him, so I need to get prepared. However, we don't have the gear, while he does. Hmmm.</p>
                                <p>We sat outside on the concrete curb before the chanting session (three times a week after yoga), waiting before going inside. Sharathji came out of the gate and walked towards his car. Sumith and I exchanged a look, realising that Guruji had everything we could use for cricket. Sumith even teased me a bit. I thought I'd just ask him; the worst thing could be that he won’t lend it to me. Dia, Andris, and Natalie were slightly shocked that I was even considering this; sorry for causing them stress. Sumith quickly moved away with a face like "ouch," not getting involved; I have no backup; I fly solo. So I approached the Guru and asked:</p>
                                <blockquote>Guruji, could we please borrow the cricket gear from your car to prepare a bit for Saturday? We'll take good care of it, I promise...<span>Zolly the Shameless</span></blockquote>
                                <p>"I swear it's not for booze." - Well, I didn't say this. Guru has this way of looking at me by looking next to me, you know, that piercing gaze. It's hard to explain, but anyway, he says:</p>
                                <blockquote>Okay<span>Sharathji Agreed</span></blockquote>
                                <p>And he's already opening the trunk. He takes out a larger bag, and everything is in it – bats, balls, plastic stumps for both wickets. By this time, Sumith and Andris dare to come over; they just wanted to avoid the hanging. Sharathji tells us to toss him some balls because, I assume, he wants to christen (such a strange word to use here) the bat first. Sumith steps forward and gently starts serving them (not sure if that’s the correct term). The Guru tells him, "Don't be soft, hit it." He says it in Kannada, so I'm just guessing. He gets a few faster balls right away. Of course, as Sharathji starts this cricket demonstration in the parking lot, about fifty people start filming, but he's not shy, and it's clear he's a pro at this sport. After about 15-20 hits, he remarks:</p>
                                <blockquote>Ok, that’s it, I would like to practice tomorrow<span>Sharathji the Batsman</span></blockquote>
                                <p>He hands over the stuff, gets into his Mercedes and drives away. <b>What a legend.</b></p>
                                <p>Later that day, we head to a cricket ground in the early afternoon, right next to the Idli Man, where, believe it or not, you can eat idlis. By the way, it's one of the best places to have idlis here if you like them. I did post in the big WhatsApp group inviting anyone to join, but in the end, only the die-hards showed up: Dia, Andris, Sumith, another Kannada guy, and Nicholas, who brought his camera to film (but had to play as well). We spent an hour bowling, batting, and, for the most part, missing the ball (mostly air shots).</p>
                                <p>What I did "well," actually, was catching. When the ball came, I didn't miss it as much. This is incredibly tiring; you must run a lot to get the ball, especially since it can go far. Also, it's more exhausting when you lack the technique. So, an hour was more than enough physically (I had already done an hour and a half of yoga earlier), but it wasn't enough to become good at cricket. It would be great if it were that easy. However, this, along with the theoretical preparation, has ensured that I won't be completely clueless if, by chance, I end up playing with the great Sharath Jois on Saturday. <b>Only the 6 points, peace</b>.</p>
                            </>
                        }
                    </article>
                    <article id="trayodasa" className="blog__article">
                        {language === "HU"
                            ? <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">त्रयोदश Trayodasa</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">Ízek, Imák, Kegyelmet</h2>
                                </hgroup>
                                <p>A Deepavali, vagy ahogy ismerősebb lehet Diwali, az kb a hindu karácsony, plusz az indiai újév kezdete egyszerre. Nem csak hinduk hanem buddhisták, szikhek (nem, nem ez nem egy Star Wars dolog), dzsáinisták (nem vagyok a ragozásban biztos, bocsesz ha dzsáini vagy) is tartják, szóval elég sokan. Az október végi, novemberi eleji újholdra esik, a legsötétebb éjszakára, és öt napig tart. Amúgy az Indiai ünnepek a holdnaptár szerint kezdődnek, így minden évben máskor vannak, Indiának oviban “random” volt a jele. Repkednek a petárdák (de durván, mintha egy csatatéren lennél), minden ház előtt lámpások égnek, rangolikat rajzolnak amik ilyen színes poros kis minták, minden kivilágítva, mindenki ünneplősben, megy az evés-ivás, családozás.</p>
                                <p className="box">A szanszkrit “deep” azt jelenti fény, a “vali” pedig azt, hogy sor, szóval a deepavali lefordítva fénysor (vagy fényfüzér). Amúgy a közeli egyetlen vegán étteremben (Burgerie) dolgozó új spanom Nipun azt mondta, hogy Kannada nyelven a diwali azt jelenti bankruptcy, vagyis csőd. Szóval a happy diwali az itt inkább egy oltás (amit elég sok embernek dobáltam), de azért azt is mondta, hogy már megszokták ezt a kis északosodást, szóval OKAY (billegesd a fejed balra-jobbra és úgy mond ezt ki).</p>
                                <p>Ez a nagy fényezés a gonosz feletti győzelmet, a reményt jelképezi. Gondoltam írok ennek a hátteréről de aztán olvasgattam és rájöttem nem vagyok India kutató, nem akarok baromságot írni. Lehet felnőttem? Mindegy, a lényeg, hogy sok féle rituálé van, mind az öt nap van valami külön téma, de kb mint sok más vallás hasonlóan fontos ünnepénél a megtisztulás, remény, megváltás, a jó győzelme a gonosz felett a fő üzenetek. Ha ez nem elég és érdekel a dolog akkor nyomasd a netezést, nagyon sok monda és mítosz kapcsolódik a Deepavalihoz (hallatlan, tudom).</p>
                                <p className="box">A szanszkrit “shala” szó otthont, házat jelent. Így hívjuk a helyet ami otthont ad a gyakorlásnak. A jóga stúdió nem pereg olyan szépen a nyelven, lásd be. Próbáld ki ha nem hiszed, mondd “shala”, most mondd “jóga stúdió”, most megint, hogy “shala”. Mondtam. Most mond azt, hogy “a Sharath shala salakján száz szarka szarja szakadt rám”. Nem, nem nőttem fel, pfu.</p>
                                <p>A shala tervezett a Diwali második napjára egy ünneplést. A szervezés az igen Indiai/katonás volt. Egy héttel korábban egy chanting után Usha, aki a titkárság, mindenes, gyakorló, kezdő tanfolyam tanár, meg ki tudja mi még megkérdezte, hogy ki jelentkezik önként egy kis felelősségre. Páran felálltak erre a bizalom próbára, én úgy voltam vele mi van ha valami véráldozatot kíván a Diwali, nem igazán vágom mi az meg ki az, szóval nope (most már kevésbé vagyok vakon). Szépen az önkéntesek kiálltak a színpadhoz ahol azt a feladatot kapták (dobpergés), hogy szedjék össze azokat akik fel szeretnének lépni valamivel az egy órás ünnepi műsor keretben. Ma sem ölünk önkénteseket, megúszták, ahimsa van meg shanti shanti.</p>
                                <p>Pénzgyűjtést is rendeznek (az mondjuk itt elég mindennapos, hogy gyűjtik tőlünk a pénzt), az összegyűlt összegből pedig robbanószert vesznek majd. Na jó, petárdákat, tűzijátékokat egyéb zajongó vagy csillogó dolgokat. Ezekkel űzzük majd el a gonoszt, adunk fényt az éjszakához, és persze kergetjük őrületbe az állatvilágot. Szinte biztos, hogy itt az állatok ezt jobban tolerálják mint otthon, de az is, hogy ha rajtuk múlna nem létezne pirotechnika.</p>
                                <p>A műsor úgy fog kinézni, hogy Lakshmishji aki a szanszkrit tanárunk, a jóga elméleti ágának a tanítója, tart egy púdzsát (pooja), egy imádkozós védikus istentiszteletet négykor. Ez egy órát vesz kb igénybe, aztán kezdődik a Sharath Jois tehetségkutató, ami eltart hatig, és aztán taka az udvarra krikettezni, meg enni (lesz catering meg minden) és robbangatni. Ezen a szombaton a Guru elengedi a konferenciát, hogy legyen időnk szépen puccba vágni magunk, sminkelni magunk meg ilyenek.</p>
                                <p>Eljött a nagy nap, szombat, ami egy vezetett astanga első sorozat gyakorlással kezdődik reggel 6:30-kor. A Guru jön segíteni a szokásosan problémás pózomban, és most kétszer szarabbul megy éppen. Nem nagyon fordul a vállam hátrafelé, még mindig érzi a két nappal ezelőtti labdázást a srácokkal az Idli Man mellett. Össze is teszi hamar mi a gond, közelebb hajol, hogy megossza velem, több mint ötven év, tudását, tapasztalatát, bölcsességét:</p>
                                <blockquote>No cricket<span>Sharathji the Life Coach</span></blockquote>
                                <p>A jóga után Sumith a krikett spanom odajön és mondja, hogy Sharathji meglátogatta őt is vezetett óra közben, még mielőtt hozzám oda jött volna, és megkérdezte:</p>
                                <blockquote>Where is my bat?<span>The Batless</span></blockquote>
                                <p>Mire a “spanom” egyből bemondta:</p>
                                <blockquote>It is with Zolly<span>Safety Player</span></blockquote>
                                <p>Szóval igazából nem csak a krikettről akart leszedni Guruji hanem arról is, hogy az ő ütőjével tegyem fossá a progressziómatot itten váll ügyileg. Amúgy ha már itt tartunk Andrisnál volt a szajré, de én nem köpök. Mindegy majd délutánra hozzuk és kész.</p>
                                <p>Szépen ki keráztuk magunkat azt négyre mentünk is a púdzsára, de más volt most a vibe a shalában. Olyan szag volt mint egy Douglas-ben (vagy bármelyik nagyobb parfümöt is áruló lebujban). LakhsmishJi elkezdte az imát, ami valahogy nem igazán ért célba sokaknál, mert zajongtak, ignorálták ami történik, elég káosz volt az egész. Guruji nem ért oda az elejére, de Dia megfejtette, hogy ez azért lehet, mert hozta a feleségét, meg a gyerekeket, és emiatt nem sok esélye volt elindulni időben. Néha van olyan (mindig), hogy négyszáz ember várja a vezetett óra előtt, csendben ülve, hogy a Guru lánya megérkezzen, és kezdhessük a gyakit. Mindegy, szép volt a púdzsa megnéztük.</p>
                                <p>A “Sharath Yoga Center Got Talent” (kredit az MC csajnak) előtt Guruji tartott egy kis beszédet. Megköszönte az összes shala alkalmazottnak a munkát egy kis ajándékkal. Azt mondta, hogy ugyan az ő fejét minden nap látjuk, de a feleségét nehéz, max csak képeken, pedig számára ő a család gerince.</p>
                                <blockquote>Ő a gyümölcs a levelek mögött. Tudod, hogy a fán nagyon sok levél van, és azok mögött ott a szaftos gyümölcs, ami értelmet ad az egésznek<span>Guru in Love</span></blockquote>
                                <p>Aztán jöttek sorban a fellépők, volt sok ének, meg tánc. Ment előzőleg a bekérdezés sokaktól, hogy adok e valami műsort, de úgy voltam vele, hogy magyarul rappelni az itt olyan lenne, mint, hát mint magyarul rappelni. Volt néhány nagyon jó meg agyeldobós előadás, sok tehetséget ide fújt a szél azért.</p>
                                <p>Én ezt az egészet úgy néztem, hogy Sharathji ütőjén meg krikett szettjén volt a kezem, hogy amint kellhet, dobom. Az utolsó előadás után Guruji szinte kilőtt a székből és repült az ajtó felé. Már elkezdett sötétedni, szűkült a krikett keret. Én ott termettem mellette kifele menet és mondtam, hogy nálam a cuccos. Azt is oda mondtam, hogy gyakoroltam ám, mire egy kisebb kacaj után megjegyezte:</p>
                                <blockquote>Leave it with the pros<span>Sharathji the Pro</span></blockquote>
                                <p>Mondom, hát ok, RIP, és rohanva elkezdte felvenni a cipőjét. Én is gyorsan ki siettem a kis placcra ahol a game megy majd. LakhsmishJi már várt és adtam is neki a dolgokat, hogy felállítsa a pályát. És ez a vége a krikett karrieremnek, mert játszanom esély nem volt. Ott álltunk a szélén a pályának Andrissal, de annyi Indiai között, akik láthatóan nem csak egy órát nyomták ezt eddig, nem is igazán éreztük, hogy beálljunk. Nem megyek házhoz a pofonért. Ahogy sötétedett úgy kezdtek elveszni a labdák, amiket amúgy a vacsorázó jógik felé ütöttek, akik mosolyogtak ugyan, de nem hiszem, hogy csipázták ezt. Aztán az utolsó labda is a kígyós bokorban landolt, és vége lett. Jó volt nézni ahogy labdázott a Guru a fiával, szép az ilyen.</p>
                                <p>Megkóstoltam a kaját ami annyira lemarta a pofám, hogy tökig leizzadtam. Sumith meg is kérdezte mi a baj, miért izzadok így, de nem igazán válaszoltam inkább mert mára elég Indiai tett már helyre. Elérkezett az idő, hogy felrobbantson mindenki mindent, és így is tettek. Mi inkább hamar leléptünk, de persze ettől itt nem menekülsz. Napokig úgy feküdtünk le, mintha egy csatatéren próbálnánk egy jót aludni, nem is ment. Szóval lehet, hogy itt a Diwali lecsórósodást jelent de egyértelmű, hogy valójában azt jelenti, hogy dobhártya-bombázó-petárda-füzér. <b>Boldog csődöt, csőztök</b>.</p>
                            </>
                            : <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">त्रयोदश Trayodasa</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">Eat, Pray, Bombs</h2>
                                </hgroup>
                                <p>Deepavali, more commonly known as Diwali, is like the Hindu Christmas, plus the beginning of the Indian New Year all at once. It is celebrated not only by Hindus but also by Buddhists, Sikhs (no, it's not a Star Wars thing), Jains, so a lots of people. It falls around the end of October or the beginning of November, on the new moon, the darkest night, and lasts five days. Indian festivals generally follow the lunar calendar, so they vary yearly, making it a bit "random" or as I see it more Indian. Firecrackers fill the air (quite intensely, like being on a battlefield), lanterns illuminate every house, colourful rangolis (patterns made with coloured powders) are drawn, everything is lit up, everyone looks fancy, and there's feasting and celebrating.</p>
                                <p className="box">In Sanskrit, "deep" means light, and "vali" means row, so Deepavali translates to a row of lights (or a series of lights). Interestingly, my new friend Nipun, who works at a nearby in the only vegan restaurant in town (Burgerie), mentioned that in Kannada, Diwali means bankruptcy. So, "Happy Diwali" here is more like a remedy (which I threw around quite a bit), but he also mentioned that they've gotten used to this little northern influence, so it's okay (tilt your head left-right and say it like that).</p>
                                <p>This grand illumination symbolises the triumph over evil and represents hope. I thought about writing about its background, but then I realised I'm not an expert on Indian culture and don't want to write nonsense. Have I grown up? Anyway, the main messages, like in many other major religious festivals, are purification, hope, redemption, and the victory of good over evil. If this isn't enough and you're interested, dive into the internet; there are countless myths and stories associated with Diwali (unbelievable, I know).</p>
                                <p className="box">The Sanskrit word "shala" means home or house. That's what we call the place that hosts our practice. The term "yoga studio" doesn't roll off the tongue as smoothly, you know. Try it; say "shala," now say "yoga studio," now again, say "shala." See? Now say, "I shat my shaking shank at the Sharath Shala's sand". No, I haven't grown up. Pfuh.</p>
                                <p>The shala planned a celebration for the second day of Diwali. The organisation was very Indian/military-style. A week earlier, after a chanting session, Usha, who is in charge of the office, practices, holds beginner courses, and who knows what else, asked who would volunteer for a bit of responsibility. Some stood up for this test of trust, but I was thinking, what if Diwali requires some blood sacrifice? I don't really get what that is, so nope (I'm less clueless now). The volunteers were asked to gather on stage and were given the task (drumroll) of finding those who would like to perform something during the one-hour festive program. We don't sacrifice volunteers today; they survived; there's ahimsa and shanti shanti. We had a similar Christmas party here last year, and there were some really good performances.</p>
                                <p>They also organise a fundraiser (quite common here, collecting money from us). The collected amount is used to buy explosives. Okay, firecrackers, fireworks, and other noisy or glittery things. With these, we drive away evil, bring light to the night, and, of course, drive the animal world crazy. It's almost certain that animals here tolerate this better than at home, but it's also true that if it were up to them, pyrotechnics wouldn't exist.</p>
                                <p>The program will start with Lakshmishji, our Sanskrit teacher and the teacher of the theoretical branch of yoga, performing a pooja, a Vedic worship, at four. It takes about an hour, then the Sharath Jois talent show begins, which lasts until six, and then we go to the courtyard to play cricket, eat (there will be catering and everything), and explode things. The Guru releases the conference this Saturday, so we have time to get ourselves dolled up.</p>
                                <p>The big day arrived on Saturday, starting with a led Ashtanga primary series practice at 6:30 in the morning. The Guru came to help with my usual problematic pose, and it was going twice shittier this time. My shoulders weren't really turning backwards; I could still feel the cricket match with the guys at Idli Man two days ago. He quickly figured out what the problem was and leaned closer to share his knowledge, experience, and wisdom of over fifty years:</p>
                                <blockquote>No cricket<span>Sharathji the Life Coach</span></blockquote>
                                <p>After yoga, Sumith, my cricket buddy, comes over and says that Sharathji visited him during the led class before coming to me and asked:</p>
                                <blockquote>Where is my bat?<span>The Batless</span></blockquote>
                                <p>To which my “friend” promptly replied:</p>
                                <blockquote>It is with Zolly<span>Safety Player</span></blockquote>
                                <p>So, Guruji wasn't just trying to take the cricket away from me; he also wanted me not to use his bat to fuck up my progression in practice. By the way, Andris had the stuff, but I didn't rat him out. Anyway, we'll bring it later, and that's it.</p>
                                <p>We put ourselves into fancy mode and headed to the pooja at four, but the vibe in the shala was different. It smelled like a cheap perfume store. Lakshmishji started the prayer, which didn't really hit the mark for many because there was noise. People ignored what was happening; it was pretty chaotic. Guruji didn't make it to the start, but Dia figured out that it might be because he brought his wife and kids, so there was little chance of getting by on time. Sometimes (always), four hundred people sit quietly waiting for the Guru's daughter to arrive before the led class starts so we can begin the practice. Anyway, the pooja was nice.</p>
                                <p>Guruji gave a little speech before the “Sharath Yoga Center Got Talent” (credit to the MC girl). He thanked all the Shala staff with a small gift. He mentioned that while we see his head every day, it's difficult to see his wife, maybe just in pictures, but she is the backbone of his family.</p>
                                <blockquote>She is the fruit behind the leaves. You know, a tree has many leaves, and behind them is the juicy fruit that gives meaning to the whole.<span>Guru in Love</span></blockquote>
                                <p>Then came the performers, there were many songs and dances. There was much questioning beforehand about whether I would do some performance, but rapping in Hungarian here would be like, well, rapping in Hungarian. There were some outstanding and mind-blowing performances; the wind brought much talent here.</p>
                                <p>I watched the whole show with my hands on Sharathji's cricket bat and cricket set, and I was ready to give it back whenever needed. After the last performance, Guruji practically jumped out of his chair and flew towards the door. It was getting dark, and the cricket frame was narrowing. I rushed to him on the way out and said I had the stuff. I also mentioned that I had done some practice, to which he chuckled and said, after a small laugh:</p>
                                <blockquote>Leave it with the pros<span>Sharathji the Pro</span></blockquote>
                                <p>I said, okay, RIP, and in a hurry, he started putting on his shoes. I quickly rushed to the little square where the game would take place. Lakshmishji was already waiting, and I handed him the stuff to set up the field. And that was the end of my cricket career because there was no chance I would play. We stood at the edge of the field with Andris, but among so many Indians who clearly hadn't just practised for an hour until now, we didn't really feel like joining. I'm not going to volunteer for a slap. As it got darker, the balls started disappearing, which were, by the way, aimed at the yogis dining, who smiled, but I don't think they appreciated it. Then, the last ball landed in the snake-infested bush, and it was over. It was nice to watch the Guru play cricket with his son.</p>
                                <p>I tried the food that tore off my face so much that I sweated all over. Sumith asked me what was wrong, why I was sweating like this, but I didn't really answer; I guess I’d had enough Indian roasts for today. It was time to blow everything and everyone up, and that's what they did. We decided to leave early, but you can't escape the explosions here. We went to bed for days as if trying to get a good sleep on a battlefield, but it didn't really work. So maybe Diwali here means getting poor, but it's clear that it actually means eardrum-bombing-firecracker-string. <b>Happy bankruptcy, folks</b>!</p>
                            </>
                        }
                    </article>
                    <article id="caturdasa" className="blog__article">
                        {language === "HU"
                            ? <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">चतुर्दश Caturdasa</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">Mélység</h2>
                                </hgroup>
                                <p>Közeleg a két hónapos utunk vége. Nehéz ezt eléggé megemészteni, mert egyszerre nem is akarok, meg akarok is visszatérni a régibe. Van ami hiányzik, de közben bennem van ez a felfedező vágy, illetve egy félelem attól, hogy elmúlik ez a sok érdekes pillanat, és visszatér a feszengés (hello darkness my old friend). Nyilván vágod, hogy ez egy más világ, de ha nem voltál még itt akkor nehéz átadni ezt. Nem vagyok író. Vagyis dalszöveget szoktam írni de az más, ott csak egy rím kell ott ahova azt vársz (smart ass). Szóval ha nem voltál itt a te feladatod marad elképzelni Indiát, én meg beleadtam amit tudok, hogy ebben segítsek. Ha meg ismersz tudod, hogy nem vagyok egy fotózós gyerek.</p>
                                <p>Sharathji megtartotta az utolsó konferenciánk. Volt egy sztori, amit viccként mesélt ugyan, de engem elkapott. Arról volt szó, hogy miért fontos a Sirsasana közismerten a fejen állás. Mondta, hogy ugyan az orvosok nem ajánlják ha vérnyomás problémája van valakinek, de a Guru azt állítja ha jól csinálja az ember akkor nagyon is jó az. El is hiszem neki, mert hallottam már orvost arról beszélni, hogy ne egyek gyümölcsöt, inkább hús levest, szóval egy jó adag szkepticizmus van bennem (tisztelet a kivételnek aki képzi magát). Sőt ha valaki bírja és meg van a balansz akár tovább is benne lehet maradni, volt valami tanítványa aki negyven percet állt fejen reggelente. Egyébként meg nem tudok fejen állni. Néha volt, hogy sikerült de inkább nem szokott, de azért közelít, gyakorlom. A történet valahogy így szól.</p>
                                <p className="box">Bhasmasura, a félisten (vagy Asura, hatalomvágyó démon), hamvakból született, és szolgálta Shivát a rombolás istenét. Nagyon elhivatott volt így Shiva, ezt honorálandó, azt mondta neki kérhet bármilyen képességet és megkapja. Ha egy isten ezt felajánlja akkor bármekkora gyökérséget is kér valaki ő megoldja, no questions asked. Szóval Bhasmasura azt találta ki neki az kell, hogy ha bárki fejére teszi a kezét, akkor az váljon hamuvá. Mint mikor Thanos csettint. Amúgy ez attól, hogy kb a legpöcsebb szupererő, azért ha Marvel lenne már forogna a sorozat (annyi van már régen sok). Mindegy Shiva elintézte neki, erre ő meg kijelentette, hogy a világ mint egy rémálomtól rettegni fog tőle, muhahaha (főgonosz nevetés). De jó. El is indult, járt faluról városra, kirabolt mindenkit aki nem akart hamuvá válni. Egyik ilyen viziten egy piachoz ért, ahol már mindenki vágta mit tud, így szaladtak amerre láttak az emberek. Kivéve egy komédiást. Az fogta magát és nyomott egy fejen állást, mert ugye akkor nem kerülhet kéz a fejére (IQ 200). Meg is úszta, szóval ezért (is) fontos a fejen állás. Amúgy az emberek sanyargatása annyira nem tetszett Vishnunak (a megtartás istene), hogy egy szexi táncos avatarba bújva meglátogatta a démonunkat. Elkezdtek táncolni és az egyik mozdulat, mint a makarénában az volt, hogy a fejre kellett tenni a kezet, és hát csóri Bhasmasura sikeresen hamuvá változtatta magát. Friendly fire.</p>
                                <p>A komédiás átverte a démont. Úgy is tudnám ezt értelmezni, hogy ha meg van az egyensúly, akkor felül tudunk kerekedni a félelmen. Ez az egyensúly keresés ami itt tart engem az első naptól amikor rájöttem, milyen instabil a kis habtestem. Az astanga gyakorlás nem sport, ez mozgó meditáció. Nincs benne sport értelemben verseny, máshogy, saját magaddal kell szembenézni, és meg kell szeretned magad annyira, hogy ne verseny hanem meditáció legyen a gyakorlásod. Végtelen türelmessé kell válni, nem széthajtani és kifacsarni a testet, hogy ha nem megy egy póz. Csak nyomod minden nap, hagyod a gravitációt dolgozni, lélegzel, és makró millikkel de mindent el fogsz érni, vagy nem. És akkor se lesz semmi, csak folytatod. Itt nincsen célszalag amit át kell tépj, csak te vagy meg a légzésed. Ezt nehéz lenyelni ilyen “nyugatiként”.</p>
                                <p>Mielőtt belevágtam a jógába, már ráléptem egy önreflexiós útra. Egyre kérdőjeleztem meg a kultúránknak mondott és a saját szokásaimat. Abbahagytam a húsevést, ami nem igazán ment át könnyen a környezetemben. A legtöbbször azt a kérdést tették fel, hogy mi az oka, és szerintem azt a választ várták mindig, hogy gyenge szar vagyok és nem tudom megemészteni, beteg vagyok. Ha ez van az jó, sőt akkor a kérdező nem, hogy bele se kell gondoljon, hogy elengedje a mekis burgert, de még erősebb is nálam. Win-win.</p>
                                <p>Van a fejekben néhány ismertebb ok, ami racionalizálja (vagy nem) a növény evést, ilyen az egészség, állatvédelem, környezetvédelem. Szeretnék ha egyet választanék, de nekem mind kell, bocsi. Annyira szeretem az állatokat, hogy abbahagytam a megevésüket, és annyit képeztem magam környezetvédelemről, hogy értem mennyire elképesztő sok energia egy húsos étel egy növényeshez képest.</p>
                                <p>Olvastam a testre gyakorolt hatásokról is rengeteget, és amellett, hogy jól tud az ember lakni egy állattal, a negatívok között ott van többek között a külső forrásból bevitt koleszterin, rengeteg zsír, nátrium-nitrit, antibiotikumok, stb. A hús fogyasztás bizonyítottan összefügg és növelheti a kockázatát a szívkoszorúér-betegségnek, 2-es típusú cukorbetegségnek (ezekben sajnos a családom érintett), az agyvérzésnek és bizonyos rákos megbetegedéseknek, különösen a vastagbélráknak. Az érdekes az, hogy sokan látják, ezeken mind potenciálisan segít ha változtatnak az étrendükön de az nem egyszerű, szóval én maradok a fura gyerek a lencse burgeremmel, nem pedig a véres hurkás disznótoros pálinkával.</p>
                                <p><b>Nem kell befeszülni</b>, ez egy blog, ez csak a véleményem, a saját tapasztalatom a világról, ahogy itt élek. Azt azért leszögezem, hogy nem vagyok orvos, kutató, akadémikus. Csak lelkes gyakorló, aki próbál fejen állni.</p>
                                <p>A legnehezebb egy szokást elengedni vagy megmásítani. Hidd el tudom miről ugatok, letettem a cigit tizenöt év után néhány éve, de még mindig kattogok. Pár éve abbahagytam az ivást is, és bármilyen szar is ezt leírni, alkoholista vagyok. A belső hangok, érzelmek letonpitására használtam a piát, rendszeresen, nem csak társaságban, ezt lefedi a definició. Az iker tesóm, aki nálam sajna többet ivott, a covid alatt leállt a piával. Nagyon nagyon örültem, hogy sikerült eljutnia ide, és ez bemotivált engem is, hogy abbahagyjam. Azt szerettem volna hinni, és ezt is mondtam ha kérdeztek, hogy azért állok le, hogy támogassam őt, de őszintén nekem is bőven voltak gondjaim.</p>
                                <p>Nem hiszek a mértékletességben. Azért nem mert én képtelen vagyok rá (nem vagyok ezzel egyedül), aki pedig képes rá azt hiszi (ő sincs egyedül), hogy már pedig az egy létező dolog, csak “önkontroll” kell hozzá. Ennek az eredménye az, hogy aki úgy van összedrótozva, hogy el tud szívni egy cigit, vagy meg tud inni egy pohár bort anélkül, hogy függővé váljon nem csinál ezekből ügyet. Azok akik viszont nem így vannak összerakva, azoknak ez egy csapda, mert el hiszik, hogy ők is bármikor abba hagyhatják, csak most pont nem akarják. A boltban meg nagyon könnyen hozzájutsz a piához, cigihez, illetve a drogokhoz sem olyan nehéz mint gondolod.</p>
                                <p>Bár nem szeretne a Magyar társadalom többsége így nézni rá, de az alkohol az egyik legnagyobb démonunk. Sokakat hamuvá változtatott a családomban, ismerősi körömben, és ahogy haladunk a korban, egyre több szomorú, kettétört életről hallok.</p>
                                <p>Próbálom a függőségeimet lecserélni mint valami lapokat a pókerben. Az alkoholt jógára, a cigit állatsimogatásra. Próbálok fejen állni mikor jön a démon, és olyan hosszan amilyen hosszan csak bírok, remélve, hogy elmegy. Sokat bukok, és tanulok a bukásból. Ez most nem volt annyira vicces meg laza, sorry vagyok. Néha le kell menni a sötétbe, hogy értékeljük azt amikor van fény. Tegnap elmentünk egy ashramba (“templom”) ahol már voltam párszor. Minden nap este hétkor negyven perces énekes, mise van. Nagyon szép. Ott szakadtak ezek fel bennem és remélem nem bánod, hogy megosztottam. Még majd jövök, <b>csigiricsá</b>.</p>
                            </>
                            : <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">चतुर्दश Caturdasa</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">Depth</h2>
                                </hgroup>
                                <p>Our two-month journey is coming to an end. It's hard to digest this fully because I don’t want to go back, and I want to go back as well. There are things I miss, but at the same time, there's this explorer's desire within me and a fear that these interesting moments will pass, and the stress will return (hello darkness, my old friend). Obviously, you understand that this is a different world, but if you haven't been here, it's hard to imagine. I'm not a writer. I usually write lyrics, but that's not the same; you just need a rhyme there when there’s a space (smart ass). So, if you haven't been here, it's your task to picture India, and I've given what I can to help with that. If you know me, you know I'm not a photographer type of dude.</p>
                                <p>Sharathji held our last conference. There was a story he told as a joke, but it caught me. It was about why Sirsasana, commonly known as headstand, is important. He said that although doctors don't recommend it for someone with blood pressure problems, the Guru claims it's very good if done correctly. I believe him because I've heard a doctor talk about not eating fruits but rather meat soup, so there’s a good dose of scepticism in me (with respect to the exception who educates themselves). Moreover, if someone can do it and has the balance, they can stay in it longer; he had a student who stood on his head for forty minutes every morning. By the way, I can't do a headstand. Sometimes, I succeed, but I usually don't, but I'm getting closer; I'm practising it. The story goes like this.</p>
                                <p className="box">Bhasmasura, the demigod (or Asura, a power-seeking demon), was born from ashes and served Shiva, the god of destruction. He was very devoted, so Shiva, in honour of this, said he could ask for any ability and receive it. If a god offers this, then no matter how lame a request is, they will fulfil it, no questions asked. So, Bhasmasura came up with the idea that he needed the ability (boon) that anyone he touches on the head would turn to ashes, like when Thanos snaps. Despite that, this is one of the dumbest superpowers ever; if it were Marvel, there would already be a series (there are so many, please stop). Anyway, Shiva took care of it for him, and Bhasmasura declared that the world would tremble him in fear like a nightmare, muhahaha (villainous laughter). Great. He set out, going from village to city, robbing anyone who didn't want to turn to ashes. On one of these visits, he came to a market where everyone knew what he could do, so they fled wherever they could. Except for a comedian. He did a headstand because, you see, no one can put their hand on his head if he stands on it (IQ 200). And he got away with it, so that's why headstands are important. By the way, Vishnu didn't like the tormenting of people so much that he transformed into a sexy dancer avatar and visited our demon. They started dancing, and in one move, like in the macarena, it was required to put the hand on the head, and poor Bhasmasura successfully turned himself into ashes. Friendly fire.</p>
                                <p>The comedian tricked the demon. I could interpret this as if one has balance; then one can overcome fear. It's the search for this balance that has kept me here from the first day when I realised how unstable my little foam body was. Astanga practice is not a sport; it's moving meditation. There's no competition in the sports sense; you have to face yourself differently. You have to love yourself enough for it to be meditation rather than a competition. You have to become infinitely patient, not pushing and squeezing the body if a pose doesn't work. You do it every day: let gravity work, breathe, and with micro-millimeters, you’ll achieve everything or not. And then there won't be anything; you continue. It's hard to swallow this as a "Westerner."</p>
                                <p>Before I got into yoga, I was already on a self-reflective journey. I increasingly questioned our culture and my own habits. I quit eating meat, which didn't go over well in my circles. Most of the time, they asked the question: what's the reason? And I think they always expected the answer that I'm weak as shit and can't digest it, that I'm sick. If that's the case, great, and then the questioner doesn't even have to think about giving up their McDonald's burger, and they are even stronger than me. Win-win.</p>
                                <p>There are some well-known reasons in people's heads that rationalise (or not) plant-based eating, such as health, animal protection, and environmental protection. They want me to choose one, but I need them all, sorry. I love animals so much that I stopped eating them, and I educated myself on environmental issues so that I understand how much more energy a meat-based meal requires compared to a plant-based one.</p>
                                <p>I've also read a lot about the effects on the body, and besides getting full by eating an animal, among the negatives, there's cholesterol from external sources, a lot of fat, sodium nitrite, antibiotics, etc. Meat consumption is proven to be associated with an increased risk of coronary heart disease, type 2 diabetes (unfortunately, my family is affected by these), stroke, and certain cancers, especially colorectal cancer. The interesting thing is that many see that changing their diet could potentially help with all of these, but it's not easy, so I'll stick to being the weird kid with my lentil burger rather than the guy with bloody sausage and pálinka.</p>
                                <p><b>No need to get tense</b>, this is a blog, just my opinion, my experience of the world as I live here. I'll emphasise that I'm not a doctor, researcher, or academic. Just an enthusiastic practitioner trying to do a headstand.</p>
                                <p>The hardest thing is to let go of or change a habit. Believe me, I know what I'm barking about; I quit smoking a few years ago after fifteen years, but I still crave it. A few years back, I also quit drinking, and as crappy as it is to write this, I'm an alcoholic. I used alcohol for the suppression of internal voices and emotions regularly, not just on social occasions but only with myself; this is covered by the definition. My twin brother, who unfortunately drank more than me, quit drinking during COVID. I was very, very happy that he managed to get here, and it motivated me to quit too. I wanted to believe, and I also said to many, if asked, that I quit to support him, but honestly, I had my own issues.</p>
                                <p>I don't believe in moderation. Not just because I'm incapable of it (I'm not alone in this), and those who can think (they are not alone either) that it's a real thing, that you only need "self-control." The result is that those who are wired to smoke a cigarette or have a glass of wine without becoming addicted don't care about these things. For those who are not wired like that, it's a trap because they believe they can quit anytime; they just don't want to do it right now. And it's very easy to get alcohol and cigarettes at a store, and getting some drugs is not so tricky either.</p>
                                <p>Although the majority of Hungarian society may not want to look at it this way, alcohol is one of our biggest demons. It turned many in my family into ashes, and as we age, I hear more and more about sad, broken lives in my close surroundings.</p>
                                <p>I try to exchange my addictions like playing cards in poker. Yoga for alcohol, petting animals for cigarettes. I try to do a headstand when the demon comes and stay there as long as I can, hoping it will go away. I fall a lot and learn from the fall. This wasn't as funny and relaxed as usual; sorry for that. Sometimes, you have to go into the darkness to appreciate the light. Yesterday, we went to an ashram (temple), where I've been a few times. Every evening at seven, there's a forty-minute singing service. Very beautiful. These thoughts broke out from me in there, and I hope you don't mind that I shared them. <b>See you later, bud</b>.</p>
                            </>
                        }
                    </article>
                    <article id="pancadasa" className="blog__article">
                        {language === "HU"
                            ? <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">पञ्चदश Pancadasa</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">Naplópó</h2>
                                </hgroup>
                                <p>Szóval nem lopom a napot, sőt úgy érzem tőlem lopják a napokat. Máshogy megfogalmazva ezt az érzést, Mysore olyan mintha víz lenne a homokórában. Segíteni szeretnék (valahol magamnak is) megérteni, hogy ez a látszólagos semmittevés, hogy aprítja fel az ébren töltött perceket, így anélkül, hogy válogatnék, következik egy napom naplója.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">03:00</span></h2>
                                <p>Ébredek, már nagyon rég nincsen ébresztő órám. Dia még korábban kel, meditál egy órát. Amúgy nekem működik, hogy mielőtt kelnem kell, felébredek, ha meg állítok ébresztőt akkor pár perccel azelőtt, hogy megszólalna nyitom a csipást. Irány a hely ahova a király is gyalog jár. Ennek a küldetésnek a sikere egyenesen arányos a közelgő gyakorlás minőségével, vagy tanulóként megfogalmazva, szorítok a kettesért. Fogmosás, kis relax, aztán öltözés. Fontos, hogy felvegyem a rövidgatyát amiben jógázok, és arra rá a hosszú cuccot ami kell a reggeli hűvösben amíg motorozok. Volt néhányszor már, hogy lemaradt a gatya alul, az igen kellemetlen, mert marad az alsógatyás vagy hosszú gatyás (susogós) gyakorlás mint opció. Miért maradt le? Mert nem mindig sikerül ilyen korán odabent minden lámpát felkapcsolni. Kell még a zsákba egy matrac lepedő, kis törölköző, nagy törölköző, váltó alsógatya és póló, mert ami rajtam van az felismerhetetlenségig szét izzadtam majd a végére. Ja meg a matrac. Régen le is zuhanyoztam, de egy ideje ezt nem csinálom csak lefekvés előtt, ami meg pár perce volt, szóval most ezt így okénak érzem. Amúgy elvileg kéne, szóval kicsit csalok.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">04:15</span></h2>
                                <p>Felpattanunk a mocira és irány a Shala ami kb 15-20 perc a töksötét-töküres utakon. Leparkolunk az udvaron, odasétálunk a kapuhoz, és leülünk sok másik gyakorlóval várva, hogy valaki kinyissa, és beengedjen minket. Nyílik a kapu, megyek az öltözőbe, lerakom ami nem kell, és kiülök a matraccal várni, hogy a Guru behívjon.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">05:00</span></h2>
                                <p>Gyakorlás, astanga első sorozat, marichyasana c-ig. Ebbe nem megyek bele, nyomom.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">06:20</span></h2>
                                <p>Kivergődés a Shalaból. A kókuszos kocsinál már nyitja a macsétával a kókuszt az ember ahogy meglát, és miközben leöntöm a torkomon ami van benne, már keresi a kókuszt amit meg is tudok majd enni. Ahogy dobom el az elsőt már nyújtja a következőt. Nagyon jól megértjük egymást szavak nélkül. Beszélgetek ezzel-azzal, aztán Kriszti, Dia, Andris, Hetti az érkezési sorrend. Megbeszéljük mi volt, mindenki leveri a kókuszát aztán megindulunk az Anokhi Garden felé.</p>
                                <div className="box">
                                    <p>Hetti, aki egész idáig éveken át Heni volt egy félreértés folytán, pedig senki nem hívja így, mégis a jógások mind így ismerik holott neki senkise sem sose nem se mondta még ennyit hogy HENI, szóval érted Hetti, a spanom. Írnék róla többet mert hát nagyon jó arc, sokat röhögünk együtt de csak ilyen kisebb sztorik ugranak be pl, hogy ellopták a sisakját az Anokhi elöl ami szomi volt, meg nem is, mert az arcát irritálta. Sharathji úgy hívja:</p>
                                    <blockquote>Hungary<span>Guru to Heni the Hetti the Hungary</span></blockquote>
                                    <p>Andris, aki többször szerepelt már, villamosmérnök, de olyan kemény, hogy a gyenge árammal szépen békén lehet hagyni capeesh?! Az a ritka fajta magyarikusz humánikusz aki annak ellenére, hogy nem nő mert férfi, sokat dolgozik magán mentálisan, ami nagyon kell is neki. Jó bocs, ez egy dicséret akart lenni csak attól meg viszketni kezdek belül ha olyanokat dobálok. Őt a Guru egy határozott kézmodulatnak hívja, vagyis non-verbális a neve, de nem olyan régen szerintem mondta, hogy:</p>
                                    <blockquote>Andris<span>Guru to Who?</span></blockquote>
                                    <p>De csak én hallottam szóval nem confirmed.</p>
                                    <p>Tegyük fel valaki mondaná a Gurunak, hogy “hívd Hungary-t Hettinek légyszike”, vagy azt, hogy “az a fehér srác ott amúgy Andris” akkor ennek szerintem Hetti örülne, Andris pedig nem. Máshogy Andris az útért van inkább. Nincs semmi baj ezzel, csak akartam valami összehasonlítást írni ami több annál, hogy Mr Bimba fiú, Őfelsége Hetti pedig lány. Szeretet kívánok.</p>
                                </div>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">07:45</span></h2>
                                <p>Reggeli az Anokhiban, ugyanaz a rendelés, egy “adash shake bowl” adassék és humuszos szendó, bent a kis szobában (Little Hungary). Chill-es földön fetrengés, megdolgoztunk érte. Ja nem tudom mondtam e már, de Sharathjivel a jóga az kurva fárasztó.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">09:15</span></h2>
                                <p>Irány a Minimal Cafe ami itt a közelben a legjobb kávézó. Az utcán kell ülni többnyire egy lépcsőn de cserébe olyan a kávé mint otthon egy jobb helyen. Mondjuk az ára is közelít a hazaihoz. Dia forrócsokit tol, Hetti matchát, Andris cappuccinót, én meg egy lapos fehéret vagy ahogy a britek mondják fle(t)vájtot. Taka haza, ez a sok fuel kb 30 perc energiát ad mert az astanga még mindig dolgozik bennem ellenem.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">10:00</span></h2>
                                <p>A dilemma, hogy hova üljek. Ha leülök a gép elé akkor nem jó de még talán hasznos leszek, de ha meg a kanapéra ülök bealszom. A kanapéra ülök. Szerencsére már verik az ajtót és üvöltik, hogy:</p>
                                <blockquote>CLEANING, 30 minutes! Okay?<span> Lalitha the Loud</span></blockquote>
                                <p>Mondjuk ok. Gyorsan össze kell pakolni takarítás előtt. Most jött meg egy sapka amit rendeltem, és ahogy fel akarom próbálni, rám köszön belőle egy csillogó hátú, nagy, húsos csótány. Egész jó, hogy nem vettem a fejemre. Próbálom az erkélyen kirázni belőle, sikerül is, de ez repül. Pontosabban visszarepül. Belemászik a kétrészes kanapé egyik felébe. De jó. Egy darabig állunk Diával, hogy mi legyen majd arra jutunk, hogy szétszedek mindent a kanapén, és felállítom az oldalára, hátha kimászik. Ez kész, folytatjuk a pakolást. Egyszer csak megjön a ház elé az egyik kedvenc tehenem (mindet szeretem amúgy) és van mindenféle gyümölcs héjunk, meg  van pár gyümink amit már inkább oda adunk a nagy barátunknak. Visszük és, Lalitha hoz egy lavórt amibe bele tudjuk rakni neki a tízórait. A tehén bocit vár, van étvágya, csak úgy felnyalja a szőlőt, banánt meg amit a tálban talál. A sárkánygyümölcs (ha nem tudod mi az Google, nagyon jól néz ki) nem kell neki, amin kicsit röhögünk. Anita a takarító lány, szét is szedi neki, de nope, az nem kell, pedig jó cucc.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">10:40</span></h2>
                                <p>Anita egy ilyen strucc toll szerű, nagy ecsettel felseper, aztán egy vízes felmosóval az egész lakást felmossa egy lendülettel, közben Lalitha leviszi a szemetet, és elmosogat. Azért mi is szoktuk ezt csinálni de ilyenkor ők csinálják, és nem is szép itt elvenni a munkát (meg akarja a tököm). Nekünk mondjuk nincs seprünk, se felmosónk. És igen, ettől nem lesz nagyon tiszta semmi, de az lényegtelen. Itt a minőséget felülírja, hogy el van végezve a munka.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">11:00</span></h2>
                                <p>A takarítás kész amit Anita minden alkalommal úgy zár, hogy az ajtóban szól:</p>
                                <blockquote>Madame, Sir, finished<span> Anita the Finisher</span></blockquote>
                                <p>Erről eszembe jutott mikor tavaly Rózsa itt volt velünk, és egy ilyen elköszönésre Roresz csak lazán odavágta:</p>
                                <blockquote>Oh really? Okay<span> Rose the Small Madame</span></blockquote>
                                <p>Leülök a kanapéra, ami elég veszélyes. Egy kiskutya sír az erkély alatt ami egyből talpra állít. Egy nagyon sovány, fehér kiskutya van az úton, szemerkél az eső ami nem tetszik neki, és ami valószínűleg az okozója, hogy eltévedt és szomi. Messziről látjuk, hogy vannak szegénynek gondjai, majd Dia eszébe jut, hogy van még baba macskatápunk, ami biztos jó lesz neki, mégse sárkánygyümölcs. Lerohan a cuccal, neki jobb a vibe-ja kisállatokhoz, nekem melósabb megszerezni a bizalmat. Végig mozizom ahogy a kutyi beveri a kaját mint aki még nem evett. Etetés finished. Amúgy sír még egy kicsit a blöki és a szembe szomszéd lánya (a néni egy lábassal terelte csak) hoz valami kaját, de ahogy rámegy a kutya látom messziről is, hogy csíp az nagyon. Eláll az eső és megindul nem túl bátran, de a jó irányba a kicsi.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">11:30</span></h2>
                                <p>Nem a kanapéra ülök, főzök egy kávét. Kifizetem a számlákat, aztán nyitom a programokat, és nekilátok designolni valami szépet. Közben Hetti ír, hogy:</p>
                                <blockquote>Remélem, dolgoztok!<span> Hetti el Jefe</span></blockquote>
                                <p>Megbeszéltem vele, hogy majd irányba terel ilyen tájt, és pontos is és fontos is. Nyomom.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">14:00</span></h2>
                                <p>Eddig kell kb kibírni az evéssel minden nap. Reggel eszünk, meg kettő fele egy ebédet, és annyi. Később enni nem jó, elrontja a gyakorlást ha tele a has. Van itthon egy valamilyen és egy heinz baked beans (paradicsomos bab), egy konzerv kukorica és hámozott paradicsom, és száz kiló rizs. Itt most nincs recept, csak képzeld bele a kukoricát a paradicsomba, a rizst és a babokat meg egy lábasba. Jó azért megszórtam köménnyel, borssal, paprikával mindent, de az alap.</p>
                                <blockquote>All you need is Bab, Bab is all you need!<span> Zol Lennon</span></blockquote>
                                <p>Eszembe jut, hogy dosa tésztás zacskó alján még van egy-két dosa, szóval azt is kisütöm. Belapátolunk mindent mintha nem lenne holnap, csak rizs marad mert azt túl toltam. Az a vicc jut eszembe, hogy: <br /><b>- Mi az ebéd baby?<br />- Rizs szarral<br />- Fúj már megint rizs?!</b></p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">15:30</span></h2>
                                <p>Tegnap valaki Dia birkenstock-jában ment haza a helyről ahol ettünk, ami elég szomorú volt, és most Kriszti küldött képet egy papucsról ami ott van hagyva. Úgy van itt, hogy nem lehet bemenni kinti cipőben a legtöbb helyre, azt így az ajtóban leveszed. Így tett tegnap, és sajnos nagyon nem véletlennek tűnik a csere, ami ott maradt az nem Diáé, és elég leszart állapotban van az övéhez képest, meg ezt már Dia látta tegnap mielőtt zokniban felült a motorra. RIP birki. Nyomjuk tovább a tanulást és a melót.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">16:50</span></h2>
                                <p>Van Indiai számunk, itt ahogy jön az ember azt meg kell csinálni, mert sok mindent csak így lehet használni. Egy hónap 1200 forint, korlátlan sms, hívás, és napi 1.5GB net. Ami viszont undorító, hogy szét spammelnek. Jön napi tizenöt sms, jó néhány hívás. Most is ez van csak épp azzal csesztetnek, hogy holnap lejárnak a kártyák szóval fel kell tölteni. Ehhez viszont el kell mennem egy emberhez. Fel a motorra irány a töltő arc. Látom, hogy a motor is le fog állni holnap ha nem töltöm fel. Teló kész, irány a kút. A motor is jóllakott. Kell a boltból is ez-az mert holnapután lesz nálunk egy brunch, úgyhogy irány a szupermarket. Ez is pipa, robogok haza és folytatom a melót.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">18:11</span></h2>
                                <p>Szokásos napi aggódásom, hogy megint éhes vagyok, de már nem lehet enni. Ezen valahogy túl kell jutni. Nem igazán láttam a csótány spanunk kijönni a kanapéból, szóval úgy döntök szépen kint fog ez a kanapé éjszakázni az erkélyem. Nem a legkönnyebb bútor de kiráncigálom.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">20:30</span></h2>
                                <p>Szerencsére beszopódtam a melóba és sokat haladtam, jó amikor ez sikerül. Megyek zuhanyozni, aztán bezuhanni az ágyba. Írogatom ezt blogot egy húsz percet még, meg olvasgatok.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">21:30</span></h2>
                                <p>Alszom, még van egy hat órám legalább mielőtt mehetek végre gyakorolni.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">Kedves naplóm, finished.</span></h2>
                                <p>Minden nap más, és máshogy zajlik ez a zsizsegés. Minden egyszerre történik, mint egy dzsungelben, megállás nélkül zajlik az élet. Nem értjük sokszor, hova tűnnek el a napok, de így átgondolva, a kulcs az, hogy itt nem unalmas. Ez a hely nem steril, és kicsit sem automata. Nem a fekete tükörbe mélyedünk egész nap, és ez beleszögez a pillanatokba, ami pedig felgyorsítja a homokórát. Az sem elhanyagolható persze hogy reggel 11-re már nyolc órája ébren vagyok, kigyakoroltam ami energiám volt, aztán bepakoltam valamennyit. Így megyünk tovább a hátralévő kicsit több mint egy hétben, amit csak hagyma aprító nindzsák kíséretében tudtam bepötyögni. Ne feledd, <b>all you need is bab, csoki</b>.</p>
                            </>
                            : <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">पञ्चदश Pancadasa</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">Bum diary</h2>
                                </hgroup>
                                <p>So, I'm not wasting the day; in fact, it feels like the days are wasting me. To put this feeling differently, Mysore is like water in an hourglass. I want to help (for myself as well) understand how this apparent idleness chops up the moments spent awake, so without further ado, here comes a journal of my day.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">03:00</span></h2>
                                <p>I wake up; it's been a long time since I had an alarm clock. Dia wakes up even earlier, meditating for an hour. My system works—I wake up before I need to, and if I set an alarm, I get up just a few minutes before it goes off. Off to the place, even kings walk. The success of this mission is directly proportional to the quality of the approaching practice, or in learner's terms; I am pushing for a pass. Teeth brushing, a bit of relaxation, then getting dressed. It's crucial to put on the shorts for yoga first, then layer up for the morning chill on the bike. There have been a few instances where I forgot the shorts, leading to the rather uncomfortable choice between practising in long (swishy) pants or my undies. Why did it happen? Because sometimes I can't turn on all the lights inside so early. Also, I need a bag with a mat, sheet, small towel, big towel, spare underwear, and a shirt, as what I'm wearing will be unrecognisably soaked by the end. Oh, and the mat. I used to shower before, but lately, I only do it before bed in the evening, just a few minutes ago, so I consider it justified. Technically, I should, but I'm bending the rules a bit.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">04:15</span></h2>
                                <p>Hop on the scooter, and let's head to the Shala, about a 15-20 minute ride through pitch-black, deserted roads. We park in the yard, stroll up to the gate, and take a seat alongside other practitioners, waiting for someone to unlock and let us in. The gate opens, I head to the changing room, drop what I don't need, and sit out with my mat, waiting for the Guru to call me in.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">05:00</span></h2>
                                <p>Practice, Ashtanga Primary Series, pushing through until Marichyasana C. I won't delve into this; I'm just pressing on.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">06:20</span></h2>
                                <p>I am emerging from the Shala. By the coconut cart, the guy with the machete is already opening coconuts as soon as he spots me. While I pour down what's inside, he is already searching for the next one I can finish. As I toss away the first, the next one is already in my other hand. We understand each other really well without words. I chat about this and that with people, and then Kriszti, Dia, Andris, and Hetti arrive in that order. We discuss what happened. Everyone smashes their coconut, and then we head towards Anokhi Garden.</p>
                                <div className="box">
                                    <p>Hetti has been mistakenly called Heni for years due to a misunderstanding, yet all the yogis at home know her as Heni, even though no one ever-ever, not ever-ever called her HENI this much, so you get it, Hetti, my buddy. I could write more about her because she's fun; we laugh a lot together, but only smaller stories come to mind, like when her helmet got stolen in front of Anokhi, which was sad and sort of not because it irritated her face. Sharathji calls her:</p>
                                    <blockquote>Hungary<span>Guru to Heni the Hetti the Hungary</span></blockquote>
                                    <p>Andris, who has appeared several times before, is an electrical engineer so tough that you can leave him alone with your low voltage issues, capeesh?! That rare kind of Hungarianus humanicus who, despite not being a woman because he's a man, works a lot on himself mentally, which he really needs. Okay, sorry, this was supposed to be a compliment; I start itching inside when I throw things like that. The Guru calls him a decisive hand gesture, which is his non-verbal name. But not long ago, I think he said:</p>
                                    <blockquote>Andris<span>Guru to Who?</span></blockquote>
                                    <p>But I only heard it, so it's not confirmed.</p>
                                    <p>Let's say someone told the Guru, "Call Hungary Hetti, please," or "That white guy over there is actually Andris." I think Hetti would be happy about this, and Andris wouldn't. Differently, Andris is more about the path. There's nothing wrong with that; I just wanted to write a comparison that goes beyond the fact that Mr. Bimba is a boy and Her Majesty Hetti is a girl. Love ya’ll.</p>
                                </div>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">07:45</span></h2>
                                <p>Breakfast at Anokhi, same order: an 'adash shake bowl' and a hummus sandwich in the little room (Little Hungary). Chilling on the chill floor, we earned it. Oh, I don't know if I mentioned, but yoga with Sharath is fucking exhausting.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">09:15</span></h2>
                                <p>Off to Minimal Cafe, the best coffee spot nearby. Mostly sitting on the street on some stairs, but the coffee is as good as back home in a decent place. Well, the price is getting close to that, too. Dia goes for hot chocolate, Hetti gets matcha, Andris orders a cappuccino, and I opt for a flat white. Way back home, this fuel gives about 30 minutes of energy because Ashtanga is still doing its thing in me, against me.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">10:00</span></h2>
                                <p>The dilemma of where to sit. If I sit at the computer, it's not good, but maybe I'll be somewhat useful. If I choose the couch, I'll blackout. I chose the couch. Fortunately, they're banging on the door and shouting:</p>
                                <blockquote>CLEANING, 30 minutes! Okay?<span> Lalitha the Loud</span></blockquote>
                                <p>Alright. Quick pack up before cleaning. I just received a hat I ordered, and as I'm about to try it on, a shiny-backed, giant, juicy cockroach greets me from it. It's a good thing I didn't put it on my head. I try to shake it off on the balcony and manage to do it, but it flies. More precisely, it flies back. Crawls into one part of the two-piece sofa. Great. Dia and I stand there for a bit, contemplating, and then we decide to dismantle everything on the couch, stand it on its side, and maybe it crawls out. Done, we continue packing. Suddenly, one of my favourite cows (I love them all) comes to the front. We rush outside with all sorts of fruit peels, and we have a few fruits we decide to give to our big friend. Lalitha brings a tray to put the snack in. The cow is waiting eagerly; she is pregnant, has an appetite, and licks up the grapes, bananas, and whatever's in the bowl. She's not interested in the dragon fruit (Google it if you don't know; it looks cool), which makes us laugh a bit. Anita, the cleaning girl, even tries to open it for her, but nope, she's not interested, even though it's good stuff.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">10:40</span></h2>
                                <p>Anita sweeps with an ostrich feather-like, large brush, then swiftly mops the entire apartment with a wet mop while Lalitha does the dishes and brings out the trash. We do this too, but they handle it now, and it's not nice to take away their job (and I don't want to be not nice, you know). We don't have a broom or a mop anyway. And yeah, it doesn't make anything very clean, but that's irrelevant. Here, the quality is overridden by the fact that the job is done.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">11:00</span></h2>
                                <p>Cleaning is done, and Anita always closes it with a farewell at the door:</p>
                                <blockquote>Madame, Sir, finished<span> Anita the Finisher</span></blockquote>
                                <p>This reminded me of when Rózsa was here with us last year, and upon such a farewell, Rose casually replied:</p>
                                <blockquote>Oh really? Okay<span> Rose the Small Madame</span></blockquote>
                                <p>I sit on the couch, which is quite dangerous. A little dog cries under the balcony, immediately getting my attention. There's a skinny, white little puppy on the road, bothered by the drizzle, probably the reason for being lost and hungry. From a distance, we see that he's struggling, and then Dia remembers that we have some kitten food left, which might be good for him—definitely better than dragon fruit. She rushes down with the food; her vibe is better with pets, while it takes me more time to gain trust. I watch the dog lick up the food like he hasn't eaten. Feeding finished. The dog still cries, and the neighbour's daughter from across the street (the lady uses a pan to guide the dog away) brings some food. However, as the dog approaches, I can see from afar that he's not fond of it at all, and I bet it is spicy as hell. The rain stops, and he cautiously starts moving in the right direction.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">11:30</span></h2>
                                <p>I don't sit on the couch; I make coffee. Pay the bills, open up the programs and start designing something nice. Meanwhile, Hetti writes:</p>
                                <blockquote>I hope you're working!<span> Hetti el Jefe</span></blockquote>
                                <p>We agreed I'd start working around this time, and she would ping me. And she is precise, and this is important. I'm on it.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">14:00</span></h2>
                                <p>I have to figure out eating until now every day. We eat in the morning, a kind of lunch around two, and that's it. Eating later is not good; it messes up the practice if the stomach is full. At home, there's a can of random and a can of Heinz baked beans, corn, peeled tomatoes, and a hundred kilos of rice. There's no recipe here. Just imagine putting corn in the tomatoes and rice and beans in a pot. Okay, I sprinkled it with cumin, pepper, paprika, everything, but just the basics.</p>
                                <blockquote>All you need is Bab, Bab is all you need!<span> Zol Lennon (bab is beans in Hungarian, and it rhymes with love)</span></blockquote>
                                <p>I remember that there are a couple of dosas left at the bottom of the dosa batter bag, so I fry those, too. We shovel everything as if there's no tomorrow. Only rice is left because I overdid it. It reminds me of the joke: <br /><b>- What's for lunch, baby?<br />- Rice with shit<br />- Ugh, rice again?!</b></p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">15:30</span></h2>
                                <p>Yesterday, someone left with Dia's Birkenstocks from where we ate, which was quite sad. Kriszti just sent a picture of a slipper left behind at the same place. Here, you can't enter most places with outdoor shoes, so you take them off at the door. That's what Dia did yesterday, and unfortunately, the swap doesn't seem accidental. What was left behind is not Dia's, and it's in quite a crappy condition compared to hers. Also, Dia already spotted this yesterday before she hopped on the scooter in her socks. RIP Birki. Let's continue learning and working.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">16:50</span></h2>
                                <p>We have an Indian phone number; here, you must get one when you come because many things can only be done that way. It's 1200 HUF (around 3 euros) monthly, unlimited SMS, calls, and 1.5GB of daily data. The disgusting part is the spam. Fifteen SMS a day, quite a few calls. Today, it's the same, just with the added annoyance that they remind me that the cards expire tomorrow, so I need to top up. For this, I need to go to a person. On the scooter, I am heading to the charging guy. I see that the scooter will also stop tomorrow if I don't recharge it. The phone is done, heading to the gas station. The scooter is well-fed, too. I need some things from the store as we're having brunch the day after tomorrow, so I'm off to the supermarket. All done, scooting back home, and I continue working.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">18:11</span></h2>
                                <p>My usual daily worry is that I'm hungry again, but it's too late to eat. Somehow, I have to get over this. I didn't see our cockroach buddy come out of the couch, so I decided the couch would spend the night on my balcony. It's not the lightest furniture, but I drag it out.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">20:30</span></h2>
                                <p>Fortunately, I got immersed in work and made good progress. It feels good when that happens. I'm going to shower, then crash into bed. I spent about twenty more minutes writing this blog and reading things.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">21:30</span></h2>
                                <p>I'm sleeping; I still have at least six hours before I can finally go practice.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">Dear diary finished.</span></h2>
                                <p>Every day is different, and this buzzing happens differently each time. Everything happens at once, like in a jungle. Life goes on non-stop. We often don't understand where the days disappear, but thinking about it, the key is that it's not boring here. This place is not sterile and not at all automatic. We don't delve into the black mirror all day, and this nails us into the moments, speeding up the hourglass. Of course, it's not negligible that by 11 in the morning, I'd been awake for eight hours, practised/used whatever energy I had, and packed some more. This is how we go on in the slightly more than a week left, which I could only scribble down accompanied by onion-chopping ninjas. Don't forget, <b>all you need is bab, peace</b>.</p>
                            </>
                        }
                    </article>
                    <article id="sodasa" className="blog__article">
                        {language === "HU"
                            ? <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">षोडश Sodasa</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">A Kígyóisten<br /><span className="heading__sub align--center display--block">1. rész</span></h2>
                                </hgroup>
                                <p>Huh, ezt most el is akarom mesélni meg nem is. Kicsit tartok attól mennyire leszel befogadó. Nem ítélkezni szeretnék, nyugodjál már meg! Csak az van, hogy kevés alkalommal voltam életemben ilyen messze a komfortzónámból, és nagyon, nagyon sok alkalommal merült fel bennem az elmúlt három napban, az az egyszerű, és sokszor ismételt kérdés, hogy “Miért?”. Szintén talán soha nem volt még részem ilyen spirituális élményben, ami még nehezebbé teszi ezt az írást, és nézd el, de lesz amit megtartok magamnak, vagy neked, hogy megtapasztald ha úgy van.</p>
                                <p>Az eleje a történetnek a következő. István mondta tavaly, hogy egyszer évekkel ezelőtt ellátogatott egy templomhoz, ahol egy Swami (pap) egyszemélyes púdzsát (pooja - imádkozós rituálé) tartott neki, és ez nagy hatással bírt. Nem akarom kifejteni jobban, szerintem a lényeg átjön. Diával mi is szerettünk volna eljutni erre a helyre, mert voltak/vannak akadályaink és problémáink amiket nem igazán sikerült sehogy feloldani, és ezt még nem próbáltuk.</p>
                                <p className="box">Rózsa AKA Roresz, bajtárs, barát, jelenleg a cicánkkal csapattja a nyolcadikon (amikor írom ezt még pont szülinapja is van). Szóval ezt most itt mondom el, hogy ahova végül tavaly elmentünk az nem ez a hely volt Rózsa. Tévesen Aihole felé vettük az irányt, ami északon van, és egy feszített 12 órás autóút, ráadásul szilveszter volt. Három ősi templomot lehet a környékén meglátogatni, és egyébként nagyon szép és jó volt az út, és a templomok, de háromszor messzebb volt mint kéne, és logisztikailag nagyon rosszul jött ki. Utolsó vezetett óra után rohanni kellett haza, és indulni, emiatt nem tudtunk fotózkodni Sharathjivel. Ott aludtunk két éjszakát, majd rohanni kellett Bangalore felé, hogy elérjük a járatunk Athénba. Sorry vagyok Roresz, majd viszünk neked valami ajcsit.</p>
                                <p>Idén már körültekintőbbek voltunk, és megkérdtük Lakhsmishjit a szanszkrit tanárunk, hogy mutasson nekünk irányt. Ezt mondjuk tavaly is csináltuk csak rosszul tettük fel a kérdést. A lényeg, hogy Lakhsmishji elmondta merre menjünk, és felkészített minket arra mit tegyünk, hogy a kívánságainkat teljesítse a kígyóisten. Tudom, ez így, hogy hangzik, de ez ilyen lesz végig, nyitottnak kell lenned haverom.</p>
                                <p>Az utolsó szombati vezetett óra után van a fotózkodás a Guruval, amit idén nem hagyunk ki. Ezután indulunk vissza a bázisra, valamit eszünk, jön a taxi, és irány a templom, a pontosított célunk tavalyról. Ez egy négy órás út (bocsi megint Rose) többnyire keresztül a dzsungelen. A sztorink a következő.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">Első nap</span></h2>
                                <p>A szombati óra és a Guruval fotózás után hazamentünk pakolni meg enni indulás előtt. Van sok dolog amit még meg kell ennünk a konyhában, összedobtam egy spagettit. Tőlünk indultunk, átjött Natalie (Nat) meg Andris is kajálni, és annyira “rossz” lett a szósz, hogy gyorsan el is fogyott, de volt egy jófajta kókusztejszín a hűtőben meg zöldségek, szóval még egy carbonara-t is összehajítottam a tésztához. Mehet a menet, a sofőrünk, Amruth egy kis zömök mosolygós maci figura, egy Toyota szedánt hajt. Az út négy óra, egy darabig simább terepen, majd kb a felétől egy kanyargós hegymenet. Andris elöl ül, Hátul balról a sor én, Dia, Nat.</p>
                                <p>Mondjuk azt, hogy Amruth dinamikusan vezet. Vagy legyen inkább türelmetlen. Az én máshol szocializálódott fejemnek meg inkább életveszélyes és meggondolatlan ahogy vereti. Az út a dzsungelen egy kicsit több csak mint egy sáv széles, de ettől még jön a kurva nagy busz szembe gond nélkül, meg még minden amit el tudsz képzelni. Közben ilyen durván kanyargós hegyi szerpentin az egész. Amruthal meg úgy megyünk neki a tű-kanyarnak 80-al, hogy emberem a fékre nem lép, a kanyart használja féknek. A seggem elemelkedik az ülésről miközben két kézzel kapaszkodok a majré vasba, hogy ne nyomjam össze Diát. Na valami ilyesmi ment másfél órán át. Úgy megbántuk mind azt a spagettit ahogy csak megbánni lehet valamit. Küszködtünk a hányással, ami így retrospektív a hétvége egyik fő témája volt nekem. Amúgy nem szóltunk rá, azt gondoltam siet, hogy sötétedés előtt le tudjuk az utat, de azért Andris megfogalmazta másnap, hogy ez csak ilyen Magyar dolog volt, hogy nem szóltunk, hogy ez amit csinál szar. Mi utólag panaszkodós arcok vagyunk, ha valami kényelmetlen abba belesimulunk, de aztán meg kikérjük magunknak, hogy milyen helyzetbe lettünk hozva. Érdekes ez.</p>
                                <p>A Bookingon foglaltam hotelt, és írták, hogy csak 50 méterre van a templomtól ami nagyon király. Csak egy gond van, amit most veszek a halál szerpentinen észre, hogy nem attól a templomtól van 50 méter ahova igyekszünk. Fck. Elkezdek hívogatni a jó templomhoz közeli helyeket, hogy kéne két szoba de minden szoba foglalt. Mindenhol. A Kígyó templom mellett egyébként olyan a térkép, mint valami homokos tengerparton, hotelek, hotelek hátán. Feladom, jó lesz ami van, egyébként három kilométerre van ami nem sok.</p>
                                <p>Megérkezünk a hotelhez ahol a fehérnél is fehérebbként szálltunk ki a kocsiból, a sápadtságtól ami az Amruth dirt rally-nak köszönhető. Itt tudtuk meg, hogy amúgy Amruth itt marad velünk a napokban, és hoz-visz minket, majd végül haza is visz. Yay. Amúgy jó arc nagyon, csak valószínűleg eddig kókusz vagy krumpli szállításból kereste a rúpiát.</p>
                                <p>Be checkoltunk, lehánytuk a cuccunk (nem úgy). Dia és Nat alszik az egyik szobában, én meg Mr. Bimba a másikban. Egy nagy ágy van, nagyon bromi. Gyorsan vissza takarodunk a templomhoz, venni jegyet, illetve megtudni, hogy mi merre hány óra lesz. Mondja az officeban a kedves információs lány, hogy csak reggel lehet jegyet venni bármire, hatkor nyit a pénztár, de érdemes előbb jönni, nagy lesz a sor. Illetve a két napos intenzív Sarpa Samskara, amit csináltunk volna, nem elérhető csak online bookinggal, amit már mikor Indiába jöttünk próbáltunk, de fullon volt.</p>
                                <p>Ahelyett tudunk csinálni három különböző púdzsát illetve a forgást amit majd később kifejtek. Kapunk papírokat infóval és elköszönünk. Taka vissza a hotelbe ahol kipróbáljuk az éttermet. A recepciós kérdezi, hogy felírják-e Amrith kajáját és fizetjük-e mi, vagy mi lesz. Persze fizetjük. Tolok egy dosa-t kipróbálunk ezt azt, nagyon jó a kaja, és Mysore Gokulamhoz képest durván olcsó. Irány az ágy, alvás, mert reggel nagy pancsi vár minket.</p>
                                <p className="box">Natalie aka Nat egy barátunk akivel tavaly sodort minket össze az Astanga. Egyszerre jöttünk elsőként Mysoreba, és szomszédok voltunk. Nagyon jó arc, csippantjuk, hasonlóak vagyunk bár igen más alomból jöttünk, annyira szoros lett a kapcsolat, hogy az esküvőjére is meghívott minket Szlovéniába, ami eléggé odavert (pedig nagyon utálom az esküvőket).</p>
                                <p>Ébredés, 3:30 körül van az órán. Kicsit izgulok. Nagyon sok minden lesz és már az első lépés is nehéznek tűnik. Hajnal 4-re kimegyünk a közeli (3 perc kocsival) Kumaradara folyó partjára, ahol hosszan elterülő beton lépcsők vezetnek le a vízhez. Már most alakul a tömeg, pedig jó korán van. Ügyesen kikerülünk a lépcsőn egy nagyobb tehén szart, majd lemegyünk a vízig.</p>
                                <p>A kígyó átok alól való megtisztulás első lépése, megfürödni a szent folyóban. Nekünk Andrissal szerencsénk van, mert pecsünk van, vagyis levehetjük a pólót és a gatyát a fürdéshez, a lányoknak ruhában kell ezt előadni. Cserébe megbeszéljük, hogy a fiúk mennek előre, felderíteni milyen ez. Tisztának tűnik a víz amikor látszik, mert ilyen alkar nagyságú halak lepik el az egészet amíg a szem ellát. Az illata a víznek nem annyira selymes. Túl kell ezen esni, küldetésünk van.</p>
                                <p>Andris elindul és mikor látom nem hal bele egyből, akkor én is belépek. A halak bökdösnek picit, kb meg lehet fogni őket, és tényleg elég nagyok, kicsit talán kényelmetlenül is. Elkezdek csapkodni és attól beszarnak, végülis csak halak, nem kell félni. Fogom a Mysore szappanom és gyors mozdulatokkal megfürdök a vízben, majd Andris szintén. Kimászunk, mikor kilépek azért még pár hal meg próbál lecsípni rólam valami ehetőt, és azzal nyugtatom magam, hogy a halpedikűrért van aki fizet.</p>
                                <p>Dia és Nat szintén neki mennek a folyónak, és halál lazán letolják az egészet. Főleg Dia lep meg ezzel, mert valami másra számítottam, de hát nem először ilyen bátor, tisztelet. Kimásznak és megindulunk a taxink felé. Útközben már főzik a chai-t az út szélén, és Andris meg Nat rámegy egyre, kicsit kifújjuk magunk. A hotelbe felfrissítjük magunk, és gyorsan a templomhoz vesszük az irányt.</p>
                                <p>Ahogy odaérünk beállunk a jegyes sorba, olyan 4:40 körül, ami ekkorra már legalább 200 méter hosszú. Beadjuk a papucsunk a megőrzőbe, innentől csak a mezítláb engedett. Elvileg a pénztár hatkor nyit, és gyűlnek  is szépen az emberek. 5:59 és nyílik a rács, ez nagyon meglepő pontosság itt. Egész gyorsan halad a sor, de ahogy közelít a kapu, úgy jönnek egyre közelebb mögöttem, és mikor odaérünk a külső bejárathoz, egy csávó lehelete folyamat a nyakamon landol. Egy darabig bírom de aztán szólok, hogy ne már, kicsit kihátrál. Eljutunk a pénztárig és veszünk három púdzsára jegyet, amire ma menni lehet, majd kivergődünk a teremből. Vicces, így visszanézve, hogy azt hittem itt nagy volt a tömeg, sokan voltak. Ami még hátra van ma, az ezt az élményt visszacsapkodja a kényelmes shoppingolás kategóriába. Folytatás lesz, nyugi van, <b>békesség</b>.</p>
                            </>
                            : <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">षोडश Sodasa</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">The Snake God<br /><span className="heading__sub align--center display--block">Part 1</span></h2>
                                </hgroup>
                                <p>Whoa, I kinda want to spill the beans on this, and I kinda don't. I'm a bit nervous about how you'll take it. I don't want to be judgy. Just chill out, okay? It's just that I've rarely been this far out of my comfort zone, and the simple, recurring question of 'Why?' has popped up in my mind a whole bunch in the last three days. I've probably never been part of such a spiritual experience either, which makes putting this into words even trickier. And, you know what, there might be a thing or two I'll keep to myself, so you can experience it yourself if you're up for it.</p>
                                <p>The beginning of the story goes like this: István mentioned last year that he visited a temple years ago, where a Swami (priest) performed a personal pooja (prayer ritual) for him, and it had a profound impact. I don't want to get into the details; I think you'll get the gist. Dia and I also wanted to visit this place because we've had/have some obstacles and issues that we haven't quite figured out how to resolve, and we haven't tried this yet.</p>
                                <p className="box">Rózsa (Rose), aka Roresz, comrade, friend, is currently teaming up with our cat on the eighth floor (as I write this, it's actually her birthday as well). So, I'm telling you now that where we eventually went last year wasn't the place we wanted to go, Rózsa. We mistakenly headed towards Aihole, which is to the north, and it turned out to be a gruelling 12-hour car ride on New Year's Eve, no less. There are three ancient temples you can visit in the area, and the journey was beautiful and enjoyable, but it was three times farther than it should have been, and logistically it was a mess. We had to rush home after the last led class with Sharathji and head out, so we couldn't take photos with him. We spent two nights there and then had to rush towards Bangalore to catch our flight to Athens. Sorry, Roresz, we'll bring you something nice.</p>
                                <p>This year, we were more careful and asked our Sanskrit teacher, Lakshmishji, for directions. We actually did this last year, too, but we asked the question wrong. The point is that Lakshmishji told us where to go and prepared us for what to do to fulfil the wishes we were about to ask for from the Snake God. I know how this sounds, but it's going to be like this throughout—stay open-minded, my dude.</p>
                                <p>After the last Saturday guided session, there's the photo session with the Guru, which we won't miss this year. After that, we head back to the base, grab a bite; the taxi arrives, and we are off to the temple, our clarified goal from last year. It's a four-hour journey (sorry again, Rose), mostly through the jungle. Here's our story.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">Day 1</span></h2>
                                <p>After the Saturday class and the photo session with the Guru, we went home to pack and eat before our journey. There were many things in the kitchen that needed to be consumed, so I whipped up some spaghetti. We started from our place. Natalie (Nat) and Andris joined us for a meal, and the sauce turned out so "bad" that it quickly disappeared. However, we had some good coconut cream and vegetables in the fridge, so I threw together a carbonara with the pasta. Off we went our driver, Amruth, a short, smiley bear-like figure, driving a Toyota sedan. The journey is four hours, initially on smoother terrain, then about halfway through a winding mountain road. Andris is in the front, and in the back, from left to right, it's me, Dia, and Nat.</p>
                                <p>Let's say that Amruth drives dynamically. Or rather, impatiently. For my totally differently conditioned brain from elsewhere, it's more like life-threatening and reckless. The road through the jungle is a bit wider than a lane, but that doesn't stop a huge fucking bus, and everything else you can imagine, from coming toward us without any problem. Meanwhile, the whole path is like a rough, winding mountain serpentine. Amruth tackles the hairpin turns at 80 km/h without stepping on the brake, using the curve as one. My butt lifts off the seat as I cling to the metal railing with both hands to avoid squishing Dia. Something like this went on for about an hour and a half. We regretted that spaghetti in every way imaginable. We struggled with nausea, which retrospectively became one of the main themes of the weekend for me. We didn't say anything at the time; I thought he was in a hurry to complete the journey before dark. But Andris formulated it the next day that it was just a Hungarian thing not to speak up, that what he was doing was bad. In hindsight, we are complaining folks; if something is uncomfortable, we blend into it, but then we bitch about how we were put in a difficult situation. It's strange but true.</p>
                                <p>I booked the hotel on Booking.com, and it said it's only 50 meters away from the temple, which is awesome. Just one problem, which I now notice on the death serpentine, it's not 50 meters from the temple we're heading to. Fck. I started calling places near the good temple, needing two rooms, but everything was fully booked. Everywhere. By the Snake temple, the map looks like a sandy beach, hotels stacked on hotels. I give up; it'll be fine as it is. Anyway, it’s only three kilometres away from the temple, which isn't much.</p>
                                <p>We arrive at the hotel, and we get out of the car looking whiter than white, thanks to Amruth's dirt rally. Here, we find out that Amruth will stay with us for the next few days, taking us here and there and eventually taking us home. Yay. He's actually a really cool guy, just probably earned his rupees from transporting coconuts or potatoes until now.</p>
                                <p>We check in and drop off our stuff. Dia and Nat sleep in one room, and I'm with Mr. Bimba in the other. There's a big bed, very bromantic. We quickly head back to the temple to buy tickets and find out the schedule. The nice information lady in the office says that you can only buy tickets in the morning. The counter opens at six, but it's worth coming earlier; there will be a big line. Also, the two-day intensive Sarpa Samskara we were planning to do is only available through online booking, which we tried when we came to India, but it was fully booked.</p>
                                <p>Instead, we can do three different poojas and the rolling, which I'll explain later. We get papers with information and say goodbye. Back to the hotel, where we try out the restaurant. The receptionist asks if we want to pay for Amrith's meals or how it will work. Of course, we'll pay. I order a dosa, we try this and that; the food is great, and compared to Mysore Gokulam, it's ridiculously cheap. Off to bed, as there's a big cleansing waiting for us in the morning.</p>
                                <p className="box">Natalie, aka Nat, is a friend of ours who was introduced to us through Ashtanga last year. We both came to Mysore at the same time and were neighbours. She's a great person, and we click well despite coming from different backgrounds. Our connection became so strong that she even invited us to her wedding in Slovenia, which was quite beautiful (even though I usually hate weddings).</p>
                                <p>Waking up around 3:30 in the morning, I feel a bit nervous. There's a lot ahead, and even the first step seems challenging. By 4 am, we head to the nearby (3-minute drive) Kumaradara riverbank, where long concrete steps lead down to the water. The crowd is already forming, even though it's quite early. We skillfully avoid a large cow dung on the stairs and make our way down to the water.</p>
                                <p>The first step in cleansing ourselves from the Snake curse is to bathe in the sacred river. Fortunately, Andris and I are lucky because we have ding-a-lings (pee-pees), so we can take off our shirts and pants for the bath, while the girls have to do it in their clothes. In return, we agree that the guys will go ahead to explore what it's like. The water looks clean when you can see it because it's covered with fish the size of forearms as far as the eye can see. The water doesn't smell so calling. We have to get over this; it's a mission.</p>
                                <p>Andris starts walking, and when I see that he doesn't instantly die, I also step in. The fish poke us a bit; you can actually catch them, and they're quite large, maybe a bit uncomfortably. I start splashing, and they get scared. After all, they're just fish, nothing to be afraid of. I grab my Mysore soap and quickly bathe in the water, and then Andris does the same. When we climb out, a few fish still try to snatch something edible from me, and I console myself with the thought that someone pays for fish pedicures.</p>
                                <p>Dia and Nat also head into the river, and they go through it all quite casually. Dia surprises me, as I expected something else, but she's been this brave before: respect. They climb out, and we head towards our taxi. Along the way, chai is already brewing on the side of the road, and Andris and Nat ask for one, and we cool off a bit. Back at the hotel, we freshen up quickly and head to the temple.</p>
                                <p>As we arrive, we join the queue for tickets, around 4:40, and by this time, it's already at least 200 meters long. We leave our slippers with the slipper storage guys. From now on, only bare feet are allowed. The ticket counter is supposed to open at six, and people are slowly gathering. At 5:59, the gate opens, surprisingly on time. The line moves quite quickly, but as we approach the gate, people behind me get closer and closer, and when we reach the outer entrance, a guy's breath is constantly on my neck. I took it for a while, but eventually, I asked him to step back a bit. We reach the ticket counter and buy three tickets for the poojas that can be done today, and then we emerge from the hall. It's funny, looking back, I thought there was a big crowd here, many people. What is yet to come today will push back this experience into the comfortable shopping category. To be continued, relax, <b>peace</b>.</p>
                            </>
                        }
                    </article>
                    <article id="saptadasa" className="blog__article">
                        {language === "HU"
                            ? <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">सप्तदश Saptadasa</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">A Kígyóisten<br /><span className="heading__sub align--center display--block">2. rész</span></h2>
                                </hgroup>
                                <p>Mielőtt belevágnék a második napunk folytatásába, fontosnak érzek megjegyezni valamit. A kígyó itt mást jelent. A Kereszténységben a sátán, a gonosz, aki átveri Évát ami az emberiség kitaszításához vezet a paradicsomból, ezzel szemben a Hindu vallásban a kígyó a termékenység, újjászületés, átalakulás, halhatatlanság és a gyógyulás szimbólumai (meg még ki tudja mi). Szóval a kígyó itt jó arc, ja és ha már itt tartunk a horogkereszt itt a jóllét szimbóluma, amit az a sutyerák osztrák teljesen megkavart, szétcseszett, és betiltatott Európában. Vissza a sztorinkhoz.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">Második nap</span></h2>
                                <p>Megszereztük a jegyeket, hat óra valamennyi van, a templom már nyitva, és a nem túl széles ajtón betolongunk a többi zarándokkal. Az épület olyan, hogy maga a kígyó szentély az egy belső építményben csücsül, van egy külső négyzetes területű gyűrű, ahonnan nyílnak folyosók és ima termek, kb 200 méter lehet körbe, és 10 méter széles. Ezek a számok később nagy jelentőséggel bírnak majd.</p>
                                <p><b>A három púdzsa amire befizettünk a következő:</b></p>
                                <ul className="list list--disc margin-md--bottom">
                                    <li>Ashlesh Bali egy ősi rituálé, egy vezetett szertartás, ami a kígyó átkok levedlését célozza (nagyon okos szó fordulat, tudom). Igen, több ilyen átok is van, szám szerint ez négyen segít.</li>
                                    <li>Nagaprathishta egy swami által vezetett személyes áldás, és egy vezetett közel óra hosszú ima.</li>
                                    <li>Prarthana, imádkozás, ami segít többek közt munkahelyi, egészségügyi, előmeneteli elakadásokban, illetve gyógyít krónikus bőrbetegséget, és nem mellékes a földi gondjainkat is kivasalja.</li>
                                </ul>
                                <p>Ahogy beérünk balra tőlünk, egy hosszabb, lépcsővel megközelíthető emelvényen látjuk, hogy ülnek a Swami-k (papok), akiket körbe vesznek emberek elég családiasan, mondjuk úgy, egymásnak simulva. Beállunk a lépcsőhöz vezető sorba. Ahogy beljebb és beljebb jutunk jövünk rá mi a menet: a Swami-t körbe ülik egyszerre kb hatan-nyolcan, és föléjük tornyosodik a tömeg akik majd váltják az ülőket mikor a szertartás kész. Ez egy személyre szóló ima, mindenki elmondja a nevét, a családtagokat, miért jött ide stb. Kapnak valamit a kezükbe, illetve van egy két részlet amivel nem untatlak, majd imádkozás a kérés teljesüléséért. Nagyon szorosan vagyunk mindenkivel, már-már összenyomnak. Ahogy mi is elkezdünk beállni mint következő váltás, egyre kijjebb szorítanak minket, majd mikor elkezdenek a földről felállni az emberek valahogy még jobban kitúrják Diát és engem, de azért le tudjuk tenni magunkat. Jóformán a nyakunkra másznak az emberek. Ahogy leülünk Andrisnak és nekem szól a Swami, hogy a pólót azt kapjuk le gyorsan. Annyi helyem alig van, hogy levegyem, de sikerül nem eltépni. Ez egy szabály itt a templomban, hogy shirtless nyomod, amit most tanulok meg. Lemegy az ima, amit megtartok magunknak, de annyit elmondok, hogy nagyon erőteljes élmény. Valahogy kivergődünk, ezúttal már meztelen felsőtesttel simulok rá az előttem álló bio akadályokra, de jobban érdekel annak a megemésztése ami történt az előbb, szóval szinte fel se tűnik.</p>
                                <p>Mikor kijutunk követjük az embereket egy sorba ami bevisz a Nagaprathishta misére. Itt van egy kis mázlink mert úgy érkezünk, hogy már majdnem tele a terem, emiatt egész közel kerülünk a Swami-hoz aki már elkezdte a szertartást. Egy elkerített részen ül lótuszban, körbe veszi mindenféle dolog, virágok, edények, fa anyag, és hasonlók amiket felhasznál folyamatosan. Egy mikrofon lóg a feje felett, és egy védikus szöveget mormol. A terem nagy, van középen egy falazat, így akik hátul vannak azoknak egy tv-n van közvetítve az esemény. Nagyon sok a kézmozdulat, a szöveg. A Swami tüzet rak, megy rá minden, és a füst megtölti a termet. Van valami ebben a füstben ami megnyugtat, aztán elgondolkodtat, aztán megint relax, szóval egy trip (nem hinném, hogy drogok, nem volt ez a jegy olyan sok). Van előttem egy kislány aki harminc perc után megunja a dolgot és beáll elém, izeg-mozog. Van egy kő platform ami mellett ülök a földön, amin a kislány talál valami kis kavicsot vagy mit, majd fogja magát és egy laza ujj mozdulattal bele pöcköli az arcomba, majd elfordul. Andris itt kicsit ezen felröhög mögöttem. Ahogy vége az emberek rárobbanak a kijáratra de úgy mintha menekülnének, tapossák egymást. Mi chillesen csak kivárunk. Ahogy kiérünk, kapunk egy kókuszt, cukorkát, és a homlokunkra kenhetünk egy pettyet. Az út itt egyébként kivisz minket a templomból, nem értjük miért.</p>
                                <p>Vissza forgunk a templomba, nagyon nehezen, mert már annyi az ember mint, hát mint Indiában. Egy feszült balhé után eldöntjük, hogy ma már nem tudjuk megcsinálni az egyik fő dolgot ami miatt itt vagyunk, ezért holnap vissza kell jönni korán. Kimegyünk a templomból és irányba vesszük a hotelünk egy könnyed reggeliért, 10 óra van. Annyira kemény volt ez a nap eddig, hogy mind tolunk egy kis power pihit, alszom öt percet. Lemegy a masala dosa meg egyebek, majd vissza a templomhoz 11-kor, hogy legyen esélyünk a deles Prarthana-t megnézni. Papucs leadás, be a templomba, és hely keresés hátrébb a külső gyűrűn. Leülünk a földre a sor végén, már alakul a tömeg. Egy Indiai családapával beszélgetünk aki, kényelmetlen kérdéseket tesz fel, olyan értelemben, hogy kiderül rólam szart se tudok semmiről. Ez amúgy elég jellemző rám itt Indiában.</p>
                                <p>Ahogy ülök a földön egy húsz perce, már úgy zsibbad a lábam mint még soha. Felhúzom a térdem ami hibának bizonyul, mert így ugye felszabadul balra-jobbra egy-egy térdnyi hely mellettem. Vannak jelentkezők az üresedésre és be is ülnek jobbra. Így már nem tudom letenni a térdem, viszont nem is tudok ilyen pózban egy órát ülni. Andris előttem van. Egy nő leül mellé jobbra, háttal Mr Bimba oldalának, majd fogja magát és mint egy kanapé háttámlát tesztelgeti Andreas oldalát, hogy miként kényelmes, és ahogy meg van a sweet spot szépen rásimul. Persze Andris mondja, hogy “how are you what?”, erre a nő elnézést kér és előrébb dől. Ami elgondolkodtat, hogy mi van ha nem szól? Akkor ez így normális? A legviccesebb, amikor egy nő konkrétan széknek próbálja Diát használni, ráül, amire ő elkezd finoman kiabálni, hogy:</p>
                                <blockquote>Úgy nézek ki mint egy szék? Menj innen, itt nincs hely<span>Dia the Chairman</span></blockquote>
                                <p>Persze a nő teljes ignorban van, de aztán talál egy láthatatlan pár négyzetcentit a földön amire kényelembe helyezi magát, másokon. Családias nah. Ahogy elindul a púdzsa akik hátul vannak elkezdenek teljesen logikusan mindenkin áttaposva előre menni, valaki kiabál velem, hogy menjek már. Ok. Megyek. Aztán azt magyarázzák vegyem le a pólóm mert ez már a Dolog, amit alig tudok levenni, megint nincs hozzá hely. Néhányan nagyon aggresszívan imádkoznak. Valakire rányom a tömeg úgy, hogy rá préselődök pár percre, mozdulni sem tudok, és ahogy épp összeolvadnak meztelen felsőtestjeink, szépen elnézést kérek tőle mire azt válaszolja:</p>
                                <blockquote>Nem kell elnézést kérned, ez ilyen<span>Guy under pressure</span></blockquote>
                                <p>Ahogy kijjebb érünk már fordulunk is rá a következő sorra, ami bevisz a Kígyóisten oltárhoz, a belső templomba. Olyan öt-hat csavaros kanyargós sor ez. Egy hosszú kígyóvá válik ez a sok ember. Van egy szakasz ami nyitott, hogy haladni lehessen a belső gyűrűn. Persze mivel ott nyitott ott sokan belógnak a sorba, levágva a kígyózást. Mi viselkedünk, és kb egy órát kígyózunk, mire odaérünk a “nyitott” szakaszhoz, ami után már közeledik az oltár bejárata.</p>
                                <p>Megjelenik egy csoport, kb tíz-húsz fiatalabb tag akik, gondolnak egyet és bevágódnak elénk ezen a szakaszon, van jobb dolguk mint ezt végig állni gondolom. Erre mögöttünk páran bepipulnak, illetve egy security csávó is látja ezt, és kis dulakodásba/lökdösődésbe torkollik a manőver, kicsit túl közel hozzánk. A szekus rendesen csapkodja tenyérrel/ököllel a tolakodókat, azok meg mint ma született bárány néznek rá, hogy nem értik mi a baj, ők csak úgy oda kerültek most így az előbb egyszercsak.</p>
                                <p>Bejutunk és besűrűsödik a massza, egy csávó az izzadt micimackó hasával ritmusosan becsapódik a verejtékes hátamba. Szinte hallok egy “boink” hangot minden alkalommal. Így terelget engem. Egy bácsi látva, hogy magam előtt hagyok pár centit, rohadt mérgesen kioszt, hogy menjek már az anyámot, mire előre engedem, hogy koccoljon szépen elfele. Remélem ő türelmet jött kérni a Kígyóistentől.</p>
                                <p>Még eggyel beljebb tekeredünk, és már arra a kanyarra fordulunk ami a legközelebb lesz az oltárhoz. Ennek a neve egyébként nem oltár hanem Garbhagudi (Kannada) vagy Garbhagriha (Hindi), a legbelső szentély a Hindu templomokban, csak az oltár valahogy nyugatosabb. Ahogy elérem az ajtót ami mögött már a Kígyóisten vár, kicsit meghátrálok. Most majd kérhetek tőle valamit. De mi legyen az? Mi az istent keresek én itt? Lehet többet is kérni? Nem sok időm van ezeken lamentálni mert én jövök, gyors tiszteletadás, még gyorsabb kérés, majd a kezembe adnak egy “feladatot” és intenek, hogy taka. Az egész lehetett tíz másodperc. Próbáltam több mindent gyorsan mondani, biztos ami biztos, amit adtak pedig segít majd a megvalósulásban.</p>
                                <p>A mai nap utolsó sora vár ránk, és tudom, tudom, uncsi ez a sorolás, de nyugodjál meg, fújd ki magad, minden rendben lesz. A spirituális mélységét ennek az egésznek nem tudom szavakba önteni, és nem is akarom megfosztani azt aki esetleg ellátogat ide majd, és olvassa ezeket a sorokat. Viszont erre az egészre a tömegből pislogtam, ahol jóformán el kellett veszíteni magamat, ahhoz, hogy végig tudjam ezt csinálni. Nem gondolom, hogy ez egy Indiainak akkora kihívás, de én még csak tanulom ezt a világot.</p>
                                <p>Van egy kicsi ajtó ami felé azt írták “EXIT”. Tényleg elég kicsi. A főbejárat már zárva van, több ember nem jöhet ma be, de ugye van ez a kicsi ajtó, és ha valamit próbáltam már többször átadni az az, hogy egy ilyen apróság, miszerint ez csak kijárat, itt nem állít meg senkit. Szóval ezen a kis résen mennénk ki, de közben meg jönnek rajta be. Ami megnyugtat, hogy itt már nincs harc, meg lett szerezve a türelem, szóval szép lassan ugyan de kicsorgunk. Pooja finished.</p>
                                <p>Odakint elsétálunk egy kis standhoz inni egy kókuszt, ahol egy tőgy arcú úriember nem szívesen szolgálja ki Diát szépen. Vannak minden kultúrának sötét foltjai. Közben én barátságot kötök a világ legcukibb kis bocijával, mászik a kezem alá a kis baba. Bimba vesz neki egy ubit, amit szépen becsócsál, majd két másik turista hoz neki kis dinnyét. A kókusz belsejét is elkunyizza mindenkitől, meg is érdemli szerintem. Ahogy Dia elővesz egy kézfertőtlenítőt úgy repülünk rá mint légy a szarra, egy ilyen mezítláb, félmeszkón eltöltött nap után.</p>
                                <p>Hívjuk Amruthot és irány vissza a Hotel. Olyan délután három lehet, de már nincs időérzékem, és nem is nagyon érdekel. Zuhany, evés, alvás, ennyi fér nekem ma bele. Kajálás közben, ahogy csendben rágcsálunk, Nat egyszer csak megszólal, hogy:</p>
                                <blockquote>I feel poojed<span>Nat the pooja survivor</span></blockquote>
                                <p>Ezen egy jót röhögünk, mert hát mind jó alaposan megpúdzsolva érezzük magunk. Nagy nap volt, de holnap még vár ránk a fő küldetés. Innen folytatom, <b>csum</b>.</p>
                            </>
                            : <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">सप्तदश Saptadasa</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">The Snake God<br /><span className="heading__sub align--center display--block">Part 2</span></h2>
                                </hgroup>
                                <p>Before diving into the continuation of our second day, it's important to note something. Here, the snake symbolises something different. In Christianity, the snake represents Satan, the evil force that deceives Eve, leading to the expulsion of humanity from paradise. In contrast, in Hinduism, the snake symbolises fertility, rebirth, transformation, immortality, and healing (and who knows what else). So, the snake here is cool. Oh, and while we're at it, the swastika here is a symbol of well-being, which that Austrian douchbag completely messed up, destroyed, and banned in Europe. Back to our story.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">Second day</span></h2>
                                <p>We got the tickets; it's around six o'clock, the temple is already open, and we squeeze through the not-so-wide door with the other devotees. The structure looks like this: an inner building for the snake sanctuary inside the middle has an outer square area where corridors and prayer rooms open up. This area is approximately 200 meters in circumference and 10 meters wide. These numbers will later prove to be significant.</p>
                                <p><b>The three pujas we paid for are as follows:</b></p>
                                <ul className="list list--disc margin-md--bottom">
                                    <li>Ashlesh Bali: An ancient ritual, a guided ceremony aimed at shedding the snake's curses (a clever turn of phrase, I know). Yes, there are several of these curses, and this one helps with four of them.</li>
                                    <li>Nagaprathishta: A personal blessing led by a swami and a guided hour-long prayer.</li>
                                    <li>Prarthana: Prayer that helps with workplace, health, and advancement obstacles and heals chronic skin diseases while smoothing out our earthly troubles.</li>
                                </ul>
                                <p>As we enter, to our left, we see a longer platform accessible by stairs where the Swamis (priests) are sitting, surrounded by people in a somewhat familial manner, snugly fitting together. We join the line leading to the stairs. As we progress further, we figure out the process: about six to eight people sit around the Swami, and a crowd surrounds them, taking turns as the ceremony progresses. It's a personal prayer; everyone states their name, family members, why they came here, etc. They receive something in their hands, and there are a few details I will keep you from. Then, pray for the fulfilment of their request. We are very close to everyone, almost squeezing each other. As we start to line up for the next shift, people push us further, and when they start to get up from the floor, somehow, the people push Dia and me even further, but we manage to put ourselves down. People are climbing on our necks. The Swami tells Andris and me to take off our shirts quickly as we sit. I barely have enough space to take it off, but I manage not to tear it. It's a rule here in the temple to go shirtless, something I'm learning now. The prayer goes on, which I'll keep to ourselves, but I'll tell you it's a very powerful experience. Somehow, we manage to get out, this time with bare upper bodies, squeezing against the bio-obstacles in front of us, but what happened earlier is more interesting, so it almost goes unnoticed.</p>
                                <p>When we get out, we follow the people into a line that takes us to the Nagaprathishta ceremony. There's a bit of luck here because we arrive when the hall is almost full, so we get pretty close to the Swami, who has already started the ritual. He sits in a lotus position in an enclosed area, surrounded by various items like flowers, vessels, wooden materials, etc., which he uses continuously. A microphone hangs above his head, and he chants Vedic texts. The hall is large, with a partition in the middle, so those at the back can watch the event on a TV. There are many hand movements and chanting, and the Swami lights a fire, putting everything into it, and the smoke fills the room. There's something in this smoke that soothes, makes you reflect, and then relaxes again, so it's like a trip (no, I don't think it's drugs; the ticket wasn't that expensive). In front of me, there's a little girl who gets bored after thirty minutes and stands in front of me, fidgeting. There's a stone platform next to where I'm sitting, and she finds a small pebble or something, picks it up, casually flicks it into my face, and then turns away. Andris chuckles a bit behind me. As the ceremony ends, people rush to the exit as if escaping, trampling over each other. We wait calmly. When we exit, we receive a coconut and candy and can put a dot on our foreheads. This time, the road takes us out of the temple; we don't understand why.</p>
                                <p>We turned back to the temple, which was quite difficult because there were already as many people as, well, you know, in India. After a tense argument, we decided we couldn’t accomplish one of the main things we came here for today, so we needed to return early tomorrow. We left the temple and headed to our hotel for a light breakfast at 10 AM. This day has been so intense that I took a short power nap of five minutes. We had masala dosa and other snacks, and then we went back to the temple at 11 AM to have a chance to see the Prarthana ceremony. We drop our slippers, enter the temple, and find a spot towards the back of the outer ring. We sit on the floor at the end of the line, and the crowd starts to form. We chat with an Indian father who asks uncomfortable questions, in the sense that it turns out I don't know shit about anything here. This is quite a typical feeling for me in India.</p>
                                <p>Sitting on the ground for about twenty minutes, my legs are completely numb. I pull up my knee, which turns out to be a mistake because it releases space on either side of me. There are volunteers for the free space, and they sit on my right. I can't put my knee down, but I can't sit in this position for an hour, either. Andris is in front of me. A woman sits next to him on the right, with her back to Mr. Bimba's side, and she pushes herself to him like testing a couch, finding the sweet spot. Of course, Andris says, “How about no?" to which the woman apologises and leans forward. This makes me wonder, what if he hadn't said anything? Is this normal? The funniest part is when a woman literally tries to use Dia as a chair, sits on her, and Dia gently starts shouting:</p>
                                <blockquote>Do I look like a chair? Go back, there's no space here.<span>Dia the Chairman</span></blockquote>
                                <p>Of course, the woman completely ignores this, but then she finds an invisible square centimetre on the floor to make herself comfortable. Quite familial. As the poojas begin, those at the back start logically pushing forward. Someone shouts at me to move, and I comply. Then they instruct me to take off my shirt because this is the Thing. I barely have space to do it again. Some people pray very aggressively. I got pressed against someone, so we leaned on each other for a few minutes. I can't move, and as our bare upper bodies almost merge, I apologise to him, and he responds:</p>
                                <blockquote>No need to apologise, that's how it is<span>Guy under pressure</span></blockquote>
                                <p>As we move further, we turn towards the next queue leading to the altar. It's a twisted line of about five or six turns. It becomes a long snake of people. There's a section that's open so that people can move along the inner ring. Of course, since it's open, many people cut into the line, disrupting the snake formation. We behave and snake for about an hour until we reach the "open" section, after which the entrance to the altar is approaching.</p>
                                <p>A group of about ten to twenty youngsters appears and squeezes in front of us in this section, apparently having better things to do than standing in line, I suppose. Behind us, some people get angry, and a security guy sees this, leading to a little scuffle/shoving, a bit too close to us. The security guy hits the pushy ones with his palms/fists, and they look at him like newborn lambs, not understanding what's wrong; they just end up being there all of a sudden.</p>
                                <p>We make it in, and the crowd thickens; a guy with a sweaty Winnie the Pooh’s belly rhythmically slams into my sweaty back. I can almost hear a "boink" sound every time. He guides me like this. Seeing that I’m leaving a few inches in front of me, an older dude angrily scolds me to stop the sightseeing, so I let him pass forward so he can chill out. I hope he came to ask for patience from the Snake God.</p>
                                <p>We twist one more turn inward, and we already turn towards the bend that will be closest to the altar. Its name is not altar, by the way; it's called Garbhagudi in Kannada or Garbhagriha in Hindi. It is the innermost sanctuary in Hindu temples, though the term altar sounds more Western. I hesitate momentarily as I reach the door where the Snake God awaits. Now I can ask something from him. But what should it be? What am I seeking here? Can I ask more than one? I don't have much time to lament about these because it's my turn; quick respect, an even quicker request, and then they hand me a "task" while signalling to beat it. The whole thing might have taken ten seconds. I said more things quickly, to be sure, and what they gave supposably will help to make it happen.</p>
                                <p>The last line of today awaits us, and I know, I know, listing all this might be boring, but relax, take a deep breath, everything will be fine. I can't put the spiritual depth of all this into words, and I don't want to deprive anyone who might visit here and read these lines. But I glimpsed at this whole thing from the crowd, where I practically had to lose myself to go through this. I don't think it's such a challenge for an Indian, but I'm still learning this world.</p>
                                <p>There's a small door labelled "EXIT." It's really small. The main entrance is already closed, no more people can enter today, but there's this small door, and if there's one thing I've tried to convey multiple times, it's that just because this, just an exit, won't stop anyone. So, we would go out through this little gap, but people start coming in through it. What comforts me is that there's no fight here; patience has been acquired, so we slowly but surely get out. Pooja finished.</p>
                                <p>Outside, we walk to a small stand to drink a coconut, where a grumpy-faced grinch is reluctant to serve Dia nicely. Every culture has its dark spots. Meanwhile, I befriend the world's cutest little calf; it crawls under my hand. Bimba buys her a cucumber, which she gracefully nibbles on, and then two other tourists bring her some watermelon. She gets the coconut meat as well from Andris. As Dia takes out a hand sanitiser, we pounce on it like a fly on shit after a day spent barefoot and half-naked.</p>
                                <p>We call Amruth and head back to the hotel. It's around three in the afternoon, but I've lost my sense of time, and I'm not really interested either. Shower, eat, sleep—this is all I have energy for today. While eating, Nat suddenly says:</p>
                                <blockquote>I feel poojed<span>Nat the pooja survivor</span></blockquote>
                                <p>We all burst into laughter because we all feel thoroughly pujed. It's been a big day, but tomorrow, the primary mission is still ahead of us. I'll continue from here, <b>peace out.</b></p>
                            </>
                        }
                    </article>
                    <article className="blog__article">
                        {language === "HU"
                            ? <>
                                <p className="box no-margin--bottom">
                                    <strong className="margin--bottom">Couchroach</strong>
                                    Emlékszel még mikor ott chilleztél a sapkámban. Milyen egyszerű volt minden. Csak annyi kellett volna, hogy mikor kérlek itt hagysz, de te ragaszkodtál hozzám. Nem álltál készen tovább lépni. Mint visszatérő kezelt a terápián, befészkelted magad a kanapéba, és vártad, hogy megbeszéljük. Én nem akartam beszélni. Mikor felvertél a szundimból tegnap, bevallom azt hittem már nem vagy itt. Napok teltek el mióta láttalak. Ijjedtemben, hogy bemész a hálószobába felverni Diát is, nem tehettem mást, de meg kellett állítsalak. Remélem nincs harag, és jó karmával vágsz neki a következő eljövetelnek. Még látlak, csákány csótány.
                                </p>
                            </>
                            : <>
                                <p className="box no-margin--bottom">
                                    <strong className="margin--bottom">Couchroach</strong>
                                    Remember when you were chilling in my cap? Everything was so easy back then. All I asked was for you to leave when I pleaded, but you were sticking by my side. You weren't ready to move on. Like a recurring patient in therapy, you buried yourself on the couch, waiting for us to talk it out. I didn't want to talk. When you woke me up from my nap yesterday, I confess, I thought you were long gone. Days have passed since I last saw you. In my panic that you might wake Dia in the bedroom, I had no choice but to stop you. I hope you have no hard feelings and embark on the next adventure with good vibes. I'll catch you around, peace out.
                                </p>
                            </>
                        }
                    </article>
                    <article id="astadasa" className="blog__article">
                        {language === "HU"
                            ? <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">अष्टादश Astadasa</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">A Kígyóisten<br /><span className="heading__sub align--center display--block">3. rész</span></h2>
                                </hgroup>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">Harmadik nap</span></h2>
                                <p>Túl korán kelek, három valamennyi van. Hamarosan nekimegyünk a legnehezebb célunknak itt, ami a következő: be kell menni a templomba, a külső körgyűrűn elsétálni oda ahol az oltárral szemben állunk, lefeküdni a földre nyújtott karral és lábbal, és körbe forogni mint egy kígyó (vagy farönk) azt a kb 200 méteres távot amíg visszatérünk a kiinduló ponthoz. Igen, tudom ez hogyan hangzik. Elkezdek izgulni. Úgy tudom ezt régen sokan csinálták, de tegnap csak egy valakit láttunk mintha forgott volna. Keresgélek a neten képet, videót, vagy valami cikket erről. Lakshmishji mondta, hogy ezt csináljuk meg mindenképpen, és ő csak tudja már. Lehet csak viccelt? Találok valamit.</p>
                                <p>Azt írják, hogy volt régen egy szertartás, ami úgy nézett ki, hogy a szerzetesek hagytak a banánlevélen (itt azt használják mint tányér) maradékot a reggeliből, amit ezen a 200 méteres körön kiterítettek a földre. Szóval ilyen kaja maradékos lett a placc. Erre jött sok száz félmeztelen férfi, és nem félmeztelen nő, és végig forogtak a földön, keresztül a kaján meg egymáson. Azt a célt szolgálta ez a rituálé, hogy a résztvevőket meggyógyítsa különböző bőrbetegségektől. Könnyű belátni, hogy ez nagy valószínűséggel hatásosabb arra, hogy elkapj valami retket. Be is tiltották emiatt tizenpár éve a földön pergést, ami lassan, de átment az emberek fején és feladtak vele. Ez nem segített a küldetésemen, abba is hagyom ezt a kutatást.</p>
                                <p>Ma 5 órakor megyünk megmártózni a halakkal. Igen megint meg kell, mert a templomba menés előtt fürcsi van a szent folyóban. Ezúttal kicsit már rutinosabbak vagyunk, nyomjuk. Teli hold van, és egész meseszerű a kép amit a hold és a folyó fest. Dia és Nat megy ma előre, és egész messze bemerészkednek. Amúgy nagyon sekély a víz. Ahogy kijönnek, már ugrunk is be Andrissal. Én egy ilyen szappan pajzsot kenek magamra, és szinte folyamatosan pótlom ahogy a víz lemossa. Kicsit se nézhetek ki mániákusan, de mondjuk pont leszarom, hogy nézek ki. Meg akarok tisztulni, azért vagyok itt, eszem a játékon, szemem a labdán. Innen rontás nélkül megyek haza akármi is lesz. Így, ahogy vagyunk vízesen kell a templomba menni.</p>
                                <p>Megérkezünk, megyek leadni a papucsom (meg a lányok itt már outsource-olják ezt a nemes feladatot nekem, szóval azokat is). Ez egyébként páronként két rúpia, tehát mondjuk kilenc forint. Ahogy nyújtom a kezem, nem akarja adni a bilétát a papucs ember, aztán elkezd beszélni valamit amit nem értek, majd megfogja a jobb kezem és belenyomja a zsetont. Szóval az volt a baja, hogy a bal kezemet nyújtottam, az meg amennyire értem miért problémás itt, szaros. Legalábbis az Indiaiak szerint. A csávó egyébként egész nap tehén szaros papucsokat fogdos, de ok, én most kentem magamra egy blokk szappant, nem én vagyok itt a baj. Balkezesek vagyunk Diával amúgy, ahogy Einstein és Eminem is az, szóval jól van ez így szerintem. Bocs, kicsit elkalandoztam.</p>
                                <p>A templom hatkor nyit, még van addig negyven perc. A bejárathoz közel leparkolunk a földre. Még nincsenek sokan, viszont mindenhol alvó emberek, családok lepik el a templom padlót. Itt töltötték az éjszakát. A lányok kicsit vacogni kezdenek, nekik vizesebb a ruhájuk, meg van hajuk is. Ahogy közeleg a nyitás, gyűlnek az emberek, de ez semmi ahhoz ami tegnap volt. Amúgy tegnap vasárnap volt és mint megtudtuk, az a legforgalmasabb nap ide jönni. Időzíteni tudunk az biztos.</p>
                                <p>Nyílik az ajtó, páran elkezdenek tolakodni, ez ilyen bemelegítés lehet nekik, mert amúgy erre most semmi szükség. Mi szépen elsétálunk a starthoz, ahonnan indítjuk majd a pörgést. Úgy be vagyok szarva mint egy nagyon fontos vizsga előtt, de nem igazán értem miért. Talán mert mi van ha néznek majd? Vagy ha ez tilos? Vagy hogy undi, mert a padló, hát az ugye olyan amilyen? Mi van ha rókázni kell közben?</p>
                                <p>Ahogy odaérünk az oltárral szemben látjuk, hogy egy nő pörög a földön. Ugyan nem akar körbe menni, pár méteren megy balra meg jobbra, de nagyon sokat segít mindannyiunknak látni, hogy valaki ezt csinálja. Megbeszéljük, hogy a menet úgy lesz, hogy fiú-lány-lány-fiú, ezzel bevédve középen a lányokat, bár nem tudom mitől, meg amúgy azt se hogyan, de így biztonságosabbnak tűnik.</p>
                                <p>Andris köszönti a Kígyóistent, lefekekszik a földre nyújtott végtagokkal, és elkezd balra forogni. Ahogy ez megtörténik, egy harmóniumos (Indiai mulatós hangszer) és dobos ember, egy ilyen induló szerűséget kezd játszani. Nagyon meghitt. Nat jön, aztán Dia indul, végül rajtam a sor. Nagyon zavarodott ez az egész, nem is sikerül megértenem még. Ahogy kb tizenötöt forgok egy pap sétál el felettünk, és mondja:</p>
                                <blockquote>God bless you, God bless you, God bless you<span>Swami boost</span></blockquote>
                                <p>Nagyon sokat segítenek ezek a szavak, mert ugyan csak most kezdtem, már szédülök, és még több száz fordulat a vége, illetve néhány miértet is kipipál ezzel a fejemben. Ahogy elérünk az első kanyarig, Dia kap egy röhögőgörcsöt, ami ragályos, és mindannyiunkra átterjed. Hangosan röhögünk és forgunk. A padló köves, egyenetlen. Vízzel felmoshatták mert itt-ott kisebb tócsák vannak, amiket a nadrágom mint egy szivacs felszív ahogy átforgok rajtuk. Nagyon guszta. Kezd a röhögés elhalványulni. Nat elhagyta a törölközőjét, amin ahogy átforgok magamhoz veszem. Megjelenik bennem egy félelem szerű érzés, hirtelen megkomolyodok.</p>
                                <p>Az alaprajza a templomnak téglalap alakú, és most az egyik hosszú oldalon vagyunk, valahol a felénél. Nem értem mit csinálok. Ahogy a második sarokra érünk, próbálok ráfordulni de nincs annyi hely, és kicsit könyökkel ki kell magam emelni, ami szépen le is horzsolódik. Elkezdek azon izgulni, hogy vérzik a könyököm és mi lesz ha ebből baj lesz. Ez most nem a gondolkodás ideje, csak forgok tovább. Ezt végig lehet csinálni?</p>
                                <blockquote>They see me rollin’...<span>Zolly Ridin'</span></blockquote>
                                <p>Amikor a táv felénél vagyok, valakibe beleütközöm, nem lép el, szóval gyorsan négykézlábon előrébb kell menjek. Ahogy viszont egy pillanatra leáll a pörgés veszem észre, hogy nagyon is pörög minden más is, és éppen csak sikerül megfogni, hogy ne hányjam össze a templom padlót meg ezt a valakit aki nem ment odébb. Vissza a forgáshoz ami ezen ponton beszippant, az egyetlen komfortom. Lehetetlennek tűnik végigcsinálni, de megállni se akarok. Ahogy fordulok a célra már azt sem tudom hol vagyok, meg ki vagyok, csak a légzésem figyelem. Egy levegővel balanszoló tüdő vagyok.</p>
                                <p>Elérem az oltár bejáratát utolsóként, négykézlábra ereszkedem, és elkezd rólam ömleni a víz. A hangszeres ember elkezd zenélni, mintha csak az induló után egy célba érős zenével is meg akarna minket jutalmazni. Minden energiámmal próbálom megakadályozni, hogy kidobjam a taccsot. Mintha transzban lennék kívülről látom magam. Semmi időérzékem nem volt forgás közben, nem tudom megmondani mennyi lehetett, de végül olyan tizenöt-húsz percre tippelünk közösen. Ennyi ideig folyamatosan forogtunk a kőpadlón. Hülyeségnek hangzik, de nem emlékszem mikor voltam büszkébb valamire. Legyőztem magam. Mind a négyen imára borulunk, kimondjuk amiért jöttünk, és megemlékezünk azokról akikért érdemes volt ez.</p>
                                <p>Ahogy összekaparjuk magunk, megyünk is ki a papucsok és a kocsi felé. Nem sokat beszélünk, mind a négyen csak hangosan pislogunk. Amruth már vár minket, és visz is a hotelhez. Nyomunk egy zuhanyt (életem talán legalaposabbját), és lemegyünk egy kávéra. Itt megosztjuk egymással ki mire gondolt közben, mi jelentett kihívást. Mind a négyen máshogy éltük meg ami történt, ami érdekes. Egy biztos, nagyon kellettünk itt végig egymásnak az elmúlt napokban, és ha eddig nem mondtam, Dia (jó azért ezt ő tudja), Nat, Andris, legnagyobb arcok, nagyon csipázom őket, legjobb volt így együtt.</p>
                                <p>Bejön egy nagy pillangó, aki nem tud kirepülni az étterem ablakán. Próbáljuk terelgetni de nem megy neki. Valamerre elszáll, majd egy perc múlva egy igen büszke tekintetű pincér jelenik meg ujjai közt szorítva a pillangót a szárnyánál fogva. Mint mikor a cica hoz egy egeret, nagyon cuki. Megköszönjük neki de jelezve, hogy mi nem megölni akartuk ezt a szép állatot, majd Nat megmutatja az irányt, és végül kirepül az ablakon a lepke. Megint elkalandoztam, bocs.</p>
                                <p>Nem eszünk mert vár ránk a hazaút, és megtanultuk a leckét idefele. Most inkább azon izgulok mennyit bír a gyomron még. Mintha egy vidámparkban kalkulálnám a kapacitásom két hullámvasút között. Mielőtt beszállnánk a kocsiban az étterem staff lerohan, mintha valaki lennék, kell nekik egy közös fotó. Biztos olvassák a blogom.</p>
                                <p>Ahogy Amruth indul, Nat, aki amúgy Dj is, rá megy a kocsi hangcuccára, és kiderül, hogy van egy igen kemény mélyláda a csomiban. Beteszi a Limp Bizkit-től a Rollin-t, amin alaposan kiröhögjük magunkat.</p>
                                <blockquote>Keep rollin', rollin', rollin', rollin’<span>Fred Durst</span></blockquote>
                                <p>A kövi track Adele Rolling in the deep, és így tovább, el tudod képzelni a playlistet. Elhaladunk a templom mellett, és elköszönünk a Kígyóistentől. Amruth nyomja neki, nem sokat változott két nap alatt, százzal kanyarodunk. Szerencsére most mi vagyunk a zene szóval bőgetjük azt a mélyládát mindennel, és partyzunk, ami segít elterelni a figyelmet a halálközeli élményről amit emberünk okoz. A nem evés nagyon megköszöni magát mert a belem már azt se tudja merre van az előre, miközben ide-oda csapódok ebben a rally autóban.</p>
                                <p>Megállunk félúton egy kávézóban, ami annyira fancy mintha nem is itt lennénk. Bedobunk egy nachost, teát, kávét, a sofőrünk meg egy shaket valami sütivel. Itt megtudjuk, hogy 27 éves, még nem házas, de már meg van beszélve neki, hogy jövőre házas lesz. Egy gonddal kevesebb, gondolhatja.</p>
                                <p>Az indulástól eltelt kicsit több mint négy óra, és hazaérünk. Rengeteg élmény, érzés, hála, szorosabbá vált testvériség, tenyérnyi zúzódások, hogy néhány szuvenírt említsek az egyértelműen legspirituálisabb utazásból amit valaha átéltem. Csak lélegezz, <b>a következő viszontra.</b></p>
                            </>
                            : <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">अष्टादश Astadasa</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">The Snake God<br /><span className="heading__sub align--center display--block">Part 3</span></h2>
                                </hgroup>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">Third day</span></h2>
                                <p>I wake up too early, it's barely three. Soon, we'll tackle our toughest goal here, which is as follows: we need to enter the temple, walk on the outer ring until we stand opposite the altar, lie down on the ground with arms and legs outstretched, and rotate around like a snake (or a log) for about 200 meters until we return to the starting point. Yes, I know how it sounds. I'm starting to get nervous. I heard many used to do this in the past, but we only saw one person doing it yesterday. I'm searching the internet for a picture, video, or some article about it. Lakshmishji said we must do it, and he already knows. Could he just be joking? I'll find something.</p>
                                <p>They say there was a ceremony where the monks left breakfast leftovers on banana leaves (they use it as a plate here) on this 200-meter circle, spreading it on the ground. The place became messy with food remnants. Then hundreds of half-naked men and no naked women came and rolled on the floor, through the food and each other. This ritual aimed to heal the participants from various skin diseases. It's easy to see that this is more effective in catching something nasty. Because of the right, this rolling was banned a dozen years ago, which slowly but surely went over people's heads, and they gave up on it. This didn't help my mission; I'm abandoning this research.</p>
                                <p>Today, at 5 AM, we will bathe with the fish again. Yes, we must do it again because one must bathe in the holy river before going to the temple. This time, we are more experienced, pushing through. There's a full moon, and the image painted by the moon on the river is quite magical. Dia and Nat are going ahead today and venture quite far. The water is very shallow, by the way. As they come out, Andris and I jump in immediately. I put on this soap shield and constantly replenish it as the water washes it away. I’m sure I look a bit too obsessive, but I don't give a shit how I look. I want to purify myself; that's why I'm here, mind on the game, eye on the ball. I'm going home without a curse. As wet as we are now, we must go to the temple like this.</p>
                                <p>We arrive, and I go to drop off my sandals (and the girls have already outsourced this noble task to me, so theirs too). It's two rupees per pair, which is about 0.025 euros. As I reach out my hand, the sandal guy doesn't want to give me the token, then he starts saying something I don't understand, then he grabs my right hand and pushes the token into it. So, his problem was that I reached out my left hand, which, as far as I understand, is considered as shitty here. At least, according to Indians. The guy, by the way, handles cow-dung-covered sandals all day, but okay, I just applied a block of soap to myself, I am not the issue here. Dia and I are left-handed, by the way, like Einstein and Eminem, so for me, it's fine this way. Sorry, I got a bit off track.</p>
                                <p>The temple opens at six, there are still forty minutes left. We park ourselves on the ground near the entrance. There aren't many people yet, but sleeping people and families cover the temple floor everywhere. They spent the night here. The girls start to shiver a bit; their clothes are wetter, and they have hair too. People gather as the opening time approaches, but it's nothing compared to yesterday. By the way, yesterday was Sunday, and as we discovered, it's the busiest day to come here. We can time things for sure.</p>
                                <p>The door opens, and some start to push; it might be a warm-up for them because otherwise, there's no need for it. We walk nicely to the starting point, where we'll initiate the rolling. I'm nervous as if it's a very important exam, but I don't really understand why. Maybe because of what if people stare? Or if this is forbidden? Or if it's yukky because of the floor, which is, you know, what it is? What if I have to throw up in the meantime?</p>
                                <p>We see a woman spinning on the ground as we reach the altar. She doesn't want to go an entire round; she goes a few meters left and right, but it helps all of us to see someone doing this. We decide that the order will be boy-girl-girl-boy, protecting the girls in the middle, though I don't know why, and I don't know how, but it seems safer this way.</p>
                                <p>Andris greets the Snake God, lies on the ground with limbs outstretched, and starts rolling to the left. As this happens, a harmonium player (an Indian folk instrument) and a drummer start playing something like a patriotic tune. Very intimate. Nat follows, then Dia, and finally, it's my turn. This whole thing is very confusing, and I still can't fully grasp it. After rolling for about fifteen rounds, a priest walks by and says:</p>
                                <blockquote>God bless you, God bless you, God bless you<span>Swami boost</span></blockquote>
                                <p>These words help a lot because even though I've just started, I'm already dizzy, and there are several hundred more spins to go. He also solves a few 'whys' in my head. As we reach the first turn, Dia bursts into laughter, which is contagious, and we all start doing it. The floor is paved and uneven. They mopped it with water because there were small puddles here and there, and my pants absorbed like a sponge as I spun over them. Quite delightful. The laughter begins to fade. Nat left her towel, which I grabbed as I spun. A fear-like feeling creeps in, and I suddenly become serious.</p>
                                <p>The temple has a rectangular layout, and we are on one of the long sides, somewhere around the middle. I don't understand what I'm doing. As we reach the second corner, I try to turn but there's not enough space, and I have to lift myself a bit with my elbows, which get nicely scraped. I start worrying about whether my elbow is bleeding and what will happen if it turns into a problem. This is not the time for thinking; I keep rolling. Can I make it through this?</p>
                                <blockquote>They see me rollin’...<span>Zolly Ridin'</span></blockquote>
                                <p>When I'm halfway through, I bump into someone who doesn't move, so I quickly have to crawl forward. However, as the rolling momentarily stops, I realise everything else is still spinning swiftly. I managed to grab onto something just in time to avoid throwing up on the temple floor and the person who didn't move. It seems impossible to roll the way through, but it is the only comfort at this point. I don't want to stop. As I turn towards the goal, I don't even know where or who I am; I'm just focused on my breathing. I am a lung balancing on a single breath.</p>
                                <p>I reach the entrance of the altar last, lower myself to all fours, and water starts pouring over me. The musician starts playing as if he wants to reward us with victory music. I use all my energy to prevent myself from throwing. Like I was in a trance; It's as if I'm watching myself from the outside. I had no sense of time during the spins; I can't tell how long it lasted, but collectively, we estimate about fifteen to twenty minutes. We spun continuously on the stone floor for that long. It may sound foolish, but I can't remember being prouder of something. I conquered myself. The four of us kneel in prayer, say what we came for, and remember those for whom this was worth it.</p>
                                <p>As we gather ourselves, we head out to get our slippers and go to the car. We don't talk much; all four of us blink loudly. Amruth is already waiting for us and takes us back to the hotel. We shower (perhaps the most thorough of my life) and go for a coffee. Here, we share with each other what we thought during the rolling and what challenges we faced. Each of us experienced what happened differently, which is interesting. One thing is for sure: we needed each other here in the past few days, and if I haven't said it before, Dia (well, she knows it), Nat, Andris, coolest folks, I really like them, it was the best doing this together with you.</p>
                                <p>A large butterfly comes in, unable to fly out of the restaurant window. We try to guide it, but it doesn't work. It flies somewhere, and then a minute later, a waiter appears with the butterfly held between his fingers by its wing, looking very proud. Like when a cat brings a mouse, it’s very cute. We thank him but indicate that we didn't want to kill this beautiful creature. Nat shows the direction, and finally, the butterfly flies out the window. Sorry, I got a bit off track again, my bad.</p>
                                <p>We don't eat because the journey back awaits us, and we learned our lesson on the way here. Now, I'm more concerned about how much my stomach can take. It's like calculating my capacity between two roller coasters in an amusement park. Before getting into the car, the restaurant staff rushed in as if I were someone important; they wanted a group photo. They probably read my blog.</p>
                                <p>As Amruth starts, Nat, who is also a DJ, goes for the car's sound system, and it turns out there's a killer subwoofer in the trunk. She puts on 'Rollin' by Limp Bizkit, and we laugh heartily at ourselves.</p>
                                <blockquote>Keep rollin', rollin', rollin', rollin’<span>Fred Durst</span></blockquote>
                                <p>The next track is Adele's 'Rolling in the Deep,' and so on; you can imagine the playlist. We pass by the temple and bid farewell to the Snake God. Amruth steps on it; a lot has stayed the same in two days; we are going into the turns by a hundred km/h. Fortunately, we are the music this time, so we blast that subwoofer with everything and party, helping distract ourselves from the near-death experience caused by our driver. Not eating is thanking itself because my stomach has no idea where forward is while I bounce back and forth in this rally car.</p>
                                <p>We stopped halfway at a café, which was so fancy that it was as if we weren't there. We throw in some nachos, tea, and coffee, and our driver gets a shake with some pastry. Here we find out that he's 27, not married yet, but it's already decided that he'll get married next year. One less worry, he must be thinking.</p>
                                <p>It's a little over four hours since we left, and we're home. Lots of experiences, feelings, gratitude, a closer brotherhood, palm-sized bruises, to mention a few souvenirs from undoubtedly the most spiritual journey I've ever had. Just breathe, <b>see ya</b>.</p>
                            </>
                        }
                    </article>
                    <article id="ekonavimsatih" className="blog__article">
                        {language === "HU"
                            ? <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">एकोनविंशति Ekonavimsatih</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">A Guru és én<br /><span className="heading__sub align--center display--block">3. rész</span></h2>
                                </hgroup>
                                <p>Ez a befejezés várat magára egy ideje, mivel még jártam reggelente a Guruhoz gyakorolni, vagyis még íródott a történetünk. Tavaly, az első vizitemen itt Mysore-ban, úgy váltak el útjaink Sharathjivel, hogy Zollynak hívott. Emlékszem valahol a szezon vége felé voltunk, amikor egy vezetett órán elértem az utolsó pózom, vagyis csak vártam, hogy a levezetőnél vissza csatlakozzak, mikor a Guru megkért, hogy töltsek fel egy vizes flakont. Vannak ilyen sprays flakonok a földön, és némelyik üres volt. Rendesen elégedett voltam magammal, mert kaptam egy kis házimunkát a főnöktől. Mondjuk lehet csak unta nézni, hogy ott ülök a seggemen amíg mindenki gürizik de ez most mindegy.</p>
                                <p>Nem sokkal ezelőtt sikerült először, kis segítséggel a “Marichyasana B”, amit látott, végig szurkolt, majd egy nagy mosollyal megdícsért, hogy végre megcsináltam. Szép pillanat volt. A következő nap ő maga jött segíteni, de nagyon be volt feszülve a vállam, aznap nem ment jól, és miközben próbálta összecsomózni a kezeim a hátam mögött, ezt mondta:</p>
                                <blockquote>Grhh lazulnod kéne, ez jóga nem birkozás… ez szórakozás kell legyen<span>Sharathji coaching</span></blockquote>
                                <p>Ezek a szavak, miután visszatértünk kis hazánkba, még legalább egy fél évig visszhangoztak bennem. Nem csak jóga közben, munkahelyi vagy bármilyen akadályba ütközésnél eszembe jutottak, és a Guru hangján elismételtem magamban, mindig működött. Milyen egyszerű mégis nagyszerű üzenet. Sajnos ahogy ment az idő úgy halványultak el ezek a mondatok bennem, és elvesztették az erejüket. Győzött a sok szar ami körülvett. Az érdekes ebben az, hogy persze magamtól is tudnék ilyen póló felirat mondatokat kitalálni, nem lenne nehéz, de valahogy azért mert Ő mondta, és amikor, amilyen helyzetben mondta, ez sokkal többet ért így nekem. Nyugudjá’ le, ez nem birkózás.</p>
                                <p>Hiába tudja Sharathji a neved ha nem figyelsz amikor mondja. Van ez a helyzet amit úgy hívok “menni vagy nem menni” mikor szólít a Guru. Fogadni mernék, hogy néha szándékosan nagyon halkan hív, teszteli, hogy megérdemled-e a neved. Jó, lehet, hogy ez csak paranoia, de nem vagyok egyedül ezzel az elmélettel (mondjuk a lapos föld hívők is többen vannak, de az egy dolog). Ha ilyenkor nem mész mert azon agyalsz jól hallottad-e amit mond, akkor vagy megpróbálja megint eggyel szigorúbban, vagy lebüntet és újra csak “one more” leszel egy darabig.</p>
                                <p>A második szezonnak úgy mentem neki, hogy reméltem emlékszik rám Sharathji, meg kicsit azt is reméltem, hogy nem, mert nem sikerült nagyon előre haladnom egy év alatt bevallom. Jó amúgy a pózok le vannak szarva az Astangában, nem az itt a lényeg, csak nyomni kell azt kész. A szezon nyitás két vezetett órával kezdődik, és a második nap, megjelent a Marichyasana közben és igazított rajtam egyet. Itt már tudtam emlékszik rám, illetve azt is, hogy továbbra is kimagaslóan mondjuk úgy másképp csinálom ezeket az ászanákat a többiekhez képest. Nem nehéz ezt az esetlenséget kiszúrni nah.</p>
                                <p>A 6:30-as jógi adagba lettünk Diával beosztva, ami korábbi a tavalyinál és ez jó dolog, leginkább azért mert kevesebben vannak, vagyis kevesebbet kell várni. Az első hét vége fele jött óra közben Sharathji és lepislogta a kártyámat. Tudta ki vagyok csak kellett kis frissítés, nem vagyok épp híres szóval persze, sima. Valamelyik következő reggelen nagyon korán odaértünk a kapuhoz, 5 óra lehetett. Még új a motorozás és biztosra akartam menni, fingom nem volt mennyi lesz odaérni. Szóval ülünk ott kint és kinéz az ajtón. Ketten ülnek legelöl, rájuk néz, és megkérdezi tőlük, hogy:</p>
                                <blockquote>Ti házasok vagytok?<span>Guruji Q&A</span></blockquote>
                                <p>Válaszolják, hogy igen, házasok, erre mondja nekik, hogy akkor menjenek be, mostantól 5:30-asok. Ahogy feldolgoztam mi történt, ezt a kis esélyt, elkezdtem idétlen fejjel jelezni, néha még mutogatva is mint egy óvodás, hogy én is, én is tancibá. Rám néz és kérdezi:</p>
                                <blockquote>Te is házas vagy?<span>Guruji Q&A</span></blockquote>
                                <p>Mondom, igen és mutatok mint egy hülye Diára. Valahogy nem hisz nekem, ezért megkérdezi:</p>
                                <blockquote>De most tényleg?<span>Guruji a nem hisz</span></blockquote>
                                <p>Megint mondom, hogy igen, még mindig házasok vagyunk, majd int, hogy akkor befele, és majd írassuk át a titkárságon a kártyánk 5:30-ra. Ezzel az időponttal még kevesebbet kell várni ami nagyon adja. Neki ülünk bent a kezdésre várakozásnak, mikor hallom, hogy:</p>
                                <blockquote>Zoltan gyere!<span>Sharathji hívás</span></blockquote>
                                <p>Én meg mentem, letettem a szőnyegem és már nyomtam is. Pár perccel beljebb a gyakorlásban, a Guru megszólal, hogy:</p>
                                <blockquote>Zoltan felesége!<span>Sharathji viccesji</span></blockquote>
                                <p>Dia nem hallja ezt, mert hát lássuk be nem ez a neve, ezért Sharathji megismétli, ezúttal kézjeleket is belevisz, és ez már így célba ér. Én közben ezt végig nevetem, mert ez vicces. Jó az a része elég ironikus, hogy Dia nélkül hangyapénisznyi esélye lenne annak, hogy itt lennék ahol épp vagyok, szóval, hogy pont így vette fel a nevem, az nem fair. De azért vicces. Egyébként van olyan akit úgy hív be a Guru, hogy “Sophie férje” szóval ez nem szexizmus, úgyhogy nem kell feszülni, csak a hangulatot csavargatja ezekkel a nevekkel a főni.</p>
                                <p>Néhány nappal később, egyszerre érkezünk a Guruval a parkolóba, és ahogy száll ki a kocsiból mi épp arra sétálunk. Így a semmiből oda vet egy kérdést:</p>
                                <blockquote>Ti vagytok a Rajeshnél?<span>Guruji a kém</span></blockquote>
                                <p>Megszeppenünk, visszakérdezek, hogy mi ez a, mi van, hogy mi? Megismétli a kérdést és itt leesik, hogy a szállásadónkról van szó, aki történetesen Rajesh. Mondom, hogy igen ott lakunk, ő meg magabiztosan mondja, hogy “tudom”. Ezen kicsit beszarunk. Ezután pár nappal mikor haza értünk, ott volt Rajesh a háznál, meg rengeteg rendőr. Kicsit megfagytak az ereim, mert mi van ha értünk jöttek, de gyorsan kiderült, hogy Rajesh spanja a rendőrfőnöknek és csak kávéztak. A többi zsaru csak az escort volt. Miután bemutatott neki kérdeztem, hogy tudja-e, hogy Sharathji tudja, hogy itt kecózunk, mire azt válaszolta, hogy igen beszéltek. Azt is mondta, hogy a Guru kérdezte, hogy a feleségemmel vagyok-e itt. Azon kezdtünk el agyalni ezután, hogy lehet nem hitte el? Gondolta trollkodok egy ekkorát egy korábbi helyért? Mindenesetre az kiderült, hogy Rajesh igazat mondott arról, hogy ismerik egymást Sharathjivel. Mindenki mond itt mindent maradjunk annyiban. Azt vagy elhiszed vagy nem.</p>
                                <p>Egy konferencián arról beszél Sharathji, hogy a nyugati világban ritka a hosszú házasság, az összetartó család. Ezt elég rossz napon (vagy pont a jó napon) mondja, mert ma van a 18. évfordulónk Zoltán Feleségével. A konf végén egy hang a fejemben azt súgja ezt most el kell neki mondanunk, hátha valami kis kreditet kap a hanyatló nyugat. Oda rángatom Diát a trónhoz, nyilván ő ezt nem tartja a legjobb ötletnek, és mondom neki, hogy:</p>
                                <blockquote>Csak annyit akarok mondani, hozzáadva az elhangzottakhoz, hogy ma van a 18. évfordulónk, szóval van még remény<span>Zolly az egyenlítő</span></blockquote>
                                <p>A Guru pedig gratulált és mosolygott. 10 pont a Griffindélnek.</p>
                                <p>A következő vezetett órán, mikor meglátogatott a jó öreg “Marichyasana B” közben, jött és egész könnyedén összeraktuk együtt. Ez az utolsó ászana amit gyakorolhatok. Sikerült megtartanom öt légzésig, közelebb hajolt, a mikrofon miatt a fején, és oda súgta:</p>
                                <blockquote>Nagyon jó, ?c / see / si?<span>Sharathji talány</span></blockquote>
                                <p>Amit a “nagyon jó” után mondott nem értettem, vagyis mindenfélét jelentett így hirtelen. Az első gondolatom az volt, hogy azt jelenti, csinálhatom a Marichyasana C-t, de csak nem így mondja ezt be, nem? Egy éve a B-vel vergődök, nem mondom, hogy tűzijáték kell vagy ilyenek, de legalább ha érteném, hogy valami új jön, az nem lenne rossz. Áh nem is azt mondta, lehet csak az volt, hogy “see?”, hogy “Látod? Megy ez gyerek”. Másokat is kérdeztem, nem segített semmit. Maradok a B-nél, a verziók és a Marichyasana-k közül is.</p>
                                <p>Megint vezetett óra van. Másnap csinálom megint a dolgom, és mikor észreveszi, hogy a B után mennék rá a végére oda rikkant, hogy</p>
                                <blockquote>Zoltan, C<span>Sharathji már C-értem</span></blockquote>
                                <p>Akkor, ok, tovább. Az van ezzel a C-vel, hogy a közelében nem vagyok. Elég asszisztens függő póz ez még nekem, de szerencsére van itt sok jó, és segítenek. Egy héttel előrébb, amikor már jó párszor rápróbáltam erre, a B sem jön megint, sajnálom az asszisztenst mert nagyon erőlködik. Sharathji látja ezt és oda kiabál, hogy:</p>
                                <blockquote>Meg tudja csinálni, várj, jövök<span>Sharathji a hívő</span></blockquote>
                                <p>Megállunk a segítőmmel és várunk. Fél perc múlva jön is a Guru, megfogja a kezeim és mintha semmi se lenne egyszerűbb belerak a pózba. Ránéz az asszisztensre és szerényen felkérdezi:</p>
                                <blockquote>Na, ki a mester?<span>Guruji a Mester</span></blockquote>
                                <p>Jót nevetek ezen, meg egyet is értek mondjuk. Ha már itt van leül a földre elém, int, hogy jöhet a C és elkezd húzni, csavarni egyszerre. Olyan ez az ászana, hogy ülsz, bal lábad egyenesen a földön, a jobb lábad behajlítva-felhúzva a mellkasodhoz, a bal kezeddel kívülről, jobbról a jobb lábad átöleled, miközben a felsőtesttel jobbra, hátra csavarodsz, a jobb kezedet közben a hátad mögött csavarod és így fordulás közben a kezeid a hátad mögött (bal oldalon) összekulcsolod (vagy a csuklóid ha nagyon pro vagy). Öt légzés itt, aztán ugyanez a másik irányba. Szóval ez még valami a listán aminek a címe: még egyelőre nem megy. Sharathji teker meg minden de inkább érzem azt, ez neki melós mint nekem. Mikor letelnek a légzések, feláll, kicsit megpaskolja a hasát és azt mondja:</p>
                                <blockquote>Jó, legalább fogyok<span>Sharathji on Zolly Update</span></blockquote>
                                <p>Tudod mit? Én meg örülök, hogy segíthetek. Minden tisztelettel de idén ebben is követem a paramparát, vagyis a Gurut, mert ahogy írtam már van egy kis ünnepi extrám, és ebben hasonlítunk. Még kicsit beszél az asszisztenssel, majd úgy zárja:</p>
                                <blockquote>Egy csoda, hogy meg tudja csinálni a B-t, a C, hát az egy nagyobb csoda lenne<span>Sharathji csodaság</span></blockquote>
                                <p>Eszembe jut erről egy másik égetése, amin nagyon jót röhögtek sokan (jó én is). A hétfői első sorozatos vezetett óra után, van egy haladó vezetett óra. A kettő óra között 15 perc van, kimenni a teremből, átöltözni, és kimenni a Shala-ból. Emiatt mikor az első lemegy, a végén csak kb egy perc pihi van, háton fekve, ami amúgy a vége a gyakorlásnak csak hosszabban. Annyi idő amíg Sharathji elsétál 50 métert az irodájához, ahol majd mondja, hogy köszi. Miközben mi fekszünk a haladók már ugrásra készen várnak velünk szemben a földön ülve, hogy megszerezzék azt a helyet amin gyakizni akarnak. Mint egy több száz fős hirtelen halál székfoglaló. Na egy ilyen “pihenés” kezdődött az óra végén. Én gondoltam, hogy egyből megyek az öltözőbe, talán így lesz fél percem nem másokon át taposva öltözni, nem kell ez a power pihi. Majdnem elérek az ajtóhoz mikor a Guru rám szól:</p>
                                <blockquote>Zoltan! Menj vissza, feküdj le!<span>Guruji rendez</span></blockquote>
                                <p>Visszafordulok és ilyen színpadiasan levágom magam a hátamra, amit szemmel követ a sok ülő és fekvő ember, de hát ez van. Abban a másodpercben ahogy becsapódom, mondja:</p>
                                <blockquote>OK, köszönöm<span>Sharath a Funnyji</span></blockquote>
                                <p>És ugrik fel mindenki röhögve. Irány az öltöző vagy épp a tatami, attól függően most végzett vagy most kezd az ember.</p>
                                <p>A vége felé a két hónapnak, Sharathji eltökélte, hogy megmutatja képes vagyok a C-ben megfogni a kezeim, annak ellenére, hogy én úgy érzem egy kilométerre vannak egymástól. Éppen Réka asszisztált mikor megjelent a Guru és jelezte, hogy most majd ketten partiba raknak. Réka húzta a kezem a földön ülve, miközben Sharathji felülről csavarta a felsőtestem. Feszítettek, én csavarodtam, majd szólt a Guru érintsem meg az ujjaim. Úgy tettem. Nem mondom gyorsan mindenki nyugalomba rakta magát, mert keményen izzasztó és fárasztó volt már ez is, de kaptam egy kis hátba veregetést, hogy csak meg van az. Sharathji azzal a sétált el, hogy:</p>
                                <blockquote>Tíz gyakorló kell neked segíteni<span>Sharathji twist</span></blockquote>
                                <p>Az utolsó héten volt rajtam egy pulóver amit még a krikett vb miatt vettem. A pulcsi szürke terepmintás, nagy narancssárga India felirat van rajta, illetve a VB és a krikett szövetség logói. Kifelé menet a Shala-ból a Guru utánam szólt, hogy menjek vissza, majd megvizsgálta a szettem és nyomott egy nagy mosolygós like-ot rá. Éppen Diát igazította, és mondta neki, hogy:</p>
                                <blockquote>Terepmintás felső, nagyon király<span>Sharathji approves</span></blockquote>
                                <p>Nagyon karmolja ezeket a terepmintás dolgokat, mert javarészt mikor nem jógázik vagy jógáztat, akkor a dzsungelt járja, szafarizik. Az utolsó nap, óra előtt, bementünk az irodájába Diával, és adtunk neki ajándékba egy ilyen pulcsit. Pontosabban kettőt is rendeltem mert nem voltam biztos a méretben. Nem nyitotta ki egyből, mert épp a drónját baszkurálta, ugyanis az utolsó óra alatt, miközben diktálta a menetet, ment egy pár kört felettünk a teremben és csinált néhány felvételt. Párszor emiatt belezavarodott a számolásba is, és megjegyezte, hogy nehéz két munkát egyszerre nyomni. Vicces volt. Nagyon remélem adja majd az ajándékunk, mert azért király belegondolni, hogy van egy egyen-pulcsim a Guruval. Majd jövőre megkérdezem. A legközelebbig, mindent kívánok.</p>
                            </>
                            : <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">एकोनविंशति Ekonavimsatih</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">The Guru and I<br /><span className="heading__sub align--center display--block">Part 3</span></h2>
                                </hgroup>
                                <p>This conclusion has been waiting for a while because I was still going to the Guru for morning practice, meaning our story was still unfolding. Last year, during my first visit here in Mysore, our paths diverged with Sharathji, who called me Zolly. I remember it was towards the end of the season when I reached my last pose in a led class. I was waiting to rejoin during the finishing part when the Guru asked me to refill a water bottle. These spray bottles were on the floor, and some were empty. I was pretty pleased with myself because I got a little chore from the boss. Maybe he was just tired of watching me sitting on my ass while everyone else was working theirs off, but who cares.</p>
                                <p>Not long ago, I did “Marichyasana B” for the first time with some help. He saw it, cheered me on, and then praised me with a big smile for finally doing it. It was a beautiful moment. He came over to help the next day, but my shoulders were tense. It didn't go well that day. While trying to knot my hands behind my back, he said:</p>
                                <blockquote>Grhh you should relax, this is yoga, this is not wrestling… this should be fun<span>Sharathji coaching</span></blockquote>
                                <p>These words echoed in me for at least half a year after we returned to our little homeland. Not just during yoga, but whenever I faced obstacles at work or elsewhere, they came to mind, and repeating them in the Guru's voice always worked—such a simple yet powerful message. Unfortunately, as time passed, these sentences faded in me, losing their strength. The crap that surrounded me won. The interesting thing is that I could come up with such T-shirt slogan sentences on my own, and It wouldn't be difficult. But somehow, because he said it, and when and in what situation he said it, it meant much more to me. Chill out. It’s not wrestling.</p>
                                <p>Sharathji knows your name in vain if you don't pay attention when he says it. I call this situation "to go or not to go" when the Guru calls. I'd bet that sometimes he intentionally calls very softly, testing whether you deserve your name, you listen. Okay, maybe this is just paranoia, but I'm not alone with this theory (though the flat-earthers are plenty as well, but that's another thing). If you don't go when he calls because you're pondering whether you heard what he said correctly, he either tries it again more sternly or penalises you, and you'll be just "one more" for a while again.</p>
                                <p>I approached the second season hoping Sharathji remembered me, and I also hoped he didn’t because I hadn’t made much progress in a year, I admit. The fuck cares about the poses in Ashtanga anyway, you just have to do it, and they will come. The season started with two led classes, and on the second day, he appeared during Marichyasana and adjusted me. At this point, I knew he remembered me and also that I still do these asanas exceptionally, let's say, differently from others. It's not hard to spot this clumsiness, you see.</p>
                                <p>We got assigned to the 6:30 am yoga slot with Dia, which is earlier than last year, and that's a good thing, mainly because there are fewer people, meaning less waiting. Around the end of the first week, Sharathji came during class and blinked at my card. He knew who I was; he just needed a little refresher. I'm not exactly famous, so it's all good. One morning, we arrived at the gate way too early, around 5 o'clock. I was still new to riding a motorcycle and had no clue how long it would take to get by, but I wanted to ensure we'd get there on time. So, we're sitting outside, and he looks out the door. Two people are sitting in the front; he looks at them and asks:</p>
                                <blockquote>Are you two married?<span>Guruji Q&A</span></blockquote>
                                <p>They answer yes, they're married. He told them to go in, and from now on, it’s at 5:30. As I processed what happened, I started signalling with a silly face, like a preschooler on vitamins, waving pick me, pick me. He looks at me and asks:</p>
                                <blockquote>Are you married?<span>Guruji Q&A</span></blockquote>
                                <p>I say yes and point at Dia. Somehow, he doesn't believe me, so he asks again:</p>
                                <blockquote>Are you really?<span>Guruji the doubter</span></blockquote>
                                <p>I say yes that we are still married, and he waves us in, telling us to update our cards for 5:30 at the office. With this time, there's even less waiting, which is great. We sit inside waiting for the practice to start when I hear:</p>
                                <blockquote>Zoltan come!<span>Sharathji calling</span></blockquote>
                                <p>I went, laid down my mat, and started my routine. A few minutes into the practice, the Guru says:</p>
                                <blockquote>Zoltan’s wife<span>Sharathji funnyji</span></blockquote>
                                <p>Dia doesn't hear this because, well, that's not her name, so Sharathji repeats, this time with hand gestures, and it hits the mark. Meanwhile, I laugh through the whole thing because it's funny. The ironic part is that without Dia, the chance for me being here right now would be equal to the size of an ant’s penis, so it's not fair that he picked up my name like this. But it's still funny. By the way, there are others he calls in, like "Sophie’s husband," so it's not sexism, chillax; he plays around to lighten the mood.</p>
                                <p>A few days later, we arrived at the parking lot with the Guru at the same time, and as he got out of the car, we happened to walk by. Out of nowhere, he throws a question:</p>
                                <blockquote>Are you staying with Rajesh?<span>Guruji spying</span></blockquote>
                                <p>We're stunned. I ask back something like wai., wha., to wait what’s now? He repeats the question, and then it hits us that he's talking about our host, who happens to be Rajesh. I say yes, we live there, and he confidently says, "I know." We're shitting our pants. A few days later, when we got home, Rajesh was there, along with a bunch of cops. My blood froze for a moment; what if they came for us? Quickly, it turned out that Rajesh was buddies with the police chief, and they were having coffee. The other cops were just the escort. After introducing us, I asked Rajesh if Sharathji knew we were staying there, and he said yes, they talked. He also mentioned that the Guru asked if I was here with my wife. After this, we started wondering if he might not have believed me. Did he think I was pulling a prank for an early batch? It turned out that Rajesh was telling the truth about knowing Sharathji. Everyone says everything here; let’s leave it at that. You believe stuff, or you don’t; it’s on you.</p>
                                <p>At a conference, Sharathji talks about how long marriages and stick-together families are rare in the Western world. He says this on a pretty bad day (or maybe the perfect day) because today is the 18th anniversary of Zoltan’s wife and me. At the end of the conference, a voice in my head tells me we should say to him this now to get some credit for the declining West. I drag Dia to the front; obviously, she doesn't think it's the best idea, and I tell him:</p>
                                <blockquote>I just want to add to what has been said that today is our 18th anniversary, so there is still hope<span>Zolly the equaliser</span></blockquote>
                                <p>The Guru congratulates and smiles. 10 points to Gryffindor.</p>
                                <p>In the next led class, when he visited me during the good old "Marichyasana B," he put my hands together effortlessly. This is the last asana I can practice. I managed to hold it for five breaths when he leaned closer because the microphone was on his head and whispered:</p>
                                <blockquote>Very good, ?c / see / si?<span>Sharathji the Riddler</span></blockquote>
                                <p>What he said after "very good" I didn't understand; it meant everything and nothing all of a sudden. My first thought was that I could do Marichyasana C, but he doesn't say it like that, right? I've been struggling with B for a year. I'm not saying it should be fireworks, but at least if I understood something new was coming, that wouldn't be bad. Ah, no, he didn't say that; maybe it was "see?" like "See? You got this, kid." I asked others, but it didn't help. I stick to B, among the versions and the Marichyasanas.</p>
                                <p>Another led class. The next day, I do my thing again, and when he notices that I'm going for C after B, he yells:</p>
                                <blockquote>Zoltan, C<span>Sharathji oh I C</span></blockquote>
                                <p>Okay, I got it, moving on. The thing with C is that I'm not there yet. This pose is still quite assistant-dependent for me, but luckily, there are many good assistants here, and they help. A week later, when I tried it several times, B didn’t work again; I felt sorry for the assistant because he was trying hard. Sharathji sees this and shouts:</p>
                                <blockquote>He can do it, wait for me, I’ll come<span>Sharathji the believer</span></blockquote>
                                <p>We stopped with my helper and waited. A minute later, the Guru comes, grabs my hands, and puts me into the pose as if it’s the easiest thing. He looks at the assistant and humbly asks:</p>
                                <blockquote>Who’s the master?<span>Guruji the Master</span></blockquote>
                                <p>I laugh at this, and I also agree. Now that he's here, he sits before me, gestures for C and starts pulling and twisting simultaneously. This asana involves sitting, your left leg straight on the ground, your right leg bent and pulled up to your chest, your left hand embracing your right leg from the outside while twisting your upper body to the right and back, twisting your right hand behind your back, and during the twist, interlocking your hands behind your back (or your wrists if you're pro). Five breaths here, then the same in the other direction. So, this is still something on the list titled: not there yet. Sharathji twists and turns, but I mostly feel it’s more challenging for him than me. When the breaths are over, he stands up, taps his stomach a bit, and says:</p>
                                <blockquote>Good, I’m losing weight<span>Sharathji weight drop</span></blockquote>
                                <p>You know what? I'm glad I can help. With all due respect, this year, I'm following the parampara in this too, meaning the Guru, because, as I mentioned before, I have a little holiday extra, and in this, we are similar. He talks with the assistant for a bit, then concludes:</p>
                                <blockquote>It is a miracle that he can do B, but C would be a bigger miracle<span>Sharathji Miracleism</span></blockquote>
                                <p>This reminds me of another burn he did, which many found hilarious (well, I did too). After the led-primary series class on Mondays, there's an intermediate-led class. There's a 15-minute break between the two to leave the room, change, and exit the Shala. Because of this, when the first class finishes, there's only about a minute of rest at the end, lying down, which is essentially the end of the practice, just longer. It's the time it takes for Sharathji to walk 50 meters to his office, where he'll say thanks. While lying down, the advanced students are ready to jump, sitting on the floor opposite us, ready to grab the spot they want to practice on. It's like a sudden-death musical chair with a few hundred participants. So, one of these "rests" started at the end of the class. I thought I'd go straight to the dressing room; maybe I’d have half a minute to change without being trampled by others. I don't need this power nap. I'm almost at the door when the Guru calls me:</p>
                                <blockquote>Zoltan! Go back and lay down!<span>Guruji directing</span></blockquote>
                                <p>I turn back and dramatically throw myself onto my back, which is observed by all the sitting and lying people, but that's how it goes. In that second, as I landed, he said:</p>
                                <blockquote>OK, thank you<span>Sharath the Funnyji</span></blockquote>
                                <p>And everyone jumps up laughing. Off to the dressing room or the tatami, depending on whether you've just finished or just about to start.</p>
                                <p>Towards the end of the two months, Sharathji was determined to show that I could hold my hands in C despite feeling like they were a mile apart. During a Mysore class, when Réka assisted me, the Guru appeared and signalled that they would put me into the pose together. Réka pulled my hands while sitting on the ground, and Sharathji twisted my upper body from above. They pulled, I twisted, and the Guru told me to touch my fingers. I did. I won't say I stayed there long; we went into a resting state immediately because this was already quite exhausting and sweaty, but I got a pat on the back that I got it. Sharathji walked away with:</p>
                                <blockquote>You need ten students to help you<span>Sharathji twist</span></blockquote>
                                <p>Last week, I wore a sweater that I bought for the cricket World Cup. The shirt is grey camouflage with a large orange "India" text and logos of the World Cup and the cricket federation. On the way out of the Shala, the Guru called after me to come back, then examined my outfit and gave it a big smiling thumbs up. While adjusting Dia, he told her:</p>
                                <blockquote>Camouflage shirt, very nice<span>Sharathji approves</span></blockquote>
                                <p>He really likes these camouflage things because most of the time, when he’s not practising or teaching yoga, he’s wandering the jungle on safari. On the last day, before the class, we went into his office with Dia and gave him one of these sweaters as a gift. More precisely, I ordered two because I wasn't sure about the size. He didn't open it right away because he was fixing his drone; during the last class, while dictating the sequence, he circled above us and took some shots with a drone. It messed up his counting a couple of times, and he remarked that it's tough to do two jobs at once. It was funny. I really hope he'll use our gift because it's pretty cool to think that I have a matching sweater with the Guru. I'll ask next year. Until next time, I wish you everything and more.</p>
                            </>
                        }
                    </article>
                    <article id="vimsatih" className="blog__article">
                        {language === "HU"
                            ? <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">विंशति Vimsatih</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">Rövidítés az utolsóhoz</h2>
                                </hgroup>
                                <p>Délután 16:30 van, elindultunk haza, Dia, Nat és én. Kiderült, hogy Nat-el ugyanarra a gépre vettük meg a kilépőnk. A sofőrünk Mysore-ból Bangalore-ba, Mr. Santosh. A harmadik fizetős kaput kerüli ki az ember úgy, hogy levisz minket a “földútra” (valami olyan csak lyukasabb), ami egy mindennel rogyásig lévő, bűzös, keskeny kis sztráda, 50 méterenként egy jó kövér fekvő rendőrrel. Jönnek minden irányból, mindennel, ahogy az itt lenni szokott. Nem régen átadták az új autópályát itt Karnatakában, ami összeköti Bangelore-t Mysore-al, lerövidítve a 4-5 órás utat, kb 3 órára. Elméletben persze, mert ha kikerülöd a fizetést akkor nem nyersz sokat. A harmadik manővernél, amikor már leveszem, hogy ezt csinálja Santoshji, megkérdezem, hogy:</p>
                                <blockquote>Minden fizetős kaput ki fogsz kerülni?<span>Zolly aki fizetett a kapukért</span></blockquote>
                                <blockquote>Ez egy rövidítés<span>Santosh a helyi Pinocchio</span></blockquote>
                                <p>Ez a harmadik “rövidítés”, a hátralévő utat időben és kilométerben is kétszerezi. Azt hittem Santosh spanok leszünk, de inkább hülyének nézel, elszomorítottad a tesód szívét. Egyébként úgy közelíti meg a repteret, meg amúgy Bangalore-t is, hogy az az érzésem én többször jártam ott nála. Google maps-el is tanácstalan merre menjen, de aztán segítek és megtaláljuk az utat. Ezt aztán jól levágtuk, hey.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">De hát.. mi csak a.. na ne basszá’ má’.. hogy a mi?!</span></h2>
                                <p>Valamikor kilencre értünk Bangalore-ba ahol kb a megérkezés pillanatában kaptam egy sms-t, hogy a München-Budapest csatlakozásunkat törölték. Nagyszerű. Van még hátra amíg át kell szállni vagy 16-17 óra szóval remélem, hogy megoldható ez ennyi idő alatt. Van a Lufthansa app, amiben van egy chat, amit spamelek megállás nélkül információért, de minden alkalommal hibát dob. Ez a leghülyébb német robot. Egy ponton elkezdek random dolgokat beírni mint, hogy “kérsz egy palacsintát?”, de leszarja. Nem kér. Közben találkozunk más hazafele tartó jógikkal, és mindenkivel ez van aki München felé menne, de van aki már kapott új átszállos repjegyet. Ez az infó segít is meg nem is. Natalie-nek, aki szintén ott szállna át Ljubljana felé, nem törölték a csatlakozását. Kezdek idegállapotba lenni, de nem akarok, csak eltöltöttem itt egy pár hónapot, hogy megtisztuljak, szóval nagy levegő be, aztán ki, kapja be ez a gyökér chat. Lesz ami lesz, bízni kell abban, hogy megoldják.</p>
                                <p>Új a reptér terminál itt Bangalore-ban és elég Dubai-os a design. Kisebb tervezési hiba, hogy étterem az nagyon kevés van, ami van az meg nem kompatibilis velünk (erről nem tehet a designer mondjuk). Érkezik a következő csattanó, miszerint a hajnal 2:50-es indulás túl könnyű lett volna, szóval megtolják egy két órával, inkább 4:50 lesz. Mivel este 21:30 körül van, gyorsan kiszámoljuk, hogy ez biza szopacs. Kezd az egész gyanús lenni, információt meg nem kapunk, de eszembe jut, hogy akár rá is kereshetek mi folyik odaát, és látom, hogy az a baj, hogy Münchent beterítette egy másfél méter hó. <b>Hó hó hó</b>!</p>
                                <p>Átkelünk a biztonsági kapun, beülünk egy étterembe, ahol eszek egy nagyon gagyi gyümölcssalátát annyiért, amennyiért Mysore-ban egy napig ettem volna, majd neki állunk keresni valami kis “fekhelyet”. A kényelmesen ülésnél a designer sajnos jobban szerette a vízeséseket. A beszállástól 6 órára vagyunk, itt lenni amúgy minden elvemmel ellentétes, mert általában a legutolsó pillanatban beeső utas vagyok. Találunk egy ablak mellett ilyen nyugágy szerű székeket (jobban néz ki mint amilyen érzés rajta lenni), ahol próbálunk aludni valamennyit, mert már nagyon késő ez az este 11 nekünk. A légkondit azt 5 fokra állította valaki, mindenünk amink van rajtunk, de nem ér sokat. Itt hasít belém a felismerés, hogy ha micimackó fázik akkor én ebben az esőkabátban egy napbarnított jégkockává fogok válni ott a fagynyugaton.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">Beszálláska</span></h2>
                                <p>A repülést ha nem próbáltad még, nem fogom lelőni a poént, a lényeg, hogy beszálltunk, felszálltunk, elszálltunk és majd 11 óra múlva leszállunk. Megnéztem Denzel-től az Equaliser 3-at, szét adta, bírtam az első kettőt is. Valahol Irán felett megszólal a kapitány, hogy:</p>
                                <blockquote>Hölgyeim és uraim, a 30 év alatt amit repültem még ilyet nem láttam, de a helyzet az, hogy lezárták a Müncheni repteret, havazás miatt. Keringeni nem tudunk a levegőben, az új irányunk Frankfurt.<span>Captain with attitude</span></blockquote>
                                <p>Az amúgy messzebb van, vagy ahogy Santosh mondaná ez egy rövidítés. Mivel ennyi nem volt elég, hogy tetőzve legyen az élmény, a fedélzeti internet is elszáll. Én nem használtam, nem is ez a lényeg, hanem, hogy a légiutas kísérők használták, és anélkül semmi infót nem kapnak a földről, vagyis vakon repülünk bele abba, hogy mi lesz ezután. Végülis ez hangnemben van az érzéseimmel a hazatéréssel kapcsolatban. Annyit tudnak mondani, hogy ha a végállomás nem München lett volna, akkor ne vegyük majd fel a feladott poggyászunk, mert azt majd szépen átrakják a járatra amivel megyünk haza. Hát ehhez olyan közel volt az ami történt ezután mint én az “Eka Pada Sirsasana”-hoz.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">Leszállogatás</span></h2>
                                <p>Hello Frankfurt. Beterelnek minket egy buszba, ami egy laza 20 percet megy a terminálhoz, nem túlzással, hanem benzinnel (ha-ha-ha). És innentől a Lufthansa olyan mint amire a neve utal, mintha levegőből lenne, mert senki nincs sehol, minden iroda amibe belebotlunk zárva, elhagyatott, akivel sikerül beszélni meg olyan mintha az első napja lenne a melóban. Az utasok bolyonganak mindenfele, köztük mi is, egyik sorból a másikba. Aztán elküldenek minket egy másik terminálba, ahova busszal kell menni, rohanunk, majd beállunk egy másik sorba. Azért csináljuk ezt, mert ezt mondták nekünk, de főleg azért mert náluk van a bőröndünk. Abban reménykedek, hogy ha már automatikusan nem is ment, de ha valakivel beszélünk, átfoglalják a repjegyünk és mehetünk haza.</p>
                                <p>Ez a sor ez áll, a senkiföldjén vagyunk, itt nincs étterem, kávézó, semmi nincs itt, csak egy millió ember egy sorban ami nem mozdul. 6 órát töltünk el itt, miközben talán 30 métert haladunk előre. Dia nem tudott enni a gépen mert a kaja amit kaptunk nem volt megfelelő, szóval már nincs vércukra, de azért tartja magát. Engem jobban bosszant, hogy ilyen helyzetben vagyunk. Észrevesszük, hogy hoztak néhány “narancs”-levet és vizet, illetve valami rágcsát a vándoroknak. Mi a snack-ről lecsúszunk, mert lassan kapcsolunk, de 5 perc elégedettség, és némi telítettség érzést ad az a cukros víz.</p>
                                <p>Valahol 4 órával beljebb, látjuk, hogy hoznak még némi zsákmányt egy zsúrkocsin, chipset, vizet, csokit stb. Lerohanom az embert a chipsért, de lazán becsicskít, hogy bírjam már ki amíg megáll vele. OK, te paraszt, megvárom amíg megállsz. Úgy kezdi el ledobálni a dobozokat a földre a kocsiról, mintha csak baromfikat etetne a tanyán ahonnan jött, egy ködbe burkolt, vasárnapi napfelkeltében, mielőtt a templomba térne, ez a remek keresztyény. Felcsipegetek vagy öt csomag chips-et, chip, chip, chips. Már 24 órája elindultunk, de végre valahára kaptunk valamit ami hasznos. Az a suttyó pöcs meg remélem felmond és elmegy Indiába megtisztulni. Erről az egész sorozásról eszembe jut egy refrén az ős régi első lemezünkről tesómmal, ami ez:</p>
                                <blockquote>Ok és okozat, de az okozat azok számára hátrány akik nem vágják mi az ok. Sok a fokozat amin felakadok, de ha kérded ki hátrál ki, nem mi vagyunk azok.
                                    <span>ÉNÉSÉN - Mert</span>
                                    <span>
                                        <iframe src="https://open.spotify.com/embed/track/4nea8ELi0CxqBmIq43lIMD?utm_source=generator&theme=0"
                                                width="100%" height="152" frameBorder="0" allowFullScreen=""
                                                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                                loading="lazy"></iframe>
                                    </span>
                                </blockquote>
                                <p>Ma leálljuk a pénzünk babám. Már majdnem mi jövünk, amikor egy új Lufti arc megjelenik, és azt mondja, nem jó sorban állunk, mert nekünk ki kéne menni a kapun, és ott van egy rövidebb és gyorsabb sor az EU-soknak. Többször megkérdeztem tőlük jó helyen vagyunk e az elmúlt órákban, és azt mondták igen, de eltelt ez a 360 perc, és most meggondolták magukat. Milyen remek ez a cég. Itt a kiemelt érzés az, hogy kiemelnek a sorokból. Mondom a csávónak, hogy 6 óra eltelt az életemből erre a sorra, szóval mondja biztosra mi vár minket kint, mert felgyújtok mindent lassan. Na jó a véget nem mondom csak gondolom, de ő még így is talál helyet és gúnyosan lecsicskít, hogy szegény én és az időm, brühühű. Nem segít semmit amit mond, de legalább bunkó mint a seggem. Arra jutunk menni kell, mert benne van, hogy itt ha sorra kerülünk nem fognak velünk foglalkozni. Elbúcsúzunk a padlótól meg a taplóktól, és belépünk az országba. Hello Német. A kinti sor olyan 3 kilométer hosszú. Nem sok esély van, hogy aki elzavart minket az olvassa ezt, de ha igen, anyád azért csuklott egész december másodikán, mert te ilyen lettél.</p>
                                <p>Ezt a sorban állást inkább kihagyjuk, mert arra jutunk ma már eleget szívattak minket. Mintha hetek óta arról szólna az életem, hogy sorban állok. Foglalok egy hotelszobát, majd egy másfél órán keresztül próbálok venni egy jegyet Budapestre, mert természetesen túlterhelt ez az egész fos, de csak ez a kiváló Lufthansa repül innen a haza irányába. Közben a reptéren mindenhol őrültek vannak, verekedés, feszültség. Nagyon fineszes energiák ezek, így az igazi visszatérni a “kultúrába”. Nat közben Szlovénia helyett Horvátorszába tud csak venni repjegyet, ahova majd Matej elkocsikázik érte. A bőröndjeinket ezen a ponton elengedjük mind, ma már nincs mit tenni. Sikerül egy Frankfurt-Brüsszel-Budapest jegyet lőnöm másnapra, egy nem túl baráti 800 euróért, mert amíg sorban álltunk persze minden jegy elkelt, és minden lepattanó ami maradt arany árban van. Elbúcsúzunk Natalitől, és megindulunk a hotelbe. Ahogy odaér az Uber, jön egy sms, hogy a járatot amire sikerült nagy nehezen jegyet venni, törölték. RIP.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">Annyira de annyira de annyira</span></h2>
                                <p>Van egy még egy utolsó opció, az is 800 euró, Frankfurt-Bécs-Budapest, holnap este. Sikerül harminc perc alatt kifizetni, de ha ezt is törlik én ide költözöm. Amúgy közben ez a tél ez így szétver minket. Mínuszok vannak mi meg húsz fokra vagyunk öltözve, ezt nem gondoltuk át a legjobban. Legalább váltás alsógatyám van, az is valami. A hotel amúgy szép, a hideget leszámítva, alszunk egyet, még valami reggeli is van ami egész jó. Van szemben egy olasz étterem, az a terv, hogy ott leszünk egy pár órát, aztán jöhet a következő rész a “Soros” kalandokból (nem politika ez, le lehet nyugodni).</p>
                                <p>A reptérre érve látunk egy sort, és hát, annyira megszoktuk már, hogy besorolunk szépen. Ez most halad, látszik, hogy egy nap kellett ahhoz, hogy felfogja a menedzsment, hogy ha egy reptér majdnem összes járatát ide küldik akkor el kell kezdeni dolgozni. Odaérünk a pulthoz, elmondom min mentünk keresztül, majd lecsipogják az új jegyet, meg a korábbi poggyász vonalkódunk, és azt mondják, hogy az új járatunkra felrakják a táskákat, ami vár minket majd otthon a szallagon, aztán ha hazaértünk kérjük vissza a jegy és a hotel árát online. Ez nagyon jól hangzik, szinte el se hiszem (utólag kár is lett volna).</p>
                                <p>Találunk Diának sapkát meg sálat, elrepülünk Bécsbe, aztán onnan Budapestre. Nem repültem még ilyen rövid szakaszon, olyan volt, hogy még emelkedett a gép amikor elkezdett ereszkedni és leszállt. Ahogy bekapcsolom a telómat, jön egy üzenet, hogy sajnos a bőröndjeinket nem tudták a gépre rakni Frankfurtban. Átvert minket az a nő amikor végre sikerült befejezni egy sort. Azt írják majd kiszállítják valamikor, csak töltsünk ki egy kérvényt, amit megcsinálok. Este 22:20 van, tegnap délben kellett volna haza érni, hívok egy taxit, és irány Nancy, a cicánk.</p>
                                <p><b>Om sweet home.</b></p>
                                <p>Hallottam olyat akinek a Lufthansa bevált de én egész biztos, hogy a cég logót is nagy ívben el fogom kerülni ha véletlen elém vetné az utam a jövőben. Ezt már két héttel később írom, Dia bőröndje egy hete jött meg, az enyém az pedig még nincs meg, de legalább az információ is egyre kevesebb, hogy merre lehet. Naponta hívogatom őket, és mindig teljesen random amit mondanak. A pénzt persze visszakértem, de mondjuk arra sem jött semmi válasz azóta. Emléknek ez biztos jobb lesz mint élménynek.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">Utószó</span></h2>
                                <p>Sokat foglalkoztat, hogy miért foglalkoztat olyan sokat, mi lesz most. Félek rohanni a vége felé, közben attól is félek, hogy megálljak. Belégzés, kilégzés, belégzés, kilégzés. Amit elrontottam vajon azt meg tudom javítani? Minden olyan mint az ászana gyakorlás, hogy csinálom újra, és újra és majd jön? Belégzés, minden rendben lesz, kilégzés, mi van ha nem? Amúgy is mi ez a rend amiben ha minden lesz az olyan jó? Elképzeltem már sokszor és mikor azt hittem megvan, már mást képzeltem helyette.</p>
                                <p>Nincs most fix munkahelyem, de ha őszintén visszanézek, nem tudom biztosabb voltam e a dolgomban amikor még volt. Tavaly úgy voltam itt, hogy dolgoztam és akkor az zavart, idén meg az zavart, hogy nem dolgoztam. Mondjuk csak egy ideig. Jön az interjú szezon, annak a kiderítése kinek és mennyiért adom el az egyetlen valutám aminek értéke van, az időm. Már most hánynom kell az egésztől. Abba akarok hinni, hogy ha elengedem a kormányt és hagyom hagy menjen akkor is lesz valami, mert valami mindig lesz. Az elmúlt évek tapasztalata számomra, hogy rájöttem arra, nem irányítok semmit. Abban bízom valami olyan lesz a következő valami, ami nem izzadtságszagú. Szemét egy koncepció a pénz, ragadozó birkákat nevel, és nem véletlen, hogy olyan nincs a természetben. Természetellenes. A birkák amúgy nagyon jó arcok.</p>
                                <p>Van azért ami hiányzott otthonról, család, cica, barátok (Nintendo/Playstation xd). A nem csípős kaja. Eszembe jut erről amikor a Govinda nevű helyen ettünk először, ami szattvikus konyha, amit most nem fejtek ki pontosan mi, de többek között, nem használnak chilit, szóval nem marja le a fejed. Ennek akkor úgy megörültem, hogy majdnem mindent megrendeltem a menüről, és szépen be is lapátoltam. Amikor mentem fizetni kérdezte a pincérnő, hogy elcsomagolja-e a maradékot, mire mondom, hogy köszi azt már elcsomiztam, és finoman megpaskoltam a bélésemet.</p>
                                <p>Jó lesz visszanézni erre a két hónapra. Jó volt írogatni ezt a blogot. Volt, hogy okozott egy ilyen alapjárat nyomást, leginkább amikor nem volt téma ötletem, de általában nagyon jó volt csinálni. Ez volt az első ilyen próbálkozásom, és tudom, rettenet le vagyok maradva, ebben a tíz-másodpercig képes-figyelmet-összpontosítani-tik-tok-fos világban, de ez van. Nagyon sok visszajelzést kaptam amiért hálás vagyok, ami tovább vitt, hogy csináljam, szóval veregesd meg a vállad olvasó, <b>tisztelet.</b></p>
                                <p>Mindenkinek mindent és még kívánok akikkel osztoztam ezen az utazáson, nagyon jó volt veletek. Neked pedig köszönöm, hogy olvastál, és ha szeretnél folytatást, iratkozz fel az email címeddel lejjebb, nem spamelek ne aggódj, és ha lesz új blog a jövőben, akkor dobom (sokan kérték szóval lesz). <b>Béke.</b></p>
                                <blockquote>Ne sírj mert vége van. Mosolyogj mert megtörtént.<span>Dr Seuss</span></blockquote>
                                <p>Ui.: Köszönöm Dia, hogy a legjobb főszerkesztő voltál nekem, annyi minden más mellett amiben a legjobb vagy. <b>XOXO</b></p>
                            </>
                            : <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">विंशति Vimsatih</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">Shortcut to the last one</h2>
                                </hgroup>
                                <p>It's 4:30 in the afternoon, and we, Dia, Nat, and I, started our journey home.  It turns out we booked the same flight out of here with Nat. Our driver from Mysore to Bangalore is Mr Santosh. This dude avoids the third toll booth by taking us down a "dirt road" (more like an infinite pothole with some road in it), which is this stinky, narrow, overloaded little highway with a big, fat speed bump every 50 meters. Typical chaos from all directions, just how things roll here. They recently opened this new highway in Karnataka, linking Bangalore and Mysore, cutting down the 4-5 hour trip to about 3 hours. In theory, that is because skipping tolls doesn't really save you much time. At the third detour, when I finally get that this is Santoshji's game plan, I ask him:</p>
                                <blockquote>So, you're gonna skip every toll booth?<span>Zolly, who paid for the tolls</span></blockquote>
                                <blockquote>It's a shortcut<span>Santosh, the local Pinocchio</span></blockquote>
                                <p>This third "shortcut" doubles the remaining journey in time and distance. I thought Santosh and I would be buddies, but it turns out he thinks I'm stupid. You are breaking your brother's heart, my G. The way he approaches the airport, and Bangalore in general, makes me feel like I've been there more than he has. He's clueless even with Google Maps, but I help out, and we find our way. What a shortcut this was.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">But... we're just like... oh, come on, man, what the fck?!</span></h2>
                                <p>We hit Bangalore at around nine o'clock, and the moment we arrived, I got a text saying our Munich-Budapest connection got cancelled. Great. Still got a bit of time, like 16-17 hours before our transfer, so fingers crossed that it will be sorted. There's this Lufthansa app with a chat I've been spamming non-stop for info, but it keeps glitching every time. This is the dumbest German robot ever. At one point, I started typing random stuff like, "Want a pancake?" but it totally ignored me. No pancakes for Mr. Robot. Meanwhile, we bump into other yogis heading home, and it's the same drama for everyone heading towards Munich, but some already have new connecting flights. This info is somewhat helpful but also not. Natalie, who's also transferring to Ljubljana, didn't get her connection cancelled. I'm starting to lose my shit, but I'm trying not to because I just spent months here detoxing. Deep breath in, out, take that, you silly chatbot. Whatever happens, happens. Gotta trust it'll work out.</p>
                                <p>The new airport terminal here in Bangalore has this Dubai-vibe design going on. Minor design oopsie - there are barely any restaurants, and the ones here aren't really our jam (not the designer's fault, though). Next plot twist: our 2:50 am flight being on time would've been too easy, right? So they pushed it by a couple of hours to 4:50 am instead. It's around 9:30 pm now, so quick math says this is a total bummer. Things are starting to smell fishy, and we're getting zero info, but then I remember I can look up what's happening over there, and boom, it turns out Munich's buried under one and a half meters of snow. <b>Damn it snow, damn it snow, damn it snow</b>!</p>
                                <p>We pass through security and settle into a restaurant where I have a super lame fruit salad that costs as much as a whole day's meals in Mysore. Then, we start scouting for a little “rest spot.” The designer here seemed to prefer waterfalls over comfy seating. We're about 6 hours from boarding, and just being here goes against all my principles because I'm usually the last-minute kind of traveller. We find these lounge-chair-like seats near a window (looks better than it feels) where we try to catch some sleep since it's really late for us early birds. The air conditioning is set to Arctic levels. We're wearing everything we own, but it's barely enough. I realise that the more it snows, the more Winnie the Pooh's toes are cold, which means that in this raincoat, I will turn into a suntanned ice cube out there in the ice west.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">All aboard</span></h2>
                                <p>If you've never flown, I won't spoil it; basically, we board, take off, fly off, and after about 11 hours, we'll land. I watched Denzel in "Equalizer 3" - totally nailed it, loved the first two as well. Somewhere over Iran, the captain announces:</p>
                                <blockquote>Ladies and gentlemen, in the 30 years I've been flying, I've never seen this, but Munich airport is closed due to heavy snow. We can't circle in the air, so we're rerouting to Frankfurt.<span>Captain with attitude</span></blockquote>
                                <p>That's actually further away, or as Santosh would say a "shortcut." If that's not enough to cap off this experience, the onboard internet goes down. I wasn't using it, but that's not the point. The flight attendants were, and without it, flying blind, with no info from the ground. It's pretty much how I feel about getting home. All they can tell us is if Munich wasn't our final destination, we shouldn't pick up our checked bags since they'll transfer them to our homebound flight. What happens next is as close to that as I am to mastering “Eka Pada Sirsasana.”</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">Landing</span></h2>
                                <p>Hello Frankfurt. They herd us into a bus for a breezy 20-minute ride to the terminal – no exaggeration. From there, Lufthansa turns as elusive as its name suggests – like it's made of thin air. No one's around, and every office we stumble upon is closed or deserted. The few people we manage to talk to seem like it's their first day on the job. Passengers, including us, wander aimlessly, moving from one line to another. Then we're sent to another terminal, requiring a bus ride. We rush there and join yet another line. We're doing all this because that's what we're told, but mainly because they have our luggage. I'm hoping if we actually talk to someone, they'll rebook our flight, and we can head home.</p>
                                <p>This line is stagnant. We're in no man's land – no restaurants, no cafes, nothing, just a million people in a line that doesn't move. We spend 6 hours there, moving maybe 30 meters. Dia couldn't eat on the plane because the food wasn't right, so she was running on empty but still hanging in there. I'm more annoyed by the situation. We notice they've brought some “orange” juice, water, and some snacks for the weary travellers. We miss out on the snacks, being a bit slow, but that sugary water gives us 5 minutes of satisfaction and a bit of fullness.</p>
                                <p>About 4 hours in, they bring more goodies on a trolley – chips, water, chocolate, etc. I rush down to grab some chips, but the guy snaps at me and tells me to wait until he stops. Okay, asshole, I'll wait. He starts tossing boxes off the trolley like feeding chickens back on his farm on a misty Sunday morning before church—such a fine Christian. I managed to grab about five bags of chips – chip, chip, chips. It's been 24 hours since we started this journey, but finally, we got something valuable. I hope that jerk quits his job and goes to India to cleanse his soul. This whole queueing ordeal reminds me of a chorus from an old song my brother and I made for our first album:</p>
                                <blockquote>Cause and effect, the effect's a handicap for those who don't understand the cause. So many hurdles I come across, but if you ask who's backing down, it's not these bros.
                                    <span>ÉNÉSÉN - Mert (meaning "Because", translated from Hungarian)</span>
                                    <span>
                                        <iframe src="https://open.spotify.com/embed/track/4nea8ELi0CxqBmIq43lIMD?utm_source=generator&theme=0"
                                                width="100%" height="152" frameBorder="0" allowFullScreen=""
                                                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                                loading="lazy"></iframe>
                                    </span>
                                </blockquote>
                                <p>We're almost at the front when a new Lufthansa guy shows up and tells us we're in the wrong line, that we need to enter the country and join a shorter, faster line for EU citizens. I've asked multiple times over the past hours if we were in the right place and was told yes, but 360 minutes later, they've changed their minds. Great company. I tell the guy I've wasted 6 hours of my life in this line, so he better be sure about what awaits us outside, or I might burn down the place. I don't say the last part out loud, think it, but he's still snarky about me losing time, he just don't give a shit. His attitude doesn't help, but at least he's as rude as fuck. We decide to move because it seems like if we wait in this line, they won't deal with us anyway. We say goodbye to the floor and the dimwits and enter the country. Hello Germany. The line outside is about 3 kilometres long. There is a slim chance the guy who sent us out here is reading this, but if you are, your mom was hiccuping all of December 2nd because you turned out this way.</p>
                                <p>We skip this line, feeling we've been messed with enough for one day. It's like my life lately is all about queueing. I booked a hotel room and spent about an hour and a half trying to buy a ticket to Budapest. Obviously, their shitty system is overloaded, but Lufthansa's the only option for home. Meanwhile, the airport is full of crazies, fights, and tension. Such refined energies, the perfect welcome back to “culture”. Nat ends up buying a ticket to Croatia instead of Slovenia, where Matej will drive to pick her up. At this point, we give up on our luggage – nothing more we can do today. I snag a Frankfurt-Brussels-Budapest ticket for the next day, costing a not-so-friendly 800 euros. Of course, all the tickets sold out while we were in line, and whatever was left was priced like gold. We say goodbye to Natalie and head to the hotel. Just as our Uber arrives, I get a text that the flight I finally booked has been cancelled. RIP.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">So, so, so much</span></h2>
                                <p>There's one last option left, also 800 euros, Frankfurt-Vienna-Budapest, tomorrow night. I manage to pay for it in 30 minutes, but if they cancel this one too, I'm just going to move here. Meanwhile, this winter is wrecking us. It's freezing, and we're dressed for 20-degree weather – not our brightest moment. At least I have a change of underwear, so there's that. The hotel is nice, aside from the cold. We get some sleep and even enjoy a decent breakfast. There's an Italian restaurant across the street where we plan to spend a few hours before the next episode of our queueing adventures.</p>
                                <p>Arriving at the airport, we see a line, and, well, we're so used to them by now that we join in. This one moves, and it seems like management finally realised that they need to start working if almost all flights are redirected here from an airport. We reach the counter, and I explain our ordeal. They scan our new tickets and our previous luggage barcode and say they'll put our bags on the new flight, and we'll find them at home after landing. Then they tell us to claim a refund for the ticket and hotel online once we're home. Sounds great, almost unbelievable (and it was too good to be true, in hindsight).</p>
                                <p>We find Dia a hat and scarf, fly to Vienna, then Budapest. I've never flown such a short segment; it felt like the plane was still ascending when it descended to land. When I turn on my phone, I get a message saying our luggage couldn't be loaded in Frankfurt. That woman was lying to us when we finally finished a line. They write they'll deliver it some time; just fill out a form, which I do. It's 10:20 pm; we were supposed to be home by noon yesterday. I call a taxi, and off we go to Nancy, our cat.</p>
                                <p><b>Om sweet home.</b></p>
                                <p>I've heard of people having good experiences with Lufthansa, but I'll be avoiding even their logo if it crosses my path in the future. I'm writing this two weeks later; Dia's luggage arrived a week ago, but mine is still missing, and information about its whereabouts is dwindling. I call them daily, and they say something completely random every time. Of course, I've asked for a refund, but I am still awaiting a response. This will surely be a better memory than experience.</p>
                                <h2 className="heading heading--3 margin-block--lg"><span className="heading__sub align--center display--block">Afterword</span></h2>
                                <p>I'm consumed by thoughts about why I'm so consumed with what's next. I fear rushing towards the end, yet I'm also scared of stopping. Inhale, exhale, inhale, exhale. Can I fix what I've messed up? Everything's like practising asana - do I keep going, and it will eventually come? Inhale, everything will be fine, exhale, but what if it's not? What's this 'fine' anyway that is supposedly so good? I've imagined it many times, and when I thought I had it, I'd envision something else instead.</p>
                                <p>I don't have a stable job right now, but looking back, I'm not sure I felt any more secure when I did have one. Last year, I was here working, and that bothered me; this year, not working bothered me. Well, at least for a while. Interview season is coming, determining who and how much I'll sell my only valuable currency to - my time. I'm already sickened by the thought. I want to believe that if I let go of the steering wheel and let things be, something will happen because something always does. The past few years have taught me that I don't control anything. I hope the next thing will be meaningful. Money is a crappy concept, breeding predatory sheep, and it's no coincidence there's no such thing in nature. It's unnatural. Sheep are actually pretty cool, no offence.</p>
                                <p>I missed things from home, though - family, cat, and friends (Nintendo/PlayStation XD). Non-spicy food. This reminds me of the first time we ate at Govinda, a sattvic kitchen, which I won't detail here, but among other things, they don't use chilli, so it doesn't burn your head off. I was so happy about it that I almost ordered everything on the menu and gobbled it all up. When I went to pay, the waitress asked if she should pack the leftovers, to which I said, "Thanks, but I've already packed it," and I patted my belly gently.</p>
                                <p>It'll be good to look back on these two months. It was nice writing this blog. Sometimes, it caused a basic level of pressure, especially when I had no idea what to write about, but generally, it was great. This was my first attempt, and I know I'm way behind in this Tik-Tok crap world where attention spans last ten seconds. I received a lot of feedback for which I'm grateful; it kept me going, so pat yourself on the back, reader. <b>Respect.</b></p>
                                <p>I wish everything and more to those who shared this journey with me. It was great being with you. And foremost, thank you for reading, and if you'd like a sequel, subscribe with your email below. Don't worry; I won't spam you, and if there's a new blog in the future, I'll drop it (many have asked, so there will be). <b>Peace.</b></p>
                                <blockquote>Don't cry because it's over. Smile because it happened.<span>Dr Seuss</span></blockquote>
                                <p>P.S.: Thank you, Dia, for being the best editor-in-chief to me, among many other things you're the best at. <b>XOXO</b></p>
                            </>
                        }
                    </article>
                </div>
                <div id="gallery" className="container blog__gallery">
                    <div className="blog__gallery-masonry">
                        {Array.from({ length: 35 }, (_, i) =>
                            <div className="brick" key={"image gallery" + i}>
                                <GalleryImage
                                    url={"/images/cliche-blog/" + (i + 1) + ".jpg"}
                                    alt={"Image" + (i + 1)}
                                />
                            </div>
                        )}
                    </div>
                    <div className="blog__gallery-singles">
                        <div className="blog__gallery-single">
                            <GalleryImage
                                url="/images/cliche-blog/the-guru-and-i.jpg"
                                alt="The Guru and I"
                            />
                        </div>
                    </div>
                </div>
                <div className="container container--700 blog__content">
                    <article className="blog__article blog__article--the-end">
                        {language === "HU"
                            ? <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">Köszi az olvasást</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">Vége</h2>
                                </hgroup>
                            </>
                            : <>
                                <hgroup className="blog__article-header">
                                    <h3 className="heading heading--4 no-margin--bottom blog__number">Thanks for reading</h3>
                                    <h2 className="heading heading--3 no-margin--bottom">The End</h2>
                                </hgroup>
                            </>
                        }
                        <div id="subscribe" className="blog__subscribe">
                            <div className="container container--small padding-lg--bottom">
                                <SubscribeForm lang={language} />
                            </div>
                        </div>
                        <div className="blog__language-select">
                            <a className={`${language === "HU" ? 'active' : ''} blog__language-button`} onClick={() => { changeLanguage('HU') }}>Magyar</a>
                            <a className={`${language === "EN" ? 'active' : ''} blog__language-button`} onClick={() => { changeLanguage('EN') }}>English</a>
                        </div>
                    </article>

                </div>
            </div>
        </>
    )
}

export default Cliche;
