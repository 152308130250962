import React, {useState} from "react";

const GalleryImage = ({ url = "", alt = "Image" }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    return (
        <>
            <img
                src={url}
                loading="lazy"
                className={`${isLoaded ? 'loaded' : ''}`}
                onLoad={() => {setIsLoaded(true)}}
                alt={alt} />
        </>
    );
};

export default GalleryImage;
