import React from "react";
import {isMobile} from 'react-device-detect';

const Hero = ({title, description, image, image2x, captionLeftTitle, captionLeftMeta, captionRightTitle, captionRightMeta}) => {
    return (
        <section className="section hero">
            <div className="hero__background">
                <img src={image} srcSet={`${image2x ? image2x + " 2x" : ""}`} alt={title}/>
            </div>
            <div className="hero__content">
                <div className="hero__title-group">
                    <h1 className="hero__title heading heading--1">
                        {title === "End-to-End" ? (
                                <span style={{
                                    overflowWrap: 'break-word'
                                }}>
                                    End-to-End
                                </span>
                            ) : (
                            title
                        )}
                    </h1>
                    {description &&
                        <p className={`hero__description container--600 ${isMobile ? "hero__description--mobile" : ""}`} dangerouslySetInnerHTML={{__html: description}} />
                    }
                </div>
                <div className="hero__caption">
                    <span className="caption">{captionLeftTitle}</span>
                    <span>{captionLeftMeta}</span>
                </div>
                <div className="hero__caption hero__caption--right">
                    <span className="caption">{captionRightTitle}</span>
                    <span>{captionRightMeta}</span>
                </div>
                <div className="hero__border">
                    <div className="hero__border-top">&nbsp;</div>
                    <div className="hero__border-right">&nbsp;</div>
                    <div className="hero__border-bottom">&nbsp;</div>
                    <div className="hero__border-left">&nbsp;</div>
                </div>
                <div className="hero__arrow">
                    <div className="hero__arrow-box">&nbsp;</div>
                </div>
            </div>
        </section>
    )
}

export default Hero;
