import React, {useState, FormEvent} from "react";

const SubscribeForm = (language) => {
    const [status, setStatus] = useState(null)
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")

    const FORM_URL = "https://app.convertkit.com/forms/5735740/subscriptions"

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const data = new FormData(event.target)

        try {
            const response = await fetch(FORM_URL, {
                method: "post",
                body: data,
                headers: {
                    accept: "application/json",
                },
            })

            setEmail("")
            const json = await response.json()

            if (json.status === "success") {
                setStatus("SUCCESS")
                return
            }
        } catch (err) {
            setStatus("ERROR")
            console.log(err)
        }
    }

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target
        setEmail(value)
    }

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target
        setName(value)
    }

    return (
        <div>
            <h3 className="heading heading--3">
                {language.lang.toUpperCase() === "HU"
                    ? <>
                        Feliratkozás
                    </>
                    : <>
                        Subscribe
                    </>
                }
            </h3>
            {status === "SUCCESS" && (
                <>
                    <p className="margin-xs--bottom">
                        <strong>Namasté{name ? `, ${name}` : ""}{" "}</strong>
                    </p>
                    {language.lang.toUpperCase() === "HU"
                        ? <>
                            <p>Küldöm amint lesz valami, és remélem nem a spam-be megy mert free szolgáltatást használok. Csumpedli.</p>
                        </>
                        : <>
                            <p>I will send an update as soon as there is one, and I hope it won't end up in your spam folder, as I am using a free service. Peace out.</p>
                        </>
                    }
                </>
                )}
            {status === "ERROR" && (
                <>
                    <p className="margin-xs--bottom">
                        {language.lang.toUpperCase() === "HU"
                            ? <>
                                <strong>Hoppá valami nem jó</strong>
                            </>
                            : <>
                                <strong>Oops something is wrong</strong>
                            </>
                        }
                    </p>
                    {language.lang.toUpperCase() === "HU"
                        ? <>
                            <p>
                            Légyszi,{" "}
                            <button onClick={() => setStatus(null)} className="button">próbáld újra.</button>
                            </p>
                        </>
                        : <>
                            <p>
                                Please,{" "}
                                <button onClick={() => setStatus(null)} className="button">try again.</button>
                            </p>
                        </>
                    }
                </>
                )}
            {status === null && (
                <>
                    <p className="margin--bottom">
                        {language.lang.toUpperCase() === "HU"
                            ? <>
                                Ha akarod tudni mikor jön valami új
                            </>
                            : <>
                                If you want to know about the updates
                            </>
                        }
                    </p>
                    <form onSubmit={handleSubmit}>
                    {/*<div className="form__field">*/}
                    {/*    <input*/}
                    {/*        aria-label="Név"*/}
                    {/*        name="fields[first_name]"*/}
                    {/*        placeholder="Név"*/}
                    {/*        type="text"*/}
                    {/*        onChange={handleNameChange}*/}
                    {/*        value={name}*/}
                    {/*        className="form__input"*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <div className="form__field">
                        <input
                            aria-label={`${language.lang.toUpperCase() === "HU" ? 'Email cím' : 'Email address'}`}
                            name="email_address"
                            placeholder={`${language.lang.toUpperCase() === "HU" ? 'Email cím' : 'Email address'}`}
                            required
                            type="email"
                            onChange={handleEmailChange}
                            value={email}
                            className="form__input"
                        />
                    </div>
                    <div className="form__footer align--center">
                        <button className="button button--simple button--full" type="submit">
                            {language.lang.toUpperCase() === "HU"
                                ? <>
                                    Feliratkozás
                                </>
                                : <>
                                    Subscribe
                                </>
                            }
                        </button>
                        <br />
                        <br />
                        {language.lang.toUpperCase() === "HU"
                            ? <>
                                <p>Vagy csak írj egy email-t ide <a className="link link--underline" href="mailto:cliche@zoltanbank.com?subject=Feliratkozás a Cliché-re" target="_blank">cliche@zoltanbank.com</a> és felraklak a listára.</p>
                                <hr />
                                <p>A ConvertKit-et használom, <a className="link link--underline" href="https://help.convertkit.com/en/articles/2502527-compliance-with-gdpr" target="_blank">GDPR info</a></p>
                            </>
                            : <>
                                <p>Or drop me an email at <a className="link link--underline" href="mailto:cliche@zoltanbank.com?subject=Feliratkozás a Cliché-re" target="_blank">cliche@zoltanbank.com</a> and I'll add you to the list.</p>
                                <hr />
                                <p>I'm using ConvertKit, <a className="link link--underline" href="https://help.convertkit.com/en/articles/2502527-compliance-with-gdpr" target="_blank">GDPR info</a></p>
                            </>
                        }
                    </div>
                </form>
                </>
            )}
        </div>
    )
}

export default SubscribeForm;
