import React, {useRef, useEffect} from 'react';
import {motion, useAnimation, useInView} from "framer-motion";
import {verticalScrollOffsetMarginBase} from "./TransitionConstants";
import BreakToLetters from "./BreakToLetters";
import {isMobile} from 'react-device-detect';

const AnimatedLetters = ({word, classList, fade = true, once, delay = .1}) => {
    const lettersArray = BreakToLetters(word);
    const ref = useRef(null);
    const controls = useAnimation();

    isMobile ? once = true : once;

    const isInView = useInView(ref, {margin: verticalScrollOffsetMarginBase, once: once});

    const lettersVariants = {
        hidden: {
            transition: {
                staggerChildren: 0.05,
                staggerDirection: -1
            }
        },
        visible: {
            transition: {
                delayChildren: delay,
                staggerChildren: 0.05,
                staggerDirection: 1
            }
        }
    }
    const letterVariants = {
        hidden: {
            top: '8vw',
            opacity: fade ? 0 : 1,
            transition: {
                transitionTimingFunction: 'ease-in-out'
            }
        },
        visible: {
            top: 0,
            opacity: 1,
            transition: {
                duration: .2,
                transitionTimingFunction: 'ease-in-out'
            }
        }
    }

    // Animation control
    useEffect(() => {
        if (isInView) {
            controls.start("visible");
        } else {
            controls.start("hidden");
        }
    }, [controls, isInView]);

    return (
        <motion.div className={`${classList ? "overflow-hidden position--relative padding-inline--tn " + classList : "overflow-hidden position--relative padding-inline--tn"}`}
                    ref={ref}
                    animate={controls}
                    variants={lettersVariants}
        >
            {lettersArray.map((letter, index) => (
                <motion.span key={letter + index}
                             variants={letterVariants}
                             className="animated-letter"
                >
                    {letter}
                </motion.span>
            ))}
        </motion.div>
    )
}

export default AnimatedLetters;
