import * as React from "react";
import Projects from "../pages/projects";
import EndToEnd from "../pages/end-to-end";
import Wrapless from "../pages/wrapless";
import QuickPay from "../pages/quick-pay";
import Cliche from "../pages/cliche-blog";
import Blog from "../pages/blog";
import About from "../pages/about";
import Post from "../pages/post";
import Error404 from "../pages/404";

export const routesConfig = [
    {
        name: "Projects",
        class: "projects",
        group: "portfolio",
        path: "/",
        title: "Zolly's Design Portfolio",
        element: <Projects />
    },
    {
        name: "End-to-End",
        class: "end-to-end",
        group: "portfolio",
        client: "bt",
        path: "/end-to-end",
        title: "Zolly's End-to-End Case Study",
        element: <EndToEnd />
    },
    {
        name: "Quick Pay",
        class: "quick-pay",
        group: "portfolio",
        client: "quick-pay",
        path: "/quick-pay",
        title: "Zolly's Quick Pay Product Design",
        element: <QuickPay />
    },
    {
        name: "Wrapless",
        class: "wrapless",
        group: "portfolio",
        client: "wrapless",
        path: "/wrapless",
        title: "Zolly's Wrapless Case Study",
        element: <Wrapless />
    },
    {
        name: "About",
        class: "about",
        group: "portfolio",
        path: "/about",
        title: "About Zolly",
        element: <About />
    },
    {
        name: "Blog",
        class: "blog",
        group: "blog",
        client: "blog",
        path: "/:lang/blog",
        title: "A few story of mine",
        element: <Blog />
    },
    {
        name: "Post",
        class: "blog-post",
        group: "blog",
        client: "blog",
        path: "/:lang/blog/:id",
        title: "Post",
        element: <Post />
    },
    {
        name: "Cliche Tales",
        class: "cliche-tales",
        group: "cliche-tales",
        client: "cliche-tales",
        path: "/cliche-blog",
        title: "Me in India ~2 hours read time",
        element: <Cliche />
    },
    {
        name: "404",
        class: "error",
        group: "error",
        path: "*",
        title: "404",
        element: <Error404 />
    }
];
