import React, {useRef} from 'react';
import {Link} from "react-router-dom";
import {routesConfig} from "../config/routes";
import Icon from "./Icons";

function NextProject({nextProject}) {
    const ref = useRef(null);
    const projectsRoute = routesConfig.filter(item => item.name.includes('Projects'))[0];
    const aboutRoute = routesConfig.filter(item => item.name.includes('About'))[0];
    const filterRoutes = routesConfig.filter(item => item.name.includes(nextProject))[0];
    return (
        <section ref={ref} className="section next-project">
            <Link to={filterRoutes.path}>
                <div className="next-project__link">
                    <span className="caption margin--bottom">Next project</span>
                    {filterRoutes.name &&
                        <Icon classList="icon--extra-large icon--fill margin--bottom" name={filterRoutes.client}/>
                    }
                    <span className="next-project__title">{filterRoutes.name}</span>
                    <div className="button button--split button--primary">
                        <span className="button__arrow" />
                        <span className="button__text">Let's go</span>
                        <span className="button__anim">
                            <span>Visit next project</span>
                            <span>Visit next project</span>
                        </span>
                        <span className="button__placeholder">Visit next project</span>
                    </div>
                </div>
            </Link>
            <div className="next-project__footer">
                <span className="caption">Or</span>
                <Link to={projectsRoute.path}>
                    <span className="button button--simple button--bordered">Projects overview</span>
                </Link>
                <span className="caption">Else</span>
                <Link to={aboutRoute.path}>
                    <span className="button button--simple button--bordered">About me</span>
                </Link>
            </div>
        </section>
    )
}

export default NextProject;
