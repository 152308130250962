import React from 'react';
import {motion} from "framer-motion";
import {isMobile} from 'react-device-detect';
import { Helmet } from 'react-helmet-async';

import Hero from "../components/Hero";
import Reveal from "../transitions/Reveal";
import Parallax from "../transitions/Parallax";
import TextBlock from "../components/TextBlock";
import ChapterTitle from "../components/ChapterTitle";
import pageTransition, {pageTransitionVariants} from "../components/PageTransition";
import NextProject from "../components/NextProject";
import ImageBlock from "../components/ImageBlock";
import VideoBlock from "../components/VideoBlock";
import Carousel from "../components/Carousel";
import {SwiperSlide} from "swiper/react";
import DrawPath from "../components/DrawPath";
import RollingText from "../components/RollingText";
import ChaptersMenu from "../components/ChaptersMenu";

const EndToEnd = () => {
    return (
        <>
            <Helmet>
                <title>Case Study: End-to-End</title>
            </Helmet>
            <motion.div
                className="page project brand--bt"
                variants={pageTransitionVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
            >
                <ChaptersMenu chapters={[
                    {id: 'kickoff', title: 'Kickoff'},
                    {id: 'team', title: 'Team'},
                    {id: 'day-1', title: 'Day 1'},
                    {id: 'day-2', title: 'Day 2'},
                    {id: 'day-3', title: 'Day 3'},
                    {id: 'day-4', title: 'Day 4'},
                    {id: 'day-5', title: 'Day 5'},
                    {id: 'next-steps', title: 'Next Steps'},
                    {id: 'wireframes', title: 'Wireframes'},
                    {id: 'product-design', title: 'Product Design'},
                    {id: 'next-project', title: 'Next project', next: 'Wrapless'}
                ]} />

                <Hero
                    title="End-to-End"
                    description="This is the journey of a new product for BT that was based on Excel sheets, old web apps and user workarounds. From conducting a discovery workshop to delivery."
                    captionLeftTitle="Type"
                    captionLeftMeta="Case study"
                    captionRightTitle="Client"
                    captionRightMeta="BT"
                    image="../images/end-to-end/hero.min.png"
                    image2x="../images/end-to-end/hero@2x.min.png"
                />

                <section className="section">
                    <div className="container container--1000">
                        <div className="row">
                            <div className="col-lg-8 col-sm-12">
                                <TextBlock
                                    caption="The project"
                                    text="This case study has a familiar start: archaic web apps and a few Excel sheets with macros circling between teams and users responsible for a multi-million pound business.<br /><br />I’d like to state that Excel is a great tool and can be a huge help with starting a product. It is accessible, a lot of your colleagues know how to use it, and on a level, it’s scalable.<br /><br />I'm not roasting Excel. It's a great tool... Phew..."
                                    shifted
                                />
                            </div>
                            <div className="col-lg-4 col-sm-12">
                                <TextBlock
                                    caption="Responsibilities"
                                    classList="margin-hg--top margin-sm-sm--top"
                                    text="<ul><li>UX Research</li><li>Product Strategy</li><li>Wireframes/Prototypes</li><li>Product design</li></ul>"
                                    align="right"
                                    shifted
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <section id="kickoff" className="section no-padding-sm--bottom">
                    <ChapterTitle title="Kickoff"/>
                    <TextBlock
                        caption="It all starts here"
                        textClassList="heading heading--3"
                        text="We got the job when there was only one person remaining (in the world probably) could understand those Macros. To rely on one person's Excel knowledge and memory is risky business."
                        container="1000"
                        align="center"
                    />
                    <div className="container container--1000 margin-block--xl margin-sm-sm--top margin-sm-sm--bottom">
                        <ImageBlock
                            classList="end-to-end__kickoff-image"
                            image="/images/end-to-end/globe.png"
                            image2x="/images/end-to-end/globe@2x.png"
                            alt="BT Global"
                            align="center"
                        />
                    </div>
                    <TextBlock
                        classList="no-margin-sm--bottom"
                        text="Our users are engineers designing and maintaining networks remotely, but I’d like to point out that my primary focus is to describe the design process we went through, not to make you understand how these folks are doing their job, but for the record: <strong>they are awesome!</strong>"
                        container="600"
                        align="center"
                    />
                </section>

                <section id="team" className="section no-padding-sm--bottom">
                    <ChapterTitle title="Team"/>
                    <TextBlock
                        caption="The objective"
                        captionClassList="margin-md--bottom"
                        textClassList="heading heading--3"
                        text="To understand the problems we had to solve, I joined a BA, an SME, and a PS team to conduct an on-site week-long discovery workshop with stakeholders and current/future users of the app we were asked to deliver."
                        shifted
                        container="1000"
                    />
                    <div className="container container--1000 margin-block--xl--neg">
                        <Parallax classList="z-index--behind" offset={40} duration={-40}>
                            <ImageBlock
                                classList="end-to-end__team-image"
                                image="/images/end-to-end/team.png"
                                image2x="/images/end-to-end/team@2x.png"
                                alt="BT Global"
                                align="center"
                                visibleAmount={.1}
                            />
                        </Parallax>
                    </div>
                    <TextBlock
                        text="For the core project team we asked for members of the management to join us, as they were overseeing the processes and workflows."
                        container="500"
                        align="center"
                    />
                </section>

                <section id="day-1">
                    <ChapterTitle title="Day 1" classList="margin-hg--bottom" />
                    <DrawPath
                        classList="end-to-end__day1-pull-up-graphics"
                        width={1440}
                        height={760}
                        paths={[
                            {
                                path: 'M1520 1L488 314L717 356V364.5L530 408L612.5 427.5V439L210 544.5L409 587V605.5L-43 709.5',
                                color: 'var(--color-grey-100)',
                                strokeWidth: 'var(--border-width-thin)'
                            },
                            {
                                path: 'M1474 816.5V0V59L631.5 308.5V314.5L885.5 358.5L662 410V421.5L767 441.5L368 539V557L593 604L-53 758V816.5',
                                color: 'var(--primary-color)',
                                fill: 'white'
                            }
                        ]}
                    />
                    <section className="section no-padding--top no-padding--bottom">
                        <TextBlock
                            classList="z-index--2 no-margin--bottom end-to-end__day1-pull-up-text"
                            caption="Session 1"
                            title="Intro & Icebreaker"
                            text="We started with an introduction and an ice-breaker exercise focused on problem-solving and design thinking. It was a short but fun teamwork that helped set the mood and mindset."
                            shifted
                            align="right"
                            container="500"
                        />
                    </section>
                    <div className="section section-bg--white end-to-end__day1-content">
                        <TextBlock
                            caption="Session 2"
                            title="Hopes & Fears"
                            text="We followed by asking everyone to write down hopes and fears about the project on post-its and place them on the whiteboard. The primary goal of this exercise was to provide an initial scope, understand expectations, and highlight any pain points. We started discussing and clustering the notes and adding post-its, creating themes to form a skeleton for our research."
                            shifted
                            container="1000"
                        />
                        <Reveal transitionDirection="bottom" colour="white">
                            {isMobile ? (
                                <VideoBlock
                                    classList="end-to-end__video"
                                    mp4="/videos/end-to-end/hopes-and-fears.mobile.mp4"
                                    align="center"
                                />
                            ) : (
                                <VideoBlock
                                    classList="end-to-end__video"
                                    webm="/videos/end-to-end/hopes-and-fears.webm"
                                    mp4="/videos/end-to-end/hopes-and-fears.min.mp4"
                                    align="center"
                                />
                            )}
                        </Reveal>
                        <TextBlock
                            caption="Session 3"
                            title="Assumptions & Questions"
                            text="Similarly, we listed assumptions and questions about the project, followed by an evaluation exercise,<br class='hide--on-lg' /> where we placed them on a Risk Matrix to see their potential risk for the project,<br class='hide--on-lg' /> and our level of understanding and knowledge of them."
                            align="center"
                            container="1000"
                        />
                        <Reveal transitionDirection="bottom" colour="white">
                            {isMobile ? (
                                <VideoBlock
                                    classList="end-to-end__video"
                                    mp4="/videos/end-to-end/assumptions-and-questions.mobile.mp4"
                                    align="center"
                                />
                            ) : (
                                <VideoBlock
                                    classList="end-to-end__video"
                                    webm="/videos/end-to-end/assumptions-and-questions.webm"
                                    mp4="/videos/end-to-end/assumptions-and-questions.min.mp4"
                                    align="center"
                                />
                            )}
                        </Reveal>
                        <TextBlock
                            classList="margin-xl--bottom"
                            text="This research tool showed the items we should start working on, as any high-risk and uncertain ticket needed investigation."
                            container="600"
                            align="center"
                        />
                        <TextBlock
                            caption="Conclusion"
                            textClassList="heading heading--3"
                            text="We created the backbone for our product, framed the most critical areas that needed research, and gathered questions we should ask our users during our upcoming interviews."
                            container="1000"
                        />
                    </div>
                </section>

                <section id="day-2" className="section section-bg--white no-padding--top no-padding-sm--bottom">
                    <ChapterTitle title="Day 2"/>
                    <TextBlock
                        caption="Session 4"
                        title="Interviews & Observation"
                        text="Our core team, including BA, Strategist and myself, sat with engineers and desk agents and asked them to show us their day-to-day work, pain points, challenges, barriers and workarounds. We also asked the relevant questions from our previous sessions."
                        container="1000"
                        align="center"
                    />
                    {isMobile ? (
                        <VideoBlock
                            classList="end-to-end__video"
                            mp4="/videos/end-to-end/user-interview.mobile.mp4"
                            align="center"
                        />
                    ) : (
                        <VideoBlock
                            classList="end-to-end__video"
                            webm="/videos/end-to-end/user-interview.webm"
                            mp4="/videos/end-to-end/user-interview.min.mp4"
                            align="center"
                        />
                    )}
                    <TextBlock
                        caption="It’s about the end-users"
                        text="It was a bit of a shock to see how disconnected the leadership was from the workforce, as their assumptions about the processes were far from reality."
                        container="600"
                        align="right"
                        shifted
                    />
                    <TextBlock
                        caption="In numbers"
                        align="left"
                        fade={false}
                        stats={[
                            {title: 'Interviewees', stat: '12'}, {
                            title: 'Job roles',
                            stat: '4'
                        }, {title: 'Hours of recording', stat: '7'}, {title: 'Questions asked', stat: 'Many'}]}
                    />
                </section>

                <section id="day-3" className="section section-bg--white no-padding--top no-padding-sm--bottom">
                    <ChapterTitle title="Day 3"/>
                    <TextBlock
                        caption="Session 5"
                        title="Empathy Mapping"
                        text="Based on our user research, we created empathy maps to use as a reference and to assist the wider team and stakeholders in understanding the user’s needs. During the interviews, we identified multiple workarounds in the daily workflows that flagged even more problems to fix than we first anticipated."
                        container="1000"
                        align="center"
                    />
                    <Reveal transitionDirection="bottom" colour="white">
                        {isMobile ? (
                            <VideoBlock
                                classList="end-to-end__video"
                                mp4="/videos/end-to-end/empathy-map.mobile.mp4"
                                align="center"
                            />
                        ) : (
                            <VideoBlock
                                classList="end-to-end__video"
                                webm="/videos/end-to-end/empathy-map.webm"
                                mp4="/videos/end-to-end/empathy-map.min.mp4"
                                align="center"
                            />
                        )}
                    </Reveal>
                    <TextBlock
                        classList="margin-xl--bottom"
                        caption="Flexible UX tools"
                        text="We used renamed job titles as UX personas because we already had dozens of users working in this office. A role as a persona can be misleading as it takes feelings/empathy out of the equation, but in this workshop, this seemed the best move to keep us on track."
                        container="700"
                        shifted
                    />
                    <TextBlock
                        classList="margin-xl--bottom"
                        caption="More discovered"
                        text="Finding out how surprised the stakeholders were about how desk agents operate was exciting. This was a big eye-opener for the management and a dealbreaker about the importance of our research work (yeah, we still had some doubters at this point)."
                        container="700"
                        align="right"
                        shifted
                    />
                    <ImageBlock image="/images/end-to-end/room.png"
                                image2x="/images/end-to-end/room@2x.png"
                                alt="Room"
                                classList="end-to-end__day2-room"
                                transitionDirection="left"
                                align="center"
                    />
                    <TextBlock
                        classList="margin-hg--bottom"
                        caption="Improved user engagement"
                        text="We put all the empathy maps in a meeting room for everyone to see and asked all employees to add notes and thoughts if they could. When we arrived back at the office the next day, our users were already there, discussing ideas during their morning coffees."
                        container="700"
                        align="center"
                    />
                    <TextBlock
                        caption="Session 6"
                        title="User Need Statements"
                        text="After answering the questions “What is our user doing, thinking and feeling throughout their experience?” we moved on to write down as many user needs and benefits as possible while <strong>staying away from writing features</strong>. We discussed these statements to create clusters from similar ideas. Based on these, we wrote User Need Statements."
                        container="800"
                        shifted
                    />
                    {isMobile ? (
                        <VideoBlock
                            classList="end-to-end__video"
                            mp4="/videos/end-to-end/user-need-statements.mobile.mp4"
                            align="center"
                        />
                    ) : (
                        <VideoBlock
                            classList="end-to-end__video"
                            webm="/videos/end-to-end/user-need-statements.webm"
                            mp4="/videos/end-to-end/user-need-statements.min.mp4"
                            align="center"
                        />
                    )}
                </section>

                <section id="day-4" className="section section-bg--white no-padding--top no-padding-sm--bottom">
                    <ChapterTitle title="Day 4"/>
                    <TextBlock
                        classList="margin-xl--bottom"
                        caption="Session 7"
                        title="Concept Creation"
                        text="The best part of this workshop was outsourcing design work to our client. Joking aside, it helps the future design phase to see how core user admins of the system are thinking about a feature or user interface and how they feel it should work and look. Since this is based on their technical knowledge, this helps to reveal more hidden gems in their processes."
                        container="800"
                        shifted
                    />
                    <TextBlock
                        title="We asked all participants to pick a User Need Statement and use the template below to draw a concept for it, and to answer a few questions."
                        container="800"
                        align="right"
                        shifted
                    />
                    <div className="container container--1000">
                        {isMobile ? (
                            <VideoBlock
                                classList="end-to-end__video end-to-end__video--wireframe-fix wireframe z-index--3"
                                mp4="/videos/end-to-end/concept-card.mobile.mp4"
                                align="center"
                            />
                        ) : (
                            <VideoBlock
                                classList="end-to-end__video end-to-end__video--wireframe-fix wireframe z-index--3"
                                webm="/videos/end-to-end/concept-card.webm"
                                mp4="/videos/end-to-end/concept-card.min.mp4"
                                align="center"
                                once={true}
                            />
                        )}
                        <Parallax offset={-40} duration={-140} classList="z-index--2">
                            <ImageBlock image="/images/end-to-end/concept-card-2.png"
                                        image2x="/images/end-to-end/concept-card-2@2x.png"
                                        classList="wireframe end-to-end__concept-card--2"
                                        width={650}
                                        alt="Concept card 2"
                                        align="right"
                                        once={true}
                            />
                        </Parallax>
                        <Parallax offset={-120} duration={-90}>
                            <ImageBlock image="/images/end-to-end/concept-card-3.png"
                                        image2x="/images/end-to-end/concept-card-3@2x.png"
                                        classList="wireframe end-to-end__concept-card--3"
                                        width={460}
                                        alt="Concept card 3"
                            />
                        </Parallax>
                    </div>
                    <TextBlock
                        classList="margin-xl--bottom"
                        caption="In numbers"
                        headingClassList="no-margin"
                        align="center"
                        fade={false}
                        stats={[{title: 'User need statements', stat: '42'}, {
                            title: 'Concept cards',
                            stat: '16'
                        }, {title: 'Participants', stat: '10'}]}
                    />
                    <TextBlock
                        caption="Session 8"
                        title="Decision Making"
                        text="We started discussing the created concepts while putting them on an Impact/Effort Matrix. The first items to address were in the high impact - low effort quadrant, which I only highlighted after we finished evaluating our concepts. Of course, after I revealed those were the quick wins, a couple of concept cards started moving around the board, opening a new conversation to re-evaluate priorities."
                        shifted
                    />
                    <Reveal transitionDirection="bottom" colour="white">
                        {isMobile ? (
                            <VideoBlock
                                classList="end-to-end__video"
                                mp4="/videos/end-to-end/prioritisation-matrix.mobile.mp4"
                                align="center"
                            />
                        ) : (
                            <VideoBlock
                                classList="end-to-end__video"
                                webm="/videos/end-to-end/prioritisation-matrix.webm"
                                mp4="/videos/end-to-end/prioritisation-matrix.min.mp4"
                                align="center"
                            />
                        )}
                    </Reveal>
                </section>

                <div id="day-5">
                    <section className="section section-bg--white no-padding--top no-padding--bottom">
                        <ChapterTitle title="Day 5"/>
                        <TextBlock
                            caption="Session 9"
                            title="User Stories"
                            text="As most of the discovery team was new to software development, we briefly introduced the Agile methodology, our preferred way to deliver the product. I wanted to ensure everyone understood why user stories are essential to the process."
                            align="center"
                        />
                    </section>
                    <section className="section section-bg--white no-padding">
                        <DrawPath
                            classList="end-to-end__day5-draw"
                            width={1440}
                            height={120}
                            paths={[
                                {
                                    path: 'M1439 134.25V111C1185.34 124.341 932.501 116.245 707.517 98.6695C392.848 74.0874 132.676 30.9604 1 2L1 134.25',
                                    color: 'var(--primary-color)',
                                    fill: 'var(--color-grey-25)'
                                }
                            ]}
                        />
                    </section>
                    <section className="fill--grey-25 section no-padding--top no-padding--bottom">
                        <div className="container">
                            <div className="container container--max end-to-end__day5-agile">
                                <div className="padding-md--top padding-lg--bottom">
                                    <TextBlock
                                        classList="margin-xl--bottom"
                                        caption="Agile manifesto snippet"
                                        textClassList="heading heading--3"
                                        text="Our highest priority is to satisfy the customer through early and continuous delivery of valuable software."
                                        shifted
                                        container="800"
                                    />
                                    <div className="container container--1000">
                                        <div className="grid grid--auto">
                                            <div className="grid__row">
                                                <div className="grid__column">
                                                    <TextBlock
                                                        caption="Story writing techniques"
                                                        text="<ul class='list list--disc'><li>Non-technical language</li><li>Each story should bring value to our users</li><li>Always write the stories from the user's perspective.</li><li>Deliver value sooner rather than later</li><li>Title, description and acceptance criteria</li></ul>"
                                                    />
                                                </div>
                                                <div className="grid__column">
                                                    <TextBlock
                                                        caption="INVEST in stories"
                                                        text="<ul><li><strong>I</strong>ndependent - can be developed in order</li><li><strong>N</strong>egotiable - open the conversation</li><li><strong>V</strong>aluable - value added to the product</li><li><strong>E</strong>stimable - relative sizing</li><li><strong>S</strong>mall - to be completed fast</li><li><strong>T</strong>estable - must be validated</li></ul>"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section no-padding">
                        <DrawPath
                            classList="end-to-end__day5-draw"
                            width={1440}
                            height={120}
                            paths={[
                                {
                                    path: 'M1 0L1 11.3118C609.425 -20.5966 1213.18 70.4754 1439 120V0',
                                    color: 'var(--primary-color)',
                                    fill: 'var(--color-grey-25)'
                                }
                            ]}
                        />
                    </section>
                    <section className="section no-padding--top no-padding--bottom">
                        <TextBlock
                            caption="Behind the stories"
                            text="We worked on the user stories but skipped creating user journeys at this project stage, as we felt like the stories were well connected and we needed to ensure we made best use of the time available."
                            shifted
                            container="600"
                        />
                        {isMobile ? (
                            <VideoBlock
                                classList="end-to-end__video"
                                mp4="/videos/end-to-end/user-stories.mobile.mp4"
                                align="center"
                            />
                        ) : (
                            <VideoBlock
                                classList="end-to-end__video"
                                webm="/videos/end-to-end/user-stories.webm"
                                mp4="/videos/end-to-end/user-stories.min.mp4"
                                align="center"
                            />
                        )}
                    </section>
                </div>

                <div id="next-steps">
                    <ChapterTitle title="Next Steps"/>
                    <section className="section">
                        <TextBlock
                            classList="margin-lg--bottom"
                            caption="Final session"
                            title="Great results"
                            text="It was a journey for everyone. After summarising the past five days we revisited the <strong>Hopes & Fears</strong> board to see how many of those clusters we addressed. The engagement from the engineers, agents, and team leads during the discovery was amazing, and they genuinely liked to be involved with the sometimes long and steamed conversation."
                            shifted
                            outerContainer="800"
                            container="700"
                        />
                        <TextBlock
                            classList="margin-hg--bottom margin-sm-md--bottom"
                            caption="Future ready"
                            title="Mindset shift"
                            text="Many assumptions from the managers were debunked as the result of our shopping floor discovery, which was clear proof of why it was better to base a new product on user research instead of just requirements, as it happened so many times in the past in BT."
                            shifted
                            align="right"
                            outerContainer="800"
                            container="700"
                        />
                        {/*REFACTOR - this carousel component can be smarter and more reusable if needed*/}
                        <Carousel classList="container--600">
                            <SwiperSlide key="testimonial-1" className="testimonial">
                                <div className="carousel__content">
                                    <p className="margin--bottom testimonial__quote">This type of exercise was new to me, but I feel super pleased now that I could be a part of shaping our future tools.</p>
                                    <p className="testimonial__author">Jenny</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide key="testimonial-2" className="testimonial">
                                <div className="carousel__content">
                                    <p className="margin--bottom testimonial__quote">It was really interesting to see how a product like this gets planned. I am looking forward to getting my hands on the prototype.</p>
                                    <p className="testimonial__author">Adam</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide key="testimonial-3" className="testimonial">
                                <div className="carousel__content">
                                    <p className="margin--bottom testimonial__quote">First, I felt like I was being tested with those questions about my work, but I soon realised that we were all in the same boat at the end of the day. </p>
                                    <p className="testimonial__author">Paul</p>
                                </div>
                            </SwiperSlide>
                        </Carousel>
                    </section>
                </div>

                <section id="wireframes" className="section section--framed no-padding--top">
                    <ChapterTitle title="Wireframes"/>
                    <TextBlock
                        classList="margin-xl--bottom"
                        caption="Selection"
                        text="These are just a few mockups of the dozens of pages and features I created wireframes for based on our workshop. I tested these with users and changed them based on their feedback."
                        align="center"
                        container="700"
                    />
                    <TextBlock
                        caption="Please note"
                        text="I tried my best to showcase the designs, but bear in mind that I had to change them due to a signed non-disclosure agreement."
                        align="right"
                        container="500"
                    />
                    <div className="container">
                        <div className="images">
                            <div className="images__column">
                                <Parallax offset={-20} duration={-30} classList="images__item">
                                    <ImageBlock
                                        caption="Ticket listings"
                                        imageClassList="wireframe"
                                        image="/images/end-to-end/wireframe-tickets.png"
                                        image2x="/images/end-to-end/wireframe-tickets@2x.png"
                                        alt="Tickets wireframe"
                                        width={700}
                                        visibleAmount={.1}
                                        once
                                    />
                                </Parallax>
                                <Parallax offset={10} duration={-40}>
                                    <ImageBlock
                                        caption="Role editor"
                                        imageClassList="wireframe"
                                        image="/images/end-to-end/wireframe-permissions.png"
                                        image2x="/images/end-to-end/wireframe-permissions@2x.png"
                                        alt="Permissions wireframe"
                                        width={600}
                                        visibleAmount={.1}
                                        once
                                        align="right"
                                    />
                                </Parallax>
                            </div>
                            <div className="images__column hide--on-sm">
                                <Parallax offset={-10} duration={90} classList="images__item">
                                    <ImageBlock
                                        imageClassList="wireframe"
                                        image="/images/end-to-end/wireframe-notes.png"
                                        image2x="/images/end-to-end/wireframe-notes@2x.png"
                                        alt="Notes wireframe"
                                        width={300}
                                        visibleAmount={.1}
                                        once
                                        align="right"
                                    />
                                </Parallax>
                            </div>
                        </div>
                        <Parallax offset={20} duration={40}>
                            <ImageBlock
                                caption="Dashboard"
                                imageClassList="wireframe"
                                image="/images/end-to-end/wireframe-dashboard.png"
                                image2x="/images/end-to-end/wireframe-dashboard@2x.png"
                                alt="Dashboard wireframe"
                                width={850}
                                visibleAmount={.1}
                                once
                                align="right"
                            />
                        </Parallax>
                        <Parallax offset={90} duration={-30}>
                            <ImageBlock
                                caption="Ticket details"
                                imageClassList="wireframe"
                                image="/images/end-to-end/wireframe-ticket.png"
                                image2x="/images/end-to-end/wireframe-ticket@2x.png"
                                alt="Ticket wireframe"
                                width={900}
                                visibleAmount={.1}
                                once
                                align="center"
                            />
                        </Parallax>
                    </div>
                </section>

                <div id="product-design">
                    <section className="section overflow-hidden no-padding-sm--inline">
                        <RollingText baseVelocity={-1}>
                            Web <b className="outline">Same</b> UX <b className="outline">Same</b> UI <b className="outline">Similar</b> Coffee <b className="outline">Research</b>&nbsp;
                        </RollingText>
                        <RollingText baseVelocity={1}>
                            Web <b className="outline">Product</b> UX <b className="outline">Design</b> UI <b className="outline">Product</b> Web <b className="outline">Design</b>&nbsp;
                        </RollingText>
                    </section>
                    <section className="section">
                        <TextBlock
                            caption="Reusable components"
                            title="Creating a Design System"
                            text="At the time we started the design phase, our client did not have a design system or UI library we could use to base our work on, so I created a library in Sketch (yeah, we used Sketch first before converting to Figma) which eventually was the first milestone of the road to a BT Design System."
                            align="center"
                            container="700"
                        />
                    </section>
                    <div className="end-to-end__design-systems">
                        <div className="images container container--1064 no-padding-sm--block">
                            <div className="images__column section no-padding--top no-padding--bottom">
                                <Parallax offset={-30} duration={30} classList="images__item">
                                    <ImageBlock
                                        image="/images/end-to-end/sketch-ds.png"
                                        image2x="/images/end-to-end/sketch-ds@2x.png"
                                        alt="Sketch Design System"
                                        width={500}
                                        visibleAmount={.1}
                                        once
                                    />
                                </Parallax>
                            </div>
                            <div className="images__column section no-padding--top no-padding--bottom">
                                <Parallax duration={40} classList="images__item margin-hg--top margin-sm-sm--top z-index--1">
                                    <ImageBlock
                                        classList="margin-auto--left margin-auto--right"
                                        image="/images/end-to-end/ds-arrow.svg"
                                        alt="DS Transform"
                                        width={121}
                                        visibleAmount={.1}
                                        once
                                    />
                                </Parallax>
                                <Parallax offset={-10} duration={80} classList="images__item">
                                    <ImageBlock
                                        image="/images/end-to-end/figma-ds.png"
                                        image2x="/images/end-to-end/figma-ds@2x.png"
                                        alt="Figma Design System"
                                        width={500}
                                        visibleAmount={.1}
                                        once
                                    />
                                </Parallax>
                            </div>
                        </div>
                        <ImageBlock
                            classList="end-to-end__design-systems-bg"
                            image="/images/end-to-end/ds-bg.png"
                            image2x="/images/end-to-end/ds-bg@2x.png"
                            alt="BT Design Systems"
                            visibleAmount={.1}
                            once
                        />
                    </div>
                    <section className="section section--framed no-padding-sm--top">
                        <TextBlock
                            classList="margin-xl--bottom"
                            caption="Making it pretty"
                            title="UI Design"
                            text="I created high-fidelity designs and prototypes based on the wireframes. I conducted user tests by asking users to complete scenarios and objectives using the prototypes. We were keen to let the users know that the tool we are creating is for them and emphasised that this is a safe place where they could come up with questions, ideas and requests anytime."
                            container="700"
                            shifted
                        />
                        <div className="container container--1000">
                            <div className="row">
                                <div className="col margin-sm-md--bottom">
                                    {isMobile ? (
                                        <VideoBlock
                                            classList="end-to-end__video--design end-to-end__video--design-rounded box-shadow--heavy"
                                            mp4="/videos/end-to-end/design-dashboard.mobile.mp4"
                                        />
                                    ) : (
                                        <VideoBlock
                                            classList="end-to-end__video--design end-to-end__video--design-rounded box-shadow--heavy"
                                            webm="/videos/end-to-end/design-dashboard.webm"
                                            mp4="/videos/end-to-end/design-dashboard.min.mp4"
                                            width={800}
                                            fade={false}
                                        />
                                    )}
                                </div>
                            </div>
                            {isMobile ? (
                                <div className="row margin-sm-md--bottom">
                                    <div className="col justify-content--flex-end">
                                        <VideoBlock
                                            classList="end-to-end__video--design end-to-end__video--design-rounded box-shadow--heavy"
                                            mp4="/videos/end-to-end/design-tickets.mobile.mp4"
                                            width={800}
                                            fade={false}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <Parallax offset={-200} duration={50}>
                                    <div className="row margin-sm-md--bottom">
                                        <div className="col justify-content--flex-end">
                                            <VideoBlock
                                                classList="end-to-end__video--design end-to-end__video--design-rounded box-shadow--heavy"
                                                webm="/videos/end-to-end/design-tickets.webm"
                                                mp4="/videos/end-to-end/design-tickets.min.mp4"
                                                width={800}
                                                fade={false}
                                            />
                                        </div>
                                    </div>
                                </Parallax>
                            )}
                        </div>
                        <div className="container">
                            {/*AUTO COLUMNS*/}
                            <div className="row">
                                {isMobile ? (
                                    <>
                                        <VideoBlock
                                            classList="end-to-end__video--design end-to-end__video--design-rounded box-shadow--heavy"
                                            mp4="/videos/end-to-end/design-permissions.mobile.mp4"
                                        />
                                        <VideoBlock
                                            classList="end-to-end__video--design end-to-end__video--design-rounded box-shadow--heavy margin-lg--top"
                                            mp4="/videos/end-to-end/design-notes.mobile.mp4"
                                        />
                                    </>
                                ) : (
                                    <>
                                        <Parallax offset={20} duration={50} classList="height--full margin-sm-md--bottom">
                                            <VideoBlock
                                                classList="end-to-end__video--design end-to-end__video--design-rounded box-shadow--heavy"
                                                webm="/videos/end-to-end/design-permissions.webm"
                                                mp4="/videos/end-to-end/design-permissions.min.mp4"
                                                width={680}
                                                fade={false}
                                            />
                                        </Parallax>
                                        <Parallax offset={-40} duration={70} classList="height--full margin-xl--top no-margin-sm--top margin-auto--left margin-lg--right margin-sm-md--bottom">
                                            <VideoBlock
                                                classList="end-to-end__video--design end-to-end__video--design-rounded box-shadow--heavy"
                                                webm="/videos/end-to-end/design-notes.webm"
                                                mp4="/videos/end-to-end/design-notes.min.mp4"
                                                width={300}
                                                fade={false}
                                            />
                                        </Parallax>
                                    </>
                                )}
                            </div>
                            <div className="row justify-content--center">
                                <Parallax offset={20} duration={-50} classList="height--full">
                                    <ImageBlock
                                        image="/images/end-to-end/design-ticket.png"
                                        image2x="/images/end-to-end/design-ticket@2x.png"
                                        classList="end-to-end__video--design end-to-end__video--design-rounded box-shadow--heavy margin-sm-md--top"
                                        alt="Ticket"
                                        visibleAmount={.1}
                                        width={800}
                                        once
                                    />
                                </Parallax>
                            </div>
                        </div>
                    </section>
                </div>
            </motion.div>
            <NextProject nextProject='Quick Pay'/>
        </>
    )
}

export default EndToEnd;
