import React from "react";
import { Helmet } from 'react-helmet-async';

const Error404 = () => {
    return (
        <>
            <Helmet>
                <title>404</title>
            </Helmet>
            <section className="section">
                <div className="container container--small">
                    <h1 className="hero__title heading heading--1">404</h1>
                    <p className="align--center">This is not the page you are looking for... or I messed up.. anyway here is a <a href="/" className="link">link to start over</a></p>
                </div>
            </section>
        </>
    );
}

export default Error404;
