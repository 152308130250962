import React, {useRef, useEffect} from "react";
import {motion, useAnimation, useInView} from "framer-motion";
import {verticalScrollOffsetMarginLarge} from "./TransitionConstants";
import {isMobile} from 'react-device-detect';

function Reveal({children, transitionDirection, addBoxShadow, classList, noMask, once, colour}) {
    const controls = useAnimation();
    const ref = useRef(null);

    isMobile ? once = true : once;

    const isInView = useInView(ref, { margin: verticalScrollOffsetMarginLarge, once: once});

    // Animation control
    useEffect(() => {
        if (isInView) {
            controls.start("visible");
        } else {
            controls.start("hidden");
        }
    }, [controls, isInView]);

    const animateMaskStyles = {
        left: {
            hidden: {
                translateX: noMask ? '100%' : 0,
                transition: {
                    duration: .25,
                    ease: 'easeOut'
                }
            },
            visible: {
                translateX: '100%',
                transition: {
                    duration: .5,
                    ease: 'easeInOut'
                }
            }
        },
        right: {
            hidden: {
                translateX: noMask ? '100%' : 0,
                transition: {
                    duration: .25,
                    ease: 'easeOut'
                }
            },
            visible: {
                translateX: '-100%',
                transition: {
                    duration: .5,
                    ease: 'easeInOut'
                }
            }
        },
        top: {
            hidden: {
                translateY: noMask ? '100%' : 0,
                transition: {
                    duration: .25,
                    ease: 'easeOut'
                }
            },
            visible: {
                translateY: '-100%',
                transition: {
                    duration: .5,
                    ease: 'easeInOut'
                }
            }
        },
        bottom: {
            hidden: {
                translateY: noMask ? '100%' : 0,
                transition: {
                    duration: .25,
                    ease: 'easeOut'
                }
            },
            visible: {
                translateY: '100%',
                transition: {
                    duration: .5,
                    ease: 'easeInOut'
                }
            }
        },
        fade: {
            hidden: {
                opacity: 1,
                transition: {
                    duration: .25,
                    ease: 'easeOut'
                }
            },
            visible: {
                opacity: 0,
                transition: {
                    duration: .5,
                    ease: 'easeInOut'
                }
            }
        }
    }

    const addBoxShadowVariants = {
        hidden: {
            boxShadow: '0px 0px 0px rgba(0,0,0,0)',
        },
        visible: {
            boxShadow: '10px 15px 60px rgba(0,0,0,.1)',
            transition: {
                delay: .5,
                duration: .3
            }
        }
    }

    return (
        <>
            {isMobile ? (
                <div ref={ref} className={`${classList ? 'reveal ' + classList : "reveal"}`}>
                    {children}
                </div>
            ) : (
                <motion.div ref={ref} animate={controls} variants={addBoxShadow ? addBoxShadowVariants : ''} className={`${classList ? 'reveal ' + classList : "reveal"}`}>
                    <motion.div
                        className="reveal__mask"
                        style={{
                            backgroundColor: colour ? colour : ''
                        }}
                        animate={controls} variants={transitionDirection ? animateMaskStyles[transitionDirection] : animateMaskStyles.right}
                    >
                        &nbsp;
                    </motion.div>
                    {children}
                </motion.div>
            )}
        </>
    )
}

export default Reveal;
