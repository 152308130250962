import React, {useRef, useEffect} from 'react';
import {
    motion,
    useScroll,
    useSpring,
    useTransform,
    useMotionValue,
    useVelocity,
    useAnimationFrame, useInView, useAnimation
} from "framer-motion";
import { wrap } from "@motionone/utils";

interface Props {
    children: string;
    baseVelocity: number;
}

export default function RollingText({ children, classList, baseVelocity = 100 }: ParallaxProps) {
    const baseX = useMotionValue(0);
    const { scrollY } = useScroll();
    const scrollVelocity = useVelocity(scrollY);
    const smoothVelocity = useSpring(scrollVelocity, {
        damping: 50,
        stiffness: 400
    });
    const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
        clamp: false
    });
    const ref = useRef(null);
    const isInView = useInView(ref, {});
    const controls = useAnimation();
    let running = 1;

    /**
     * This is a magic wrapping for the length of the text - you
     * have to replace for wrapping that works for you or dynamically
     * calculate
     */
    const x = useTransform(baseX, (v) => `${wrap(-20, -45, v)}%`);

    const directionFactor = useRef(1);

    useAnimationFrame((t, delta) => {
        let moveBy = directionFactor.current * baseVelocity * (delta / 1000) * running;

        /**
         * This is what changes the direction of the scroll once we
         * switch scrolling directions.
         */
        if (velocityFactor.get() < 0) {
            directionFactor.current = -1;
        } else if (velocityFactor.get() > 0) {
            directionFactor.current = 1;
        }

        moveBy += directionFactor.current * moveBy * velocityFactor.get();

        baseX.set(baseX.get() + moveBy);
    });

    useEffect(() => {
        if (isInView) {
            running = 1;
        } else {
            running = 0;
        }
    }, [controls, isInView]);


    /**
     * The number of times to repeat the child text should be dynamically calculated
     * based on the size of the text and viewport. Likewise, the x motion value is
     * currently wrapped between -20 and -45% - this 25% is derived from the fact
     * we have four children (100% / 4). This would also want deriving from the
     * dynamically generated number of children.
     */
    return (
        <motion.div ref={ref} className={`rolling-text ${classList ? classList : ""}`}
                    hidden="hidden"
                    visible="visible"
                    animate={controls}
                    variants={{
                        hidden: {
                            visibility: 'hidden'
                        },
                        visible: {
                            visibility: 'visible'
                        }
                    }}
        >
            <motion.div className="rolling-text__container" style={{ x }}>
                <span>{children}</span>
                <span>{children}</span>
                <span>{children}</span>
                <span>{children}</span>
            </motion.div>
        </motion.div>
    );
}
