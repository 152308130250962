import React from "react";
import Icons from '../icons/sprite.svg';
import PropTypes from 'prop-types';

const Icon = ({ name, color, size, classList }) => (
    <svg className={`icon icon-${name} ${classList ? classList : ""}`} fill={color} width={size} height={size}>
        <use xlinkHref={`${Icons}#${name}`} />
    </svg>
);

Icon.propTypes = {
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    size: PropTypes.number
};

export default Icon;
