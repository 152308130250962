export default function breakToLetters(string) {
    const str = string.trim();
    const length = str.length;
    let retArr = [];
    for (let i = 0; i < length; i++) {
        if (str[i] === ' ') {
            retArr[retArr.length - 1] += ' ';
            continue;
        }
        retArr.push(str[i]);
    }
    return retArr;
}
