import React from "react";
import { useParams } from "react-router-dom";

const Post2 = () => {
    const { id, lang } = useParams();

    return (
        <>
            {lang === "hu"
                ?
                    <article className="blog__article">
                        <hgroup className="blog__article-header">
                            <h2 className="heading heading--3 no-margin--bottom">Mi történik a világgal?</h2>
                        </hgroup>
                        <p>Nincs az az érzésed, hogy túl okosak vagyunk? Nem azért de kétlem, hogy erről van szó. Sőt, pont fordítva van. Az egész kultúránk a butításra épült, csak már úgy megszoktuk, hogy megvédjük akármennyire is fullba kell nyomni a kretént. Mint a Mátrix alvó ügynökei. Mintha a múlt század végén túl tolták volna az oktatást, ami ma már túl veszélyes a gyíkemberek szerint. Legalábbis, így tudok csak pár dolgot megmagyarázni. Le kellett állni az okosodással, és az enyém előtti generáció lehetett ennek a leállásnak a prototípusa. Mi vagyunk a történelem tompikái. </p>
                        <p className="spotify box no-padding--top">
                            <iframe
                                src="https://open.spotify.com/embed/track/59WN2psjkt1tyaxjspN8fp?utm_source=generator&theme=0"
                                width="100%" height="152" frameBorder="0" allowFullScreen=""
                                className="no-margin--top"
                                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                loading="lazy"></iframe>
                        </p>
                        <blockquote>Honnan ismered fel ha valaki vegán? … onnan, hogy elmondja<span>Valami Hentes</span></blockquote>
                        <p>Ha a vegán szó említése is már startból felbasz, akkor nyugodtan engedd el az olvasást, nyisd ki az ablakot, szívj egy kis friss levegőt. Minden rendben van, relax. De ha mondjuk tényleg így van és megfigyeled a jelenlegi érzésedet, és összeveted azzal mi történt, egy biztos: én nem mondtam meg mit csinálj (az ablak nyitást leszámítva). Nem tukmálok semmit, nem dolgom hogyan élsz. A vegánságra zúdoló szervezett és csúcsra tolt lejáratás tehet erről az érzésről.</p>
                        <blockquote>Mintha folyton beszolnának, meg elvennék a vacsimat, megazéletem ezek a hipszter csicska vegások!<span>Mr/Mrs Ucca Embör</span></blockquote>
                        <p>Az előítélet olyan, hogy majdnem mindig téves, de elég ha egyszer nem, és akkor már igazzá válik. Na nem mintha beképzelt lennék annyira, hogy tudnám mi az igazság, de ez más téma. Felismerem viszont a baromságot, és ritkán hiszek el bármit anélkül, hogy megvizsgáljam kitől/miből ered egy állítás. Véleményre meg kb sosem adok.</p>
                        <p>Az indította el ezt az írást, hogy láttam egy új sorit a Netflixen. Yeah, yeah, ok, tudom, a filmeket színezik, de az alapjáról, illetve, hogy az hogyan kapcsolódik hozzám, arról akarok mesélni. Ez egy doku sorozat aminek a címe “You Are What You Eat: A Twin Experiment” (Az vagy amit megeszel: Az ikerkísérlet) - <a href="https://www.netflix.com/title/81133260" target="_blank">Netflix link</a>.</p>
                        <p>Vegán propaganda tulajdonképpen ikrekkel (elég sok kapcsolódásom van ehhez). Persze csak etetem a trollokat, mert olyan, hogy vegán propaganda nem létezik. Nem lehet propaganda valami amiről nagyrészt csak önkéntes aktivistáktól hallasz (nincs ilyen film azért sokszor). Nem egy szervezett intézmény ez, mint pl, a húsipar, tejipar, állatartás stb. amik állami támogatásokból tudják megvásárolható áron tartani a termékeiket, és amik fixen fentartanak propagandát (got milk?). A vegánság nem csak a kajáról szól, hanem lélekről, fentarthatóságról, együtt élésről a kék bolygón.</p>
                        <p>Valamelyest jártas vagyok a növény evés témában (mondjuk még csak 6 éve vagyok benne), sokat hallottam/olvastam érvelni mellette/ellene. Van viszont egy összefüggés ami nagyon tetszett a soriban, ami visszakanyarít az elején felvetett tömeges butaság témához.</p>
                        <p>Nem tudom vágod-e, de volt egy időszak a nagy háborúk után, amikor az orvosok (finanszírozással persze), a politikussok azt vallották a dohányzásról, hogy nem rákkeltő, nyugodtan szívjad, jó az.  Pontosabban a legtöbb mondat olyasmi volt, hogy nincs összefüggés a rák meg smokk közt, de nem kell szőrszálhasogatni. Miközben ez ment, voltak a kutatók, tudósok (nem mindig ugyanaz a két dolog) akik rámentek a témára, és hát mit ad isten, nem az az eredmény jött ki, amit a reklám nyomott. De kb soha. Ezt persze ma már tudjuk, de több mint tíz év telt el úgy, hogy minden kutatási eredményt bukott a bagó, mégsem történt változás, nem léptek fel ellene. Aztán egy ponton túl már nem lehetett görbíteni az igazságot, és kimondta a politika, az orvosok, minden, hogy nem mehet tovább, hogy pl 5 éves gyerekek veszik meg szívják a cigit, mert megöli őket. Nem kell naívnak lenni, nem sajnálta meg az embereket senki, csak már túl nyilvánvaló volt hazudni, az meg népszerűség vesztéshez vezet, és esetleg felfed egyéb más hazugságokat.</p>
                        <p>Azért talált be ez a párhuzam, mert a húsevés a korunk bagólya (amit hasonlóan ki is mondanak a filmben). A tudomány már húsz éve arra jutott (és azóta több tízezer kutatás), hogy a bolygónak a legjobb lenne ha mindenki növényeket enne, és, hogy egy kiegyensúlyozott növényi étrend minden korcsoportnak, minden élet helyzetben megfelelő és egészséges (babának, kismamának, kőművesnek, influenszernek, neked stb).</p>
                        <p><strong>Mi tartja akkor vissza az embereket, hogy változtassanak?</strong></p>
                        <p>Ez egy összetett kérdés mert sok, különböző motiváció fékez be embereket, hogy rálépjenek erre az útra. Hogy valahogy mégis próbáljak választ adni erre, lerombolni néhány mentál blokkot, beodobok egy pár kérdést, amit már hallottam (mint minden vegán) ezerszer. Remélem sikerül legalább egy valamit, egy tévhitet kivasalnom benned. Megjegyzem, hogy nem minden válasz saját, olyan értelemben, hogy lehet van amit hallottam és megtetszett. Utalok is majd pénzt a titkos-illuminátus-szabad-zöldművesek szereveztnek, hogy fentartsam a vegán propagandát. Ennek az egésznek a végén lesz egy lista könyvekről, filmekről, csatornákról ha elmélyednél a miértekben.</p>
                        <h2 className="heading heading--3">
                            <span className="heading__sub align--center display--block">
                                Biológia
                            </span>
                        </h2>
                        <ul className="list display--block list--disc">
                            <li>
                                <p>
                                    <strong>Húsevő fogunk van, az véletlen lenne?</strong><br />
                                    Nincs az, van általában 4 “hegyes” szemfogunk amik nem olyan hosszúak és élesek mint pl egy macska ragadozó fogazata. Az egy vadászatra, nyershús evésére való, húsevő protkó. Azért van az a hegyes fogunk, hogy beletudjunk harapni pl egy almába (átszúrjuk a héját), Illetve a kaját segítenek a helyén tartani rágás közben. Ha tök sima lenne a fogsorunk nehezebben tudnánk belekezdeni az evésbe, csak örlődnénk (ha-ha).
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>A tej kell a csontoknak abból van a kálcium.</strong><br />
                                    Ha ez igaz, akkor vajon miért követi a tej reklámot a kálcium tabletta reklám a tv-ben? Amennyi tejterméket fogyasztunk átlagosan hallanunk sem kéne csonttörésről, ritkulásról stb. Tudományosan a mennyiségi tej fogyasztás növeli a vér savasságát, ami miatt a test a csontokból kálciumot von el ennek a savasságnak a csökkentésére, elég ironkikusan. Azt még hozzátenném a témához, hogy az anyatej szopizás abbahagyása után nem kell tej. Semelyik kifejlett állat nem megy rá másik állat tejére, az emberen kívül.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Csak a húsból lehet megszerezni a fehérjét ami kell te ropi.</strong><br />
                                    Ha ez igaz volna akkor a gorilla nagyon pici állat lenne, mert nem eszik csak növényeket. A hús egy olyan fehérje forrás ami úgy keletkezik, hogy növényi fehérjét fogyaszt egy állat (pl szója babot), aztán feldolgozza, beépíti a testébe ami kell, ami nem azt kiszarja, stb. Ha kihagyod ezt a proceszt, akkor ugyanúgy eheted a növényi fehérjét mint egy tehén, oposszum vagy gorilla. Amúgy nagyon ritka a fehérje hiányos ember, a vegák között is (én nem is hallottam még ilyenről).
                                </p>
                            </li>
                        </ul>
                        <h2 className="heading heading--3">
                            <span className="heading__sub align--center display--block">
                                Környezetismeret
                            </span>
                        </h2>
                        <ul className="list display--block list--disc">
                            <li>
                                <p>
                                    <strong>Ha mindenki magokat meg zöldet zabálna akkor lekéne gyalulni a bolygót, hogy bevessük termőföldnek.</strong><br />
                                    A fonákom erre az, hogy az állatok amit az ember megeszik többnyire növényevők. Vajon melyik eszik több növényt, pl. egy tehén vagy egy ember? A tehén. Ebből következik ha mindenki növényt enne (és nem lenne állattartás) sokkal kevesebb területre lenne szükség a termeléshez. A teljes esőerdőt és még igen sok tarlót helyre lehetne állítani.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>A vegánok avokádója meg a szója tejük miatt vágják ki az esőerdőket.</strong><br />
                                    Összefügg az előzővel, csak annyit, hogy a megtermelt szója 70-80 százaléka takarmány. A fakivágások első számú oka már évtizedek óta, a takarmány termelés. Máshogy mondva a teljes esőerdőt lassan beletömik a mekis burgerekbe (meg minden más húsos akármibe)
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Ne gyere nekem a környezettel mikor a múltkor is elrepültél Indiába, a repülés sokkal rosszabb mint a húsevés.</strong><br />
                                    Igaz, hogy a légi utazás belekoszol az ember környezeti lábnyomába de csúnyán. Viszont az is tény, hogy az állattartásnak önmagában nagyobb bolygó gyilkos mércéje van mint a teljes közlekedési szektornak (autó, repülés, teher hajók, minden). Nem lesz a fosszilis energia égetés sosem megfelelő a környezetre nézve, de nem az utazás a fő probléma. Ettől függetlenül jogos és a vegánság része, hogy minél tudatosabban éljük az életünk.
                                </p>
                            </li>
                        </ul>
                        <h2 className="heading heading--3">
                            <span className="heading__sub align--center display--block">
                                Kultúra és egyéb mesék
                            </span>
                        </h2>
                        <ul className="list display--block list--disc">
                            <li>
                                <p>
                                    <strong>Az ember évezredek óta eszi a húst, az evolúciónk része, így domináltuk le a táplálékláncot.</strong><br />
                                    Egy személyes megvilágosodásom volt mikor rájöttem közöm nincs a Aldis benejlonozott csirkemellel a tápláléklánchoz. Attól nem leszünk csúcsragadozók, hogy valahol a távolban, egy eldugott helyen, valami gyártósoron, egy robot kés átvágja egy csirke torkát, aztán egy héttel később kivesszük egy hűtőből. Máshogy, gépek etetnek minket. Tény, hogy rég esszük a húst, de az is tény, hogy volt időszak, hogy muszály volt azt enni a túléléshez. Most is van persze ilyen hely a bolygón. Te viszont, nem vagy ilyen helyen (és valószínűleg sose voltál). Nekünk ez egy döntés, és millió opciónk van mást tenni/enni mint 3000 éve egy embernek.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>A hagyományunk része a gulyás, a húsevés, és azt meg kell őrizni.</strong><br />
                                    A hagyomány is igényli olykor a megújjulást. Volt, hogy szokás volt a gyereket leküldeni cigiért. Volt, hogy hagyomány volt, hogy a nők nem szavazhattak. Egy drasztikusabb példa, hogy a hagyomány része volt sok helyen a bolygón szolgákat tartani, embereket adni/venni. Vagy a nőket megcsonkítani. Csak azért mert valami hagyományos még nem jelenti, hogy kérdés nélkül követendő. Döntéseken élünk.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>A növények is éreznek, láttam valahol, szóval azzal mi van te kis pöcsöm szentfazék?</strong><br />
                                    Legyen, éreznek. Mivel már leszögeztük, hogy egy tehén (illetve a legtöbb nagytestű állat) sokkal de sokkal több növényt eszik mint te, csak jót teszel ha nem eszel húst, kevesebb növény fog szenvedni. Ugyanez igaz a sajnálatos áldozatairól a mezőgazdaságnak, ha kevesebbet kell termelni, kevesebb lesz a probléma.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Hogyan lehet egészéges sőt természetes a vegánság, mikor szedni kell a B12-t?</strong><br />
                                    A B12 a földből jön, és mi nem eszünk földet. Amikor legel egy állat, felnyalja, így kerül a szervezetébe. A húsipar is tolja az állatokba a B12-t táplálékkiegészítőként mivel ők sem jutnak hozzá már ehhez természetes módon, mert nem legelnek (meg már nincs elegendő a földekben). Szóval közvetve de a húsevő is szedi kiegészítőként, pont úgy mintha pl zabtejet iszol amibe feltehetőleg tettek, ahogy a legtöbb vega termékbe raknak. Szóval belehet szedni, de ha sok vegán kész terméket tolsz lehet nem is kell szedni (persze érdemes időnként megnézetni mi van, de ez igaz midnenkire mindegy mit eszik). Fontos újra megismételni, a vegán (és bármilyen) diéata úgy a legmegfelelőbb ha tudatos, kiegyensúlyozott.
                                </p>
                            </li>
                        </ul>
                        <h2 className="heading heading--3">
                            <span className="heading__sub align--center display--block">
                                Morál és más
                            </span>
                        </h2>
                        <ul className="list display--block list--disc">
                            <li>
                                <p>
                                    <strong>Ezek az állatok azért vannak tenyésztve, hogy megegyük őket. </strong><br />
                                    Ez nem a te döntésed volt, pláne nem az övék. Csak mert beleszülettek ebbe a terrorba, ez nem tesz igazságot. Egy állat komplex érzésekkel bír, személyisége van akár csak neked, nekem, tervez, élni akar. Ez egy olyan érvelés a húsevés mellett amiből már kihipózták az érzést. Hiányzik belőle a nyers bemutatása annak mi zajlik egy vágóhídon. Nincs ott olyan állat aki azt gondolja, “de jó én erre születtem”.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Az iparág kifejlesztett egy humánus módot, hogy az állatokat megöljék. </strong><br />
                                    Nem tudom neked mit jelent a humánus szó. Talán azt, hogy fájdalom mentesen ölni. Ed Winters akitől rengeteget tanultam a témáról (tisztelet), nagyon jól keretezi be ezt a gondolatot. Ha objektíven nézzük a humánus az, a szó definiciója szerint, aki másokkal szemben jóindulatú, jóságos, kedves, elnéző, könyürületes. A kérdés az, hogy hogyan lehet megölni valakit aki nem akar meghalni, akinek nem kell meghallnia, könyürületesen, jóságosan? Panelprolisabban szólva, ha cukormáz van a szaron attól még az szar. Nincs humánus gyilkosság (esetleg a tudatos eutanáziát ide lehet venni, de a tudatoson van a hangsúly, ha ez illető meg akar halni és ebben segítséget kér).
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Akkor te most azt mondod, hogy egy csirke az egyenlő egy emberrel?</strong><br />
                                    Azt mondom, hogy minden porcikámmal ellenzem a felsőbbrendűség gondolatát, ami számtalanszor taszította az embert a leggonosszabb, legsötétebb korokhoz. Nekem nem kell legyen semmilyen műveleti jel élőlények között. Ez pedig lehet nem fog bejönni neked, de azt sem tartom okénak, hogy állatok akiket mi szelídítettünk meg, jogok nélkül, alárendelve élnek, hogy például egy hím csibét át lehet a születése után pár perccel tolni egy darálón.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>De ha lesz gyereked az normális lesz ugye? Az eszik majd husit rendesen? Vagy legalább halat…</strong><br />
                                    Nem, nem lesz normális gyerekem az kurva élet. A hal pedig ugyanúgy hús.
                                </p>
                            </li>
                        </ul>
                        <h2 className="heading heading--3">
                            <span className="heading__sub align--center display--block">
                                Egyebek
                            </span>
                        </h2>
                        <ul className="list display--block list--disc">
                            <li>
                                <p>
                                    <strong>A tehén tejet termel, és jól is esik neki ha megfejik, mert fáj tőle a tőgye.</strong><br />
                                    A tehén azért termel tejet mert életet hozott a világra, akárcsak ahogy az embernél van ez (és minden emlősnél). Ha nem szül egy nő akkor nincs teje. A tejipari teheneket megtermékenyítik (megerőszakolják, csak cukormázasabban), és amint megszületik a kicsinyük, elveszik tőlük és soha nem láthatják őket többé. Ezután lefejik a tejüket oké, de ezt azért nem tartom egy hősies dolognak, gondolom te sem.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Mondhatsz akármit mert ez olyan mint a kávé, egyszer egészséges aztán máskor rákot okoz…</strong><br />
                                    A vegán étrend nem olyan mint a kávé. Ahogy az elején említettem a tudomány szerint, egy kiegyensúlyozott vegán étrend mindenkinek egészséges, kicsitől nagyokig. Minden dietetikus szövetség egyetért ebben, annak ellenére, hogy az állatartás lobbi hatalmas nyomást képes gyakorolni mindenre. Alul vannak linkek, nézd át, de röviden, a vegán étrend jó neked, a bolygónak, az állatoknak.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>A világ összes pénze nem elég a vegán kajára, nem tehetem meg anyagilag.</strong><br />
                                    A vegán kaja nem drágább, és nem, nem élek buborékban. A kész étel, étterem vonalon még nem igaz ez mindig (bár az amúgy is k drága most mindenből), de már versenyképes árak vannak az utóbbi években. Mivel a fehérje mindig az alap érv (mindenki testtépítő úgy látszik) egy kiló csirke mellet kicsit több mint két kiló lencsével lehet fehérjéből fedezni, persze cserébe sokkal kevesebb (és jobb minőségű) zsírt is viszel be, több tápanyagot, meg pár dolgot nem (pl antibiotikum, tartósítószer stb.). Ha nem a legolcsóbb húst választja az ember akkor ez nagyjából egy árban van, és mennyiségre sem rossz. Extraként pedig az előállításból következő, környezetre gyakorolt káros hatása a lencsének a tizede a csirkéhez képest. Bár egyre több a processzált vegán dolog mint például felvágott, ha nem csak ilyeneken él az ember, hanem teljes értékű növényi dolgokon is (zöldség, gyümölcs, bab stb), meg lehet hasonló összegből oldani az étkezést mint egy hús fogyasztó. Ez csak adalék, de már említettem, hogy a húsipart a legtöbb ország költségvetési pénzből illetve adókedvezményekkel támogatja. Az, hogy a hús ára annyi amennyi, az egy mesterséges dolog, egy lufi. Az, hogy ez miért működik így arról pedig érdemes kutakodni, ide ez túl hosszú lenne.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Az íz a lényeg, a nyúlkaja meg nem finom</strong><br />
                                    Szerintem finom, szerintem nem kostolt sok mindent aki ilyet mond. Nem mondom, hogy a hús evést azért hagytam fel mert nem ízlett, sokan nem, de lehetetlennek tartom, hogy ne találjon az ember a vegán kaják között ízeket, mikor több is van bennük mint bármi másban.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>A szabadtartásos, és helyi élelmiszer az sokkal fentarhatóbb és jobb, mint az importált banánod vagy avokádód te vérvegán</strong><br />
                                    A szabadtartásos a csomagoláson az olyan, mint amikor “otthon készült” jelzőt látsz egy étterem menüjén. Nem az nem otthon készül, az egy étteremben készül, és nem, az az állat nem szabad, csak a gyár megfelel a jogalkotók feltételeinek, például, hogy néhány centivel (nem többel) több helyen van az állat a másik gyárban lévőhöz képest. Az pedig, hogy valami helyi, nem garancia arra, hogy jobb a környezeti lábnyoma mint valaminek ami import. Gondolok például egy üvegházas paradicsomra mondjuk Angliában ami összevetve egy spanyol import paradicsommal, sosem lesz jobb a környezetnek, a szállítást beleszámolva.
                                </p>
                            </li>
                        </ul>
                        <p>Remélem segített amit leírtam és, hogy nem mindennel nem értessz egyet. Sok részét nem vizsgáltam a témának mert hát ez egy suttyó blog, de lehet, hogy ideje letenni a korunk cigijét, a húst.</p>
                        <p>Van az életben sok szokás amit felveszünk, és az agyunknak ez kényelmet jelent. Ha ezt a kényelmet valami fenyegeti akkor ellenállásba kezd az agy, viszont egy valami biztos: még mindig jobb néha felülvizsgálni mit miért teszünk, mint eszetlenül neki csapódni a szélvédőnek amikor közelítenek a nehezebb idők. Változás lesz akármit csinálunk ma, mert a holnap és a létszükség azt diktálja. Viszont minden bizonnyal felgyorsíthatjuk a megújulás folyamatát, csökkenthetjük az éghajlatváltozás veszélyét és annak jelenünkre és jövőnkre gyakorolt hatásait a döntéseinkkel.</p>
                        <p>A legnagyobb elérhető személyi eredmény amit a bolygóért tehetsz, hogy elköszönsz a húsevéstől. Nem vagy egyedül, nem vagy tehetetlen, és itt egy adag segítség a témában (ezek általam kipróbált dolgok):</p>
                        <p><strong>Filmek</strong></p>
                        <ul className="list display--block list--disc">
                            <li>
                                <p>
                                    Az vagy, amit eszel: Az ikerkísérlet (eredeti cím: You are what you eat: A twin experiment
                                    <br /><a href="https://www.netflix.com/title/81133260" target="_blank">Netflix link</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    Földlakók (eredeti cím: Earthlings)
                                    <br /><a href="https://indavideo.hu/video/Foldlakok_-_Earthlings_-_dokumentumfilm" target="_blank">Indavideo link magyar felirattal</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    Mit jelent az egészség? (eredeti cím: What the health)
                                    <br /><a href="https://www.netflix.com/title/80174177" target="_blank">Netflix link</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    Cowspiracy: A fenntarhatóság titka (eredeti cím: Cowspiracy)
                                    <br /><a href="https://www.netflix.com/title/80033772" target="_blank">Netflix link</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    A fenntarható halászat valódi arca (eredeti cím: Seaspiracy)
                                    <br /><a href="https://www.netflix.com/title/81014008" target="_blank">Netflix link</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    Sportforradalmárok (eredeti cím: The game changers)
                                    <br /><a href="https://www.netflix.com/title/81157840" target="_blank">Netflix link</a>
                                </p>
                            </li>
                        </ul>
                        <p><strong>Youtube videók és csatornák</strong></p>
                        <ul className="list display--block list--disc">
                            <li>
                                <p>
                                    Saját vegán recept playlistem
                                    <br /><a href="https://www.youtube.com/playlist?list=PL_9sLIDBroBeSLlK2jMJeYlycPKS5t_ox" target="_blank">Youtube link</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    Gary Yuorofsky beszéde 2010-ben
                                    <br /><a href="https://www.youtube.com/watch?v=es6U00LMmC4" target="_blank">Youtube link</a> (van magyar felirat)
                                </p>
                            </li>
                            <li>
                                <p>
                                    Earthling Ed AKA Ed Winters Youtube csatornája
                                    <br /><a href="https://www.youtube.com/@ed.winters" target="_blank">Youtube link</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    Yeung Man Cooking recept csatorna (bírom ezt a tagot nagyon)
                                    <br /><a href="http://youtube.com/wilyeung" target="_blank">Youtube link</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    Vegán Blog recept csatorna (magyar cucc)
                                    <br /><a href="https://www.youtube.com/@VeganReceptekVeganBlog" target="_blank">Netflix link</a>
                                </p>
                            </li>
                        </ul>
                        <p><strong>Könyvek</strong></p>
                        <ul className="list display--block list--disc">
                            <li>
                                <p>
                                    Varga Balázs - Fehérjemítosz
                                    <br /><a href="https://bookline.hu/product/home.action?_v=Varga_Balazs_Feherjemitosz&type=22&id=300398" target="_blank">Bookline link</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    Mike Berners-Lee: There is no planet B (angol nyelvű könyv)
                                    <br /><a href="https://bookline.hu/product/home.action?_v=Berners_Lee_Mike_Lancaster_University_&type=200&id=5582578" target="_blank">Bookline link</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    Mike Berners-Lee: How Bad are Bananas? The Carbon Footprint of Everything (angol nyelvű könyv)
                                    <br /><a href="https://bookline.hu/product/home.action?_v=Mike_Berners_Lee_How_Bad_are_Bananas_T&type=20&id=3985261" target="_blank">Bookline link</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    Ed Winters - This Is Vegan Propaganda - (And Other Lies the Meat Industry Tells You) (angol nyelvű könyv)
                                    <br /><a href="https://bookline.hu/product/home.action?_v=Winters_Ed_This_Is_Vegan_Propaganda&type=200&id=6138034" target="_blank">Bookline link</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    Ed Winters - How to Argue With a Meat Eater (And Win Every Time)
                                    <br /><a href="https://bookline.hu/product/home.action?_v=Winters_Ed_How_to_Argue_With_a_Meat_Ea&type=200&id=6311544" target="_blank">Bookline link</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    Steiner Kristóf recept könyvek
                                    <br /><a href="https://bookline.hu/szerzo/steiner-kristof/21285?page=1&gad_source=1&gclid=Cj0KCQiAw6yuBhDrARIsACf94RUU2sC8Bv33HkeMHSZc1fNunhrMA7NGm52VPNphW_RWC-BRz7_H-IsaApdUEALw_wcB" target="_blank">Bookline link</a>
                                </p>
                            </li>
                        </ul>
                        <p>Ez a lista arra jó, hogy elindulj. Nem ajánlok itt semmi olyat amit nem ismerek, emiatt persze ha már benne vagy ebben, hiányolhatsz róla dolgokat. Ha esetleg bármit hozzátennél, vagy segíthetek még tippekkel, bármivel, dobj egy emailt (<a href="mailto:itsme@zoltanbank.com">itsme@zoltanbank.com</a>) azt csacsógjunk, <strong>csumi</strong>.</p>
                    </article>
                :
                    <>
                        <article className="blog__article">
                            <hgroup className="blog__article-header">
                                <h2 className="heading heading--3 no-margin--bottom">What's happening with the world?</h2>
                            </hgroup>
                            <p>Ever get the feeling we're too smart? Not to be a doubter, but I'm sceptical that's the case. Quite the opposite, actually. Our whole culture's built on dumbing down to the point that we've gotten so used to defending it, no matter how much we have to go full retard. Like agents of the Matrix in sleep mode. It's as if they overdid the education bit at the end of the last century, and now, according to the lizard people, it's way too dangerous. That's the only way I can explain some things. We had to put a stop to getting smarter, and the generation before mine might just have been the prototype for this dumbdown. We're the dumbers of history.</p>
                            <p className="spotify box no-padding--top">
                                <iframe
                                    src="https://open.spotify.com/embed/track/59WN2psjkt1tyaxjspN8fp?utm_source=generator&theme=0"
                                    width="100%" height="152" frameBorder="0" allowFullScreen=""
                                    className="no-margin--top"
                                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                    loading="lazy"></iframe>
                            </p>
                            <blockquote>How do you know someone's vegan? … they'll tell you<span>Some Butcher</span></blockquote>
                            <p>If merely mentioning 'vegan' already pisses you off, feel free to stop reading, open a window, and take a deep breath of fresh air. All is well, relax. But if you're seriously feeling this way and you reflect on your current state and what happened, one thing's for sure: I didn't tell you what you should do (apart from suggesting you open a window). I'm not shoving anything down your throat. How you live is none of my business. The organised and hyped-up smear campaign against veganism is probably to blame for this feeling.</p>
                            <blockquote>It's like they're always dissing me, shaming my dinner, ruining my life, these dipshit hipster vegans!<span>Mr/Mrs Street Peaps</span></blockquote>
                            <p>Prejudice is such that it's almost always wrong, but it only takes one time to be right, and then it becomes the “truth”. Not that I'm so ego-centric to claim I know any truth, but that's another story. However, I do recognise bullshit and rarely believe anything without investigating its source. And I hardly ever take an opinion seriously.</p>
                            <p>What kicked off this piece was a new series I saw on Netflix. Yeah, yeah, I know, movies are coloured, but I want to talk about its basis and how it relates to me. It's a docu-series titled “You Are What You Eat: A Twin Experiment”. <a href="https://www.netflix.com/title/81133260" target="_blank">Netflix link</a>.</p>
                            <p>Vegan propaganda, essentially with twins (I have quite a connection to that). Sure, I'm just feeding the trolls because I believe that vegan propaganda doesn't exist. You mostly hear about it from voluntary activists (hence the lack of such films often). It's not an organised institution like the meat, dairy, or animal farming industries, which maintain their products at affordable prices through state subsidies and consistently run propaganda (got milk?). Veganism isn't just about food; it's about the soul, sustainability, and coexistence in peace on this blue planet.</p>
                            <p>I'm somewhat wired in the topic of plant-based eating (I’ve been at it for about six years now), and I’ve heard and read many arguments for and against it. But I really liked one correlation in the series, bringing us back to the theme of widespread stupidity.</p>
                            <p>I’m not sure if you know this, but there was a time after the big wars when doctors (backed by funding, of course) and politicians proclaimed smoking wasn't cancer-causing, urging you to smoke away, no harm done. More accurately, most statements were something like, “There's no connection between cancer and smoking”, but don’t be a smartass. While this was going on, there were researchers and scientists (not always the same thing) who delved into the subject, and surprise, surprise, the findings were never what the ads were pushing. Like, ever. We know this now, but it took over a decade with every piece of research against smoking had the same results, yet no changes were made, and no stand was taken against it. Then, at some point, it became impossible to twist the truth any further, and politics, doctors, and everyone declared it couldn't continue, that 5-year-olds buying and smoking cigarettes because it would kill them. You don't have to be naive; nobody felt sorry for the people; it was just too obvious to lie about anymore without a loss of popularity and potentially exposing other lies.</p>
                            <p>This parallel struck a chord in me because meat-eating is our era's smoking (as also stated in the film). Science has concluded for more than twenty years now (with thousands of studies since) that the planet would be better off if everyone ate plants and that a balanced plant-based diet is suitable and healthy for every age group, in every life situation (babies, pregnant women, bricklayers, influencers, you, etc.).</p>
                            <p><strong>What's holding people back from changing?</strong></p>
                            <p>It's a complex question because various motivations stop people from getting on this path. To attempt an answer and break down a few mental blocks, I'll throw in a couple of questions I've heard (like every vegan) a thousand times. I plan to iron out at least one misconception in you. Note that not all answers are mine because I might have heard and liked some. I'll also be donating money to the secret vegan Illuminati to keep up the vegan propaganda (this was a joke). At the end of all this, there'll be a list of books, movies, and channels if you're keen on diving deeper into the whys.</p>
                            <h2 className="heading heading--3">
                            <span className="heading__sub align--center display--block">
                                Biology
                            </span>
                            </h2>
                            <ul className="list display--block list--disc">
                                <li>
                                    <p>
                                        <strong>We have carnivorous teeth. Is that a coincidence?</strong><br />
                                        No, it's not, and no, we don’t. We have 4 "pointy" canines that aren't as long and sharp as, say, a cat's predatory dentition, meant for hunting and eating raw meat. The reason for our pointy teeth is to be able to bite into, for instance, an apple (piercing its skin) and to help keep the food in place while chewing.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Milk is necessary for bones because that's where calcium comes from.</strong><br />
                                        If true, why does a milk ad follow a calcium supplement ad on TV? With the average dairy consumption, we shouldn't even hear about fractures, osteoporosis, etc. Scientifically, milk consumption can increase the acidity of the blood, which causes the body to leach calcium from the bones to reduce this acidity, quite ironically. After finishing our mother's milk, there's no need for milk. No adult animal drinks another animal's milk except humans.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>You can only get the protein you need from meat.</strong><br />
                                        If that were true, gorillas would be very tiny creatures since they only eat plants. Meat is a source of protein that originates from an animal consuming plant protein (like soybeans), then processes it, incorporates what's needed into its body, and shits the rest, etc. If you skip this process, you can consume plant protein like a cow, opossum, or gorilla. Moreover, protein deficiency is very rare, even among vegans (I've never even heard of such a case).
                                    </p>
                                </li>
                            </ul>
                            <h2 className="heading heading--3">
                            <span className="heading__sub align--center display--block">
                                Environmental Science
                            </span>
                            </h2>
                            <ul className="list display--block list--disc">
                                <li>
                                    <p>
                                        <strong>If everyone started munching on seeds and greens, we'd have to bulldoze the planet to make space for crops.</strong><br />
                                        My counter to this is that the animals we eat are primarily herbivores. Which consumes more plants, a cow or a human? The cow. Hence, if everyone ate plants (and there was no animal farming), much less land would be needed for production. Entire rainforests and many fields could be restored for wildlife.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Vegans' avocados and soy milk are why rainforests are being cut down.</strong><br />
                                        This ties back to the previous point, just that 70-90% of the soy produced is for feeding animals. The primary reason for deforestation for decades has been feed production. In other words, the entire rainforest is slowly being stuffed into McDonald's burgers (and everything else meaty).
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Don't come at me with environmental stuff when you flew to India just the other day; flying is way worse than eating meat.</strong><br />
                                        True, air travel significantly dirties one's environmental footprint. However, it's also true that animal farming has a bigger planetary killer metric than the entire transportation sector (cars, flights, cargo ships, all of it). Fossil fuel combustion will never be okay for the environment, but travel isn't the main issue. That said, being mindful is part and parcel of veganism.
                                    </p>
                                </li>
                            </ul>
                            <h2 className="heading heading--3">
                            <span className="heading__sub align--center display--block">
                                Culture and Other stuff
                            </span>
                            </h2>
                            <ul className="list display--block list--disc">
                                <li>
                                    <p>
                                        <strong>Humans have been eating meat for thousands of years; it's part of our evolution and how we dominated the food chain.</strong><br />
                                        I had an epiphany when I realised I had no connection with my wrapped chicken breast from Aldi to the food chain. We don't become apex predators because somewhere far away, in a hidden and classified spot on a production line, a robot blade cuts a chicken's throat, and then we take it out of a fridge a week later. In other words, machines feed us. True, we've been eating meat for ages, but there were times when it was necessary for survival. There are still such places on the planet. But you, you're not in such a place (and likely never have been). For us, it's a choice, and we have a million options to do/eat something different than a person had 3000 years ago.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Our tradition includes goulash, meat-eating, and we must preserve that.</strong><br />
                                        Tradition sometimes needs renewal. There was a time when it was customary to send kids to buy cigarettes. It was a tradition that women couldn't vote. A more drastic example, in many places on the planet, it was traditional to keep slaves to buy/sell people. Or to mutilate women. Just because something is traditional doesn't mean it's unquestionably right. We live on decisions.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Plants feel too; I saw it somewhere, so what about that, you saintly lil’ prick?</strong><br />
                                        Okay, let's say they feel. Since we've established that a cow (and most large animals) eats way more plants than you do, by not eating meat, you're actually doing a good deed, lessening plant suffering. The same goes for the unfortunate victims of agriculture; less production means fewer problems.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>How can veganism be healthy, let alone natural, when you have to take B12 supplements?</strong><br />
                                        B12 comes from the soil, and we don't eat dirt. When an animal grazes, it licks up B12, thus entering its system. The meat industry also pumps B12 into animals as a supplement since they no longer get it naturally due to not grazing (and the soil's depletion). So indirectly, meat-eaters are also taking supplements, just like if you were drinking, for instance, B12 fortified oat milk, as most vegan products are supplemented with B12. So, you can take it, but if you consume many vegan processed foods, you might not need to (though it's wise to check your levels occasionally, true for everyone regardless of diet). It's important to reiterate that a vegan (and any) diet is healthy when it is well-balanced.
                                    </p>
                                </li>
                            </ul>
                            <h2 className="heading heading--3">
                            <span className="heading__sub align--center display--block">
                                Morals and More
                            </span>
                            </h2>
                            <ul className="list display--block list--disc">
                                <li>
                                    <p>
                                        <strong>These animals are bred to be eaten.</strong><br />
                                        That wasn't your decision, certainly not theirs. Just because they were born into this terror doesn't make it justified. An animal has complex feelings, a personality just like you and me, plans, and wants to live. This is a line of reasoning for meat-eating that's already had the feeling edited out. It lacks the raw presentation of what happens in a slaughterhouse. There's no animal there thinking, “Oh good, I was born for this.”
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>The industry has developed a humane way to kill animals.</strong><br />
                                        I'm not sure what 'humane' means to you. Perhaps it means killing without pain. Ed Winters, from whom I've learned a lot on this topic (respect), frames this thought well. Objectively, 'humane', by definition, means being compassionate, kind, gentle, merciful towards others. The question is, how do you kill someone who doesn't want to die, who doesn't need to die mercifully, kindly? Putting it more bluntly, if there's icing on shit, it's still shit. There's no such thing as humane killing (except maybe conscious euthanasia, but the emphasis is on 'conscious' if the person wants to die and asks for help).
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>So, are you saying a chicken is equal to a human?</strong><br />
                                        I'm saying, with every fiber of my being, I oppose the idea of superiority, which has led humanity into the darkest, most evil times, again and again. I don't need any operational signs between living beings. This might not sit well with you, but I also don't see a reason why animals we've domesticated, living without rights, and subjugated; for example, male chicks, can be shoved into a grinder minutes after being born.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>But if you have kids, they'll be normal, right? Will they eat meat properly? Or at least fish…</strong><br />
                                        No, there’s not a slim fucking chance that my kids will be 'normal'. And fish is still meat.
                                    </p>
                                </li>
                            </ul>
                            <h2 className="heading heading--3">
                            <span className="heading__sub align--center display--block">
                                Other Bits
                            </span>
                            </h2>
                            <ul className="list display--block list--disc">
                                <li>
                                    <p>
                                        <strong>Cows produce milk, and it feels good for them to be milked because their udders hurt.</strong><br />
                                        A cow produces milk because it has brought life into the world, just as with humans (and all mammals). If a woman doesn't give birth, she doesn't have milk. Dairy cows are impregnated (raped without sugar coating), and as soon as their calves are born, they're taken away, never to see them again. Then their milk is milked, okay, but I wouldn't call that a heroic act, and I suppose neither would you.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>You can say whatever because this is like coffee; one minute it's healthy, the next it causes cancer…</strong><br />
                                        The vegan diet isn't like coffee. As mentioned at the beginning, according to science, a balanced vegan diet is healthy for everyone, from the youngest to the oldest. All the major dietetic association agrees, despite the immense pressure the animal farming lobby can exert on everything. Links are below, but in short, the vegan diet is good for you, the planet, and the animals.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>All the money in the world isn't enough for vegan food; I can't afford it.</strong><br />
                                        Vegan food isn't more expensive, and no, I'm not living in a bubble. The ready-meals and restaurants might not always hold true (though that's expensive for all diets right now), but competitive prices have emerged in recent years. Since protein is always the main argument (everyone seems to be a bodybuilder), a kilo of chicken breast can be replaced with a little more than two kilos of lentils in terms of protein in exchange for much less (and better quality) fat, more nutrients, and a few things not included (like antibiotics, preservatives, etc.). If you don't choose the cheapest meat, then it's roughly the same price, and the quantity isn't bad either. Additionally, the environmental impact of producing lentils is a fraction of that of chicken. Although there's more and more processed vegan stuff like deli slices, if you don't live off these alone but also eat whole plant foods (vegetables, fruits, beans, etc.), you can manage your meals for a similar amount as a meat-eater. This is just an extra note, but I've mentioned before that most countries subsidize the meat industry from the taxpayer’s money and tax breaks. The fact that meat costs what it does is an artificial thing. Why this works the way it does is worth investigating, but that's too long to get into here.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>It's all about the taste, and rabbit food just isn't tasty.</strong><br />
                                        I think it's delicious, and I reckon those who say that haven't tasted much. I'm not saying I gave up meat because it didn't taste good; many don't, but I find it impossible not to find flavours in vegan foods when they contain more than anything else, in my experience.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Free-range and local food is much more sustainable and better than your imported bananas or avocados, you bloody vegan.</strong><br />
                                        Free-range on packaging is like seeing "homemade" on a restaurant menu. It's not made at home; it's made in a restaurant, and no, that animal isn't free, just that the factory meets the legislators' requirements, for example, by having the animal a few centimetres (not more) more space compared to another factory. And just because something is local doesn't guarantee it has a better environmental footprint than something imported. Think of a greenhouse-grown tomato in England compared to an imported Spanish tomato; even with transportation included, the former will never be better for the environment.
                                    </p>
                                </li>
                            </ul>
                            <p>I hope what I've written helps and that you don't disagree with everything. I haven't examined many aspects of the topic because, well, this is just a crappy blog, but the point is: it's probably the right time to put down our era's cigarette, the meat.</p>
                            <p>There are many habits we adopt in life, and they provide comfort to our brains. When something threatens this comfort, the brain starts to resist, but one thing is certain: it's still better to occasionally reassess why we do what we do rather than mindlessly crashing into the windshield when tough times approach. Change will happen no matter what we do today because tomorrow and necessity dictate it. But we surely can speed things up to reduce the threat of climate change and its effects on our present and our future with our actions.</p>
                            <p>The greatest personal achievement you can make for the planet is to say goodbye to meat-eating. You're not alone, you're not powerless, and here's a bunch of help on the topic (things I've tried myself):</p>
                            <p><strong>Movies</strong></p>
                            <ul className="list display--block list--disc">
                                <li>
                                    <p>
                                        You are what you eat: A twin experiment
                                        <br /><a href="https://www.netflix.com/title/81133260" target="_blank">Netflix link</a>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Earthlings
                                        <br /><a href="https://indavideo.hu/video/Foldlakok_-_Earthlings_-_dokumentumfilm" target="_blank">Indavideo link</a>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        What the health
                                        <br /><a href="https://www.netflix.com/title/80174177" target="_blank">Netflix link</a>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Cowspiracy
                                        <br /><a href="https://www.netflix.com/title/80033772" target="_blank">Netflix link</a>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Seaspiracy
                                        <br /><a href="https://www.netflix.com/title/81014008" target="_blank">Netflix link</a>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        The game changers
                                        <br /><a href="https://www.netflix.com/title/81157840" target="_blank">Netflix link</a>
                                    </p>
                                </li>
                            </ul>
                            <p><strong>Youtube videos and channels</strong></p>
                            <ul className="list display--block list--disc">
                                <li>
                                    <p>
                                        My own vegan cooking playlist
                                        <br /><a href="https://www.youtube.com/playlist?list=PL_9sLIDBroBeSLlK2jMJeYlycPKS5t_ox" target="_blank">Youtube link</a>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Gary Yuorofsky's speach in 2010
                                        <br /><a href="https://www.youtube.com/watch?v=es6U00LMmC4" target="_blank">Youtube link</a>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Earthling Ed AKA Ed Winters's Youtube channel
                                        <br /><a href="https://www.youtube.com/@ed.winters" target="_blank">Youtube link</a>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Yeung Man Cooking recipe channel (I like this dude a great deal)
                                        <br /><a href="http://youtube.com/wilyeung" target="_blank">Youtube link</a>
                                    </p>
                                </li>
                            </ul>
                            <p><strong>Books</strong></p>
                            <ul className="list display--block list--disc">
                                <li>
                                    <p>
                                        Varga Balázs - Fehérjemítosz (hungarian book)
                                        <br /><a href="https://bookline.hu/product/home.action?_v=Varga_Balazs_Feherjemitosz&type=22&id=300398" target="_blank">Bookline link</a>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Mike Berners-Lee: There is no planet B
                                        <br /><a href="https://bookline.hu/product/home.action?_v=Berners_Lee_Mike_Lancaster_University_&type=200&id=5582578" target="_blank">Bookline link</a>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Mike Berners-Lee: How Bad are Bananas? The Carbon Footprint of Everything
                                        <br /><a href="https://bookline.hu/product/home.action?_v=Mike_Berners_Lee_How_Bad_are_Bananas_T&type=20&id=3985261" target="_blank">Bookline link</a>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Ed Winters - This Is Vegan Propaganda - (And Other Lies the Meat Industry Tells You)
                                        <br /><a href="https://bookline.hu/product/home.action?_v=Winters_Ed_This_Is_Vegan_Propaganda&type=200&id=6138034" target="_blank">Bookline link</a>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Ed Winters - How to Argue With a Meat Eater (And Win Every Time)
                                        <br /><a href="https://bookline.hu/product/home.action?_v=Winters_Ed_How_to_Argue_With_a_Meat_Ea&type=200&id=6311544" target="_blank">Bookline link</a>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Steiner Kristóf's recipe books
                                        <br /><a href="https://bookline.hu/szerzo/steiner-kristof/21285?page=1&gad_source=1&gclid=Cj0KCQiAw6yuBhDrARIsACf94RUU2sC8Bv33HkeMHSZc1fNunhrMA7NGm52VPNphW_RWC-BRz7_H-IsaApdUEALw_wcB" target="_blank">Bookline link</a>
                                    </p>
                                </li>
                            </ul>
                            <p>This list is a good starting point. I don't recommend anything here that I'm not familiar with, so of course, if you're already into this, you might find things missing. If you'd like to add anything or if I can help with more tips, drop me an email (<a href="mailto:itsme@zoltanbank.com">itsme@zoltanbank.com</a>) and let's chat. Peas out.</p>
                        </article>
                    </>
            }
        </>
    );
}

export default Post2;
