import React from 'react';
import {motion} from "framer-motion";
import {isMobile} from 'react-device-detect';
import { Helmet } from 'react-helmet-async';

import Hero from "../components/Hero";
import Reveal from "../transitions/Reveal";
import Parallax from "../transitions/Parallax";
import TextBlock from "../components/TextBlock";
import ChapterTitle from "../components/ChapterTitle";
import pageTransition, {pageTransitionVariants} from "../components/PageTransition";
import NextProject from "../components/NextProject";
import ImageBlock from "../components/ImageBlock";
import VideoBlock from "../components/VideoBlock";
import Carousel from "../components/Carousel";
import {SwiperSlide} from "swiper/react";
import DrawPath from "../components/DrawPath";
import RollingText from "../components/RollingText";
import ChaptersMenu from "../components/ChaptersMenu";

const QuickPay = () => {
    return (
        <>
            <Helmet>
                <title>Product Design: Quick Pay</title>
            </Helmet>
            <motion.div
                className="page project quick-pay"
                variants={pageTransitionVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
            >
                <ChaptersMenu chapters={[
                    {id: 'pitch-deck', title: 'Pitch Deck'},
                    {id: 'the-app', title: 'The App'},
                    {id: 'landing-page', title: 'Landing Page'},
                ]} />

                <Hero
                    title="Quick Pay"
                    captionRightTitle="Type"
                    captionRightMeta="Concept Design"
                    image="../images/quick-pay/hero-quick-pay.png"
                    image2x="../images/quick-pay/hero-quick-pay@2x.png"
                />

                <section className="section">
                    <div className="container container--1000">
                        <div className="row">
                            <div className="col-lg-8 col-sm-12">
                                <TextBlock
                                    caption="The project"
                                    text="Quick Pay was made not just to validate a product idea but to revolutionise QR code payments in Europe and the US. Despite its vast success, totalling over $10 billion in Asia, this efficient payment method is underutilised in Western markets.<br /><br />Businesses and consumers now have the opportunity to choose a charity close to their hearts. With each transaction, Quick Pay contributes a percentage to the selected charity, a distinctive feature that transforms routine payments into acts of philanthropy, all while sidestepping the traditional Visa/Mastercard fees.<br /><br />Beyond its charitable focus, Quick Pay emerges as a cost-effective alternative to traditional card payments. By avoiding hefty Visa/Mastercard fees, Quick Pay facilitates the growth of small businesses, providing an economic edge in the market.<br /><br /><strong>Redefine the payment landscape, one charitable transaction at a time.</strong>"
                                    shifted
                                />
                            </div>
                            <div className="col-lg-4 col-sm-12">
                                <TextBlock
                                    caption="Responsibilities"
                                    classList="margin-hg--top margin-sm-sm--top"
                                    text="<ul><li>Conducting Research</li><li>Product Strategy</li><li>Product Design</li><li>Web Design</li><li>Branding</li><li>Artifacts</li></ul>"
                                    align="right"
                                    shifted
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <section id="pitch-deck" className="section no-margin--bottom no-padding--bottom quick-pay__pitch-section">
                    <ChapterTitle title="Pitch Deck"/>
                    <TextBlock
                        caption="Sliding slides"
                        textClassList="heading heading--3"
                        text="The design of a pitch deck slide is crucial as it serves as the visual gateway to an idea, influencing the audience's perception and engagement. This is the first step to establish credibility."
                        container="1000"
                        align="center"
                    />
                    <section className="section overflow-hidden no-padding-sm--inline">
                        <RollingText baseVelocity={-.5}>
                            <img width="740" src="/images/quick-pay/2.jpg" srcSet="/images/quick-pay/2.jpg 1x, /images/quick-pay/2@2x.jpg 2x" />
                            <img width="740" src="/images/quick-pay/3.jpg" srcSet="/images/quick-pay/3.jpg 1x, /images/quick-pay/3@2x.jpg 2x" />
                            <img width="740" src="/images/quick-pay/4.jpg" srcSet="/images/quick-pay/4.jpg 1x, /images/quick-pay/4@2x.jpg 2x" />
                            <img width="740" src="/images/quick-pay/5.jpg" srcSet="/images/quick-pay/5.jpg 1x, /images/quick-pay/5@2x.jpg 2x" />
                            <img width="740" src="/images/quick-pay/6.jpg" srcSet="/images/quick-pay/6.jpg 1x, /images/quick-pay/6@2x.jpg 2x" />
                            <img width="740" src="/images/quick-pay/8.jpg" srcSet="/images/quick-pay/8.jpg 1x, /images/quick-pay/8@2x.jpg 2x" />
                        </RollingText>
                        <RollingText classList="rolling-text--rev margin" baseVelocity={.3}>
                            <img width="740" src="/images/quick-pay/7.jpg" srcSet="/images/quick-pay/7.jpg 1x, /images/quick-pay/7@2x.jpg 2x" />
                            <img width="740" src="/images/quick-pay/1.jpg" srcSet="/images/quick-pay/1.jpg 1x, /images/quick-pay/1@2x.jpg 2x" />
                            <img width="740" src="/images/quick-pay/9.jpg" srcSet="/images/quick-pay/9.jpg 1x, /images/quick-pay/9@2x.jpg 2x" />
                            <img width="740" src="/images/quick-pay/10.jpg" srcSet="/images/quick-pay/10.jpg 1x, /images/quick-pay/10@2x.jpg 2x" />
                            <img width="740" src="/images/quick-pay/11.jpg" srcSet="/images/quick-pay/11.jpg 1x, /images/quick-pay/11@2x.jpg 2x" />
                            <img width="740" src="/images/quick-pay/12.jpg" srcSet="/images/quick-pay/12.jpg 1x, /images/quick-pay/12@2x.jpg 2x" />
                        </RollingText>
                    </section>
                </section>

                <section id="the-app" className="section quick-pay__app-section">
                    <ChapterTitle title="The App"/>
                    <div className="container container--1000 quick-pay__app-container">
                        <div className="grid grid--2-columns">
                            <div className="grid__row">
                                <div className="grid__column grid__column--full">
                                    <TextBlock
                                        caption="Mobile first"
                                        captionClassList="margin-md--bottom"
                                        textClassList="heading heading--3"
                                        text="The goal is not to revolutionise the mobile payment experience with QR codes but to ensure swift transactions, enhanced security, and a visually easy journey for our users, seasoned with valuable features to track payments."
                                        shifted
                                        container="600"
                                    />
                                </div>
                                <div className="grid__column grid__column--auto justify-content-sm--center">
                                    {isMobile ? (
                                        <ImageBlock
                                            classList="quick-pay__mobile-image quick-pay__mobile-image--medium"
                                            image="/images/quick-pay/mobile-splash.png"
                                            image2x="/images/quick-pay/mobile-splash@2x.png"
                                            alt="Splash Screen"
                                            align="center"
                                            visibleAmount={.1}
                                        />
                                    ) : (
                                        <Parallax offset={0} duration={80}>
                                            <ImageBlock
                                                classList="quick-pay__mobile-image quick-pay__mobile-image--medium"
                                                image="/images/quick-pay/mobile-splash.png"
                                                image2x="/images/quick-pay/mobile-splash@2x.png"
                                                alt="Splash Screen"
                                                align="center"
                                                visibleAmount={.1}
                                            />
                                        </Parallax>
                                    )}
                                </div>
                            </div>
                            <div className="grid__row">
                                <div className="grid__column padding-xl--left no-padding-sm--left  justify-content-sm--center">
                                    {isMobile ? (
                                        <ImageBlock
                                            classList="quick-pay__mobile-image quick-pay__mobile-image--large"
                                            image="/images/quick-pay/mobile-home.png"
                                            image2x="/images/quick-pay/mobile-home@2x.png"
                                            alt="Home Screen"
                                            align="center"
                                            visibleAmount={.1}
                                        />
                                    ) : (
                                        <Parallax offset={-120} duration={20}>
                                            <ImageBlock
                                                classList="quick-pay__mobile-image quick-pay__mobile-image--large"
                                                image="/images/quick-pay/mobile-home.png"
                                                image2x="/images/quick-pay/mobile-home@2x.png"
                                                alt="Home Screen"
                                                align="center"
                                                visibleAmount={.1}
                                            />
                                        </Parallax>
                                    )}
                                </div>
                                <div className="grid__column grid__column--full padding-xl--left">
                                    {isMobile ? (
                                        <ImageBlock
                                            classList="quick-pay__mobile-image quick-pay__mobile-image--medium"
                                            image="/images/quick-pay/mobile-payment-2.png"
                                            image2x="/images/quick-pay/mobile-payment-2@2x.png"
                                            alt="Payment Screen 2"
                                            align="center"
                                            visibleAmount={.1}
                                        />
                                    ) : (
                                        <Parallax classList="z-index--2" offset={0} duration={-80}>
                                            <ImageBlock
                                                classList="quick-pay__mobile-image quick-pay__mobile-image--medium"
                                                image="/images/quick-pay/mobile-payment-2.png"
                                                image2x="/images/quick-pay/mobile-payment-2@2x.png"
                                                alt="Payment Screen 2"
                                                align="center"
                                                visibleAmount={.1}
                                            />
                                        </Parallax>
                                    )}
                                </div>
                            </div>
                            <div className="grid__row">
                                <div className="grid__column grid__column--full">
                                    {isMobile ? (
                                        <ImageBlock
                                            classList="quick-pay__mobile-image quick-pay__mobile-image--medium"
                                            image="/images/quick-pay/mobile-payment-1.png"
                                            image2x="/images/quick-pay/mobile-payment-1@2x.png"
                                            alt="Payment Screen 1"
                                            align="center"
                                            visibleAmount={.1}
                                        />
                                    ) : (
                                        <Parallax offset={-80} duration={-80}>
                                            <ImageBlock
                                                classList="quick-pay__mobile-image quick-pay__mobile-image--medium"
                                                image="/images/quick-pay/mobile-payment-1.png"
                                                image2x="/images/quick-pay/mobile-payment-1@2x.png"
                                                alt="Payment Screen 1"
                                                align="center"
                                                visibleAmount={.1}
                                            />
                                        </Parallax>
                                    )}
                                </div>
                                <div className="grid__column grid__column--full justify-content--center">
                                    <Parallax classList="z-index--1" offset={-280} duration={120}>
                                        {isMobile ? (
                                            <VideoBlock
                                                classList="quick-pay__video"
                                                mp4="/videos/quick-pay/mobile-1.min.mp4"
                                                align="center"
                                            />
                                        ) : (
                                            <VideoBlock
                                                classList="quick-pay__video"
                                                webm="/videos/quick-pay/mobile-1.webm"
                                                mp4="/videos/quick-pay/mobile-1.mp4"
                                                align="center"
                                            />
                                        )}
                                    </Parallax>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="landing-page" className="section no-padding--top">
                    <ChapterTitle title="Landing Page"/>
                    <TextBlock
                        classList="margin-xl--bottom"
                        title="Consumers and Businesses"
                        text="Transform payments into acts of kindness in our model is appealing to both consumers and businesses, which I explained with a landing page. The site showcases the unique feature of donating to a chosen charity after each transaction, as well as the business benefits of using this service."
                        container="800"
                        align="center"
                    />
                    <div className="container container--1000 hide--on-sm">
                        <div className="row">
                            <div className="col justify-content--flex-end margin-sm-md--bottom">
                                <Parallax classList="z-index--1" offset={-100} duration={80}>
                                    <ImageBlock
                                        image="/images/quick-pay/site-part-1.png"
                                        image2x="/images/quick-pay/site-part-1@2x.png"
                                        classList=""
                                        alt="Ticket"
                                        visibleAmount={.1}
                                        width={800}
                                        once
                                    />
                                </Parallax>
                            </div>
                        </div>
                    </div>
                    <div className="container margin-xl--bottom--neg">
                        <div className="grid grid--2-columns">
                            <div className="grid__row">
                                <div className="grid__column grid__column--full justify-content--flex-end">
                                    <Parallax offset={-220} duration={120} classList="z-index--2">
                                        <ImageBlock
                                            image="/images/quick-pay/site-part-2.png"
                                            image2x="/images/quick-pay/site-part-2@2x.png"
                                            classList="box-shadow--heavy image-block--rounded"
                                            width={673}
                                            once
                                        />
                                    </Parallax>
                                </div>
                                <div className="grid__column grid__column--full margin-hg--top margin-inline--xl--neg">
                                    <Parallax offset={120} duration={20} classList="z-index--2">
                                        <ImageBlock
                                            image="/images/quick-pay/site-part-3.png"
                                            image2x="/images/quick-pay/site-part-3@2x.png"
                                            classList="box-shadow--heavy image-block--rounded"
                                            width={672}
                                            once
                                        />
                                    </Parallax>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="grid">
                            <div className="grid__row">
                                <div className="grid__column grid__column--full justify-content--center">
                                    <Parallax offset={-120} duration={20} classList="z-index--1">
                                        <ImageBlock
                                            image="/images/quick-pay/site-part-4.png"
                                            image2x="/images/quick-pay/site-part-4@2x.png"
                                            classList="box-shadow--heavy image-block--rounded"
                                            width={751}
                                            once
                                        />
                                    </Parallax>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container container--1000">
                        <div className="device device--notebook">
                            <div className="device__screen">
                                {isMobile ? (
                                    <VideoBlock
                                        classList="quick-pay__desktop-video"
                                        mp4="/videos/quick-pay/desktop-1.min.mp4"
                                        align="center"
                                    />
                                ) : (
                                    <VideoBlock
                                        classList=""
                                        webm="/videos/quick-pay/desktop-1.webm"
                                        mp4="/videos/quick-pay/desktop-1.mp4"
                                        align="center"
                                    />
                                )}
                            </div>
                            <img src="/images/quick-pay/notebook.png" className="device__image" alt="Device Notebook"/>
                        </div>
                    </div>
                </section>

            </motion.div>
            <NextProject nextProject='Wrapless'/>
        </>
    )
}

export default QuickPay;
