import React, {useRef, useEffect} from 'react';
import {motion, useAnimation, useInView} from "framer-motion";
import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet-async';

import pageTransition, {pageTransitionVariants} from "../components/PageTransition";
import {pageTransitionDelay, verticalScrollOffsetMarginBase} from "../transitions/TransitionConstants";
import TextBlock from "../components/TextBlock";
import Parallax from "../transitions/Parallax";
import Reveal from "../transitions/Reveal";
import BreakToLetters from "../transitions/BreakToLetters";
import Icon from "../components/Icons";
import ImageBlock from "../components/ImageBlock";
import {routesConfig} from "../config/routes";

const lettersVariants = {
    visible: {
        transition: {
            delayChildren: pageTransitionDelay + .5,
            staggerChildren: 0.04,
            staggerDirection: 1
        }
    }
}
const letterVariants = {
    hidden: {
        top: '4vw',
        opacity: 0
    },
    visible: {
        top: 0,
        opacity: 1,
        transition: {
            duration: .1,
            transitionTimingFunction: 'ease-in-out'
        }
    }
}

const fadeInWelcomeContainer = {
    hidden: {
        opacity: 0,
        scale: 1.2
    },
    show: {
        opacity: 1,
        scale: 1,
        transition: {
            delay: pageTransitionDelay + 1,
            delayChildren: pageTransitionDelay + 1,
            staggerChildren: 0.4,
            staggerDirection: 1
        }
    }
}

const fadeInWelcomeVariants = {
    hidden: {
        opacity: 0,
        scale: .8
    },
    show: {
        opacity: 1,
        scale: 1
    }
}

const fadeInAllVariants = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,
        transition: {
            delay: pageTransitionDelay + 2.5
        }
    }
}

const clientsVariants = {
    hidden: {
        transition: {
            staggerChildren: 0.1,
            staggerDirection: -1
        }
    },
    visible: {
        transition: {
            staggerChildren: 0.1,
            staggerDirection: 1
        }
    }
}

const clientVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1
    }
}

const About = () => {
    const lettersArray = BreakToLetters('Zoltan Bank');
    const welcomeRef = useRef(null);
    const isWelcomeInView = useInView(welcomeRef);
    const clientsRef = useRef(null);
    const isClientsInView = useInView(clientsRef, { margin: verticalScrollOffsetMarginBase, once: false });
    const clientsControls = useAnimation();
    const blogRoute = routesConfig.filter(item => item.name.includes('Tales'))[0];

    useEffect(() => {
        if (isClientsInView) {
            clientsControls.start("visible");
        } else {
            clientsControls.start("hidden");
        }
    }, [clientsControls, isClientsInView]);

    return (
        <>
            <Helmet>
                <title>About Zolly</title>
            </Helmet>
            <motion.div
                className="page about"
                variants={pageTransitionVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
            >
                <section className="section position--static">
                    <div className="container container--1000">
                        <motion.h1 ref={welcomeRef} className="heading heading--2 about__welcome">
                            <motion.span
                                initial={{opacity: 0}}
                                animate={{opacity: 1}}
                                transition={{
                                    duration: .5,
                                    delay: pageTransitionDelay
                                }}
                            >I am&nbsp;</motion.span>
                            <span className="about__zolly-container">
                                <motion.div className="overflow-hidden about__zolly-text" variants={lettersVariants}>
                                    {lettersArray.map((letter, index) => (
                                        <motion.span key={letter + index}
                                                     variants={letterVariants}
                                                     className="animated-letter"
                                        >
                                            {letter}
                                        </motion.span>
                                    ))}
                                </motion.div>
                                <div className="image-block about__zolly">
                                    <img src="/images/zoltan-bank.png" alt="Zolly" />
                                </div>
                            </span>
                            <span className="hide--on-md">&nbsp;</span>
                            <motion.span
                                className="about__welcome-text"
                                variants={fadeInWelcomeContainer}
                                initial="hidden"
                                animate="show"
                            >
                                <motion.span variants={fadeInWelcomeVariants}>a product designer,&nbsp;</motion.span>
                                <motion.span className="outline" variants={fadeInWelcomeVariants}>musician&nbsp;</motion.span>
                                <motion.span className="outline" variants={fadeInWelcomeVariants}>and yogi&nbsp;</motion.span>
                                <motion.span variants={fadeInWelcomeVariants}>based in London.</motion.span>
                            </motion.span>

                        </motion.h1>
                    </div>
                </section>

                <motion.div
                    variants={fadeInAllVariants}
                    initial="hidden"
                    animate="show"
                >
                    <section className="section no-padding--top no-padding-sm--top">
                        <div className="section__container section__container--narrow">
                            <div className="row about__welcome-content">
                                <div className="col-md-6 col-sm-6 col-xs-12 no-padding-sm--inline">
                                    <div className="section__text-block margin-hg--top">
                                        <TextBlock
                                            classList="margin-lg--bottom"
                                            headingClassList="margin--bottom"
                                            caption="Core skills"
                                            captionClassList="heading heading--4 no-margin--bottom"
                                            list={[
                                                {text: 'Research & Design'},
                                                {text: 'Design Systems'},
                                                {text: 'Prototyping'},
                                                {text: 'User Testing'},
                                                {text: 'Agile Champion'}
                                            ]}
                                            shifted
                                            once={true}
                                        />
                                        <TextBlock
                                            classList="margin-lg--bottom"
                                            headingClassList="margin--bottom"
                                            caption="Favourite tools"
                                            captionClassList="heading heading--4 no-margin--bottom"
                                            list={[
                                                {text: 'Figma'},
                                                {text: 'Figjam'},
                                                {text: 'Sketch'},
                                                {text: 'InVision'},
                                                {text: 'Dovetail'}
                                            ]}
                                            shifted
                                            once={true}
                                        />
                                        <TextBlock
                                            headingClassList="margin--bottom"
                                            caption="I like these too"
                                            captionClassList="heading heading--4 no-margin--bottom"
                                            list={[
                                                {text: 'Adobe CC'},
                                                {text: 'Framer'},
                                                {text: 'Mural'},
                                                {text: 'Miro'},
                                                {text: 'Trello'},
                                                {text: 'Jetbrains'},
                                                {text: 'Jira'},
                                                {text: 'Git'},
                                                {text: 'HTML & CSS'},
                                                {text: 'React & JS'},
                                                {text: 'Azure Dev Ops'},
                                                {text: 'And more...'}
                                            ]}
                                            shifted
                                            once={true}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-12 no-padding-sm--inline">
                                    <Parallax offset={-20} duration={160}>
                                        <Reveal transitionDirection="bottom">
                                            <>
                                                <p className="paragraph--medium margin-lg--bottom">I am fuelled by a passion for designing products and services. I have broad design experience working with companies worldwide, from industry leaders to start-ups. My main focus is on UI & experience design, but I also conduct design workshops and research, sometimes coding for the frontend or helping others understand agile's importance and benefits. My combined skills help me cover projects end-to-end and make me a good fit for various teams. I love animals, playing basketball, and working with/on design systems. I'm always up for a challenge and happy to team up.</p>
                                                <div className="box">
                                                    <p className="margin-md--bottom">I am mentoring people at <a href="https://adplist.org" target="_blank">ADPList</a>, and if you are looking for support or want to know how to get started as a designer let's meet there.</p>
                                                    <a href="https://adplist.org/mentors/zoltan-bank" target="_blank" className="button button--simple">
                                                        <span className="button__placeholder">Book a session</span>
                                                    </a>
                                                </div>
                                            </>
                                        </Reveal>
                                    </Parallax>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section">
                    <div className="container container--900">
                        <TextBlock
                            caption="Few clients I recently worked with"
                            captionClassList="heading heading--4 no-margin--bottom"
                            align="center"
                        />
                        <motion.ul ref={clientsRef} className="about__clients"
                                   initial="hidden"
                                   animate={clientsControls}
                                   variants={clientsVariants}
                        >
                            <motion.li className="about__clients-item"
                                       variants={clientVariants}
                            >
                                <Icon classList="icon--extra-large margin--bottom" name="shell" />
                                Shell
                            </motion.li>
                            <motion.li className="about__clients-item"
                                       variants={clientVariants}
                            >
                                <Icon classList="icon--extra-large margin--bottom" name="bt" />
                                BT
                            </motion.li>
                            <motion.li className="about__clients-item"
                                       variants={clientVariants}
                            >
                                <Icon classList="icon--extra-large margin--bottom" name="vodafone" />
                                Vodafone
                            </motion.li>
                            <motion.li className="about__clients-item"
                                       variants={clientVariants}
                            >
                                <Icon classList="icon--extra-large margin--bottom" name="ee" />
                                EE
                            </motion.li>
                        </motion.ul>
                    </div>
                </section>
                </motion.div>

                <section className="about__footer">
                    <div className="about__social-links">
                        <a href="https://www.linkedin.com/in/zoltanbank/" className="about__social-link about__social-link--linkedin" target="_blank">
                            <Icon classList="icon--large icon--fill" name="linkedin" />
                            Follow me on LinkedIn
                        </a>
                        <a href="/files/zoltan_bank_cv.pdf" className="about__social-link about__social-link--cv" download>
                            <Icon classList="icon--large icon--fill" name="download" />
                            Download my CV
                        </a>
                        <a href="https://open.spotify.com/playlist/3cDDpJzQi1YmLfgclyDSDo?si=4e8da6bddb204673" className="about__social-link about__social-link--spotify" target="_blank">
                            <Icon classList="icon--large icon--fill" name="spotify" />
                            Music I listen to
                        </a>
                        <a href={blogRoute.path} className="about__social-link about__social-link--blog">
                            Cliché Tales
                        </a>
                    </div>
                </section>
            </motion.div>
        </>
    );
}

// export default pageTransition(About);
export default About;
