import React from "react";
import { useParams } from "react-router-dom";

const Post3 = () => {
    const { id, lang } = useParams();

    return (
        <>
            {lang === "hu"
                ?
                    <article className="blog__article">
                        <hgroup className="blog__article-header">
                            <h2 className="heading heading--3 no-margin--bottom">Újra meg újra</h2>
                        </hgroup>
                        <p>Az elmúlt hetem a témám (kedves naplóm…). Ok, lehet nem csak egy hét mert összefolyik minden mostanság mint a csatornába. Legyen az elmúlt hónapom, az úgy fixebb. A lényeg, hogy valahol hamarosan be kell rugnom egy iroda ajtót.</p>
                        <p className="spotify box no-padding--top">
                            <iframe
                                src="https://open.spotify.com/embed/track/6wCJ3VezXBqYboAruJoAd4?utm_source=generator&theme=0"
                                width="100%" height="152" frameBorder="0" allowFullScreen=""
                                className="no-margin--top"
                                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                loading="lazy"></iframe>
                        </p>
                        <p>Ugye dobtam, hogy felmondtam, hogy elmenjek Indiába szerencsétlenkedni Sharathji Gurunak valami jóga szerűséget előadva. Szép volt, jó volt, de már egy ideje elfogyott a pénzünk, és nem annyira zavar ha van mit enni, meg ilyenek, szóval ideje van visszatakarodni dolgozni.</p>
                        <p>Nyomom az álláskeresést izomból. Nem mondom, hogy a legkönnyebb időszak ez arra, hogy valami karrier szempontból értékelhető melót zsákoljak be, de azt se mondom, hogy nincs munka. Jó néhány hete veretem, ezzel kelek, ezzel fekszem. Már úgy ráfagytak az érzékeim, hogy mielőtt jönne egy email vagy pl. LinkedIn bármi, már nyitom az appot. Előbb ott vagyok mint a gondolat vagy az algoritmus.</p>
                        <p>Amúgy termék tervező (product designer) a címerem, ami nem titok mert a portfolió oldalamon szotyizol épp. Ha kattingatsz picit láthatod mibe vagyok, miből élek. Szeretem ezt csinálni, sok évnyi kanyargás a web, mobil és egyéb digitális termék fejlesztésben kellett ahhoz, hogy itt kössek ki. Egy ilyen svájci bicska (aka unikornis) dolog ez a termék tervező, sok mindent le kell fedni, minél jobban.</p>
                        <p>Talán a legnagyobb off a böngészésben, hogy a mostanság bedobált bérek többnyire a négy évvel ezelőtti bérek alatt vannak, miközben, amit költeni kell az ugye sokszoros, ahogy ezt mind közösen beszoptuk. Az is elég csumi, hogy a remote (távmunka) elpárolog a szemünk előtt, és a többsége a cégeknek már terel vissza az irodákba. Persze ha mondjuk neked ez sose volt opció (pl. konyhán dolgozol) ez picsongásnak tűnhet, de azzal nem tudok mit kezdeni, igazad van. Vannak tevékenységek, mint például egy közös ötletelés amikor adom a személyességet, de a legtöbb munkafolyamatom simán elvégezhető bárhol. És a hangsúlyt a bárhol-ra teszem, nem feltétlen csak otthon.</p>
                        <p>Vannak emberek, akik hozzáadott érték hiányában, irodai környezetben oldódnak, a víztartály mellett motyognak, hosszú ebéd szünetre mennek, pletykálnak stb. Ők azok akiket mikor megkérdeztek akarnak-e home office-t vastagon aláhúzták, hogy nem, és azok is akik többségben menedzser/vezető pozíciókat töltenek be, tehát a döntéshozók. Tisztelet a kivételnek, nem mindenki kvalifikál irodai szobanövénynek, de mind ismerünk ilyeneket. Na szóval szerintem, miattuk nincs remote pozi lassan. Legalábbis nem sok olyat hallottam, hogy mondjuk hatékonysági felmérés készült volna a home office hatásáról (céges szinten) és empirikus adatok alapján hozták meg a döntést pro-kontra. A lobbista irodista csicskák nyertek és kész.</p>
                        <p>Nem tudom sokat interjúzol-e, de ha van benne bármilyen tapasztalatod, ha nincs, rohadt egy dolog tud ez lenni. Jól kell válaszolni olyan kérdésekre amire amúgy nincs rossz válasz (de van jó). Bele kell látni a másik fejébe, meg kell érteni másodpercek alatt a motivációját, hogy az jöjjön ki belőled amit hallani akar a kérdező. Ha nem vagy totálisan szar abban amit csinálsz, és bízol magadban annyira, hogy ha felvennének meg tudnád csinálni amiért felvettek, még az nem jelent semmit (mondjuk ha ellenkezőleg vagy az nem segít). Egy interjún te vagy a termék és szét kell marketingezned magad.</p>
                        <p>Egyébként voltam a füstölő másik végén is, interjúztattam jelentkezőket, elég sokat. Nagyon más onnan nézni. Hiába van bennem (legalábbis úgy gondolom) kellő empátia, ha mint egy puzzle darabnak be kell pattannia az embernek a meghirdetett helyre, az egy szigorú elvárás felé. Persze, ez nem nyilvános elvárás mert akkor mindenre igen lenne a válasz.</p>
                        <div className="box">
                            <p className="no-margin--bottom">Eszembe jut erről az egészről egy régi sztorim amiből kiderül, nekem az sem segít amilyen vagyok így elsőre. Elkezdtem a melót egy új cégnél, ahol szokásosan ment a reggeli stand-up, ami azt jelenti, hogy körbe áll a csapat, és egyesével mindenki eldarálja mit csinált tegnap, mi lesz ma, és ha van valami blokker akkor megemlíti azt is. Szóval az első napomon rám került a sor, és megkértek mutatkozzak be, aztán megkérdezték hogyan szólíthatnak, mire azt sikerült felböfögnöm, hogy:</p>
                            <blockquote>Szólítsatok kapitánynak<span>Zolly a Proli</span></blockquote>
                            <p className="no-margin--bottom">Nem nevetett senki a cringe-elésemen. Amúgy nem vagyok szar arc (mind ezt mondja), ami aztán kiderült, és egy-két hónap múlva egy csapatépítőn az akkor már haverok mondták, hogy az elején azt gondolták rólam egy hatalmas pecs vagyok. Nem vagyok továbbra sem erős első benyomásban, engem meg kell szokni.</p>
                        </div>
                        <p>Becéloztam egy vezető terméktervező (lead product designer) pozíciót a monday.com nevű cégnél Londonban. Fullosan felhasználó és design centrikus termékeik vannak, nagyon adom amiért dolgoznak. A folyamat hat fázisból áll:</p>
                        <ol className="list display--block">
                            <li>Fejvadász beszélgetés</li>
                            <li>HR beszélgetés</li>
                            <li>Technikai (design) beszélgetés és közös ötletelés</li>
                            <li>Próba feladat</li>
                            <li>Menedzsment beszélgetés</li>
                            <li>HR beszélgetés/zárás</li>
                        </ol>
                        <p>Nem egyedülálló dolog ez a szakmámban, hogy ennyi kört kell futni, az átlag olyan 4 kör, itt egy kicsit túl is tolják. Ezért van az, hogy a munkakeresés önmagában felér egy munkával, mert ha kell meló, rettenet sok ilyen folyamatot kell elkezdeni, és végig vinni, egy ajánlat előtt. Mondjuk, hogy egyáltalán elkezdhesd annyi mindenen elvérezhetsz már előtte, hogy a negyedéről se tudsz. Úgy pattan le a CV-m sokszor, hogy csak pislogok, de nem szabad mellre szívni. Lehet az ok bármi, például a szoftver amibe betették a cv-m nem ette meg a mezőket, vagy a pénz sok/kevés… Sose tudod meg. Ja az se nagy segítség, hogy ha jó egy pozíció akkor pár óra alatt ezer ember rámegy.</p>
                        <p>Miután jelentkeztem a monday-hez pár héttel rá visszahívtak, és letoltam a beszélgetést a fejvadásszal. Általában a tapasztalatom az, hogy ezek a körök csak arra világítanak rá ha nagyon vakon lennél, esetleg bér igényben túl messze vagytok egymástól, ilyenek. A pozíció szintjétől is függ persze, hogy mire figyel akivel beszélsz, mivel ez pl. egy vezető designer hely, itt sokat számít a személyiség, magabiztosság (soft skill-ek) stb.</p>
                        <p>Baráti beszélgetés volt, tovább a második körre, ami pár napon belül lesz. Egy talent expert (vagy HR-es, vagy tudom is én) kérdezgetett, itt már kicsit részletesebben, belekérdezve a múltamba, tapasztalatomba. Szintén jól kijöttünk, és mondta is az interjú végén, hogy mehetek tovább, illetve, hogy majd mire számítsak. Közben a recruiter arccal Whatsapp-on haverkodtunk, illetve segített felkészülni abból, hogy mit nézzek át, miket olvassak el stb. Szépen bejáratott volt a folyamat.</p>
                        <p>Amúgy mellékesen épp felújítják a teraszunk, és előfordult, hogy egy nap három interjúm volt, és csak azalatt melóztak. Egész nap csak azt várták a munkások, hogy elkezdjek beszélni a múltamról, hogy egy kalapáccsal és flexel felszerelve neki essenek végre az ablakom előtti terasznak, de izomból. Aztán szünet a következő interjúig, ahogy kell.</p>
                        <p>A harmadik körös interjú már egy designer-el zajlik, két órás. Az első órában megy a beszélgetés a karrierről, a cégről, kultúráról, a második órában pedig egy közös ötletelés valami termékről, appról amit szeretek, használok, vagy eszembe jut kb. Elég kötetlen ami nem nagy segítség, meg közben mégis lehet, hogy az. Az is kemény volt, hogy az interjú előtt két nappal éjfél körül kiderült, hogy be kéne mennem az irodába Londonba, amivel csak annyi volt a baj, hogy én épp Budapesten voltam. Vettem gyorsan repjegyet másnapra, lefoglaltam egy szállást a King's Crossnál. Tetszik a cég, teszem amit kell. Gondolom, mondhattam volna, hogy legyen online, de nem rossz jel ha behívnak, kár az ilyet visszadobni.</p>
                        <p>Szóval London, Tottenham Court road, interjú harmadik felvonás. Cuki iroda elég jó helyen (a meta aka facebook Londoni irodája mellett, szóval egyesek mondhatnák fancy is), vannak kutyákok meg kávégép, Lego, stb. Nagyon jó beszélgetés a design csoport vezetővel, klikkelnek a dolgok, jól megértjük egymást. A második óra előtt van egy szünet, amikor ilyen cica fotókat mutogatunk egymásnak, és kiderül, hogy az ő cicája is autóval költözött Angliába mint régen a mienk, Nancy. Jó mondjuk az övé repült is mert Tel Avivból jött, de mivel a britek még mindig nem engednek repülővel (se vonattal) kisállatot az országba, Párizsból az alagúton át robogott be a királyságba a macsek.</p>
                        <p>Belekezdünk a közös termék agyalásba. Bedobom, hogy mint zenész nézve a Spotify, Apple music és hasonló platformokon lévő zenekari profilok nem kezelhetőek egy helyről, ami szerintem egy fos felhasználói élmény. Ez volt a téma, amit fejtegettünk majd megkaptam feladatnak, hogy akkor most otthon, menjek rá, hogy ebből készítek valami termék tervet, amihez összerakok egy marketing weboldalt, bemutatva ez miért jó. Ja igen, és egy prezentációt is mert ugye ez majd körbe megy a cégben véleményezésre. Szóval ilyen kis laza rohadt összetett meló, amire van egy hetem, ami persze nincs mert ugye nem csak ez a dolgom, szóval nyomatni kell, éjjel-nappal.</p>
                        <p>Kitaláltam egy arculatot, megcsináltam a termék terveket, a marketing weboldal tervet, prezentációt, amit ha érdekel elérsz itt <a href="https://www.figma.com/proto/CJPkIXpkvD5vVNYXTa2Lgz/Up-n-Roll?page-id=19%3A158&type=design&node-id=56-551&viewport=66%2C496%2C0.06&t=OdwoggzXeJQdwZIx-1&scaling=contain&starting-point-node-id=56%3A551&show-proto-sidebar=1&mode=design" target="_blank">ezen a linken</a> (nyomkodd a preziben a gombokat pl. "Start", "Prototype" etc., a kurzorral pedig tudsz lapozni). Kedden interjúztam, és a következő hétfőn leadtam a cuccot és vártam. Szerencsére sokat nem kellett mert tetszett amit összeraktam, úgy, hogy jöhet a kövi, menedzsgment beszélgetés.</p>
                        <p>Spoiler alert ez volt az utolsó lépés ahova elértem. Egy órát beszélgettem a design vezér fejjel, és egy másik designerrel. Nagyon összetett, bár jó kérdéseket tettek fel, és ugyan készültem, nem volt könnyű dolgom. Nem adják a jó munkahelyet olcsón. Azt a visszajelzést kaptam (bár nem kértem, hogy kifejtsék), hogy nagyon szoros volt de valaki mást választanak helyettem, valakit aki eggyel jobban illett a puzzle-be. Tanulság persze mindig van, elfogadom a döntést, nyilván nem ezt vártam de az élet megy tovább. Ilyenek és hasonló közhelyek peregnek ilyen szomi időben, közben jöhet a következő, meg a többi ami már fut.</p>
                        <p>Volt egy olyan interjúm a napokban, hogy a szakmázás után, mondja a nő, hogy ez egy kis startup, fontos a személyiség a főninek (akinek nagyon Indiai neve van), szóval meséljek magamról. Oh, mondom az sima, és kicsit véletlen be is oltom, hogy végre egy jó kérdés. Kimentem gyorsan magam, hogy csak ilyen hülye gyerek vibe-om van (remélem kell az ilyen nekik), sorry, és nyomom, hogy India, jóga, rap, kosárlabda, főzés ilyenek. Kiderült, hogy nagyon betalálok ezekkel, mert a csapatépítőkön jógázni, zenélni, főzni szoktak az arcok.</p>
                        <p>Egy másik csávó az interjú kezdés után öt perccel elnézést kér, mert fel kell vennie egy telefont, majd tizenöt percre betűnik az egyébként harminc perces szituból. Jön vissza és mondja, hogy sajnálja, ő orosz, és valami orosz minisztérium hívta és azt fel kell venni. Nekem már ennek a gondolatától visszakúsztak a golyóim a hasamba szóval mondtam neki, hogy hárásó, semmi gond.</p>
                        <p>Ha esetleg hasonló cipőben jársz (keresel valami új melót), illetve hasonló szakmában vagy, és adnál egy kis beszélgetést a portfóliódról, önéletrajzodról, készülnél interjúra vagy valami ilyesmi, akkor <a href="https://adplist.org/mentors/zoltan-bank" target="_blank">ezen a linken</a> mentorkodom. Az Adplist (amazing design people list) lényege, hogy segítsük a másikat, vissza adjunk egy kicsit a közösségnek, amit igyekszem minél több órával támogatni. <a href="https://adplist.org/mentors/zoltan-bank" target="_blank">Foglalj időpontot</a> ha úgy van, aztán remélem tudok segíteni. <b>Na csumpesz</b>.</p>
                    </article>
                :
                    <>
                        <article className="blog__article">
                            <hgroup className="blog__article-header">
                                <h2 className="heading heading--3 no-margin--bottom">Again and again</h2>
                            </hgroup>
                            <p>So, my last week is going to be my topic (dear diary...). Okay, maybe it wasn't just a week because everything has been blending together lately, just like as in the drain. Let it be a month instead; that's more definitive. The point is that I’ll have to kick in an office door somewhere soon.</p>
                            <p className="spotify box no-padding--top">
                                <iframe
                                    src="https://open.spotify.com/embed/track/6wCJ3VezXBqYboAruJoAd4?utm_source=generator&theme=0"
                                    width="100%" height="152" frameBorder="0" allowFullScreen=""
                                    className="no-margin--top"
                                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                    loading="lazy"></iframe>
                            </p>
                            <p>Remember when I threw in the towel and quit my job to go to India to clumsily demonstrate some yoga-like stuff to Sharathji Guru? It was nice, it was good, but our money has been running out for a while, and it would be nice to eat, so it's time to pack up and get back to work.</p>
                            <p>I'm hitting the job search with all my muscles. I won't say this is the easiest time to dunk a career-worthy gig, but I also won't say there's no work out there. I've been pounding away at this for weeks, and it's what I wake up to and fall asleep with. My senses are so on edge that I open the app before an email or a LinkedIn ping even arrives. I'm there before the thought or the algorithm.</p>
                            <p>Anyway, Product Designer is my title, which isn't a secret because you're poking around my portfolio right now. If you click around a bit, you can see what I'm into and what pays the bills. I love doing this, and it took years of wandering through web, mobile, and other digital product development to land here. Being a product designer is like being a Swiss army knife (aka unicorn), you gotta cover a lot of ground, the more, the better.</p>
                            <p>One of the biggest buzzkills in browsing is that the salaries being thrown around these days are mostly below what they were four years ago, while the expenses have multiplied, as we've all collectively screwed. It's also pretty shitty that remote work is evaporating before our eyes, and most companies are herding people back into offices. Sure, if you've never had the option (like if you work in a kitchen), this might seem like bitching, but I can't argue with that, you're right. There are activities, like brainstorming sessions, where I thrive on the personal touch, but most of my workflow can be done anywhere. And I emphasize the "anywhere," not necessarily just at home.</p>
                            <p>There are people who lack added value and thrive in office environments, mumbling by the water cooler, taking long lunch breaks, gossiping, etc. They're the ones who when asked if they want to work from home, underline “no” heavily, and they’re also the ones mostly occupying managerial positions, so they’re the decision-makers. With respect to the exceptions, not everyone qualifies as an office plant, but we all know people like that. So, I think it's because of them that remote positions are becoming scarce. At least, I haven't heard much about, say, efficiency surveys being conducted on the impact of home office (at the company level) and decisions being made based on empirical data. The lobbyist office drones win, and that's that.</p>
                            <p>I don't know if you interview a lot, but if you do, or even if you don't, damn, it can be a tough thing. You gotta answer questions for which there isn't necessarily a wrong answer, but there's a right one. You have to get into the interviewer's head and understand their motivation in seconds so you can say what they want to hear. If you're not totally clueless at what you do, and you believe in yourself enough that you could do the job if hired, that still doesn't mean anything (although if you feel the opposite, that doesn't help). In an interview, you are the product, and you have to market yourself like a boss.</p>
                            <p>By the way, I've been on the other end of the smoke, interviewing applicants, quite a few of them. It's very different looking at it from there. Even though I think I have enough empathy (or at least I hope so), when someone has to fit like a puzzle piece into the advertised position, it's a strict requirement. Of course, this isn't a public expectation because then the answer to everything would be yes.</p>
                            <div className="box">
                                <p className="no-margin--bottom">This whole thing reminds me of an old story where it becomes clear that the first impression I make does not help my case. I started a job at a new company where they had the usual morning stand-up, which means the team gathers around, and one by one, everyone talks about what they did yesterday, what they'll do today, and if there's any blocker. So, on my first day, it was my turn, and they asked me to introduce myself, and then they asked how they should call me, to which I managed to burp out:</p>
                                <blockquote>Call me Captain<span>Zolly the Jolly</span></blockquote>
                                <p className="no-margin--bottom">No one laughed at my cringe-worthy moment. I'm not a egomaniac dick (they would all say that), and they found that out eventually, and a couple of months later, at a team-building event, my now friends said that initially, they thought I was some huge asshole. I still don't make a strong first impression. You gotta get used to me.</p>
                            </div>
                            <p>I targeted a Lead Product Designer position at monday.com in London. They have fully user and design-centric products, which I really dig. The process consists of six phases:</p>
                            <ol className="list display--block">
                                <li>Recruiter interview</li>
                                <li>HR interview</li>
                                <li>Technical (design) interview and brainstorming session</li>
                                <li>Test assignment</li>
                                <li>Management interview</li>
                                <li>HR interview/closure</li>
                            </ol>
                            <p>This isn't unique in my field, having to go through this many rounds, the average is about 4 rounds, but they're pushing it a bit here. That's why job hunting alone is like having a job because if you need work, you have to start and go through so many of these processes before an offer. Just to start, you can already bleed out from so many things you're unaware of. Sometimes, my CV gets rejected, and I just blink, but I can't take it personally. It could be anything like the software they used to parse my CV not recognizing the fields or the money being too much/not enough... You never know. Oh, and it doesn't help that if a position is good, thousands of people jump on it within hours.</p>
                            <p>After applying to monday, they called me back a few weeks later, and I had a chat with their recruiter. Generally, my experience is that these rounds only highlight if you're really blind to the role or if your salary expectations are too far apart, stuff like that. It also depends on the level of the position, with this being a senior designer role, personality, confidence (soft skills) matter a lot.</p>
                            <p>It was a friendly chat. On to the second round, which will be in a few days. A talent expert (or HR or something) asked me questions, this time a bit more detailed, delving into my past and my experience. We got along well, and at the end of the interview, she said I could proceed and also gave me an idea of what to expect. Meanwhile, the recruiter and I were chitchatting on WhatsApp, and he helped me prepare by suggesting what to review, what to read, etc. The process was well-greased.</p>
                            <p>By the way, they're renovating our terrace right now, and there was a day when I had three interviews. The workers were just waiting all day for me to start talking about my past so they could finally attack the terrace in front of my window with hammers and saws with all their might. Then, it's a break until my next interview, so great.</p>
                            <p>The third round of interviews is with a designer, and it is two hours long. The first hour is spent discussing my career, the company, and the culture, and the second hour is spent brainstorming a product, app or something that I like, use, or just comes to mind. It's pretty laid-back, which is not much help, yet somehow it might be. It was also tough that two days before the interview, around midnight, I found out that I had to go to the office in London, which was only a problem because I was in Budapest at the time. I quickly bought a plane ticket for the next day and booked accommodation near King's Cross. I like the company, so I'm doing what needs to be done. I guess I could have asked for it to be online, but it's not a bad sign if they invite you. It would be a shame to turn that down.</p>
                            <p>So, London, Tottenham Court Road, the third act of the interview. It’s a cute office in a pretty good location (next to the Meta, aka Facebook London office, so some might call it fancy), with a doggy, coffee machines, Lego, etc. Had a great chat with the design group lead, things clicked, we understood each other well. Before the second hour, there's a break, during which we show each other cute cat photos, and it turns out her cat also moved to England by car, just like ours did a while ago, Nancy. Well, hers flew because it came from Tel Aviv, but since the British still don't allow pets into the country by plane (or train), the cat cruised in from Paris through the tunnel into the kingdom.</p>
                            <p>We dive into brainstorming the product together. I suggest that as a musician, looking at the band profiles on Spotify, Apple Music, and similar platforms isn't manageable from one place, which I think is a crappy user experience. That was the topic we discussed, and then I got the task to work on it at home, to come up with a product plan and put together a marketing website showing why this is good. Oh yeah, and also a presentation because this will circulate around the company for feedback. So, it's this little, casual, damn complex task, which I have a week for, but of course, I don't because it's not my only task, so I gotta push through, day and night.</p>
                            <p>I came up with a brand, made the product plans, the marketing website design, and the presentation, which you can check out here if you are interested: <a href="https://www.figma.com/proto/CJPkIXpkvD5vVNYXTa2Lgz/Up-n-Roll?page-id=19%3A158&type=design&node-id=56-551&viewport=66%2C496%2C0.06&t=OdwoggzXeJQdwZIx-1&scaling=contain&starting-point-node-id=56%3A551&show-proto-sidebar=1&mode=design" target="_blank">here is a link</a> (press the buttons in the presentation, for example, "Start", "Prototype", etc., you can navigate with the cursor as well). I interviewed on Tuesday, and the following Monday, I submitted the stuff and waited. Fortunately, I didn't have to wait long because they liked what I put together, so, onto the next one, management interview.</p>
                            <p>Spoiler alert: this was the last step I reached. I talked for an hour with the head of design and another designer. It was very complex; they asked good questions, and although I was prepared, it wasn't easy. They don't give away good jobs cheaply. I got the feedback (although I didn't ask for it to be elaborated) that it was very close, but they're choosing someone else, someone who fits the puzzle a bit better. There's always a lesson; I accept the decision; obviously, it's not what I expected, but life goes on. These and similar clichés run through your head in sad times like these. Meanwhile, the next one comes, and the others are already in progress. The show must go on.</p>
                            <p>I had an interview recently where, after geeking out about the field, the woman says it's a small startup, personality is key for the boss (who has a very Indian name), so tell me about yourself. Oh, I say, that's easy, and I accidentally let slip that, finally, a good question. I quickly let out my vibe that I'm just this goofy kid (hope they need someone like that), sorry, and I go on about India, yoga, rap, basketball, cooking, stuff like that. It turns out I really hit it off with these because, at team-building events, they do yoga, play music, and cook.</p>
                            <p>Another dude, five minutes after the interview starts, apologizes and says he needs to take a call, disappears from the thirty-minute situation for fifteen minutes. He comes back and says he's sorry, he is russian, and some russian gov office called him up, and he had to take it. Just the thought of this made my balls crawl back into my stomach, so I told him, "No worries, take your time."</p>
                            <p>If you happen to be in the same boat (looking for a new job) and in a similar field and would like to have a chat about your portfolio, resume, preparing for an interview or something like that, then I'm mentoring through <a href="https://adplist.org/mentors/zoltan-bank" target="_blank">this link</a>. The idea of Adplist (Amazing Design People List) is to help each other out and give back to the community, which I try to support with as many hours as possible. <a href="https://adplist.org/mentors/zoltan-bank" target="_blank">Book a slot</a> if you want, and hopefully, I can be helpful. <b>Peace out</b>.</p>
                        </article>
                    </>
            }
        </>
    );
}

export default Post3;
