import { StrictMode } from "react";
import * as ReactDOMClient from "react-dom/client";
// import RouterTitle from 'react-router-title';
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import {routesConfig} from "./config/routes";

function MyAwesomeApp() {
    return (
        <StrictMode>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </StrictMode>
    );
}

const rootElement = document.getElementById("root");
const root = ReactDOMClient.createRoot(rootElement);
root.render(<MyAwesomeApp />);
