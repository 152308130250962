import React, {useRef, useEffect, useState} from "react";
import {motion} from "framer-motion";
import {isMobile} from 'react-device-detect';
import {useParams, useNavigate, useLocation, Link} from "react-router-dom";
import { getPost, changeLanguage } from "./blog/api";
import { Helmet } from 'react-helmet-async';
import {pageTransitionVariants} from "../components/PageTransition";

const Post = () => {
    const ref = useRef(null);
    const { id, lang } = useParams();
    const post = getPost(id);
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <>
            <Helmet>
                {lang === "hu"
                    ? <title>{post.title_hu}</title>
                    : <title>{post.title_en}</title>
                }
            </Helmet>

            <motion.div
                className="page"
                variants={pageTransitionVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
            >
                <div className="site-frame site-frame--top">
                    <a className={`${lang === "en" ? 'active' : ''} blog__language-button`} onClick={() => {
                        changeLanguage(navigate, location, 'en')
                    }}>
                        {isMobile
                            ? <>EN</>
                            : <>English</>
                        }
                    </a>
                    <a className={`${lang === "hu" ? 'active' : ''} blog__language-button`} onClick={() => {
                        changeLanguage(navigate, location, 'hu')
                    }}>
                        {isMobile
                            ? <>HU</>
                            : <>Magyar</>
                        }
                    </a>
                </div>
                <section className="section hero no-padding">
                    <div className="hero__content padding-sm--left padding-sm--right">
                        <div className="hero__title-group">
                            <Link className="link margin-lg--bottom" to={`/${lang}/blog`}>
                                {lang === "hu"
                                    ? <>Vissza a bejegyzésekhez</>
                                    : <>Back to all posts</>
                                }
                            </Link>
                            <h1 className="hero__title heading heading--1 align--center margin-xl--bottom">
                                {lang === "hu"
                                    ? <>{post.title_hu}</>
                                    : <>{post.title_en}</>
                                }
                                {post.part_hu && lang === "hu" &&
                                    <span className="hero__part heading heading--4">{post.part_hu}</span>
                                }
                                {post.part_en && lang === "en" &&
                                    <span className="hero__part heading heading--4">{post.part_en}</span>
                                }
                            </h1>
                            <p className="margin-sm-xl--bottom">
                                {lang === "hu"
                                    ? <>Menjen a scroll meg a swipe</>
                                    : <>Let's scroll and swipe</>
                                }
                            </p>
                        </div>
                        <div className="hero__arrow">
                            <div className="hero__arrow-box">&nbsp;</div>
                        </div>
                    </div>
                </section>
                <div className="blog" ref={ref}>
                <div className="container container--800 blog__content">
                    {post.content}
                </div>
                <div className="container container--800 align--center padding-xl--bottom">
                    <Link className="link" to={`/${lang}/blog`}>
                        {lang === "hu"
                            ? <>Vissza a bejegyzésekhez</>
                            : <>Back to all posts</>
                        }
                    </Link>
                </div>
            </div>
            </motion.div>
        </>
    );
}

export default Post;
