import React, {useRef} from "react";
import {motion, useInView} from "framer-motion";
import {verticalScrollOffsetMarginBase} from "./TransitionConstants";

function SlideTransition({children, transitionDirection}) {
    const ref = useRef(null);
    const isInView = useInView(ref, { margin: verticalScrollOffsetMarginBase, once: false });

    const animateElementStyle = {
        left: {
            opacity: isInView ? 1 : 0,
            translateX: isInView ? 0 : 50,
            transition: "all 400ms ease"
        },
        right: {
            opacity: isInView ? 1 : 0,
            translateX: isInView ? 0 : -50,
            transition: "all 400ms ease"
        }
    }

    return (
        <motion.div
            ref={ref}
            style={transitionDirection ? animateElementStyle[transitionDirection] : animateElementStyle.right}
        >
            {children}
        </motion.div>
    )
}

export default SlideTransition;
