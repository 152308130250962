import React, {useEffect, useState, useRef} from "react";
import {motion, AnimatePresence, useAnimation} from "framer-motion";
import {Link, useLocation} from "react-router-dom";
import Icon from "./Icons";
import Overlay from "./Overlay";
import {pageTransitionDelay, pageTransitionDuration} from "../transitions/TransitionConstants";
import {routesConfig} from "../config/routes";

function SiteFrame({currentPage, overlayVisible, setShowOverlay}) {
    const location = useLocation();
    const controls = useAnimation();
    const projectsRoute = routesConfig.filter(item => item.name.includes('Projects'))[0];
    const aboutRoute = routesConfig.filter(item => item.name.includes('About'))[0];
    const clicheRoute = routesConfig.filter(item => item.name.includes('Tales'))[0];
    const blogRoute = routesConfig.filter(item => item.name.includes('Blog'))[0];
    const isProjects = (location.pathname === projectsRoute.path);
    const isAbout = (location.pathname === aboutRoute.path);
    const isBlog = location.pathname.includes('blog');
    const isCliche = (location.pathname === clicheRoute.path);

    // Site frame animation
    const siteFrameVariants = {
        // Site frame enters the scene as hidden
        hidden: {
            opacity: 1,
            transition: {
                ease: "easeInOut"
            }
        },
        // Site frame becomes visible
        visible: {
            opacity: 1,
            transition: {
                ease: "easeInOut"
            }
        }
    }

    // REFACTOR stop them running on page load
    useEffect(() => {
        controls.start("hidden");

        setTimeout(() => {
            controls.start("visible");
        }, 1000)
    }, [currentPage]);

    return (
        <>
            <Overlay key="overlay" overlayVisible={overlayVisible} setShowOverlay={setShowOverlay}/>
            <motion.div
                variants={siteFrameVariants}
                initial="hidden"
                animate={controls}
                className="site-frame site-frame__block site-frame__logo"
            >
                <Link to={isProjects ? '' : projectsRoute.path} key="logo">
                    <Icon name="logo" classList="logo"/>
                </Link>

            </motion.div>

            <div className="site-frame site-frame--navigation hide--on-sm">
                <div className={`site-frame__block site-frame__block--absolute ${isProjects || isBlog || isCliche ? "site-frame__block--hidden" : ""}`}>
                    <Link to={projectsRoute.path} key="projects" className="site-frame__text">
                        Projects
                    </Link>
                </div>
                <div className={`site-frame__block site-frame__block--absolute ${!isBlog || isCliche ? "site-frame__block--hidden" : ""}`}>
                    <Link to={blogRoute.path.replace(':lang', 'en')} key="projects" className="site-frame__text">
                        Blog
                    </Link>
                </div>
                <div className={`site-frame__block site-frame__block--absolute ${isProjects || isCliche ? "" : "site-frame__block--hidden"}`}>
                    <Link to={aboutRoute.path} key="about" className="site-frame__text">
                        About
                    </Link>
                </div>
            </div>
        </>
    );
}

export default SiteFrame;
