import Post1 from "./posts/post-1";
import Post2 from "./posts/post-2";
import Post3 from "./posts/post-3";
import Post4 from "./posts/post-4";
import {useLocation, useNavigate} from "react-router-dom";

const posts = [
    {
        id: "the-wolf-of-hatfield-part-1",
        title_en: "The Wolf of Hatfield",
        title_hu: "Hatfield Farkasa",
        part_en: "Part One",
        part_hu: "Első Rész",
        excerpt_hu: "Hogyan keveredtem Angliába.",
        excerpt_en: "How did I end up in England",
        date: "3 Apr 2024",
        image: "/images/blog/blog-4.png",
        content: <Post4/>
    },
    {
        id: "the-job-game",
        title_en: "The job game",
        title_hu: "A munka játszma",
        excerpt_hu: "Néhány szösszenet álláskereső pózból.",
        excerpt_en: "Some snippets from a job seeker stance.",
        date: "24 Feb 2024",
        image: "/images/blog/blog-3.png",
        content: <Post3/>
    },
    {
        id: "a-b-1-2",
        title_en: "A B 1 2",
        title_hu: "A B 1 2",
        excerpt_hu: "Életmód kérdések, frusztrációk kivesézése (nem szó szerint).",
        excerpt_en: "Dissecting lifestyle issues and frustrations (not literally).",
        date: "13 Feb 2024",
        image: "/images/blog/blog-2.png",
        content: <Post2/>
    },
    {
        id: "thats-right-man",
        title_en: "That's right, man",
        title_hu: "Az igen, ember",
        excerpt_hu: "Ez az első bejegyzés, arról, hogy ki vagyok meg mire számíthatsz itt.",
        excerpt_en: "This is the first post and it's about who I am and what you can expect here.",
        date: "25 Jan 2024",
        image: "/images/blog/blog-1.png",
        content: <Post1/>
    }
];

export function changeLanguage(navigate, location, changeTo) {
    let url = location.pathname.toString();
    url = url.replace(/^.{3}/g, changeTo);
    navigate('/' + url);
}

export function getPost(postId) {
    return posts.find(({id}) => postId === id);
}

export function getPosts() {
    return posts.map(({id, title_en, title_hu,excerpt_hu,excerpt_en, date, image}) => ({id, title_en, title_hu, excerpt_hu, excerpt_en, date, image}));
}
