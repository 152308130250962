import React from "react";
import AnimatedCursor from "react-animated-cursor";

const Cursor = () => {
    return (
        <div className="cursor">
            <AnimatedCursor
                color="255,255,255"
                innerSize={6}
                outerSize={40}
                innerScale={1}
                outerScale={2}
                outerAlpha={1}
                trailingSpeed={3.4}
                hasBlendMode={true}
                outerStyle={{
                    mixBlendMode: 'difference'
                }}
                innerStyle={{
                    backgroundColor: 'white',
                    mixBlendMode: 'exclusion'
                }}
            />
        </div>
    );
};

export default Cursor;
