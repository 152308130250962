import React, {useState, createRef, useRef} from "react";
import {Link} from "react-router-dom";
import {routesConfig} from "../config/routes";
import ScrollSpy from 'react-scrollspy-navigation';

const ChaptersMenu = ({ title = "Chapter select", menu = "Chapters", chapters }) => {
    const [isActive, setIsActive] = useState(false);
    const [isUserOpened, setIsUserOpened] = useState(false);
    const triggerRef = useRef();
    const chaptersDOM = [];

    if (chapters) {
        chapters.map(function (chapter, index) {
            if (chapter.next) {
                const filterRoutes = routesConfig.filter(item => item.name.includes(chapter.next))[0];

                if (filterRoutes) {
                    chaptersDOM.push(
                        <Link key={chapter.next} to={filterRoutes.path} className="chapters-menu__item chapters-menu__item--next-project caption"
                        >
                            {chapter.title}
                        </Link>
                    )
                }
            } else {
                return (
                    chaptersDOM.push(
                        <a onClick={triggerClick} key={chapter.title + '-' + index} className="chapters-menu__item" href={'#' + chapter.id} ref={createRef()}>
                            {chapter.title}
                        </a>
                    )
                )
            }
        })
    }

    function triggerClick(event) {
        if (event) {
            if (event.target.classList.contains('chapters-menu__item')) {
                setIsUserOpened(false);
                setIsActive(false);
                return;
            }
            if (event.target && !event.target.classList.contains('chapters-menu__close')) {
                setIsUserOpened(true);
                setIsActive(true);
            } else {
                setIsUserOpened(false);
                setIsActive(false);
            }
        }
    }

    window.addEventListener('scroll', function (event) {
        if (window.pageYOffset < 700 && isActive) {
            triggerClick();
        }
    })

    return (
        <div className={`chapters-menu ${isActive ? 'chapters-menu--opened' : ''}`}>
            <div className="chapters-menu__content">
                <span onClick={triggerClick} className={`chapters-menu__close ${isActive ? 'chapters-menu__close--visible' : ''}`} />
                <ScrollSpy>
                    {chaptersDOM}
                </ScrollSpy>
                <a
                    ref={triggerRef}
                    className={`chapters-menu__title ${isActive ? 'active' : ''}`}
                    onClick={triggerClick}>
                    {isActive ? menu : title }
                </a>
            </div>
        </div>
    );
};

export default ChaptersMenu;
