import React from "react";
import { useParams } from "react-router-dom";

const Post4 = () => {
    const { id, lang } = useParams();

    return (
        <>
            {lang === "hu"
                ?
                <>
                    <article className="blog__article">
                        <p>Ültem a Luton felé tartó gépen felszállás előtt és be voltam szarva. Nem a repülés miatt, azt már csináltam, és voltam már Angliában is. Diával elmentünk a Wembley stadionba megnézni Eminem-et és Dr Dre-t. Elég epic volt a koncert, adtam nagyon a mászkálást Londonban. Pár nap alatt nagyon sok mindent lehet arra látni, de igazából csak a felszínt kapirgálja az ember. Szóval a parám abból fakadt, hogy full vakon voltam, hogy mi lesz.</p>
                        <p>Ülök a gépen, feladtam egy bőröndöt olyan egy hónapra elég cuccal, és könnyes búcsút vettem Diától. 19’ februárja van, az úti célom Hatfield, amiről semmit sem tudok, csak annyit, egy kis város Londontól északra az aglomerációban. Van ez a projektünk az EE-vel, ami, mióta megvették tulajdonképpen British Telecom, szóval egy telekommunikációs cég. A projektre egy ideje locsolják a pénzt a megrendelő oldalról, de nem kaptak érte semmi olyat ami kell nekik tőlünk. Egy nagy Indiai cégnél nyomom éppen, sok az ügyfél, nagyon sok országban.</p>
                        <p>Amolyan Ponyvaregény Wolf karakter vagyok a kollegák szemében, akik megszereztek maguknak a projektre. Tőlem várják, hogy megszervezzem és megcsináljam egy hónap alatt, amire mások nem voltak képesek az elmúlt egy évben. Mondjuk ezt most még nem tudom, legalábbis holnapig. Egyenlőre csak érzem, hogy ez nem lesz séta menet.</p>
                        <p className="spotify box no-padding--top">
                            <iframe
                                src="https://open.spotify.com/embed/track/6PgVDY8GTkxF3GmhVGPzoB?utm_source=generator&theme=0"
                                width="100%" height="152" frameBorder="0" allowFullScreen=""
                                className="no-margin--top"
                                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                loading="lazy"></iframe>
                        </p>
                        <p>Felszállás, leszállás, börőnd begyűjtés, határellenőrzés, taxi, végállomás Hatfield Travelodge Hotel. Nem valami jó ez a hely, egy hotelnek álcázott munkásszálló. Este esek be az ajtón, támasztják lent a pultot Írország, a recepción fogad Lengyelország és Románia, majd elkísér a lifthez Magyarország. Multikulti a kitaszítottakkal… legalábbis ilyen a vibe. Ezek az emberek szerintem nem akarnak itt lenni, csak hát ez maradt. Már fix, hogy lesz Brexit, és ezt érezni a morálon.</p>
                        <p>Van itt egy bevásárlóközpont olyan 10 perc sétára, oda megyek valami vacsora reményében. Találok egy-két helyet ahol van vegán kaja, és kikötök a Frankie & Benny’s-ben, ilyen amcsi diner stílusban nyomatják, olaszos konyhával, leküldök egy BBQ pizzát. Hirtelen jött ez az egész kiküldetés, egyik napról a másikra, végig beszélgetjük a vacsit Diával. Vissza tiplizek a hotelbe és leülök a gép elé valamit vázlatolni az alapján amit tudok, mert küldtek némi, ködös infót arról mi a feladat. Kb két órat beletolok, aztán beájulok olyan éjfél körül a Family Guy-ra.</p>
                        <p>A hotel reggeli zöld banán és éretlen sárga dinnye. Mondjuk tél van (nem mintha ez máshogy lenne nyáron a királyságban). Leverek egy earl grey-t. Az iroda 15 perc sétára van, legalább legyaloglom a bevitt néhány kalóriát, bár igazából azt remélem lesz elég savam legyőzni az éretlen gyümölcsöt mielőtt fájni kezdene. Nem lett. Izgalmas, hogy mi lesz mert nagyon keveset tudok itt most bármiről, az embereket sem ismerem.</p>
                        <p>A fogadó oldalon Sri a projekt menedzser és Saad, a téma szakértő (subject matter expert). Mindössze egyszer beszéltünk telefonon. Felvázolják a munkamenetet, és bemutatnak a jelenlegi designernek aki miatt végülis itt kell most lennem. Nem akarok igazságtalan lenni mert nem vele van csak a baj, hanem a projekt tervezéssel és a random beígért határidőkkel, ahogy az lenni szokott. Ő ilyen komótosabb arc, ami itt, a jelenlegi szituban inkább káros mint hasznos.</p>
                        <p className="box">Megrendeltek tőlünk egy alkalmazást, ami az EE hálózati mérnökeit, és ügyfélszolgálatát támogatná. A mondás az, hogy mivel a hálózati topológia nem vizuális csak adat alapú, illetve papíron van vagy valami random file-ban, ez nehezíti a munkát. Ja ez a topológia kb olyan mint egy alaprajz, csak nem mondjuk egy lakásnak, hanem egy internet hálózati kiépítésnek pl egy egész bevásárló központban. Az egyik csavar az, hogy nem csak azt akarják látni, hogy mi mivel van összedugva, de azt is, hogy egy épületen belül hol helyezkednek el, az eszközök és a kábelek. A másik csavar, hogy a UK összes hálózatát, kereshetően látni akarják. Erre kell egy olyan koncepció bizonyítást készíteni (Proof of Concept aka PoC) ami lehetőleg interaktív, szóval nem csak statikus képek, hanem valami kattingatható cucc.</p>
                        <p>Na hát ezzel sehogy sem állnak. Az egyedüli pozitív az Saad, aki nagyon képben van arról, mi kell egy mérnöknek és az ügyfélszolgálatosnak. A designer aki egy ideje ezt csinálja, mutatja hol tart, de az sajnos teljesen offos. Mutatom én is amit este vázlatoltam és ez megindítja a gondolat cunamit az emberekben, egész okés irányba indultam.</p>
                        <p>Körülbelül két napra van szükségem amíg olyan tízszazalékos megértésig eljutok (nagyon alap a tudásom a témában), de ezen a ponton, mivel a nyomás nagy a leadáson, ezzel kell beérnen. Az irodában vagyok tíz órát aztán, vacsi, és a hotelban kezdem a második műszakot. Napi átlag 16 órát beleteszek ebbe, hétvégén is. A munka-élet egyensúly egy ilyen értelmezhetetlen libernyák halandzsa számomra ezen a ponton.</p>
                        <p>Az ötödik este a hotelban, olyan tíz körül, lemegyek inni egy dzsúszt a bárba, hogy lazítsak pár percet. Ott vannak ezek az írek és már eléggé meg vannak menve agyügyileg. Hárman vannak, valami útépítés miatt jöttek Hatfield-be ha jól értem. Az egyik csukot szemmel lassúzik a tv előtt valami videóklipre, a másik néha maga elé kiabál valamit, üveges tekintettel, egy asztalnál ülve. És van ez a harmadik, elég hangos csávó a pultnál, aki nem hagy engem lazulni, és annyit kérdez mint egy gyerek. Valahogy szóba kerül Bulgária, mire mondom neki, hogy Dia félig bolgár, mire rákezd, hogy lakott ott évekig, legjobb hely, és wow micsoda véletlen. Ok, legyen, wow.</p>
                        <p>Aztán bedobja, hogy mivel technikailag földik vagyunk (?!… én Magyar, ő Ír, ez Anglia…de legyen) meghív egy sörre. Mondom ok, köcsögöm széken. Jön a sör, rákostolok aztán meg elmegyek a slozira egy egyesre. Mikor visszaérek a pulthoz észlelem, hogy hát ez a sör ez ilyen tiszta etil alkohol lehetett, mert elpárolgott. Üres a pohár. Nézem kérdőn az embert, hogy mi történt, mire megszólal, hogy</p>
                        <blockquote>Bolgár spanom, mivel meghívtunk egy körre, a következő a te köröd.<span>McPultember</span></blockquote>
                        <p>Az ember spanjai mintha valami repülősóba szippantottak volna, ott teremtek mellette a pultnál, mazsolázgatva mit isznak akkor most legközelebb. Ezen a ponton félig magyarul, félig angolul elmagyaráztam, hogy mit kívánok nekik, illetve, hogy azért még csergedezik ez ereimben némi gettó, mert csak lakótelepen nevelkedtem, és amit most előadnak az itt véget is ér. A néha felkiáltós egyre közelebb jön a fejemhez, és próbal meggyőzni, hogy bunkó szar vagyok, és, hogy tartozom nekik.</p>
                        <p>Ez volt a pont ahol elköszöntem, nem fajult el a helyzet, mert talán, valahol igazam volt ezt a tartozást illetően, amit még az a kevés, kollektív agysejtjük is processzálni tudott, és még elképzelhező, hogy el is fogadott. Amúgy is lejárt a húsz perces szünetem, mert még sok van hátra az estéből, nem mellesleg reggel négykor Indiával meetingelek a tervekről amik még csak készülnek.</p>
                        <p>Ja igen, a fejlesztése a terméknek amit tervezek Indiában fog történni. Hogy véletlen se zavarjam meg az ottani kollégák rutinját, vagyis hogy úgy kezdik a reggelt, hogy beszámolnak arról tegnap mit sikerült csinálni és ma mi lesz, nekem kell alkalmazkodni az ő időzónájukhoz. Ha esetleg nem jött át a szarkazmus ez egy masszív extra réteg szopatás a cég részéről.</p>
                        <p>Főleg mivel az első héten csak annyit hallgattam hajnalban tőlük, hogy a hat programozó arcnak egy darab (!) számítógépet sikerült beszerezni, szóval azt ülik körbe mint valami tábortüzet amíg nem lesz még gép. Ja és mind ezt úgy, hogy nincs még design, tehát amúgy se tudnak semmit kódolni, de azért megkértek, adjak valami feladatot, mert számlázni azért fogunk. Amikor már négy napja jó formán nem aludtam, ezek a képek, meg gondolatok, annyira képesek megtapadni az értelmemben, hogy mikor rám kerül a sor, hogy beszéljek, semmilyen nyelven se sikerül értlemeset mondanom. Szóval ez a hónap, ez nem lesz egy könnyű menet.</p>
                        <p className="spotify box no-padding--top">
                            <iframe
                                src="https://open.spotify.com/embed/track/3LHbk7L7mFQj0Wiv4dB9lR?utm_source=generator&theme=0"
                                width="100%" height="152" frameBorder="0" allowFullScreen=""
                                className="no-margin--top"
                                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                loading="lazy"></iframe>
                        </p>
                        <p><strong>Folyt. köv. Peace.</strong></p>
                    </article>
                </>
                :
                <>
                    <article className="blog__article">
                        <p>So, here I was, sitting on this plane headed towards Luton, freaking out a bit. Not about flying – been there, done that, even been to England before. Dia and I went to Wembley Stadium once to catch Eminem and Dr. Dre live. It was epic! I enjoyed sightseeing in London, you can see loads in a couple of days, and you are still just scratching the surface. So, my freak-out? It's because I was totally flying blind about what's next.</p>
                        <p>Here I am, on this plane, checked in a suitcase packed for a month, and I've said my tearful goodbyes to Dia. It's February 2019, and my destination is Hatfield. All I know? It's this little town north of London in the suburbs. Got this project with EE, you know, the telecom company that's basically British Telecom now. They've been pouring money into this project for a while from the client side, but they ain't getting what they need from us. Currently, I'm doing my thing at this big Indian company with many clients spanning dozens of countries.</p>
                        <p>So, in the eyes of my colleagues who snagged me for this project, I'm like Wolf, the character from Pulp Fiction. They expect me to organise and pull off in a month what others couldn't do in the past year. I don't know this for sure yet, at least not until tomorrow. But I'm getting this vibe that it ain't gonna be a walk in the park.</p>
                        <p className="spotify box no-padding--top">
                            <iframe
                                src="https://open.spotify.com/embed/track/6PgVDY8GTkxF3GmhVGPzoB?utm_source=generator&theme=0"
                                width="100%" height="152" frameBorder="0" allowFullScreen=""
                                className="no-margin--top"
                                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                loading="lazy"></iframe>
                        </p>
                        <p>Takeoff, touchdown, grab my suitcase, border check, taxi, final stop: Hatfield Travelodge Hotel. This place isn't exactly a gem, more like a working-class disguise for a hotel. I stroll in the door in the evening, and there they are, holding down the fort – Ireland at the bar, Poland and Romania on reception duty, then Hungary escorts me to the elevator. It's a multicultural mishmash with the outcasts... at least, that's the vibe. I reckon these folks don't wanna be here, but hey, it's what's left. Brexit's a done deal now, and you can feel it in the morale.</p>
                        <p>There's a shopping centre about a 10-minute walk away, so I head there hoping to find some dinner. There are a couple of spots with vegan options, so settle on Frankie & Benny’s – it's got that classic American diner vibe with an Italian twist. I order a BBQ pizza. This project came out of the blue, from one day to the next. I spent the entire dinner chatting with Dia about it. Back to the hotel, and sit in front of my laptop to sketch out some ideas based on the vague information they sent about the task. I put in about two hours, then crashed around midnight watching Family Guy.</p>
                        <p>Hotel breakfast: green bananas and unripe yellow melon. Winter, I guess (it’s not like this would be different in the summer in the UK). Wash it down with some Earl Grey. The office is a 15-minute walk away. I figure it’ll burn off a few calories at least, though honestly, I’m hoping my stomach can handle the unripe fruit before it starts burning. Nope, it’s not happening. Exciting times ahead. I don’t know much about anything here, and I don’t know anyone either.</p>
                        <p>On the receiving end, we've got Sri, the project manager, and Saad, the subject matter expert. We've only talked on the phone once. They lay out the workflow and introduce me to the current designer, who's the reason I gotta be here now. Now, I don't wanna throw shade unfairly 'cause it ain't just about him, it's about the project planning and those random promised deadlines, you know how it goes. He's got this laid-back, slow-thinker vibe, which in the current situation is more harmful than helpful.</p>
                        <p className="box">They've ordered an app from us that would support EE's network engineers and customer service. Since the network topology isn't up-to-date and visual, it's all data-based or on paper or some random file, which makes the job harder. By the way, this topology is like a blueprint, but not for a house, more like for setting up an internet network in a whole shopping centre, for example. One twist is they don't just wanna see what's connected to what, but also where devices and cables are located within a building. Another twist is that they want to see all of the UK's networks in a searchable form. So, we gotta whip up a Proof of Concept (aka PoC) that's preferably interactive, not just static images, something clickable.</p>
                        <p>Well, we are nowhere near this goal. The only silver lining is Saad, who's really clued in on what engineers and customer service folks need. The designer, who's been doing this for a while, shows where things are, but it's all just off. I show them what I sketched out in the evening, and it kickstarts a brainstorm in people's heads – heading in a decent direction.</p>
                        <p>I figure I need about two days to get to, like, ten per cent understanding (my knowledge of the subject is pretty basic), but at this point, with the pressure on for the deadline, I gotta settle for that. I'm in the office for ten hours, then dinner, and start the second shift at the hotel. I'm putting in an average of 16 hours a day, even on weekends. Work-life balance is this liberal mumbo-jumbo to me right now.</p>
                        <p>On the fifth evening at the hotel, around ten, I head down to the bar to grab a juice and chill for a few minutes. There are these Irish lads, already pretty mentally checked out. Three of 'em, came to Hatfield for some roadwork if I understood correctly. One dude with half-shut eyes is slow dancing in front of the TV, watching some music video; another is, occasionally, yelling something to himself with a glassy stare, sitting at a table. And then there's this third guy, loud as can be at the bar, not letting me relax, asking questions like a kid. Somehow, Bulgaria comes up, so I mention that Dia (my wife) is half Bulgarian, and he goes off because he lived there for years. It is the best place ever, and wow, what a coincidence. Sure, buddy, wow.</p>
                        <p>Then he throws in, since we're technically from the same hood (?!... I'm Hungarian, he's Irish, this is England... but sure), he buys me a beer. I'm like, okay, lad, cheers. The beer comes, I take a sip, and then I head to the loo for a quick break. When I get back to the bar, I realise this beer must've been pure ethyl alcohol 'cause it's evaporated. Empty glass. I give him this quizzical look, like, what happened, and he goes</p>
                        <blockquote>My Bulgarian mate, since we invited you for a round, the next one's on you.<span>McBarman</span></blockquote>
                        <p>It felt like his buddies had taken a hit of some sort of rocket fuel, planting themselves at the bar, pondering what to drink next. At this point, I half explained my wishes to them in Hungarian, half in English, that despite their efforts, a bit of my street-raised upbringing still courses through my veins, and what they're presenting now ends right here. The occasional yeller guy gets closer to my head, trying to convince me I'm a jerk and that I owe them.</p>
                        <p>That was my cue to bow out. The situation didn't escalate because maybe, somewhere deep down, they agreed with me about this debt thing, which even their few remaining collective brain cells could process and maybe even accept. Anyway, my twenty-minute break was up 'cause there's still plenty of evening left, not to mention I have a meeting at four in the morning with India about plans that are still in the works.</p>
                        <p>Oh yeah, the development of the product I'm planning will happen in India. Just so I don't accidentally disrupt the routine of my colleagues there, you know, how they start their mornings by reporting on what they managed to do yesterday and what's on the agenda for today, I have to adapt to their time zone. If sarcasm didn't come through, this is a massive extra layer of hassle from the company.</p>
                        <p>Especially since in the first week, all I heard from them in the nightly hours was that out of the six programmers, they managed to get hold of exactly one (!) computer, so they're huddling around it like it's a campfire until they get more machines. And they're doing all this without a design, so they can't code anything anyway, but they still asked me to give them some tasks 'cause, hey, we gotta bill. When I hadn't slept properly in four days, these images and thoughts stuck so firmly in my mind that when it was my turn to speak, I couldn't manage to say anything coherent in any language. So yeah, this month, it's not gonna be a walk in the park.</p>
                        <p className="spotify box no-padding--top">
                            <iframe
                                src="https://open.spotify.com/embed/track/3LHbk7L7mFQj0Wiv4dB9lR?utm_source=generator&theme=0"
                                width="100%" height="152" frameBorder="0" allowFullScreen=""
                                className="no-margin--top"
                                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                loading="lazy"></iframe>
                        </p>
                        <p><strong>To be concluded, peace.</strong></p>
                    </article>
                </>
            }
        </>
    );
}

export default Post4;
