import React, {useRef} from 'react';
import {motion, useInView} from "framer-motion";
import {isMobile} from 'react-device-detect';

import AnimatedLetters from "../transitions/AnimatedLetters";
import {verticalScrollOffsetMarginBase} from "../transitions/TransitionConstants";

interface Props {
    title: string;
    classList: string;
}

function ChapterTitle({title, classList}) {
    const ref = useRef(null);
    const isInView = useInView(ref, {margin: verticalScrollOffsetMarginBase, once: !!isMobile});

    return (
        <motion.section className={`${classList ? 'chapter-title ' + classList : 'section chapter-title' }`}>
            <h2 className="heading heading--2"
                ref={ref}
                style={{
                    opacity: isInView ? 1 : 0,
                    transition: "all 300ms ease-in-out"
                }}
            >
                <AnimatedLetters word={title} />
            </h2>
        </motion.section>
    );
}

export default ChapterTitle;
