import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/pagination';

import { EffectCards, Pagination } from 'swiper/modules';

const Carousel = ({children, classList}) => {
    return (
        <>
            <Swiper
                effect={'cards'}
                grabCursor={true}
                modules={[EffectCards, Pagination]}
                pagination={{
                    clickable: true
                }}
                className={`${classList ? "carousel " + classList : "carousel"}`}
            >
                {children && (
                    children
                )}
            </Swiper>
        </>
    )
}

export default Carousel;
