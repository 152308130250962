import React, {useRef, useEffect, useState} from 'react';
import {motion, useInView, useAnimation} from "framer-motion";
import {Link} from "react-router-dom";
import {isMobile} from 'react-device-detect';
import Icon from "../components/Icons";

function VideoBlock({classList, fade, width, poster, mp4, webm, visibleAmount = .2, transitionDirection, align}) {
    const controls = useAnimation();
    const ref = useRef(null);
    const replayRef = useRef(null);
    const videoObject = useRef(null);

    isMobile ? fade = false : fade;

    const isInView = useInView(ref, { amount: visibleAmount, once: false });

    const imageBlockVariants = {
        default: {
            hidden: {
                opacity: fade ? 0 : 1
            },
            visible: {
                opacity: 1,
                transition: {
                    duration: .3
                }
            }
        },
        left: {
            hidden: {
                opacity: fade ? 0 : 1,
                translateX: 50
            },
            visible: {
                opacity: 1,
                translateX: 0
            }
        },
        right: {
            hidden: {
                opacity: fade ? 0 : 1,
                translateX: -50
            },
            visible: {
                opacity: 1,
                translateX: 0
            }
        }
    }

    function videoFunctions(action) {
        if (action === 'play') {
            videoObject.current.muted = true;
            const promise = videoObject.current.play();
            replayRef.current.classList.remove('visible');

            if (promise !== undefined) {
                promise.catch(error => {
                    showReplay();
                }).then(() => {
                    videoObject.current.currentTime = 0;
                    videoObject.current.play();
                });
            }
            if (isMobile) {
                videoObject.current.addEventListener('ended', showReplay, false);
            }
        } else if (action === 'stop') {
            if (videoObject.current.currentTime > 0 && !videoObject.current.paused && !videoObject.current.ended && videoObject.current.readyState > videoObject.current.HAVE_CURRENT_DATA) {
                videoObject.current.pause();
                videoObject.current.currentTime = 0;
            }
        }

        function showReplay(e) {
            videoObject.current.currentTime = (videoObject.current.duration - 3);
            replayRef.current.classList.add('visible');
        }
    }

    function replayClick() {
        videoFunctions('play');
    }

    // Animation control
    useEffect(() => {
        if (isInView) {
            controls.start("visible");
            videoFunctions('play');
        } else {
            controls.start("hidden");
            videoFunctions('stop');
        }
    }, [controls, isInView]);

    // REFACTOR - check for empty props better than below, this causes empty spaces in the DOM
    return (
        <motion.div className={`${classList ? "video-block " + classList : "video-block"} ${align ? "video-block--align-" + align : ""}`}
                     ref={ref}
                     animate={controls}
                     variants={transitionDirection ? imageBlockVariants[transitionDirection] : imageBlockVariants["default"]}
                     initial="hidden"
                    style={{
                        maxWidth: width ? width : 'auto'
                    }}
        >
            <div ref={replayRef} className="video-block__replay-button" onClick={replayClick}>
                <div className="video-block__replay-button-content">
                    <Icon classList="icon--large icon--fill video-block__replay-button-icon" name="play" />
                    <span className="video-block__replay-button-text">Play</span>
                </div>
            </div>
            <video ref={videoObject} muted={true} playsInline preload="auto" loop={!isMobile} poster={`${poster ? poster : "/images/video-poster.jpg"}`}>
                {webm && (
                    <source src={webm} type="video/webm"/>
                )}
                {mp4 && (
                    <source src={mp4} type="video/mp4"/>
                )}
                Your browser does not support the video tag.
            </video>
        </motion.div>
    );
}

export default VideoBlock;
