import React, {useRef, useEffect} from 'react';
import {motion, useInView, useAnimation} from "framer-motion";
import {isMobile} from 'react-device-detect';

function ImageBlock({
                        classList,
                        caption,
                        captionClassList,
                        imageClassList,
                        fade = true,
                        image,
                        image2x,
                        width,
                        alt,
                        align,
                        visibleAmount = .4,
                        once,
                        transitionDirection
                    }) {
    const controls = useAnimation();
    const ref = useRef(null);

    isMobile ? once = true : once;

    const isInView = useInView(ref, {amount: visibleAmount, once: once});

    const imageBlockVariants = {
        default: {
            hidden: {
                opacity: fade ? 0 : 1,
            },
            visible: {
                opacity: 1,
                transition: {
                    duration: .3
                }
            }
        },
        left: {
            hidden: {
                opacity: fade ? 0 : 1,
                translateX: 50
            },
            visible: {
                opacity: 1,
                translateX: 0
            }
        },
        right: {
            hidden: {
                opacity: fade ? 0 : 1,
                translateX: -50
            },
            visible: {
                opacity: 1,
                translateX: 0
            }
        }
    }

    // Animation control
    useEffect(() => {
        if (isInView) {
            controls.start("visible");
        } else {
            controls.start("hidden");
        }
    }, [controls, isInView]);

    // REFACTOR - check for empty props better than below, this causes empty spaces in the DOM
    return (
        <motion.div
            className={`${classList ? "image-block " + classList : "image-block"} ${align ? "image-block--align-" + align : ""}`}
            ref={ref}
            animate={controls}
            variants={transitionDirection ? imageBlockVariants[transitionDirection] : imageBlockVariants["default"]}
            initial="hidden"
            style={{
                maxWidth: width ? width : 'auto'
            }}
        >
            {caption ? <p className={`${captionClassList ? "caption text-block__caption margin--bottom " + captionClassList : "caption text-block__caption margin--bottom"}`} dangerouslySetInnerHTML={{__html: caption}}/> : ''}
            <img className={`${imageClassList ? imageClassList : ""}`} src={image}
                 srcSet={`${image2x ? image2x + " 2x" : ""}`} alt={alt}/>
        </motion.div>
    );
}

export default ImageBlock;
