import React, {useRef, useState, useEffect, ReactNode} from "react";
import {motion, useScroll, useTransform, useSpring, useReducedMotion, useInView} from "framer-motion";
import {verticalScrollOffsetMarginLarge} from "./TransitionConstants";
import {isMobile} from 'react-device-detect';

type ParallaxProps = {
    element: ReactNode;
    offset?: number;
    clampInitial?: boolean;
    clampFinal?: boolean;
};

const Parallax = ({children, offset = 0, duration = 100, classList, once}: ParallaxProps): JSX.Element => {
    const prefersReducedMotion = useReducedMotion();
    const [elementTop, setElementTop] = useState(0);
    const [clientHeight, setClientHeight] = useState(0);
    const ref = useRef(null);
    const isInView = useInView(ref, { margin: verticalScrollOffsetMarginLarge, once: once ? once : false });
    let y;

    if (!isMobile) {
        const {scrollY} = useScroll();

        const initial = elementTop - clientHeight;
        const final = elementTop + offset;

        let calcDuration;

        if (offset < 0) {
            calcDuration = duration * -1;

            if (calcDuration < offset) {
                calcDuration = (offset + duration) * -1;

                if ((duration * -1) < offset) {
                    calcDuration = offset + duration;
                }
            } else {
                calcDuration = offset + duration;
            }
        } else {
            calcDuration = duration;
        }

        const yRange = useTransform(scrollY, [initial, final], [offset, calcDuration]);
        y = useSpring(yRange, {stiffness: 400, damping: 90});


        const positionElement = (element) => {
            if (!element) return;
            setElementTop(element.getBoundingClientRect().top + window.scrollY || window.pageYOffset);
            setClientHeight(window.innerHeight);
        }

        useEffect(() => {
            const element = ref.current;

            window.addEventListener("resize", positionElement(element));
            window.dispatchEvent(new Event('resize'));
            return () => window.removeEventListener("resize", positionElement(element));
        }, [ref, isInView]);

        React.useEffect(() => {
            // dispatch it once mounted
            window.dispatchEvent(new Event('resize'));
        }, []);

        // Don't parallax if the user has "reduced motion" enabled
        if (prefersReducedMotion) {
            return <>{element}</>;
        }
    }

    return (
        <>
            {isMobile ? (
                <div ref={ref} className={`${classList ? classList : ""}`}>
                    {children}
                </div>
            ) : (
                <motion.div ref={ref} style={{y}} className={`${classList ? "parallax " + classList : "parallax"}`}>
                    {children}
                </motion.div>
            )}
        </>
    );
};

export default Parallax;
