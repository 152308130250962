import React, {useRef, useEffect} from 'react';
import {motion, useInView, useAnimation} from "framer-motion";
import {isMobile} from 'react-device-detect';
import AnimatedLetters from "../transitions/AnimatedLetters";
import {verticalScrollOffsetMarginBase} from "../transitions/TransitionConstants";
import {pageTransitionDelay} from "./PageTransition";
import SlideTransition from "../transitions/SlideTransition";

function TextBlock({
               classList,
               headingClassList,
               outerContainer,
               fade = true,
               container,
               shifted,
               align,
               caption,
               captionClassList,
               title,
               titleClassList,
               text,
               textClassList,
               stats,
               list,
               once,
               transitionDirection
           }) {

    isMobile ? once = true : once;

    // Overlay background animation
    const textBlockVariants = {
        default: {
            hidden: {
                opacity: fade ? 0 : 1
            },
            visible: {
                opacity: 1,
                transition: {
                    duration: .3
                }
            }
        },
        left: {
            hidden: {
                opacity: fade ? 0 : 1,
                translateX: 50,
                transition: {
                    staggerChildren: 0.2,
                    staggerDirection: -1
                }
            },
            visible: {
                opacity: 1,
                translateX: 0,
                transition: {
                    staggerChildren: 0.2,
                    staggerDirection: 1
                }
            }
        },
        right: {
            hidden: {
                opacity: fade ? 0 : 1,
                translateX: -50,
                transition: {
                    staggerChildren: 0.2,
                    staggerDirection: -1
                }
            },
            visible: {
                opacity: 1,
                translateX: 0,
                transition: {
                    staggerChildren: 0.2,
                    staggerDirection: 1
                }
            }
        }
    }

    const listVariants = {
        hidden: {
            transition: {
                staggerChildren: 0.1,
                staggerDirection: -1
            }
        },
        visible: {
            transition: {
                staggerChildren: 0.1,
                staggerDirection: 1
            }
        }
    }

    // Overlay menu animation
    const childVariants = {
        default: {
            hidden: {
                opacity: fade ? 0 : 1
            },
            visible: {
                opacity: 1
            }
        },
        left: {
            hidden: {
                opacity: fade ? 0 : 1,
                translateX: 40
            },
            visible: {
                opacity: 1,
                translateX: 0
            }
        },
        right: {
            hidden: {
                opacity: fade ? 0 : 1,
                translateX: -40
            },
            visible: {
                opacity: 1,
                translateX: 0
            }
        }
    };

    let statsList = [];
    let listArray = [];
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, {margin: verticalScrollOffsetMarginBase, once: once});
    const displayHeader = !(!caption && !title);

    // Animation control
    useEffect(() => {
        if (isInView) {
            controls.start("visible");
        } else {
            controls.start("hidden");
        }
    }, [controls, isInView]);

    if (stats) {
        stats.map(function (item, index) {
            return (
                statsList.push(
                    <motion.li variants={childVariants["default"]} className="stats-list__item" key={index}>
                        <AnimatedLetters word={item.stat} fade={fade}
                                         classList="heading heading--2 outline transform--uppercase margin-sm--bottom"/>
                        <p className="stats-list__caption">{item.title}</p>
                    </motion.li>
                )
            )
        })
    }

    if (list) {
        list.map(function (item, index) {
            return (
                listArray.push(
                    <motion.li variants={childVariants["default"]} key={index}>
                        <SlideTransition transitionDirection={transitionDirection ? transitionDirection : "right"}>
                            {item.text}
                        </SlideTransition>
                    </motion.li>
                )
            )
        })
    }

    // REFACTOR - check for empty props better than below, this causes empty spaces in the DOM
    return (
        <div
            className={`text-block ${classList ? classList : ""} ${align ? "align--" + align : ""} ${shifted ? "text-block--shifted" : ""} ${outerContainer ? "container container--" + outerContainer : "container"}`}>
            <motion.div className={`${container ? "container container--" + container : "container"}`}
                        ref={ref}
                        animate={controls}
                        variants={transitionDirection ? textBlockVariants[transitionDirection] : textBlockVariants["default"]}
                        initial="hidden"
            >
                {displayHeader &&
                    <motion.hgroup
                        className={`${shifted ? "text-block__heading text-block__heading--shifted" : "text-block__heading"} ${headingClassList ? headingClassList : ""}`}
                        variants={transitionDirection ? childVariants[transitionDirection] : childVariants["default"]}
                    >
                        <h4 className={`caption text-block__caption ${captionClassList ? captionClassList : ""} ${caption ? "" : "hidden"}`}>{caption}</h4>
                        <h3 className={`heading heading--3 ${titleClassList ? titleClassList : ""} ${title ? "" : "hidden"}`}>{title}</h3>
                    </motion.hgroup>
                }
                {text ? <motion.p
                    variants={transitionDirection ? childVariants[transitionDirection] : childVariants["default"]}
                    className={`${textClassList ? "text-block__text " + textClassList : "text-block__text"} ${align ? "align--" + align : ""}`}
                    dangerouslySetInnerHTML={{__html: text}}/> : ''}
                {statsList.length > 0 &&
                    <motion.ul variants={listVariants} className="stats-list">
                        {statsList}
                    </motion.ul>
                }
                {listArray.length > 0 &&
                    <motion.ul variants={listVariants}>
                        {listArray}
                    </motion.ul>
                }
            </motion.div>
        </div>
    );
}

export default TextBlock;
