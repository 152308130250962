import React, {useRef, useEffect} from "react";
import {motion, useAnimation, useInView} from "framer-motion";
import {verticalScrollOffsetMarginLarge} from "../transitions/TransitionConstants";
import AnimatedLetters from "../transitions/AnimatedLetters";
import {isMobile} from 'react-device-detect';

const drawPathVariants = {
    hidden: {
        pathLength: 0,
        transition: {
            duration: .75,
            ease: "easeInOut"
        }
    },
    visible: {
        pathLength: 1,
        transition: {
            duration: 1.2,
            ease: "easeInOut"
        }
    }
}

// REFACTOR make this more portable

export default function DrawPath({width, height, absolute, paths, classList, once}) {
    const controls = useAnimation();
    const ref = useRef(null);

    isMobile ? once = true : once;

    const isInView = useInView(ref, { margin: verticalScrollOffsetMarginLarge, once: once });
    const pathList = [];

    paths.map(function(item, index) {
        return (
            pathList.push(
                <motion.path
                    key={index}
                    d={item.path}
                    fill={item.fill ? item.fill : 'none'}
                    stroke={item.color ? item.color : 'var(--color-accent-primary)'}
                    strokeWidth={item.strokeWidth ? item.strokeWidth : 'var(--border-width-base)'}
                    strokeLinecap="round"
                    animate={controls}
                    variants={drawPathVariants}
                />
            )
        )
    })

    // Animation control
    useEffect(() => {
        if (isInView) {
            controls.start("visible");
        } else {
            controls.start("hidden");
        }
    }, [controls, isInView]);

    return (
        <div className={`${absolute ? 'draw draw--absolute' : 'draw' } ${classList ? classList : '' }`}>
            {pathList.length > 0 &&
                <svg className="display--block" ref={ref} xmlns="http://www.w3.org/2000/svg" width={width ? width : '1440'} height={height ? height : '500'}>
                    {pathList}
                </svg>
            }
        </div>
    );
}
