import React from 'react';
import {motion} from "framer-motion";
import {isMobile} from 'react-device-detect';
import { Helmet } from 'react-helmet-async';

import Hero from "../components/Hero";
import pageTransition, {pageTransitionDuration, pageTransitionVariants} from "../components/PageTransition";
import SlideTransition from "../transitions/SlideTransition";
import Reveal from "../transitions/Reveal";
import Parallax from "../transitions/Parallax";
import TextBlock from "../components/TextBlock";
import ImageBlock from "../components/ImageBlock";
import VideoBlock from "../components/VideoBlock";
import ChapterTitle from "../components/ChapterTitle";
import DrawPath from "../components/DrawPath";
import NextProject from "../components/NextProject";
import ChaptersMenu from "../components/ChaptersMenu";

const Wrapless = () => {
    return (
        <>
            <Helmet>
                <title>Case Study: Wrapless</title>
            </Helmet>
            <motion.div
                className="page project brand--wrapless"
                variants={pageTransitionVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
            >
                <Hero
                    title="Wrapless"
                    captionLeftTitle="Type"
                    captionLeftMeta="Case study"
                    captionRightTitle="Role"
                    captionRightMeta="Founder"
                    image="../images/wrapless/hero.min.png"
                    image2x="../images/wrapless/hero@2x.min.png"
                />

                <ChaptersMenu chapters={[
                    {id: 'discovery', title: 'Discovery'},
                    {id: 'ux-design', title: 'UX Design'},
                    {id: 'visual-identity', title: 'Visual Identity'},
                    {id: 'ui-design', title: 'UI Design'},
                    {id: 'how-it-works', title: 'How It Works'},
                    {id: 'next-project', title: 'Next project', next: 'End-to-End'}
                ]} />

                <section className="section">
                    <div className="container container--1000">
                        <div className="row">
                            <div className="col-lg-8">
                                <TextBlock
                                    caption="Backstory"
                                    text="We all have expat friends. Staying in touch has never been easier, but moving abroad makes some social aspects difficult. One of these is the challenge of gifting. We have tried to recreate the magic of gifting a loved one just like doing it in person - with warmth and added elements of surprise."
                                    shifted
                                />
                            </div>
                            <div className="col-lg-4">
                                <TextBlock
                                    caption="Responsibilities"
                                    classList="margin-hg--top"
                                    text="<ul><li>Founder</li><li>Branding</li><li>Service Design</li><li>Web Design</li></ul>"
                                    align="right"
                                    shifted
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <div id="discovery">
                    <section className="section no-padding--top">
                        <ChapterTitle title="Discovery"/>
                        <TextBlock
                            classList="margin-xl--bottom"
                            caption="Key questions"
                            textClassList="heading heading--3"
                            text="How can I send a gift to my expat friend when I only know their email address? How can I keep my gift a secret until they receive it? What if they'd prefer else?"
                            shifted
                        />
                        <div className="container container--900">
                            <TextBlock
                                caption="Survey & Interviews"
                                text="We asked expats in the UK and people with expat friends or relatives living there about their gifting habits. We wanted to learn about their gifting practices before the change in their situations and whether they find gifting more difficult now. We also wanted to understand how they perceive virtual gifts instead of physical ones."
                                container="700"
                                shifted
                            />
                        </div>
                        <div className="container container--1000">
                            <div className="wrapless__surveys">
                                <div
                                    className="wrapless__surveys-item section__row section__row--narrow section__row--right align-items--center">
                                    <TextBlock
                                        classList="hidden show--on-md"
                                        caption="Results"
                                        align="right"
                                        shifted
                                    />

                                    <SlideTransition transitionDirection="right">
                                        <img src="/images/wrapless/chart-1.svg" alt="Wrapless Chart 1"
                                             className="wrapless__surveys-image margin-tablet--bottom"/>
                                    </SlideTransition>
                                    <TextBlock
                                        classList="margin-md--left no-margin-md--left"
                                        caption="Results"
                                        text="The segment of participants answered that they find gifting too much of a hassle or need more necessary information."
                                        shifted
                                        align="right"
                                        transitionDirection="left"
                                    />
                                </div>
                                <div
                                    className="wrapless__surveys-item section__row section__row--narrow section__row--left align-items--center margin-sm--top--neg no-margin-tablet--top">
                                    <SlideTransition transitionDirection="right">
                                        <img src="/images/wrapless/chart-2.svg" alt="Wrapless Chart 2"
                                             className="wrapless__surveys-image"/>
                                    </SlideTransition>
                                    <TextBlock
                                        classList="margin-md--left no-margin-md--left"
                                        text="The segment of participants who answered that they would send a real, physical gift in a virtual package."
                                        transitionDirection="left"
                                    />
                                </div>
                                <div
                                    className="wrapless__surveys-item section__row section__row--narrow section__row--right reverse--tablet align-items--center margin-lg--top--neg no-margin-tablet--top">

                                    <TextBlock
                                        classList="margin-md--right no-margin-md--right"
                                        text="The segment of participants answered that they usually have second thoughts about whether their gift suits the recipient."
                                        align="right"
                                        transitionDirection="right"
                                    />
                                    <SlideTransition transitionDirection="left">
                                        <img src="/images/wrapless/chart-3.svg" alt="Wrapless Chart 3"
                                             className="wrapless__surveys-image"/>
                                    </SlideTransition>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section section-bg--black">
                        <div className="container container--1000">
                            <TextBlock
                                classList="margin-xl--bottom"
                                caption="Pain points"
                                text="By evaluating our survey/interview results, we identified six pain points. These points were the basis of our work on the core features of our MVP."
                                container="700"
                                shifted
                            />
                            <div className="padding-md--left padding-md--bottom">
                                <div className="row margin-md--bottom">
                                    <motion.div className="col-lg-6 align-items--center flex-direction--row margin-sm--bottom"
                                                initial={{opacity: 0}}
                                                whileInView={{opacity: 1}}
                                                viewport={{once: false}}
                                    >
                                        <h4 className="heading heading--4 no-margin--bottom margin-md--right heading--circled">1</h4>
                                        <p>Don’t have an idea what to buy</p>
                                    </motion.div>
                                    <motion.div className="col-lg-6 align-items--center flex-direction--row"
                                                initial={{opacity: 0}}
                                                whileInView={{opacity: 1}}
                                                viewport={{once: false}}
                                    >
                                        <h4 className="heading heading--4 no-margin--bottom margin-md--right heading--circled">2</h4>
                                        <p>Second thoughts about the chosen gift</p>
                                    </motion.div>
                                </div>
                                <div className="row margin-md--bottom">
                                    <motion.div className="col-lg-6 align-items--center flex-direction--row margin-sm--bottom"
                                                initial={{opacity: 0}}
                                                whileInView={{opacity: 1}}
                                                viewport={{once: false}}
                                    >
                                        <h4 className="heading heading--4 no-margin--bottom margin-md--right heading--circled">3</h4>
                                        <p>Don’t know which stores are shipping to the UK</p>
                                    </motion.div>
                                    <motion.div className="col-lg-6 align-items--center flex-direction--row"
                                                initial={{opacity: 0}}
                                                whileInView={{opacity: 1}}
                                                viewport={{once: false}}
                                    >
                                        <h4 className="heading heading--4 no-margin--bottom margin-md--right heading--circled">4</h4>
                                        <p>Lack of necessary information like clothing size, address</p>
                                    </motion.div>
                                </div>
                                <div className="row">
                                    <motion.div className="col-lg-6 align-items--center flex-direction--row margin-sm--bottom"
                                                initial={{opacity: 0}}
                                                whileInView={{opacity: 1}}
                                                viewport={{once: false}}
                                    >
                                        <h4 className="heading heading--4 no-margin--bottom margin-md--right heading--circled">5</h4>
                                        <p>Surprise is spoiled by asking for details from the recipient</p>
                                    </motion.div>
                                    <motion.div className="col-lg-6 align-items--center flex-direction--row"
                                                initial={{opacity: 0}}
                                                whileInView={{opacity: 1}}
                                                viewport={{once: false}}
                                    >
                                        <h4 className="heading heading--4 no-margin--bottom margin-md--right heading--circled">6</h4>
                                        <p>Verify that the gift has arrived without asking</p>
                                    </motion.div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section no-padding-sm--bottom">
                        <div className="container">
                            <TextBlock
                                classList="no-margin-sm--bottom"
                                caption="Personas"
                                text="We have created two personas to focus on based on our survey results:"
                                align="center"
                            />
                            <div className="wrapless__personas">
                                <Parallax duration={40}>
                                    <ImageBlock image="/images/wrapless/persona-1.svg"
                                                alt="Wrapless Persona 1"
                                                classList="wrapless__personas-item wireframe"
                                                transitionDirection="left"
                                                fade={false}
                                    />
                                </Parallax>
                                <Parallax offset={-30} duration={-30}>
                                    <ImageBlock image="/images/wrapless/persona-2.svg"
                                                alt="Wrapless Persona 2"
                                                classList="wrapless__personas-item wireframe"
                                                transitionDirection="right"
                                                fade={false}
                                    />
                                </Parallax>
                            </div>
                        </div>
                    </section>
                </div>

                <div id="ux-design">
                    <ChapterTitle title="UX Design"/>
                    <section className="section">
                        <TextBlock
                            caption="Primary goal"
                            text="To send a gift, you only have to know three things:<br class='hide--on-md' />email address, name and the date you choose."
                            textClassList="heading heading--3"
                            align="center"
                        />
                    </section>
                    <section className="section">
                        <TextBlock
                            headingClassList="no-margin--bottom"
                            captionClassList="no-margin--bottom"
                            classList="align--center"
                            caption="User journey (High-level process flow)"
                        />
                        <ImageBlock image="/images/wrapless/user-journey.png"
                                    image2x="/images/wrapless/user-journey@2x.png"
                                    alt="Wrapless User Journey"
                                    classList="wrapless__user-journey"
                                    visibleAmount={.2}
                        />
                    </section>
                    <section className="section section--framed">
                        <TextBlock
                            classList="margin-xl--bottom"
                            caption="Wireframes"
                            text="Wrapless has four key pages: the home page, browse gifts, product page and lastly, the unboxing page, which we identified to be critical for the project."
                            container="600"
                            align="right"
                            shifted
                        />
                        <div className="container">
                            <div className="grid grid--2-columns">
                                <div className="grid__row">
                                    <div className="grid__column grid__column--rows">
                                        <div className="margin-lg--bottom">
                                            <Parallax offset={30}>
                                                <>
                                                    <TextBlock
                                                        caption="Home page"
                                                        headingClassList="no-margin--bottom"
                                                        captionClassList="no-margin--bottom"
                                                    />
                                                    <ImageBlock
                                                        classList="wireframe no-margin--bottom"
                                                        image="/images/wrapless/home-wireframe.png"
                                                        image2x="/images/wrapless/home-wireframe@2x.png"
                                                        alt="Wrapless Projects Wireframe"
                                                        visibleAmount={.1}
                                                        once
                                                    />
                                                </>
                                            </Parallax>
                                        </div>
                                        <div className="padding-xl--left padding-md--right">
                                            <Parallax offset={40} duration={100}>
                                                <>
                                                    <TextBlock
                                                        caption="Unboxing"
                                                        headingClassList="no-margin--bottom"
                                                        captionClassList="no-margin--bottom"
                                                    />
                                                    {isMobile ? (
                                                        <VideoBlock
                                                            classList="wireframe no-margin--bottom"
                                                            poster="/images/wrapless/unboxing-wireframe.png"
                                                            mp4="/videos/wrapless/unboxing-wireframe.mobile.mp4"
                                                            once
                                                        />
                                                    ) : (

                                                        <VideoBlock
                                                            classList="wireframe no-margin--bottom"
                                                            poster="/images/wrapless/unboxing-wireframe.png"
                                                            mp4="/videos/wrapless/unboxing-wireframe.mp4"
                                                            once
                                                        />
                                                    )}
                                                </>
                                            </Parallax>
                                        </div>
                                    </div>
                                    <div className="grid__column grid__column--rows">
                                        <div
                                            className="padding-xl--left padding-hg--top padding-lg--bottom padding-md--right">
                                            <Parallax offset={-20} duration={40}>
                                                <>
                                                    <TextBlock
                                                        caption="Gift list overlay"
                                                        headingClassList="no-margin--bottom"
                                                        captionClassList="no-margin--bottom"
                                                    />
                                                    <VideoBlock
                                                        classList="wireframe no-margin--bottom"
                                                        poster="/images/wrapless/gift-list-overlay-wireframe.png"
                                                        mp4="/videos/wrapless/gift-list-wireframe.mp4"
                                                        once
                                                    />
                                                </>
                                            </Parallax>
                                        </div>
                                        <div className="padding-xl--top">
                                            <Parallax offset={40}>
                                                <>
                                                    <TextBlock
                                                        caption="Browse gifts"
                                                        headingClassList="no-margin--bottom"
                                                        captionClassList="no-margin--bottom"
                                                    />
                                                    <ImageBlock
                                                        classList="wireframe no-margin--bottom"
                                                        image="/images/wrapless/browse-gifts-wireframe.png"
                                                        image2x="/images/wrapless/browse-gifts-wireframe@2x.png"
                                                        alt="Wrapless Browse Gif"
                                                        visibleAmount={.1}
                                                        once
                                                    />
                                                </>
                                            </Parallax>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div id="visual-identity">
                    <ChapterTitle title="Visual Identity"/>
                    <section className="section section--framed">
                        <TextBlock
                            classList="margin-xl--bottom"
                            caption="Identify"
                            textClassList="heading heading--3"
                            text="A unique visual identity was a vital element of the project, with expectations of a clean but warm vibe that matched the gifting experience."
                            shifted
                        />
                        <TextBlock
                            classList="margin-xl--bottom"
                            caption="Colour palette"
                            text="Since we decided to target the UK only for our MVP, I designed a narrow colour palette dominated by red and blue."
                            align="right"
                            container="600"
                            shifted
                        />
                        <div className="container">
                            <div className="wrapless__colors">
                                <div className="wrapless__colors-item overflow-hidden">
                                    <Reveal transitionDirection="top">
                                        <div className="wrapless__colors-box wrapless__colors-box--red">&nbsp;</div>
                                    </Reveal>
                                    <TextBlock
                                        text="<p class='margin--bottom caption'>Red</p><p>#E84C3D</p><ul><li><span>R</span>232</li><li><span>G</span>76</li><li><span>B</span>61</li></ul>"
                                    />
                                </div>
                                <div className="wrapless__colors-item">
                                    <Reveal transitionDirection="left">
                                        <div
                                            className="wrapless__colors-box wrapless__colors-box--dark-blue">&nbsp;</div>
                                    </Reveal>
                                    <TextBlock
                                        text="<p class='margin--bottom caption'>Dark Blue</p><p>#34495E</p><ul><li><span>R</span>52</li><li><span>G</span>73</li><li><span>B</span>94</li></ul>"
                                    />
                                </div>
                                <div className="wrapless__colors-item wrapless__colors-item--half">
                                    <Reveal transitionDirection="bottom">
                                        <div className="wrapless__colors-box wrapless__colors-box--green">&nbsp;</div>
                                    </Reveal>
                                    <TextBlock
                                        text="<p class='margin--bottom caption'>Green</p><p>#27B899</p><ul><li><span>R</span>36</li><li><span>G</span>184</li><li><span>B</span>153</li></ul>"
                                    />
                                </div>
                                <div className="wrapless__colors-item wrapless__colors-item--half">
                                    <Reveal transitionDirection="right">
                                        <div
                                            className="wrapless__colors-box wrapless__colors-box--light-blue">&nbsp;</div>
                                    </Reveal>
                                    <TextBlock
                                        text="<p class='margin--bottom caption'>Light Blue</p><p>#43A1E2</p><ul><li><span>R</span>67</li><li><span>G</span>161</li><li><span>B</span>226</li></ul>"
                                    />
                                </div>
                                <div className="wrapless__colors-item wrapless__colors-item--quarter">
                                    <Reveal transitionDirection="top">
                                        <div className="wrapless__colors-box wrapless__colors-box--yellow">&nbsp;</div>
                                    </Reveal>
                                    <TextBlock
                                        text="<p class='margin--bottom caption'>Yellow</p><p>#FABE3A</p><ul><li><span>R</span>250</li><li><span>G</span>190</li><li><span>B</span>58</li></ul>"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section">
                        <div className="container container--1000">
                            <TextBlock
                                classList="margin-xl--bottom"
                                caption="Typography"
                                text="The chosen font for headers, titles and copy was 'Montserrat'. The 'Indie Flower' font was used across the visuals for captions."
                                align="center"
                                container="600"
                                shifted
                            />
                            <div className="grid grid--4-columns grid--tablet--2-columns">
                                <div className="grid__row">
                                    <ImageBlock image="/images/wrapless/typo-1.svg"
                                                classList="grid__column justify-content--center"
                                                alt="Wrapless Typo 1"
                                    />
                                    <ImageBlock image="/images/wrapless/typo-2.svg"
                                                classList="grid__column justify-content--center"
                                                alt="Wrapless Typo 2"
                                    />
                                    <ImageBlock image="/images/wrapless/typo-3.svg"
                                                classList="grid__column justify-content--center"
                                                alt="Wrapless Typo 3"
                                    />
                                    <ImageBlock image="/images/wrapless/typo-4.svg"
                                                classList="grid__column justify-content--center"
                                                alt="Wrapless Typo 4"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section no-padding-sm--inline no-padding-sm--block">
                        <DrawPath
                            absolute
                            paths={[
                                {
                                    path: 'M1663 451.253C1307.82 428.487 1048.97 265.08 879.658 120.813C710.347 -23.4542 494.234 -44.988 247.507 99.3296C157.274 152.11 75.1043 164.245 1 135.734',
                                    color: 'var(--primary-color)'
                                },
                                {
                                    path: 'M1 1C111.521 172.878 248.752 211.273 412.692 116.185C658.602 -26.4482 853.527 -54.4958 1042.75 137.417C1149.83 236.499 1423.8 437.931 1663 451',
                                }
                            ]}
                        />
                        <Parallax offset={200} duration={-300}>
                            <ImageBlock image="/images/wrapless/logo-section-box.svg"
                                        alt="Wrapless Gift Box"
                                        imageClassList="margin-auto--left"
                                        visibleAmount={.1}
                                        once={true}
                            />
                        </Parallax>
                    </section>
                    <section className="section no-padding-sm--top">
                        <TextBlock
                            classList="margin-xl--bottom"
                            caption="Logo"
                            text="The character alignment in the Wraples logo was inspired by ribbons wrapped around a gift box."
                            shifted
                            container="400"
                        />
                        <div className="container container--500">
                            <ImageBlock image="/images/wrapless/logo-1.svg"
                                        classList="margin-lg--bottom"
                                        imageClassList="margin-inline--auto"
                                        alt="Wrapless Logo 1"
                                        visibleAmount={.1}
                            />
                            <ImageBlock image="/images/wrapless/logo-2.svg"
                                        imageClassList="margin-inline--auto"
                                        alt="Wrapless Logo 2"
                                        visibleAmount={.1}
                            />
                        </div>
                    </section>
                </div>

                <div id="ui-design">
                    <ChapterTitle title="UI Design"/>
                    <section className="section">
                        <TextBlock
                            caption="Component library"
                            text="I designed and developed a design system to give the brand a visual depth and consistency across platforms and displays."
                            textClassList="heading heading--3"
                            container="900"
                            shifted
                        />
                    </section>
                    <div className="grid grid--2-columns">
                        <div className="grid__row">
                            <div className="grid__column grid__column--rows">
                                <Parallax offset={30} duration={40} classList="margin-auto--left margin-lg--bottom margin-tablet-lg--bottom margin-auto-md--right wrapless__ui-design--how-it-works-blocks">
                                    <Reveal transitionDirection="top" addBoxShadow once={true}>
                                        <VideoBlock
                                            classList="no-margin--bottom"
                                            poster="/images/wrapless/home-box-1@2x.png"
                                            mp4="/videos/wrapless/how-it-works-blocks.mp4"
                                            once={true}
                                            visibleAmount={.1}
                                        />
                                    </Reveal>
                                </Parallax>
                                <Parallax offset={-20} duration={90} classList="margin-auto--left margin-lg--right margin-xl--bottom hide--on-md">
                                    <Reveal transitionDirection="right" addBoxShadow>
                                        <ImageBlock image="/images/wrapless/button.svg"
                                                    classList="no-margin--bottom"
                                                    alt="Wrapless Button"
                                                    fade={false}
                                                    visibleAmount={.1}
                                        />
                                    </Reveal>
                                </Parallax>
                                <Parallax classList="margin-auto--left margin-hg--right margin-md-lg--bottom margin-auto-md--left margin-auto-md--right"
                                    offset={-20} duration={120}>
                                    <Reveal transitionDirection="left" addBoxShadow once={true}>
                                        <ImageBlock classList="no-margin--bottom"
                                                    image="/images/wrapless/filters-sidebar.png"
                                                    image2x="/images/wrapless/filters-sidebar@2x.png"
                                                    alt="Wrapless Filters Sidebar"
                                                    fade={false}
                                                    once={true}
                                                    visibleAmount={.1}
                                        />
                                    </Reveal>
                                </Parallax>
                            </div>
                            <div className="grid__column grid__column--rows">
                                <Parallax classList="margin-hg--left margin-hg--top margin-hg--bottom no-margin-md--top margin-md-lg--bottom margin-auto-md--left margin-auto-md--right wrapless__ui-design--product-item"
                                    offset={-40} duration={30}>
                                    <Reveal transitionDirection="right" addBoxShadow>
                                        <VideoBlock
                                            classList="no-margin--bottom"
                                            poster="/images/wrapless/product-item@2x.png"
                                            mp4="/videos/wrapless/product-item.mp4"
                                            once={true}
                                            visibleAmount={.1}
                                        />
                                    </Reveal>
                                </Parallax>
                                <Parallax classList="margin-hg--top no-margin-md--top margin-auto-md--left margin-auto-md--right wrapless__ui-design--gift-list-overlay"
                                          offset={-55} duration={150}
                                >
                                    <Reveal transitionDirection="right" addBoxShadow>
                                        <VideoBlock
                                            classList="no-margin--bottom"
                                            poster="/images/wrapless/gift-list-overlay@2x.png"
                                            mp4="/videos/wrapless/gift-list-overlay.mp4"
                                            once={true}
                                            visibleAmount={.1}
                                        />
                                    </Reveal>
                                </Parallax>
                            </div>
                        </div>
                    </div>
                    <section className="section section--gray-lightest wrapless__ui-design--screens-grid">
                        <div className="wrapless__ui-design--screens-grid-background">&nbsp;</div>
                    </section>

                    <section className="section">
                        <TextBlock
                            classList="margin-xl--bottom"
                            caption="The checkout experience"
                            text="To achieve a close experience to real-life gifting, we showed the gifter how the email will be presented to the giftee, and we wrapped their presence into a digital box that the receiver will open."
                            align="right"
                            container="700"
                        />
                        <div className="section__container wrapless__ui-design--notebook-container">
                            <div className="section__row align-items--center">
                                <div className="device device--notebook wrapless__ui-design--notebook-neg-offset">
                                    <img src="/images/notebook.png" srcSet="/images/notebook@2x.png 2x"
                                         className="device__image" alt="Device Notebook"/>
                                    <div className="device__screen">
                                        <VideoBlock
                                            classList="no-margin--bottom"
                                            poster="/images/wrapless/checkout.png"
                                            mp4="/videos/wrapless/checkout@2x.mp4"
                                            once={true}
                                            visibleAmount={.1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <TextBlock
                            caption="Fully responsive"
                            text="The website was designed to support all devices, screens and major browsers."
                            container="400"
                        />
                        <div className="section__container no-padding">
                            <div className="section__row align-items--center">
                                <div className="device-composition device-composition--2-columns">
                                    <div className="device-composition__column">
                                        <div className="device-composition__section">
                                            <div className="device-composition__device">
                                                <Reveal transitionDirection="top" addBoxShadow once={true}
                                                        classList="device device--mobile device--mobile--landscape">
                                                            <>
                                                                <img src="/images/device-mobile-landscape.png"
                                                                     srcSet="/images/device-mobile-landscape@2x.png 2x"
                                                                     className="device__image"
                                                                     alt="Device Mobile Landscape"/>
                                                                <div className="device__screen">
                                                                    <img src="/images/wrapless/cart-overlay-mobile.png"
                                                                         srcSet="/images/wrapless/cart-overlay-mobile@2x.png 2x"
                                                                         alt="Wrapless Cart Overlay"/>
                                                                </div>
                                                            </>
                                                </Reveal>
                                            </div>
                                            <div className="device-composition__device">
                                                <Reveal transitionDirection="bottom" addBoxShadow once={true}
                                                        classList="device device--mobile">
                                                    <>
                                                        <img src="/images/device-mobile.png"
                                                             srcSet="/images/device-mobile@2x.png 2x"
                                                             className="device__image" alt="Device Mobile"/>
                                                        <div className="device__screen">
                                                            <img src="/images/wrapless/products-mobile.png"
                                                                 srcSet="/images/wrapless/products-mobile@2x.png 2x"
                                                                 alt="Wrapless Products Mobile"/>
                                                        </div>
                                                    </>
                                                </Reveal>
                                            </div>
                                        </div>
                                        <div className="device-composition__section device-composition__section--end">
                                            <div className="device-composition__device">
                                                <Reveal transitionDirection="left" addBoxShadow once={true}
                                                        classList="device device--tablet">
                                                    <>
                                                        <img src="/images/device-tablet.png"
                                                             srcSet="/images/device-tablet@2x.png 2x"
                                                             className="device__image" alt="Device Tablet"/>
                                                        <div className="device__screen">
                                                            <img src="/images/wrapless/claim-gift-form-tablet.png"
                                                                 srcSet="/images/wrapless/claim-gift-form-tablet@2x.png 2x"
                                                                 alt="Wrapless Claim Gift"/>
                                                        </div>
                                                    </>
                                                </Reveal>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="device-composition__column">
                                        <div
                                            className="device-composition__section device-composition__section--offset--top">
                                            <div className="device-composition__device">
                                                <Reveal transitionDirection="right" addBoxShadow once={true}
                                                        classList="device device--tablet">
                                                    <>
                                                        <img src="/images/device-tablet.png"
                                                             srcSet="/images/device-tablet@2x.png 2x"
                                                             className="device__image" alt="Device Tablet"/>
                                                        <div className="device__screen">
                                                            <img src="/images/wrapless/claim-gift-tablet.png"
                                                                 srcSet="/images/wrapless/claim-gift-tablet@2x.png 2x"
                                                                 alt="Wrapless Claim Gift 2"/>
                                                        </div>
                                                    </>
                                                </Reveal>
                                            </div>
                                        </div>
                                        <div className="device-composition__section">
                                            <div className="device-composition__device">
                                                <Reveal transitionDirection="top" addBoxShadow once={true}
                                                        classList="device device--mobile">
                                                    <>
                                                        <img src="/images/device-mobile.png"
                                                             srcSet="/images/device-mobile@2x.png 2x"
                                                             className="device__image" alt="Device Mobile"/>
                                                        <div className="device__screen">
                                                            <img src="/images/wrapless/products-mobile-filters.png"
                                                                 srcSet="/images/wrapless/products-mobile-filters@2x.png 2x"
                                                                 alt="Wrapless Products Filter Mobile"/>
                                                        </div>
                                                    </>
                                                </Reveal>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div id="how-it-works">
                    <ChapterTitle title="How it works"/>
                    <section className="section no-padding--bottom">
                        <TextBlock
                            caption="Creative video"
                            text="I designed and animated a short video about Wrapless. The goal was to show our viewers the idea behind our service in a playful way."
                            textClassList="heading heading--3"
                            shifted
                            container="900"
                        />
                    </section>
                    <section className="section padding-hg--bottom">
                        <div className="section__container section__container--framed">
                            <div className="embed">12
                                <div className="embed__youtube">
                                    <iframe
                                        src="https://www.youtube-nocookie.com/embed/2DsGSKfXS5k?rel=0&controls=0&autoplay=0&mute=0&start=0"
                                        frameBorder="0" allow="autoplay; encrypted-media;" allowFullScreen
                                        title="How Wrapless works"
                                        style={{
                                            position: 'absolute',
                                            left: '0',
                                            top: '0',
                                            width: '100%',
                                            height: '100%',
                                            pointerEvents: 'auto'
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

            </motion.div>
            <NextProject nextProject='End-to-End'/>
        </>
    );
}

export default Wrapless;
