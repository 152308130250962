import React from "react";
import { useParams } from "react-router-dom";

const Post1 = () => {
    const { id, lang } = useParams();

    return (
        <>
            {lang === "hu"
                ?
                    <article className="blog__article">
                        <hgroup className="blog__article-header">
                            <h2 className="heading heading--3 no-margin--bottom">Yo</h2>
                        </hgroup>
                        <p>Itthon, Budapesten. Nem tudom BP van e olyan izgalmas mint India. Na jó, tudom, és no-no-no. Itt megfeszült homlokerezetek találkoznak, sok évnyi felgyülemlett stresszel amikor dudálást hallasz. Indiában viszont ha nem hallassz dudát akkor fixen megsüketültél, de meg amúgy sem nem azért nyomják, hogy a jó édes anyád csuklani kezdjen, hanem szeretetből… mert mennének, te meg bámészkodsz mint egy barom, és nem akarják összekoszolni az arcodat a retkes kerekeikkel. Ha belegondolsz, ez nagyon figyelmes tőlük.</p>
                        <p className="box box--bordered"><b>Wot?!</b><br />
                            Mielőtt tovább mész, vagy lelépnél, vagy bánom is én mit találsz ki, elmondom mi ez az egész. Voltam megint Indiában, Mysore-ban jógázni két hónapot, másodszor. Ezúttal írtam egy blogot, néhány funny shit-ről, kultúráról, jógáról, kalandokról. Itt találod a Klisé Miséket (ez a címe): <a href="https://www.zoltanbank.com/cliche-blog">www.zoltanbank.com/cliche-blog</a>. Pereg a szófosásom magyar meg angol nyelven, az elején tudsz választani (akárcsak itt). Elég sok ember rácsüngött a témára, és többen köztük, pl anyám csak, hogy valakit említsek, mondták, hogy olvasnának tovább ha hazaértem. Egy ideje ülök ezen a gondolaton, de megtetszett ez a firkálgatás, hajnal egyóra van, már jobban széjjel unom a streaming szarokat mint a politikát, szóval inkább erre cseszem el az időm. Jó nem úgy nah-dzsízüsz! Úgy értettem, hogy szívem, lelkem beleteszem ezekbe a sorokba neked, fo’só (“for sure” Hunglish-ül ha nincs meg). Ha nem adod ezt akkor csinálj mást, de ha tetszik iratkozz fel azt szólok ha van folytatás. Persze az is okés ha néha visszanézel, ahogy akarod, ezen nem fogunk össze veszni.
                        </p>
                        <h2 className="heading heading--3">
                            <span className="heading__sub align--center display--block">
                                A kezdetem
                            </span>
                        </h2>
                        <p>Most, hogy ezt a ceruza zászlót leszögeztem, a feladatom nem kevesebb mint, hogy találjak valamit, ami van annyira izgalmas a tyúk kakis kis életemben, hogy elolvasd. Lehet rámegyek ilyen csoport terápiás módon és kiteszek egy névtáblát magamra azt bemutatkozom.</p>
                        <blockquote>Hi my name is, chicky-chicky-Zolatan2000</blockquote>
                        <p>87-es vagyok, márciusos. Jó terhes év volt anyámnak, mert egyből ketten lettem ahogy ikremmel lepörgőrúgásoztuk a placentát. Persze neki mást jelent a szülinapunk, amire van egy ötletem amit megakarok osztani. Évek óta tolom a születésnapomon muternak, hogy Boldog Szülésnapot. Meg amúgy más anyukáknak is amikor a gyerekük épp elfújja a gyertyát és arra csövezek. Nem tudom ez tud e naggyá lenni, de szerintem fair lenne. Ha belegondolsz a születésed kb full autópilótába nyomtad, nem emlékszel semmire, ha meg igen, akkor sem. A szüleidnek viszont biztos meg van, ahogy anyád kikínlódott magából, meg ahogy apád rájött (ha figyelt), hogy a lónak a patát tartozik az erősebb nemhez. </p>
                        <p className="spotify box">
                            <span>Fogok hagyni itt-ott zenéket neked, ami így elkap a témáról, vagy amit épp hallgatok amikor írok. Nem fogom mondani melyik-melyik, arra próbálj meg rájönni te.</span>
                            <iframe
                                src="https://open.spotify.com/embed/track/1SyQ6t9RdRBK0QUCS6a797?utm_source=generator&theme=0"
                                width="100%" height="152" frameBorder="0" allowFullScreen=""
                                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                loading="lazy"></iframe>
                        </p>
                        <p>Vissza a márciusi ifjakhoz, elkezdtük az élet. Nővérem verhetetlen az ilyen szó-”vicc”-eskedésben, azért hagytam így a mondat végét. Ja, igen, ebből következik, hogy van egy növérem is, két évvel idősebb nálam. Az iker bro és köztem percek vannak, ha ezen agyalnál, én törtem az utat, de ettől nem vagyok öregebb. Emberek szeretnek ráfixálódni amúgy erre a kérdésre, mármint arra, hogy melyikünk az idősebb, de ez nekem csak azt mutatja, hogy nem megy át mit jelent az ikerség. Van akinek muszáj ráerőltetni a konvenciós felállást mindenre mielőtt még emiatt behorpad az univerzum. Hogy lehetne ezért hibáztatni őket?</p>
                        <p>Nálam nem az volt, hogy az idősebb tesó cuccai landoltak rajtam (de azért simán lehoztam volna lányruhában), hanem az, hogy volt egy klónom aki ugyanazokat a ruhákat kapta mint én. Anyám meg Mamám ráhímeztek egy Z-t a cuccaimra, tesóm meg mindenre kapott egy A-t, mert úgy hívják Tibor. Na jó, nem, a neve Ati. Én voltam a kis telepi Zorró a Z-vel, egy szuperhős akinek a különleges képességei a szotyiköpködés meg a tenyérbemászás fejjel.</p>
                        <blockquote>Békásmegyeren nőttem fel, az utcán tekeregtem mert az tuti hely.<span>A Fess PrinZ</span></blockquote>
                        <p>A Békási lakótelep volt a fészek. Mikor hetedikes lettem, a szüleim elkezdtek Fóton építeni egy házat, amit mivel elég nagy all-in volt a családi kasszánknak, mindenki, mindennap épített, amíg sikerült befejezni. Innentől Fóton nyomtam ahol találkoztam a legjobb Diával. Pár év után beköltöztünk Angyalföldre, más nevén, Fos Angeles-re (ami Gagyiforniában található). Már jó rég házasok vagyunk, és 18 éve együtt. Idén fordult át, hogy már többet voltunk egymás mellett mint külön. Ebből következik a korom amit matekolj ki ha érdekel, én már egy ideje leszarom, nem is értem, hogy jön ki ez az eredmény amúgyse. Van egy cicánk Nancy, és tudom mindenki így van vele, de ő a legjobb kis szőrös haver a világon. Minden másik se nem ilyen szép meg okos se nem.</p>
                        <p>Most azért előre fogom csévélni a szallagot mert ez nem egy memoár, csak akartam valami háterret adni. Ha lesz még, hogy elkap a nosztalgia, dobok panel profi sztorikat, ha éled. Mondjuk ez nem egy párbeszéd, szóval tudja vér mit szeretnél, de azért majd valahogy megoldom, tippelek.</p>
                        <h2 className="heading heading--3">
                            <span className="heading__sub align--center display--block">
                                Mostanság
                            </span>
                        </h2>
                        <p>A foglalkozásom az nem író, és tudom, miután ezeket a mesterien egymás mögé tett szavakat emészted egy ideje, ez most sokkolóan hatott. Ülj le, nyugi, igyál egy pohár vizet. Product Designer vagyok (termék tervező de ez így meh), ami kicsit ilyen Chandler (RIP) meló, nem sokan értik meg, de egy rendes karrier amúgy (ha az AI is úgy akarja). Képernyőre tervezek felhasználói felületeket, többnyire nagy vállalatoknak. Ja szarul hangzik, néha olyan is, de 50 százalék felett van, hogy még szeretem csinálni. Ez egy szabály amit követek, ha a mérleg átbillen, és valamit jobban vagy ugyannyira rühellek mint amennyire nem, akkor már ott se vagyok, a csácsumitot kívánok.</p>
                        <p>Ha nem a melót akkor nyomom az Astanga Jógát, időnként a kosarat, meg általában a szővegírást, mert kis szaros korom óta rappelek. Gondolsz amit akarsz, amit nyomok az messze nem szar, nem úgy mint a rap műfaj az utóbbi években. Énésén a band név, de volt, hogy WhoTheFunk is volt, és még biztos lesz más is. Tesómmal mostanában elúszunk a dolgainkkal, két éve kiadtunk a harmadik nagy lemezünk, azóta meg csak tervek vannak (meg néhány single). Ja igen az iker bro-val nyomom a világmeghódító rappeket magyarul, mert az világnyelv, akár milliók is beszélik, és amilyenek a Magyarok, mind egyszerre beszéli. Ha hallgatsz minket akkor annyit, hogy nincs vége, lesz még majd, és köszi písz. </p>
                        <p>Egy ideig Londonban laktunk Diával meg Nancyvel, ahonnan a Covid alatt visszajöttünk Budapestre. Azóta ingázgatok, annak függvényében mennyit kíván a melóhelyem azzal szopatni, hogy menjek be egy zajos-fos irodába kávézgatni, ahelyett, hogy kényelmesen csapatom a munkám. Jelenleg amúgy nem nyomaszt egy főnök sem, mivel épp keresgélek valami új helyet. A korábbi munkámat bekellett dobnom, mert nem húztam ki a nyugodtan-dolgozz-remote-Indiából kártyát a vállalati retektivityn. Valamit valamiért gondolom.</p>
                        <p>Ez így elég lesz a kezdethez. Remélem, hogy nem csak a WC-n fogsz olvasni, de ha ez most pont így van, akkor hajrá spanom! Menni fog! A kéz mosást nem elfelejteni. Biztos voltál már úgy, hogy lazán felért valami célod elérésével, a budin elért nagy dolog érzése. Olyan az mint amilyen ez a blog lesz, csak állok majd felette, keresem, hogy ez vagy az vajon honnan kerülhetett bele, majd öblitek egyet miközben azzal nyugtázom amit elértem, hogy: <b>Az igen, ember</b>.</p>
                    </article>
                :
                    <>
                        <article className="blog__article">
                            <hgroup className="blog__article-header">
                                <h2 className="heading heading--3 no-margin--bottom">Yo</h2>
                            </hgroup>
                            <p>I'm here, in Budapest. I'm not sure if BP is as exciting as India. Okay, I know, and no-no-no. Here, tense foreheads meet many years of accumulated stress when you hear honking. But in India, if you don't hear a honk, you've probably gone deaf.</p>
                            <p>They don't honk to make your dear mother do hiccups, but out of love... because they want to go, and you're gawking like a fool, and they don't want to dirty your face with their filthy wheels. If you think about it, that's pretty considerate.</p>
                            <p className="box box--bordered"><b>Wot?!</b><br />
                                Before you move on or leave, or whatever you're planning to do, let me tell you what this is all about. I was in India again, doing yoga in Mysore for two months for the second time. This time, I wrote a blog about some funny shit, culture, yoga, adventures. You can find the Cliche Tales here (that's the title): <a href="https://www.zoltanbank.com/cliche-blog">www.zoltanbank.com/cliche-blog.</a><br />My babbling rolls in both Hungarian and English, and you can choose at the beginning (just like here). Quite a lot of people got hooked on it, and many, including my mom, just to name someone, said they'd read more if I returned home. I've been sitting on this thought for a while, but I've grown fond of this scribbling. It’s 1 AM, and I'm more bored of streaming crap than politics, so I'd rather waste my time on this. Not like that, jeez! I mean, I'm putting my heart and soul into these lines for you, for sure. If this isn't your thing, then do something else, but if you like it, subscribe, and I'll let you know if there's more. Of course, it's also cool if you check back occasionally. Whatever works for you, we're not going to fall out over this.
                            </p>
                            <h2 className="heading heading--3">
                            <span className="heading__sub align--center display--block">
                                My beginning
                            </span>
                            </h2>
                            <p>Now that I've planted this pencil flag, my task is nothing less than finding something in my chicken-crap life exciting enough for you to read. Maybe I'll go therapy-group style, put on a name tag, and introduce myself.</p>
                            <blockquote>Hi my name is, chicky-chicky-Zolatan2000</blockquote>
                            <p>Born in '87, in March. Tough year for my mom because I became two as my twin and I kicked the placenta around. Of course, our birthday means something different to her, and I have an idea I want to share. For years, on my birthday, I've been telling my mom, "Happy Birthing Day." And other moms, too, when their kid is blowing out the candles, and I happen to be there. I’m not sure if this could become a thing, but it seems fair. Think about it: your birth was basically on autopilot; you don't remember anything, and even if you do, you don't. But your parents surely remember how your mom struggled to bring you into the world and how your dad realised (if he was paying attention) that true strength belongs to the other sex.</p>
                            <p className="spotify box">
                                <span>I'll leave you some music here and there, something that catches my attention on the topic, or what I'm listening to while writing. I won't say which is which, you try to figure that out.</span>
                                <iframe
                                        src="https://open.spotify.com/embed/track/1SyQ6t9RdRBK0QUCS6a797?utm_source=generator&theme=0"
                                        width="100%" height="152" frameBorder="0" allowFullScreen=""
                                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                        loading="lazy"></iframe>
                            </p>
                            <p>Back to us, March-born folks, we started life. My sister is two years older. Oh yes, this means I have an older sister. There are just minutes between my twin brother and me if you're wondering. I broke the way, but that doesn't make me older. People tend to fixate on this question, like which one of us is older, but to me, it just shows they don't get what being a twin means. Some people must impose conventional setups on everything before the universe implodes. How can you blame them?</p>
                            <p>For me, it wasn't about wearing my older sibling's hand-me-downs (though I could have rocked girl's clothes) but about having a clone who got the same clothes as me. Mom and Grandma embroidered a Z on my stuff, and my bro got an A on everything 'cause his name is Tibor. No, just kidding, it's Ati. I was the little neighbourhood Zorro with the Z, a superhero whose special powers were spitting sunflower seeds and having the most slap-worthy face.</p>
                            <blockquote>In Békásmegyer born and raised, on the playground is where I spent most of my days<span>The Smash PrinZ</span></blockquote>
                            <p>The Békásmegyer panel block was my nest. When I got to seventh grade, my parents started building a house in Fót (a village at the outskirts of Budapest), which, being a big all-in for our family budget, meant everyone, every day, was building it until it was done. From then on, I did my thing in Fót, where I met the best Dia. A few years later, we moved to Angyalföld (Angels Land), also known as Not Angeles (located in Crapifornia). We've been married for a long time and together for 18 years. This year, it turned out that we've been together longer than apart. Figure out my age from that if you're interested, I've stopped caring, and don't understand how that number comes up anyway. We have a cat, Nancy, and I know everyone feels this way, but she's the best furry pal in the world. No other is as beautiful or smart.</p>
                            <p>But I'll fast forward because this isn't a memoir, just wanted to give some background. If nostalgia hits me again, I'll drop some old-time stories if you're up for it. Though this isn't a conversation, so who knows what you'd like? I'll figure something out, I guess.</p>
                            <h2 className="heading heading--3">
                            <span className="heading__sub align--center display--block">
                                Nowadays
                            </span>
                            </h2>
                            <p>My job isn't a writer, and I know, after digesting these masterfully arranged words, that's shocking. Sit down, relax, and have a glass of water. I'm a Product Designer (sounds meh, but it's a decent career if AI allows it). I design user interfaces for screens, mostly for big companies. Yeah, sounds boring, sometimes it is, but I enjoy it more than 50% of the time. That's a rule I follow: if I start hating it as much or more than I like it, I'm out, bon voyage to all that crap.</p>
                            <p>When not working, I do Ashtanga Yoga, play basketball occasionally, and generally write because I've been rapping since I was a brat. Think what you want; what I do is far from crap, unlike the rap genre these days. My band's name is Énésén (Me & I). Lately, my bro and I have drifted in our stuff; we released our third big album two years ago, and since then, just plans (and a few singles). Oh yeah, I do world-conquering raps in Hungarian with my twin bro because that's a global language spoken by millions, and as I know Hungarians, they all speak it at once. If you listen to us, just know it’s not over; there will be more, and thanks, peace.</p>
                            <p>We lived in London for a while with Dia and Nancy and came back to Budapest during Covid. Currently, no boss is pressuring me, as I'm looking for a new place. I had left my previous job because I couldn't pull off the calmly-work-remote-from-India card in corporate stupidity. You win some, you lose some, I guess.</p>
                            <p>That should be enough for a start. I hope you're not just reading this in the toilet, but if that's the case, go for it, buddy! You got this! Don't forget to wash your hands. Surely, you've had moments that feel as great as achieving something big in the bathroom. It's like this blog: I'll stand over it, wondering where this or that came from, then flush it down while acknowledging my achievement with a: <b>That's right, man</b>.</p>
                        </article>
                    </>
            }
        </>
    );
}

export default Post1;
