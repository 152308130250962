import React, {useRef, useEffect, useState} from "react";
import {isMobile} from 'react-device-detect';
import { Helmet } from 'react-helmet-async';
import {
    motion,
    useScroll,
    useSpring,
    useTransform,
    MotionValue, useInView, useAnimation
} from "framer-motion";
import { Link } from "react-router-dom";
import pageTransition, {pageTransitionVariants} from "../components/PageTransition";
import {routesConfig} from "../config/routes";
import AnimatedLetters from "../transitions/AnimatedLetters";
import SlideTransition from "../transitions/SlideTransition";
import {pageTransitionDelay, verticalScrollOffsetMarginLarge} from "../transitions/TransitionConstants";

function useParallax(value: MotionValue<number>, distance: number) {
    return useTransform(value, [0, 1], [-distance, distance]);
}

function Slide({ data }) {
    const ref = useRef(null);
    const isInView = useInView(ref, {margin: verticalScrollOffsetMarginLarge});
    const [slideActive, setSlideActive] = useState(0);
    const { scrollYProgress } = useScroll({ target: ref });
    const y = useParallax(scrollYProgress, isMobile ? 30 : 300);
    const htmlRoot = document.getElementsByTagName("html")[0];
    const path = routesConfig.filter(item => item.name.includes(data.title))[0].path;
    const pathName = path.replace(/\//g,'');

    const controls = useAnimation();

    const imageVariants = {
        hidden: {
            opacity: 1
        },
        visible: {
            opacity: 1
        }
    }

    const dataListVariants = {
        hidden: {
            transition: {
                staggerChildren: 0.1,
                staggerDirection: -1
            }
        },
        visible: {
            transition: {
                delayChildren: .75,
                staggerChildren: 0.1,
                staggerDirection: 1
            }
        }
    };

    const dataListItemVariants = {
        hidden: {
            opacity: 0,
            top: -40
        },
        visible: {
            opacity: 1,
            top: 0
        }
    };

    useEffect(() => {
        if (isInView) {
            setSlideActive(true);
            controls.start("visible");
            htmlRoot.setAttribute("section", data.title.toLowerCase());
        } else {
            controls.start("hidden");
            setSlideActive(false);
        }
    }, [ref, controls, isInView]);

    return (
        <>
            <Helmet>
                <title>Projects</title>
            </Helmet>
            <section className={`projects__section projects__section--${data.title.toLowerCase()} ${slideActive ? 'projects__section--active' : ''}`}>
                {isMobile ? (
                    <div className="projects__section-background">
                        <img src={data.image}
                             srcSet={`${data.image2x ? data.image2x + " 2x" : ""}`}
                             alt={`Project: ${data.title}`} />
                        {/*<img src={data.svg} alt={`Project: ${data.title}`} />*/}
                    </div>
                ) : (
                    <motion.div className="projects__section-background"
                                initial="visible"
                                animate={controls}
                                variants={imageVariants}
                    >
                        <img src={data.image}
                             srcSet={`${data.image2x ? data.image2x + " 2x" : ""}`}
                             alt={`Project: ${data.title}`} />
                        {/*<img src={data.svg} alt={`Project: ${data.title}`} />*/}
                    </motion.div>
                )}
                <div className="projects__section-content">
                    <motion.h2 className="heading heading--2 transform--uppercase projects__section-title" style={{y}}>
                        <AnimatedLetters word={data.title} />
                    </motion.h2>

                    {!isMobile &&
                        <motion.ul className="projects__section-list" variants={dataListVariants} animate={controls}>
                            {data.words.map((word, index) => (
                                <motion.li key={data.title + word.text + index} variants={dataListItemVariants} style={{
                                    translateX: word.positionX,
                                    translateY: word.positionY,
                                    rotate: word.rotate
                                }}>
                                    {word.text}
                                </motion.li>
                            ))}
                        </motion.ul>
                    }
                    <motion.div className="projects__section-button-container" ref={ref} style={isMobile ? '' : {y}}>
                        <Link to={path} className="button button--split">
                            <span className="button__arrow" />
                            <span className="button__text">Let's go</span>
                            <span className="button__anim">
                            <span>Check the {data.type}</span>
                            <span>Check the {data.type}</span>
                        </span>
                            <span className="button__placeholder">Check the {data.type}</span>
                        </Link>
                    </motion.div>
                </div>
            </section>
        </>
    );
}


const Projects = () => {
    const { scrollYProgress } = useScroll();

    const slides = [
        {
            svg: '/images/projects/bt-home.svg',
            image:"../images/projects/bt-home.min.png",
            image2x:"../images/projects/bt-home@2x.min.png",
            type: "case study",
            title: 'End-to-End',
            words: [
                {
                    text: 'BT',
                    positionX: '-25vw',
                    positionY: '-25vh',
                    rotate: '90deg'
                },
                {
                    text: 'UX Research',
                    positionX: '-30vw',
                    positionY: '20vh',
                    rotate: '90deg'
                },
                {
                    text: 'Design System',
                    positionX: '30vw',
                    positionY: '10vh',
                    rotate: '90deg'
                },
                {
                    text: 'Product Design',
                    positionX: '40vw',
                    positionY: '-20vh',
                    rotate: '90deg'
                }
            ]
        },
        {
            svg: '/images/projects/quick-pay-home.svg',
            image:"../images/projects/quick-pay-home.min.png",
            image2x:"../images/projects/quick-pay-home@2x.min.png",
            type: "product design",
            title: 'Quick Pay',
            words: [
                {
                    text: 'Startup',
                    positionX: '-20vw',
                    positionY: '-20vh',
                    rotate: '90deg'
                },
                {
                    text: 'Concept Design',
                    positionX: '-33vw',
                    positionY: '10vh',
                    rotate: '90deg'
                },
                {
                    text: 'Pitch Deck',
                    positionX: '28vw',
                    positionY: '14vh',
                    rotate: '90deg'
                },
                {
                    text: 'Mobile App',
                    positionX: '33vw',
                    positionY: '-22vh',
                    rotate: '90deg'
                }
            ]
        },
        {
            svg: '/images/projects/wrapless-home.svg',
            image:"../images/projects/wrapless-home.min.png",
            image2x:"../images/projects/wrapless-home@2x.min.png",
            type: "case study",
            title: 'Wrapless',
            words: [
                {
                    text: 'Case study',
                    positionX: '-27vw',
                    positionY: '13vh',
                    rotate: '90deg'
                },
                {
                    text: 'Startup',
                    positionX: '-33vw',
                    positionY: '-15vh',
                    rotate: '90deg'
                },
                {
                    text: 'Service Design',
                    positionX: '35vw',
                    positionY: '13vh',
                    rotate: '90deg'
                },
                {
                    text: 'Website',
                    positionX: '30vw',
                    positionY: '-24vh',
                    rotate: '90deg'
                }
            ]
        }
    ]

    return (
        <div className="page projects">
            <motion.div
                variants={pageTransitionVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
            >
                <>
                    {slides.map((slide, index) => (
                        <Slide key={slide.title + index} data={slide} />
                    ))}
                    {/*<motion.div className="progress" style={{ scaleX }} />*/}
                </>
            </motion.div>
        </div>
    );
}

export default Projects;
// export default pageTransition(Projects);
